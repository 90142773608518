import React, {Component} from "react";
import Cookies from 'universal-cookie';
import './List.scss';
const cookies = new Cookies();

// let lang = cookies.get('language')
class Report extends Component {
    constructor(props) {
        super(props);
        this.state = {
            domain : global.Api.domain_url(),
            lang : cookies.get('language')
        }
    };

    componentDidMount() {
        if (this.state.lang === 'ge'){
            this.setState({
                lang:'de'
            })
          }else if (this.state.lang === 'sp'){
              this.setState({
                  lang:'es'
              })
          }else if (this.state.lang === 'ma'){
            this.setState({
                lang:'ms'
            })
          }else if (this.state.lang === 'lt'){
            this.setState({
                lang:'it'
            })
          }else if (this.state.lang === 'tw'){
            this.setState({
                lang:'zh_TW'
            })
          }else if (this.state.lang === 'jp'){
            this.setState({
                lang:'ja'
            })
          }else if (this.state.lang === 'po'){
            this.setState({
                lang:'pl'
            })
          }else if (this.state.lang === 'por'){
            this.setState({
                lang:'pt'
            })
          }else if (this.state.lang === 'dk'){
            this.setState({
                lang:'da'
            })
          }
         
    }


    render() {
        if (this.state.domain ==='iptirescanner.com'){
            return (   
                <div style={{margin:'0 auto',textAlign:'center',display:'flex'}}>
                    <div style={{textAlign:'left',width:"30%"}}> </div>
                    <div style={{textAlign:'center',width:"700px",height:"100%",}}>             
                        <iframe 
                        style={{height:"1000px",width:"700px",border: '0px'}} 
                        sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                        scrolling="auto"
                        src={`http://customer.${this.state.domain}/report/${this.props.match.params.uuid}/?lang=${this.state.lang}`} >

                        </iframe>
                    </div>
                    <div style={{textAlign:'right',width:"30%"}}> </div>
                </div>
                )
        }else{
            return (   
                <div style={{margin:'0 auto',textAlign:'center',display:'flex'}}>
                    <div style={{textAlign:'left',width:"30%"}}> </div>
                    <div style={{textAlign:'center',width:"700px",height:"100%",}}>             
                        <iframe 
                        style={{height:"1000px",width:"700px",border: '0px'}} 
                        sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                        scrolling="auto"
                        src={`https://customer.${this.state.domain}/report/${this.props.match.params.uuid}/?lang=${this.state.lang}`} >

                        </iframe>
                    </div>
                    <div style={{textAlign:'right',width:"30%"}}> </div>
                </div>
                )
        }

    }
}

export default Report;