import React, { Component } from 'react';
import { Card, Form, Layout, Input, text,Button, message, Select, Divider} from 'antd';
import './Setting.scss';
import Cookies from 'universal-cookie';
import intl from 'react-intl-universal';
import { Link } from "react-router-dom";
const cookies = new Cookies();
const { Option } = Select;

const { Header, Content, } = Layout;
class Setting extends Component {
  constructor(props){
    super();
   
    this.state = {
      isShow:"none",
      inputWidth:"100%",
      areaCode:'86',
      color1:"#006dd3",
      color2:"#A8A8A8",
      domain : global.Api.domain_url(),
      store: null,
      newpass:'',
      logo:'',
    }
  }

  componentDidMount(){
    // this.getStore();
  }

  // getStore(){
  //   global.Api.getStore().then(res=>{
  //     if (res.data.code === 0) {
  //       let _data = res.data.data;
  //       this.setState({store:_data.store});
  //       // if(this.props.location.pathname === '/login'){
  //       //   this.props.history.push('/reports')
  //       // }
  //       this.props.form.setFieldsValue({
  //         name: _data.store.name,
  //         address: _data.store.address,
  //         telephone: _data.store.contact,
  //         email: _data.store.email,
  //         wear_threshold_low: _data.store.wear_threshold_low,
  //         wear_threshold_high: _data.store.wear_threshold_high,
  //         logo:_data.store.logo,
  //       })
  //     }
  //   })
  // }

  handleChange = (value) => {
  value = Number(value);
  return value
  }

handleChangeOversea = (value) => {
  this.setState({
    areaCode:value
  })
}
  showSelect = (code)=>{
    if(code===0){
      this.setState({
            isShow:"none",
            inputWidth:"100%",
            color1:"#006dd3",
            color2:"#A8A8A8",
      })
    }else {
      this.setState(
          {
            isShow:"inline-block",
            inputWidth:"70%",
            color1:"#A8A8A8",
            color2:"#006dd3"
        }
      )
    }

  }


  goback(){
    window.history.back();
  }

  handleFileChange =(e) =>{
    console.log(e.target.value);
    this.setState({
      newpass:e.target.value,
    })
  }
     // On file upload (click the upload button)
   onConfirm = () => {
    if (this.state.newpass!==null && this.state.newpass!=='' && this.state.newpass.length >5){
      // Request made to the backend api
      let param = {
        newpass: this.state.newpass
      }
      global.Api.updatePassword(param).then(res=>{
        if(res.data.code === 0){
          // let _data = res.data.data;  
          message.info('Update successfully!');
          // this.props.history.push('/Reports');
          this.goback();        
          return
        }
        message.error(res.data.message);
      })
    }else
      message.error('The length of the new password should not less than 6 ! ');
  };

  render() {
   
    return (
      <Layout className="reports">
        <Header style={{position: 'fixed', zIndex: 1, width: '100%',height:'120px',backgroundColor:'#3385ff',color:"#fff",}}>
          <div style={{marginRight:"20px",color:"#fff"}} >
          <span style={{fontSize:"20px",color:"#fff"}}>Handy Tyre Scanner</span>
          {/* <img class="logo img-fluid" src="https://mobilhubdiagnostics.com/img/logo-1667829529.jpg" alt='' width="228" height="38" /> */}
          </div>
          <Button type="link" style={{marginLeft:"10px",color:"#fff",fontSize:'15px',fontWeight:'500',}} ><Link to="/dashboarddealer">{intl.get('dashboard')}</Link></Button>
          <Button type="link" style={{marginLeft:"10px",color:"#fff",fontSize:'15px',fontWeight:'500',}} ><Link to="/dealerlist">{intl.get('reports')}</Link></Button>
          {/* <Button type="link" style={{marginLeft:"10px",color:"#fff",fontSize:'15px',fontWeight:'500',}} ><Link to="/devices">{intl.get('devices')}</Link></Button> */}
          <Button type="link" style={{marginLeft:"10px",color:"#fff",fontSize:'15px',fontWeight:'500',}} ><Link to="/storelist">{intl.get('storemanagement')}</Link></Button>
          
          <Button type="link" style={{marginRight:"10px",color:"#fff",fontSize:'15px',fontWeight:'500',}} ><Link to="/settingdealer">{intl.get('setting')}</Link></Button>
          
        </Header>
        <Content style={{ padding: '0 50px', marginTop:'110px'}}>
        <div className="setting" style={{ padding: '0 50px', marginTop:'20px'}}>            
            <span style={{marginLeft:"0px"}}>Please input your new login password</span>
        </div>
        <div className="setting">      
              <Input   onChange={this.handleFileChange} style={{width:"500px"}}/>              
        </div>
          <div className="setting">
            <button onClick={this.onConfirm}>
              Confirm
            </button>
            <button onClick={this.goback} style={{marginLeft:"50px"}}>
              Back
            </button>
            </div>
        </Content>
      </Layout>
    );
  }
}

export default Form.create()(Setting);
