import React, {Component} from "react";
import './css/TyrehubTitle.scss'
class TyrehubTitle extends Component{
    constructor(props) {
        super(props);
        this.state ={};
    }

    render() {
        return (
            <div className="ty-tl">
                 <p className="ty-title"><span>{this.props.content}</span></p>
            </div>
        )
    }
}

export default TyrehubTitle;