import React, { Component } from 'react';
import { Layout,Card, Form, Icon, Input, Button, message, Select, Divider} from 'antd';
import './Login.scss';
import Cookies from 'universal-cookie';
import intl from 'react-intl-universal';
import { Link } from "react-router-dom";
import android_oversea from "../../assets/app/android_oversea.png";
import android_china from "../../assets/app/android_china.png";

const cookies = new Cookies();
const { Option } = Select;
const { Header, Content, } = Layout;
class Login extends Component {
  constructor(props){
    super();
    this.submit = this.submit.bind(this);
    this.state = {
      isShow:"none",
      inputWidth:"100%",
      areaCode:'86',
      color1:"#006dd3",
      color2:"#A8A8A8",
      domain : global.Api.domain_url(),
      role:"2",
      app_url: "../../assets/app/android_china.png",
    }
  }

  handleChange = (value) => {
  value = Number(value);
  return value
}

handleChangeOversea = (value) => {
  this.setState({
    areaCode:value
  })
}

handleOptionChange = (e) => {
  this.setState({
    role: e.target.value
  });
}

showSelect = (code)=>{
    if(code===0){
      this.setState({
            isShow:"none",
            inputWidth:"100%",
            color1:"#006dd3",
            color2:"#A8A8A8",
      })
    }else {
      this.setState(
          {
            isShow:"inline-block",
            inputWidth:"70%",
            color1:"#A8A8A8",
            color2:"#006dd3"
        }
      )
    }

  }
  submit(e){
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      values["role"]=this.state.role
      // if (this.state.domain ==='tyrehubtech.com'){
      //   let {username, password} = values
      //   if(this.state.isShow === "inline-block" ){
      //     let strRegex = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/;
      //     if (strRegex.test(username)){
      //       message.error('Please enter your mobile phone number');
      //       return
      //     }
      //     values.username = this.state.areaCode + '-' + values.username
      //   }
      // }

      if (!err) {
        global.Api.login(values).then(res=>{
          if(res.data.code === 0){
            cookies.set('tyrehub-pc',res.data.data.token)
            message.success(intl.get('login_su')+"!");
            this.props.history.push('/dashboard')
            // window.location.href = "https://dashboard."+ global.Api.domain_url()+"/dashboard";
            return
          }
          message.error(res.data.message);
        })
      }
    });
  }

  componentDidMount(){
    if (this.state.domain ==='taihapo.com.cn')
      this.setState({
        app_url: android_china
      })
    else
      this.setState({
        app_url: android_oversea
      })
  }
  
  gotoDealer() {
    this.props.history.push('/dealer')
    // window.location.href = "https://dashboard."+ global.Api.domain_url()+"/dealer";
  }

  downloadApp(){
    // global.Api.getApp().then(res=>{
    //       if(res.data.code === 0){
    //         let url = res.data.data.url;
    //         window.open(url);
    //       }else {
    //          message.error(res.data.message);
    //       }
    //     })
    //http://customer.tyrehubtech.com/download
    let url = "https://customer." + global.Api.domain_url()+"/download";
    window.open(url);
  }


  render() {
    const { getFieldDecorator } = this.props.form;
    
     
    // if (this.state.domain ==='tyrehubtech.com')
    //   return (
    //   <div className="login">
    //       <Button type="primary" style={{position:"absolute",left:"20px",top:"18px"}} onClick={this.downloadApp}>{intl.get('awd')}</Button>
    //       <Card title={intl.get('login')} style={{ width: 500 }}>
    //       <Form onSubmit={this.submit}>
    //         <Form.Item >
    //           <Button type="text" style={{outline:'none',border:'none', color: this.state.color1}} onClick={()=>this.showSelect(0)}>Email</Button>
    //           <Button type="text" style={{outline:'none', border:'none', color:this.state.color2}} onClick={()=>this.showSelect(1)}>Phone</Button>
    //           <Divider  style={{margin:0}}/>
    //       </Form.Item>
    //         <Form.Item >
    //          <Input.Group compact >
    //           <Select defaultValue="86" style={{ Width: '30%', display: this.state.isShow }} onChange={this.handleChangeOversea}>
    //             <Option value="86">+86 China</Option>
    //             <Option value="1">+1 America</Option>
    //             <Option value="34">+34 Spain</Option>
    //             <Option value="49">+49 Germany</Option>
    //             <Option value="55">+55 Brazil</Option>                
    //             <Option value="60">+60 Malaysia</Option>
    //           </Select>
    //               {getFieldDecorator('username', {
    //             rules: [{ required: true, message: intl.get('input_name') }],
    //           })(
    //         <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)'}} />} placeholder={intl.get('username')} style={{ maxWidth: this.state.inputWidth }}/> )}
    //     </Input.Group>
    //         </Form.Item>
    //         <Form.Item>
    //           {getFieldDecorator('password', {
    //             rules: [{ required: true, message: intl.get('input_password') }],
    //           })(
    //             <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder={intl.get('password')} />
    //           )}
    //         </Form.Item>
    //         <Form.Item>
    //           <Button type="primary" htmlType="submit" style={{width:'100%'}}>
    //           {intl.get('login')}
    //           </Button>
    //         </Form.Item>
    //       </Form>
    //       </Card>
    //     </div>
    //   );
    // else 
    if (this.state.domain ==='iptirescanner.com')
      
    return (
   
      <div className="login">
        {/* <Button type="primary" style={{position:"absolute",left:"20px",top:"18px"}} onClick={this.downloadApp}>{intl.get('awd')}</Button> */}
         <Card title={intl.get('login')} style={{ width: 500 }}>
        <Form onSubmit={this.submit}>

          {/* <Form.Item >
            <Button type="text" style={{outline:'none',border:'none', color: this.state.color1}} onClick={()=>this.showSelect(0)}>Email</Button>
            <Button type="text" style={{outline:'none', border:'none', color:this.state.color2}} onClick={()=>this.showSelect(1)}>Phone</Button>
            <Divider  style={{margin:0}}/>
        </Form.Item> */}
          <Form.Item >
           <Input.Group compact >
            {/* <Select defaultValue="86" style={{ Width: '30%', display: this.state.isShow }} onChange={this.handleChangeOversea}>
              <Option value="86">+86 China</Option>
              <Option value="1">+1 America</Option>
              <Option value="49">+49 Germany</Option>
              <Option value="34">+34 Spain</Option>
              <Option value="60">+60 Malaysia</Option>
            </Select> */}
                {getFieldDecorator('username', {
              rules: [{ required: true, message: intl.get('input_name') }],
            })(
          <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)'}} />} placeholder={intl.get('username')} style={{ maxWidth: this.state.inputWidth }}/> )}
      </Input.Group>
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: intl.get('input_password') }],
            })(
              <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder={intl.get('password')} />
            )}
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{width:'100%'}}>
            {intl.get('login')}
            </Button>
          </Form.Item>
        </Form>
        </Card>
      </div>
    );
    else {
      return (
        <div className="login">
          {/* <Button type="primary" style={{position:"absolute",left:"20px",top:"18px"}} onClick={this.downloadApp}>{intl.get('awd')}</Button> */}
          <div style={{position:"absolute",left:"20px",top:"18px",display:'flex',flexDirection:'column', alignItems:'center',justifyContent:'center'}} >
            <img src={this.state.app_url} alt="Android APP" style={{width:"100px",heigth:"100px"}} />
            <span style={{fontSize:"12px"}}>Android APP</span>
          </div>
          <Card title={intl.get('login')} style={{ width: 500 }}>
          <Form onSubmit={this.submit}>         
            {/* <Form.Item>
              {
                getFieldDecorator('role',{ rules:[{ required: true }]})(
                <Select  defaultValue="2" onChange={this.handleChange} placeholder={intl.get('role')} >
                      <Option  value= "2">{intl.get('manager')}</Option>
                      <Option  value= "3">{intl.get('assistant')}</Option>
                      <Option   value= "1">{intl.get('merchant')}</Option>
                      <Option  value= "2">{intl.get('store_user')}</Option>
                      <Option  value= "1">{intl.get('distributor')}</Option>
                </Select>
                )
              }
            </Form.Item> */}
            <Form.Item>
              {getFieldDecorator('username', {
                rules: [{ required: true, message: intl.get('input_name') }],
              })(
                <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder='Your account email or phone(ex:56-954036133) ' />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('password', {
                rules: [{ required: true, message: intl.get('input_password') }],
              })(
                <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder={intl.get('password')} />
              )}
            </Form.Item>

            <Form.Item>
              <div style={{ alignItems: 'right',justifyContent:'right',display:'flex' }}>
                {/* <label style={{ fontSize: '13px' }}>
                <input type="radio" value="2" checked={this.state.role === '2'}  style={{ marginRight: '10px'}} onChange={this.handleOptionChange} />
                {intl.get('store_user')}
                </label>
                <label style={{ marginLeft: '30px',fontSize: '13px' }}>
                <input type="radio" value="1" checked={this.state.role === '1'} style={{ marginRight: '10px' }} onChange={this.handleOptionChange} />
                {intl.get('distributor')}
                </label> */}
                
                <Link to="/dealer"><span>{intl.get('distributor')}</span></Link>
                {/* <Button type="primary"  onClick={()=>this.gotoDealer}>{intl.get('distributor')}</Button> */}
              </div>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" style={{width:'100%'}}>
              {intl.get('login')}
              </Button>
            </Form.Item>
          </Form>
          </Card>
        </div>
      );
    }
  }
}

export default Form.create()(Login);
