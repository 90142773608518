import React, {Component} from "react";
import './css/TyrehubCar.scss'

class TyrehubCar extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {lf_degree, rf_degree, lb_degree, rb_degree, ecc} = this.props.data;

        const returnColor = (content) => {
            switch (content) {
                case 'low':
                    if (ecc === 'ecc') {
                        return require("../assets/tyre-low.png");
                    } else {
                        return require("../assets/tyre-green.png");
                    }

                case 'middle':
                    if (ecc === 'ecc') {
                        return require("../assets/tyre-middle.png");
                    } else {
                        return require("../assets/tyre-yellow.png");
                    }

                case 'high':
                    if (ecc === 'ecc') {
                        return require("../assets/tyre-high.png");
                    } else {
                        return require("../assets/tyre-red.png");
                    }
                default:
                    return require("../assets/tyre-green.png");


            }

        };
        return (
            <div className="tyre-con">
                <div className="tyre-lf"><img src={returnColor(lf_degree)} alt=""/></div>
                <div className="tyre-rf"><img src={returnColor(rf_degree)} alt=""/></div>
                <div className="tyre-car"><img src={require("../assets/car.png")} alt=""/></div>
                <div className="tyre-lb"><img src={returnColor(lb_degree)} alt=""/></div>
                <div className="tyre-rb"><img src={returnColor(rb_degree)} alt=""/></div>
            </div>
        )
    }

}

export default TyrehubCar;