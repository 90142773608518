import React, {Component} from 'react';
import "./css/DillHeader.scss"
import intl from 'react-intl-universal';
import QRCode from "qrcode.react";

class DillHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }


    render() {
        // ʱ���ʽ��
        const formatDate = (date, fmt) => {
            var currentDate = new Date(date);
            var o = {
                "M+": currentDate.getMonth() + 1, //�·�
                "d+": currentDate.getDate(), //��
                "h+": currentDate.getHours(), //Сʱ
                "m+": currentDate.getMinutes(), //��
                "s+": currentDate.getSeconds(), //��
                "q+": Math.floor((currentDate.getMonth() + 3) / 3), //����
                "S": currentDate.getMilliseconds() //����
            };
            if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (currentDate.getFullYear() + "").substr(4 - RegExp.$1.length));
            for (var k in o)
                if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
            return fmt;
        };
        let {logo, uuid, language, device_time, plate, manufacturer, car, porduced_year} = this.props.data;
        return (
            <div className="dill-header">
                <img src={logo ? logo : require("../assets/logo.png")} alt=""
                     style={{width: 186, objectFit: "contain"}}/>
                <div className="header-device">
                    <div className='item'>
                        <span className="tcl">{intl.get('plate_no')}</span><span className="ccl">{plate.toUpperCase()}</span>
                    </div>
                    <div className='item'>
                        {/*<span className="tcl">{intl.get('purpose')}</span><span className="ccl">{intl.get(purpose)}</span>*/}
                    </div>
                    <div className='item'>
                        <span className="tcl">{intl.get('device_time')}</span><span
                        className="ccl">{formatDate(device_time, 'dd/MM/yyyy hh:mm:ss')}</span>
                    </div>
                    <div className='item'>
                        <span className="tcl">{intl.get('manufacturer')}</span><span className="ccl">{manufacturer}</span>
                    </div>
                    <div className='item'>
                        <span className="tcl">{intl.get('model')}</span><span
                        className="ccl">{car}</span>
                    </div>
                    <div className='item'>
                        <span className="tcl">{intl.get('porduced_year')}</span><span className="ccl">{porduced_year}</span>
                    </div>
                </div>
                <div className="qrcode" >
                    <QRCode
                        value={`http://customer.taihapo.com.cn/report/${uuid}/?lang=${language}`}
                        size={105}
                    />
                      <div className="qr" style={{textAlign:"center"}}>{intl.get('download')}</div>
                </div>


            </div>

        )
    }

}

export default DillHeader;