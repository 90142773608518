import React from "react";

export default {
  'login':'Đăng nhập',
  'input_name':'Vui lòng nhập tên người dùng của bạn',
  'input_password':'Vui lòng nhập mật khẩu của bạn',
  'login_su':'Thành công đăng nhập',
  'role':'vai trò',
  'username':'Tên người dùng',
  'password':'Mật khẩu',
  'search_btn': 'search',
  'reports': 'Inspection Reports',
  'setting': 'System Setting',
  'store':'Cửa hàng',
  'number':'Số',
  'device_time':'Thời gian phát hiện',
  'wear_co':'Tình trạng mặc (trước - trước bên phải - lưng trái - lưng phải)',
  'ecc_co':'Tình trạng quá mức (trước bên trái - trước bên phải - hậu vệ trái - lưng phải)',
  'plate_no':'Biển số xe',
  'cus_ph':'Sự tiếp xúc',
  'car_type':'Loại xe',
  'operation':'Hoạt động',
  'view':'Xem Chi tiết',
  'manufacturer':'Làm xe',
  'porduced_year':'Năm xe',

  'print':'In(kiểm tra ảnh nền)',
  'store_name':'Cửa hàng',
  'address':'Địa chỉ Cửa hàng',
  'model':'Mẫu xe',
  'mileage':'Mileage',
  'device':'Thiết bị',
  'remark':'Nhận xét',
  'purpose':'Mục đích',
  're':'Khuyến nghị',
  'color_flag':'Phối màu',
  'wear':'Mặc',
  'brake':'Nhắc nhở khoảng cách phanh',
  'ecc':'Chênh lệch',
  'lf':'L-F',
  'lr':'L-R',
  'rf':'R-F',
  'rr':'R-R',
  'untest':'Lốp xe không được kiểm tra',
  'low':'Bình thường',
  'middle':'Ranh giới',
  'high':'Thay thế ngay lập tức',
  'avg':'Trung bình',
  'spec':"Kích thước",
  'brand':'Thương hiệu',
  'ecc_avg':'Mặc không màu',
  'trauma':'Chấn thương lốp xe',
  'zc':'Bình thường',
  'image':'zh',
  'qr':'Quét để báo cáo',
  'model_number':'Máy dò độ sâu lốp HH',
  'purp_wash':'Rửa xe & Chi tiết',
  'purp_alignment':'Chỉnh',
  'purp_replace':'Thay lốp',
  'purp_maintain':'Bảo trì',
  'warn': 'Nhắc nhở: Theo mòn lốp, khoảng cách phanh được tăng lên bởi',
  'trauma_normal':'Bình thường',
  'trauma_bulge':'Phình',
  'trauma_aging':'Lão hóa',
  'damage':'Ảnh về thiệt hại',
  'dill-re':'Báo cáo kiểm tra',

  'txt_lf_outworn':'Bên ngoài lốp bên trái được mòn hơn bên trong',
  'txt_lf_innerworn':'Bên trong lốp bên trái được mòn hơn bên ngoài',
  'txt_lf_bothsideworn':'Cả hai bên của lốp phía trước bên trái được đeo hơn trung tâm',
  'txt_lf_centreworn':'Trung tâm lốp trước bên trái được mòn hơn cả hai bên',
  'txt_lb_outworn':'Bên ngoài lốp phía sau bên trái được mòn hơn bên trong',
  'txt_lb_innerworn':'Bên trong lốp phía sau bên trái bị mòn hơn bên ngoài',
  'txt_lb_bothsideworn':'Cả hai bên của lốp phía sau bên trái được đeo hơn trung tâm',
  'txt_lb_centreworn':'Trung tâm lốp sau bên trái được mòn hơn cả hai bên',
  'txt_rb_outworn':'Bên ngoài phía sau bên phải được mặc hơn bên trong',
  'txt_rb_innerworn': 'Bên trong của phía sau bên phải được mặc hơn bên ngoài',
  'txt_rb_bothsideworn': 'Cả hai bên của phía sau bên phải được mặc hơn trung tâm',
  'txt_rb_centreworn': 'Trung tâm phía sau bên phải được mặc hơn cả hai bên',
  'txt_rf_outworn': 'Bên ngoài lốp bên phải được mòn hơn bên trong',
  'txt_rf_innerworn':  'Bên trong lốp bên phải bị mòn so với bên ngoài',
  'txt_rf_bothsideworn': 'Cả hai bên của lốp trước bên phải đều bị mòn hơn trung tâm',
  'txt_rf_centreworn': 'Trung tâm lốp trước bên phải được mòn hơn cả hai bên' ,
  'txt_ecc_abnormal': 'Lốp xe bất thường Hao mòn quá mức',

  'txt_tire_need_replace':          'Tình trạng mòn lốp: Thay thế ngay lập tức',
  'txt_tire_boundary':              'Tình trạng mòn lốp: Ranh giới',
  'txt_alignment_chassis':          'Nên căn chỉnh và kiểm tra khung gầm',
  'txt_wear_uniform':               'Mòn lốp là đồng phục',
  'txt_do_further_check':           'Vui lòng kiểm tra thêm',
  'txt_uneven_check':                'Lốp xe có hao mòn không đều, vui lòng kiểm tra thêm',
  'txt_tire_rotation':              'Lốp xeSuggest để xoay lốp',
  'txt_wear_normal':                'Mòn lốp là bình thường',
  'txt_tire':                       'Lốp',

  'time':'Vui lòng chọn thời gian',
  'today':'Hôm nay',
  'yesterday':'Hôm qua',
  'week':'Tuần',
  'month':'Tháng',
  'last_month':'Last_month',
  'year':'Năm',
  'language':'Vui lòng chọn ngôn ngữ',
  'zh':'中文',
  'en':'English',
  'ma':'Bahasa Melayu',
  'ge':'Deutsch',
  'fr':'Français',
  'lt':'Italiano',
  'sp':'Español',
  'tw':'繁體中文',
  'jp':'にほんご',
  'po':'Polski',
  'por':'Português',
  'thai':'ข้อความ',
  'viet':'Việt nam',
  'download':'Tải xuống Báo cáo',
  'advise': 'Lời khuyên: Lốp xe có độ mòn không đồng đều, Vui lòng kiểm tra thêm',

  'txt_tire_age_5': 'Có những lốp xe đã được sử dụng hơn 5 năm, nên tiến hành kiểm tra toàn diện lốp xe;;',
  'txt_tire_age_3': 'Có những lốp xe đã được sử dụng trong hơn 3 năm;;',
  'txt_tire_age_ok': 'Tuổi thọ lốp tốt;',
  'txt_tire_trauma_check': 'Có một vết thương trên bề mặt lốp, nên kiểm tra thêm;',
  'txt_install_abnormal_check': 'Lắp đặt lốp xe là bất thường, kiểm tra thêm được khuyến khích;',
  'txt_tire_appearance_ok': 'Lốp xe trong tình trạng tốt;',
  'txt_pinnate_check': 'Có sự mòn lông vũ trên lốp xe.  Nên kiểm tra thêm các bộ phận khung gầm;',
  'trauma_lacerate': 'Rách',
  'trauma_dropblock': 'Khối thả',
  'trauma_puncture': 'Puncture',
  'trauma_scratch': 'Gãi',
  'trauma_pinnate': 'Pinnate mặc',
  'txt_tire_age': 'Tuổi thọ của lốp xe',
  'txt_tire appearance': 'xuất hiện lốp xe',
  'tire_installed': 'Lốp xe được lắp đặt',
  'abnormal': 'Đảo ngược bên trong và bên ngoài',
  'endurance': 'Ước tính phạm vi lốp',
  'endurance-warn': 'Phạm vi còn lại là',
  'endurance-zero': 'Lốp xe đã đạt đến giới hạn, vui lòng thay thế ngay lập tức',
  'estimated_value': 'Giá trị ước tính',
  'abrasion': 'Mặc',
  'remanent': 'Những ngày còn lại của thời gian sử dụng lốp',
  'useful_life': 'Ước tính tuổi thọ lốp xe',
  'dot_explain': 'CHẤM',
  'unit_km': 'Đơn vị',
  'unit_year': 'Đơn vị',
  'dot_no': 'Thời gian sản xuất chính xác không thu được',
  'made_time': 'Thời gian sản xuất: {dot} ({year} year {week} week)',
  'exceed_five': 'Tuổi thọ của lốp xe đã vượt quá 5 năm, vui lòng thay thế chúng ngay lập tức',
  'inspector': 'Nhà kỹ thuật',
  'txt_install_check': 'Kiểm tra cài đặt',
  'txt_install_ok': 'Cài đặt bình thường',
  'txt_pinnate_wear': 'Pinnate mặc',

  'LFtxt_tire_need_replace': 'Tình trạng mòn lốp: Thay thế ngay lập tức',
  'RFtxt_tire_need_replace': 'Tình trạng mòn lốp: Thay thế ngay lập tức',
  'LBtxt_tire_need_replace': 'Tình trạng mòn lốp: Thay thế ngay lập tức',
  'RBtxt_tire_need_replace': 'Tình trạng mòn lốp: Thay thế ngay lập tức',
  'FFtxt_tire_need_replace': 'Tình trạng mòn lốp: Thay thế ngay lập tức',
  'BBtxt_tire_need_replace': 'Tình trạng mòn lốp: Thay thế ngay lập tức',


  'txt_pattern_wear': 'Chênh lệch',
  'txt_uneven_center': 'Hao mòn bất thường ở giữa lốp.  Nên kiểm tra áp suất lốp',
  'LFtxt_uneven_center': 'Hao mòn bất thường ở giữa lốp.  Nên kiểm tra áp suất lốp',
  'RFtxt_uneven_center': 'Hao mòn bất thường ở giữa lốp.  Nên kiểm tra áp suất lốp',
  'LBtxt_uneven_center': 'Hao mòn bất thường ở giữa lốp.  Nên kiểm tra áp suất lốp',
  'RBtxt_uneven_center': 'Hao mòn bất thường ở giữa lốp.  Nên kiểm tra áp suất lốp',
  'FFtxt_uneven_center': 'Hao mòn bất thường ở giữa lốp.  Nên kiểm tra áp suất lốp',
  'BBtxt_uneven_center': 'Hao mòn bất thường ở giữa lốp.  Nên kiểm tra áp suất lốp',

  'txt_uneven_out': 'Mặt ngoài của lốp xe bị mòn bất thường.  Nên kiểm tra sự liên kết bốn bánh và áp suất lốp',
  'LFtxt_uneven_out': 'Mặt ngoài của lốp xe bị mòn bất thường.  Nên kiểm tra sự liên kết bốn bánh và áp suất lốp',
  'RFtxt_uneven_out': 'Mặt ngoài của lốp xe bị mòn bất thường.  Nên kiểm tra sự liên kết bốn bánh và áp suất lốp',
  'LBtxt_uneven_out': 'Mặt ngoài của lốp xe bị mòn bất thường.  Nên kiểm tra sự liên kết bốn bánh và áp suất lốp',
  'RBtxt_uneven_out': 'Mặt ngoài của lốp xe bị mòn bất thường.  Nên kiểm tra sự liên kết bốn bánh và áp suất lốp',
  'FFtxt_uneven_out': 'Mặt ngoài của lốp xe bị mòn bất thường.  Nên kiểm tra sự liên kết bốn bánh và áp suất lốp',
  'BBtxt_uneven_out': 'Mặt ngoài của lốp xe bị mòn bất thường.  Nên kiểm tra sự liên kết bốn bánh và áp suất lốp',

  'LFtxt_pinnate_check':'Có sự mòn lông vũ trên lốp xe.   Nên kiểm tra thêm các bộ phận khung gầm;',
  'RFtxt_pinnate_check':'Có sự mòn lông vũ trên lốp xe.   Nên kiểm tra thêm các bộ phận khung gầm;',
  'LBtxt_pinnate_check':'Có sự mòn lông vũ trên lốp xe.   Nên kiểm tra thêm các bộ phận khung gầm;',
  'RBtxt_pinnate_check':'Có sự mòn lông vũ trên lốp xe.   Nên kiểm tra thêm các bộ phận khung gầm;',
  'FFtxt_pinnate_check':'Có sự mòn lông vũ trên lốp xe.   Nên kiểm tra thêm các bộ phận khung gầm;查',
  'BBtxt_pinnate_check':'Có sự mòn lông vũ trên lốp xe.   Nên kiểm tra thêm các bộ phận khung gầm;查',

  'txt_uneven_inside': 'Mặt trong của lốp xe bị mòn bất thường.  Nên kiểm tra sự liên kết bốn bánh và áp suất lốp',
  'LFtxt_uneven_inside': 'Mặt trong của lốp xe bị mòn bất thường.  Nên kiểm tra sự liên kết bốn bánh và áp suất lốp',
  'RFtxt_uneven_inside': 'Mặt trong của lốp xe bị mòn bất thường.  Nên kiểm tra sự liên kết bốn bánh và áp suất lốp',
  'LBtxt_uneven_inside': 'Mặt trong của lốp xe bị mòn bất thường.  Nên kiểm tra sự liên kết bốn bánh và áp suất lốp',
  'RBtxt_uneven_inside': 'Mặt trong của lốp xe bị mòn bất thường.  Nên kiểm tra sự liên kết bốn bánh và áp suất lốp',
  'FFtxt_uneven_inside': 'Mặt trong của lốp xe bị mòn bất thường.  Nên kiểm tra sự liên kết bốn bánh và áp suất lốp',
  'BBtxt_uneven_inside': 'Mặt trong của lốp xe bị mòn bất thường.  Nên kiểm tra sự liên kết bốn bánh và áp suất lốp',

  'FFtxt_uneven_check': 'Hao mòn lốp bất thường, nên kiểm tra các bộ phận định vị và khung gầm bốn bánh',
  'BBtxt_uneven_check': 'Hao mòn lốp bất thường, nên kiểm tra các bộ phận định vị và khung gầm bốn bánh',


  'LFtxt_tire_age_5': 'Có những lốp xe đã được sử dụng hơn 5 năm, nên tiến hành kiểm tra toàn diện lốp xe;',
  'RFtxt_tire_age_5': 'Có những lốp xe đã được sử dụng hơn 5 năm, nên tiến hành kiểm tra toàn diện lốp xe;',
  'LBtxt_tire_age_5': 'Có những lốp xe đã được sử dụng hơn 5 năm, nên tiến hành kiểm tra toàn diện lốp xe;',
  'RBtxt_tire_age_5': 'Có những lốp xe đã được sử dụng hơn 5 năm, nên tiến hành kiểm tra toàn diện lốp xe;',
  'FFtxt_tire_age_5': 'Có những lốp xe đã được sử dụng hơn 5 năm, nên tiến hành kiểm tra toàn diện lốp xe;',
  'BBtxt_tire_age_5': 'Có những lốp xe đã được sử dụng hơn 5 năm, nên tiến hành kiểm tra toàn diện lốp xe;',

  'txt_appearance_check':'Sự xuất hiện của lốp xe là bất thường, kiểm tra thêm được khuyến khích',
  'LFtxt_appearance_check':'Sự xuất hiện của lốp xe là bất thường, kiểm tra thêm được khuyến khích',
  'RFtxt_appearance_check':'Sự xuất hiện của lốp xe là bất thường, kiểm tra thêm được khuyến khích',
  'LBtxt_appearance_check':'Sự xuất hiện của lốp xe là bất thường, kiểm tra thêm được khuyến khích',
  'RBtxt_appearance_check':'Sự xuất hiện của lốp xe là bất thường, kiểm tra thêm được khuyến khích',
  'FFtxt_appearance_check':'Sự xuất hiện của lốp xe là bất thường, kiểm tra thêm được khuyến khích',
  'BBtxt_appearance_check':'Sự xuất hiện của lốp xe là bất thường, kiểm tra thêm được khuyến khích',


  'LFtxt_install_abnormal_check':'Lốp xe được lắp đặt ngược, vui lòng xác nhận để đối phó với nó',
  'RFtxt_install_abnormal_check':'Lốp xe được lắp đặt ngược, vui lòng xác nhận để đối phó với nó',
  'LBtxt_install_abnormal_check':'Lốp xe được lắp đặt ngược, vui lòng xác nhận để đối phó với nó',
  'RBtxt_install_abnormal_check':'Lốp xe được lắp đặt ngược, vui lòng xác nhận để đối phó với nó',
  'FFtxt_install_abnormal_check':'Lốp xe được lắp đặt ngược, vui lòng xác nhận để đối phó với nó',
  'BBtxt_install_abnormal_check':'Lốp xe được lắp đặt ngược, vui lòng xác nhận để đối phó với nó',

  'txt_uneven_bothside': 'Hao mòn bất thường ở cả hai bên của lốp xe.  Nên kiểm tra vị trí bốn bánh và áp suất lốp.',
  'LFtxt_uneven_bothside': 'Hao mòn bất thường ở cả hai bên của lốp xe.  Nên kiểm tra vị trí bốn bánh và áp suất lốp.',
  'RFtxt_uneven_bothside': 'Hao mòn bất thường ở cả hai bên của lốp xe.  Nên kiểm tra vị trí bốn bánh và áp suất lốp.',
  'LBtxt_uneven_bothside': 'Hao mòn bất thường ở cả hai bên của lốp xe.  Nên kiểm tra vị trí bốn bánh và áp suất lốp.',
  'RBtxt_uneven_bothside': 'Hao mòn bất thường ở cả hai bên của lốp xe.  Nên kiểm tra vị trí bốn bánh và áp suất lốp.',
  'FFtxt_uneven_bothside': 'Hao mòn bất thường ở cả hai bên của lốp xe.  Nên kiểm tra vị trí bốn bánh và áp suất lốp.',
  'BBtxt_uneven_bothside': 'Hao mòn bất thường ở cả hai bên của lốp xe.  Nên kiểm tra vị trí bốn bánh và áp suất lốp.',

  'txt_wear_lookup': 'Vui lòng kiểm tra mòn lốp',
  'LFtxt_wear_lookup': 'Vui lòng kiểm tra mòn lốp',
  'RFtxt_wear_lookup': 'Vui lòng kiểm tra mòn lốp',
  'LBtxt_wear_lookup': 'Vui lòng kiểm tra mòn lốp',
  'RBtxt_wear_lookup': 'Vui lòng kiểm tra mòn lốp',
  'FFtxt_wear_lookup': 'Vui lòng kiểm tra mòn lốp',
  'BBtxt_wear_lookup': 'Vui lòng kiểm tra mòn lốp',

  'txt_appearance_lookup': 'Vui lòng kiểm tra sự xuất hiện của lốp xe',
  'LFtxt_appearance_lookup': 'Vui lòng kiểm tra sự xuất hiện của lốp xe',
  'RFtxt_appearance_lookup': 'Vui lòng kiểm tra sự xuất hiện của lốp xe',
  'LBtxt_appearance_lookup': 'Vui lòng kiểm tra sự xuất hiện của lốp xe',
  'RBtxt_appearance_lookup': 'Vui lòng kiểm tra sự xuất hiện của lốp xe',
  'FFtxt_appearance_lookup': 'Vui lòng kiểm tra sự xuất hiện của lốp xe',
  'BBtxt_appearance_lookup': 'Vui lòng kiểm tra sự xuất hiện của lốp xe',

  'txt_serious_trauma_check': 'Lốp xe bị chấn thương nghiêm trọng.  Nên thay lốp',
  'LFtxt_serious_trauma_check': 'Lốp xe bị chấn thương nghiêm trọng.  Nên thay lốp',
  'RFtxt_serious_trauma_check': 'Lốp xe bị chấn thương nghiêm trọng.  Nên thay lốp',
  'LBtxt_serious_trauma_check': 'Lốp xe bị chấn thương nghiêm trọng.  Nên thay lốp',
  'RBtxt_serious_trauma_check': 'Lốp xe bị chấn thương nghiêm trọng.  Nên thay lốp',
  'FFtxt_serious_trauma_check': 'Lốp xe bị chấn thương nghiêm trọng.  Nên thay lốp',
  'BBtxt_serious_trauma_check': 'Lốp xe bị chấn thương nghiêm trọng.  Nên thay lốp',

  'purp_tireservice':'Dịch vụ lốp xe',
  'purp_vehicle_inspection':'Kiểm tra phương tiện',
  'merchant': 'Nhà buôn',
  'manager': 'Giám đốc',
  'assistant': 'Phó',

  'txt_chassis_check': 'Kiểm tra khung gầm',
  'txt_chassis_ok': 'Khung gầm được kiểm tra kỹ lưỡng',
  'LFtxt_chassis_ok': 'Khung gầm được kiểm tra kỹ lưỡng',
  'RFtxt_chassis_ok': 'Khung gầm được kiểm tra kỹ lưỡng',
  'LBtxt_chassis_ok': 'Khung gầm được kiểm tra kỹ lưỡng',
  'RBtxt_chassis_ok': 'Khung gầm được kiểm tra kỹ lưỡng',
  'FFtxt_chassis_ok': 'Khung gầm được kiểm tra kỹ lưỡng',
  'BBtxt_chassis_ok': 'Khung gầm được kiểm tra kỹ lưỡng',
  'txt_chassis_oil': 'Giảm xóc rò rỉ dầu, xác nhận thêm được khuyến khích, nếu cần thiết, vui lòng thay thế nó.',
  'LFtxt_chassis_oil': 'Giảm xóc rò rỉ dầu, xác nhận thêm được khuyến khích, nếu cần thiết, vui lòng thay thế nó.',
  'RFtxt_chassis_oil': 'Giảm xóc rò rỉ dầu, xác nhận thêm được khuyến khích, nếu cần thiết, vui lòng thay thế nó.',
  'LBtxt_chassis_oil': 'Giảm xóc rò rỉ dầu, xác nhận thêm được khuyến khích, nếu cần thiết, vui lòng thay thế nó.',
  'RBtxt_chassis_oil': 'Giảm xóc rò rỉ dầu, xác nhận thêm được khuyến khích, nếu cần thiết, vui lòng thay thế nó.',
  'FFtxt_chassis_oil': 'Giảm xóc rò rỉ dầu, xác nhận thêm được khuyến khích, nếu cần thiết, vui lòng thay thế nó.',
  'BBtxt_chassis_oil': 'Giảm xóc rò rỉ dầu, xác nhận thêm được khuyến khích, nếu cần thiết, vui lòng thay thế nó.',
  'txt_chassis_aging': 'Lão hóa cao su đầu trục, điều trị thay thế được khuyến khích.',
  'LFtxt_chassis_aging': 'Lão hóa cao su đầu trục, điều trị thay thế được khuyến khích.',
  'RFtxt_chassis_aging': 'Lão hóa cao su đầu trục, điều trị thay thế được khuyến khích.',
  'LBtxt_chassis_aging': 'Lão hóa cao su đầu trục, điều trị thay thế được khuyến khích.',
  'RBtxt_chassis_aging': 'Lão hóa cao su đầu trục, điều trị thay thế được khuyến khích.',
  'FFtxt_chassis_aging': 'Lão hóa cao su đầu trục, điều trị thay thế được khuyến khích.',
  'BBtxt_chassis_aging': 'Lão hóa cao su đầu trục, điều trị thay thế được khuyến khích.',
  'awd': 'Tải xuống ứng dụng',

  'depth': 'Độ sâu gai lốp',
  'usura': 'Mặc quá nhiều',
  'usura-l': 'Bình thường',
  'usura-m': 'Mặc nhẹ',
  'usura-h': 'Hao mòn nghiêm trọng',

  'engine-hood': 'NẮP CA-PÔ ĐỘNG CƠ',
  'change-control': 'KIỂM TRA KHÁC',
  'subtotal': 'Tổng phụ',

  'replace': 'THAY THẾ',
  'vedi': 'ĐỌC GHI CHÚ',
  'ok': 'OK',

  'liquid': 'CHẤT LỎNG RỬA THỦY TINH',
  'level-control': 'Kiểm soát mức độ',
  'air': 'ĐIỀU HÒA KHÔNG KHÍ',
  'last-reload': 'Lần nạp tiền cuối cùng',
  'absorber': 'GIẢM XÓC',
  'check-loss': 'Kiểm tra rò rỉ có thể nhìn thấy',
  'fluid': 'CHẤT LỎNG TẢN NHIỆT',
  'lighting': 'ĐÈN',
  'verification': 'Kiểm tra mở',
  'disc': 'ĐĨA PHANH',
  'condition': 'Mặc',
  'oil': 'DẦU ĐỘNG CƠ',
  'wiper': 'CẦN GẠT NƯỚC',
  'pads': 'MÁ PHANH',
  'brake-fluid': 'DẦU PHANH',
  'quality-control': 'Kiểm tra chất lượng',
  'battery': 'Pin',
  'multimetro': 'Thử nghiệm vạn năng',
   'control-quality': 'KIỂM TRA CHẤT LƯỢNG',
   'tightening': 'Thắt chặt với máy đo lực kế được thực hiện bởi',
  'buone': 'OK',
  'lavoro': 'Lập kế hoạch làm việc',
  'concordare': 'Cho phép thay thế',
  'season_summer': 'Lốp xe mùa hè',
  'season_winter': 'Lốp xe mùa đông',
  'wearecc': 'Chênh lệch',

  'inspection_list':'Danh sách kiểm tra xe',
  'lights':'Đèn',
  'high_beam': 'Chùm tia cao',
  'low_beam':'Chùm tia thấp',
  'daytime': 'Ban ngày',
  'fog_light':'Đèn sương mù',
  'break_light':'Đèn phanh',
  'turn_signals':'Đèn báo rẽ',
  'backup_lights':'Đèn sao lưu',
  'hazards':'Mối nguy hiểm',
  'glass':'Kính',
  'windshield':'Kính chắn gió',
  'backglass':'Kính mặt sau',
  'right_glass':'Cửa sổ bên phải',
  'left_glass':'Cửa sổ bên trái',
  'wipers_front':'Mặt trước cần gạt nước',
  'wipers_rear':'Cần gạt nước phía sau',
  'under_hood':'Dưới mui xe',
  'horn':'Sừng',
  'oil_level':'Mức dầu',
  'air_filter':'Bộ lọc không khí',
  'trans_fluid':'Chất lỏng chuyển giới',
  'break_fluid':'Dầu phanh',
  'coolant_level':'Mức nước làm mát',
  'windshield_washer_fluid':'Chất lỏng máy giặt kính chắn gió',
  'power_steering_fluid':'Chất lỏng lái trợ lực',
  'belts':'Thắt lưng',
  'hoses':'Vòi',
  'ac_system':'Hệ thống AC',
  // 'battery':'蓄电池',
  'under_vehicle':'Dưới xe',
  'suspension':'Treo',
  'exhaust':'Kiệt',
  'break_lines':'Đường phanh',
  'oil_drain_plug':'Phích cắm thoát dầu',
  'differential':'Vi phân',
  'breaks':'Phanh',
  'le_fr_brake':'Phía trước bên trái',
  'ri_fr_brake':'Phía trước bên phải',
  'le_re_brake':'Phía sau bên trái',
  'ri_re_brake':'Phía sau bên phải',
  'parking_brake':'Phanh đỗ xe',
  'interior':'Nội thất',
  'cabin_filter':'Bộ lọc cabin',
  'heating':'Sưởi ấm',
  'interior_lights':'Đèn nội thất',
  'fr_defrost':'Rã đông phía trước',
  're_defrost':'Rã đông phía sau',
  'pressure': 'Áp lực',
  'nor':'OK',
  'caution':'Thận trọng',
  'attention':'Cần chú ý',

  'trauma_eattyre': '吃胎',
  'txt_uneven_eattyre': '轮胎吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'LFtxt_uneven_eattyre': '[左前轮]轮胎吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'RFtxt_uneven_eattyre': '[右前轮]轮胎吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'LBtxt_uneven_eattyre': '[左后轮]轮胎吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'RBtxt_uneven_eattyre': '[右后轮]轮胎吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'FFtxt_uneven_eattyre': '[前轴]轮胎吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'BBtxt_uneven_eattyre': '[后轴]轮胎吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',


  'txt_appearance_eattyre': '轮胎吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'LFtxt_appearance_eattyre': '[左前轮]轮胎吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'RFtxt_appearance_eattyre': '[右前轮]轮胎吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'LBtxt_appearance_eattyre': '[左后轮]轮胎吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'RBtxt_appearance_eattyre': '[右后轮]轮胎吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'FFtxt_appearance_eattyre': '[前轴]轮胎吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'BBtxt_appearance_eattyre': '[后轴]轮胎吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',


  'txt_chassis_eattyre': '轮胎吃胎，建议对四轮定位，悬挂系统进行检查',
  'LFtxt_chassis_eattyre': '[左前轮]轮胎吃胎，建议对四轮定位，悬挂系统进行检查',
  'RFtxt_chassis_eattyre': '[右前轮]轮胎吃胎，建议对四轮定位，悬挂系统进行检查',
  'LBtxt_chassis_eattyre': '[左后轮]轮胎吃胎，建议对四轮定位，悬挂系统进行检查',
  'RBtxt_chassis_eattyre': '[右后轮]轮胎吃胎，建议对四轮定位，悬挂系统进行检查',
  'FFtxt_chassis_eattyre': '[前轴]轮胎吃胎，建议对四轮定位，悬挂系统进行检查',
  'BBtxt_chassis_eattyre': '[后轴]轮胎吃胎，建议对四轮定位，悬挂系统进行检查',

  'txt_uneven_high_eattyre':'轮胎严重吃胎，建议更换轮胎，以及对四轮定位，悬挂系统进行检查',
  'LFtxt_uneven_high_eattyre': '[左前轮]轮胎严重吃胎，建议更换轮胎，以及对四轮定位，悬挂系统进行检查',
  'RFtxt_uneven_high_eattyre': '[右前轮]轮胎严重吃胎，建议更换轮胎，以及对四轮定位，悬挂系统进行检查',
  'LBtxt_uneven_high_eattyre': '[左后轮]轮胎严重吃胎，建议更换轮胎，以及对四轮定位，悬挂系统进行检查',
  'RBtxt_uneven_high_eattyre': '[右后轮]轮胎严重吃胎，建议更换轮胎，以及对四轮定位，悬挂系统进行检查',
  'FFtxt_uneven_high_eattyre': '[前轴]轮胎严重吃胎，建议更换轮胎，以及对四轮定位，悬挂系统进行检查',
  'BBtxt_uneven_high_eattyre': '[后轴]轮胎严重吃胎，建议更换轮胎，以及对四轮定位，悬挂系统进行检查',


  'txt_appearance_high_eattyre':'轮胎严重吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'LFtxt_appearance_high_eattyre': '[左前轮]轮胎严重吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'RFtxt_appearance_high_eattyre': '[右前轮]轮胎严重吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'LBtxt_appearance_high_eattyre': '[左后轮]轮胎严重吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'RBtxt_appearance_high_eattyre': '[右后轮]轮胎严重吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'FFtxt_appearance_high_eattyre': '[前轴]轮胎严重吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'BBtxt_appearance_high_eattyre': '[后轴]轮胎严重吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',

  'txt_chassis_high_eattyre':'轮胎严重吃胎，建议对四轮定位，悬挂系统进行检查',
  'LFtxt_chassis_high_eattyre': '[左前轮]轮胎严重吃胎，建议对四轮定位，悬挂系统进行检查',
  'RFtxt_chassis_high_eattyre': '[右前轮]轮胎严重吃胎，建议对四轮定位，悬挂系统进行检查',
  'LBtxt_chassis_high_eattyre': '[左后轮]轮胎严重吃胎，建议对四轮定位，悬挂系统进行检查',
  'RBtxt_chassis_high_eattyre': '[右后轮]轮胎严重吃胎，建议对四轮定位，悬挂系统进行检查',
  'FFtxt_chassis_high_eattyre': '[前轴]轮胎严重吃胎，建议对四轮定位，悬挂系统进行检查',
  'BBtxt_chassis_high_eattyre': '[后轴]轮胎严重吃胎，建议对四轮定位，悬挂系统进行检查',

  'txt_chassis_uneven': 'Lốp xe bất thường xay, đề nghị bốn bánh định vị, hệ thống treo kiểm tra  ',

  'com':'Thương mại',
  'pass':'Hành khách',

  // 大车翻译
  'truck': 'Máy kéo',
  'mount': 'Trailer',
  'left': 'Bên trái',
  'right': 'Bên phải',
  'undevice': 'Không quét',
  'axle': 'Trục',
  'spare': 'Lốp dự phòng',
  'nodata': 'KHÔNG CÓ Dữ liệu',

  'tireplace': 'Nơi lốp xe',
  'tire_season': 'Lốp xe cả mùa',
  'tire_others': 'Lốp xe khác',
  'bolt': 'Nuốt chửng',
  'goods_name': 'Tên hàng hóa',
  'bolt_yes': 'Có',
  'bolt_no': 'Không',
}
