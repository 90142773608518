import React from "react";

export default {
  'login': '로그인',
  'input_name': '사용자명을 입력하세요',
  'input_password': '비밀번호를 입력하세요',
  'login_su': '로그인 성공',
  'role': '역할',
  'username': '사용자명',
  'password': '비밀번호',
  'search_btn': 'search',
  'reports': 'Inspection Reports',
  'setting': 'System Setting',
  'store': '매장 명칭',
  'number': '번호',
  'device_time': '검사 시간',
  'wear_co': '마모 상태 (좌측 앞-우측 앞-좌측 뒤-우측 뒤)',
  'ecc_co': '편마모 상태(좌측 앞-우측 앞-좌측 뒤-우측 뒤)',
  'plate_no': '번호판 번호',
  'cus_ph': '연락 방식',
  'car_type': '차종',
  'operation': '조작',
  'view': '세부사항 확인',
  'manufacturer': '제조업체',
  'porduced_year': '제조날짜',

  'print': '인쇄 (배경 이미지 확인)',
  'store_name': '서비스 매장',
  'address': '매장 주소',
  'model': '차종',
  'mileage': '마일리지',
  'device': '검사 장비',
  'remark': '비고',
  'purpose': '방문 목적',
  're': '검사 권장',
  'color_flag': '색상 인식',
  'wear': '전체적 마모',
  'brake': '현재 제동 거리 알림',
  'ecc': '비정상적 마모',
  'lf': '좌측 전방 타이어',
  'lr': '좌측 후방 타이어',
  'rf': '우측 전방 타이어',
  'rr': '우측 후방 타이어',
  'untest': '타이어 미검사',
  'low': '상태 양호',
  'middle': '경계 상태',
  'high': '즉시 교체',
  'avg': '평균치',
  'spec': "사양",
  'brand': '브랜드',
  'ecc_avg': '균일한 트레드 마모',
  'trauma': '타이어 외관',
  'zc': '정상',
  'image': 'zh',
  'qr': 'QR코드를 스캔하여 보고서 보기',
  'model_number': '휴대용 타이어 검사기',
  'purp_wash': '세차 및 미용',
  'purp_alignment': '휠 얼라인먼트',
  'purp_replace': '타이어 교체',
  'purp_maintain': '유지보수',
  'warn': '알림 : 현재 타이어 마모 상태에 따라 제동 거리가 길어질 수 있습니다.',
  'trauma_normal': '정상',
  'trauma_bulge': '벌지',
  'trauma_aging': '노화',
  'damage': '손상 사진',
  'dill-re': '검사 보고서',

  'txt_lf_outworn': '좌측 전방 타이어 바깥쪽이 안쪽보다 심하게 마모되었습니다.',
  'txt_lf_innerworn': '좌측 전방 타이어 안쪽이 바깥쪽보다 심하게 마모되었습니다.',
  'txt_lf_bothsideworn': '좌측 전방 타이어 양쪽이 중간보다 심하게 마모되었습니다.',
  'txt_lf_centreworn': '좌측 전방 타이어 중간이 양쪽보다 심하게 마모되었습니다.',
  'txt_lb_outworn': '좌측 후방 타이어 바깥쪽이 안쪽보다 심하게 마모되었습니다.',
  'txt_lb_innerworn': '좌측 후방 타이어 안쪽이 바깥쪽보다 심하게 마모되었습니다.',
  'txt_lb_bothsideworn': '좌측 후방 타이어 양쪽이 중간보다 심하게 마모되었습니다.',
  'txt_lb_centreworn': '좌측 후방 타이어 중간이 양쪽보다 심하게 마모되었습니다.',
  'txt_rb_outworn': '우측 후방 타이어 바깥쪽이 안쪽보다 심하게 마모되었습니다.',
  'txt_rb_innerworn': '우측 후방 타이어 안쪽이 바깥쪽보다 심하게 마모되었습니다.',
  'txt_rb_bothsideworn': '우측 후방 타이어 양쪽이 중간보다 심하게 마모되었습니다.',
  'txt_rb_centreworn': '우측 후방 타이어 중간이 양쪽보다 심하게 마모되었습니다.',
  'txt_rf_outworn': '우측 전방 타이어 바깥쪽이 안쪽보다 심하게 마모되었습니다.',
  'txt_rf_innerworn': '우측 전방 타이어 안쪽이 바깥쪽보다 심하게 마모되었습니다.',
  'txt_rf_bothsideworn': '우측 전방 타이어 양쪽이 중간보다 심하게 마모되었습니다.',
  'txt_rf_centreworn': '우측 전방 타이어 중간이 양쪽보다 심하게 마모되었습니다.',
  'txt_ecc_abnormal': '타이어의 비정상적 편마모',

  'txt_tire_need_replace': '타이어의 심각한 마모, 즉시 교체하세요',
  'txt_tire_boundary': '타이어 마모가 이미 경계 상태에 달했습니다. 정기적으로 검사를 받으세요',
  'txt_alignment_chassis': '휠 얼라인먼트 및 섀시 추가 검사 권장.',
  'txt_wear_uniform': '타이어 마모가 비교적 균일합니다',
  'txt_do_further_check': '추가 검사를 받으세요',
  'txt_uneven_check': '타이어 마모 비정상, 동적 밸런스 및 휠 얼라인먼트 권장',
  'txt_tire_rotation': '타이어 로테이션 권장',
  'txt_wear_normal': '타이어 마모 정상',
  'txt_tire': '타이어',

  'time': '시간을 선택하세요',
  'today': '오늘',
  'yesterday': '어제',
  'week': '이번 주',
  'month': '이번 달',
  'last_month': '지난 달',
  'year': '올해',
  'language': '언어를 선택하세요',
  'zh': '중국어',
  'en': '영어',
  'ma': '말레이시아어',
  'ge': '독일어',
  'fr': '프랑스어',
  'lt': '이탈리아어',
  'sp': '스페인어',
  'tw': '중국어 번체',
  'jp': '일본어',
  'po': '폴란드어',
  'por': '포르투갈어',
  'download': '보고서 다운로드',
  'advise': '제안 : 타이어에 편마모가 발생했으므로 추가 검사를 받는 것이 좋습니다.',

  'txt_tire_age_5': '타이어 유효기한이 5년를 초과했으므로 타이어를 교체하는 것이 좋습니다.',
  'txt_tire_age_3': '타이어 유효기한이 3년을 초과했으므로 정기적 검사를 받으세요.',
  'txt_tire_age_ok': '타이어 연령 양호',
  'txt_tire_trauma_check': '타이어 표면이 손상되었으므로 추가 검사를 받는 것이 좋습니다.',
  'txt_install_abnormal_check': '타이어를 반대로 장착했으니 확인하고 처리하세요.',
  'txt_tire_appearance_ok': '타이어 외관 양호',
  'txt_pinnate_check': '타이어에 깃털형 마모가 발생했으므로 동적 밸런스 및 휠 얼라인먼트, 서스펜션 시스템 검사를 받는 것이 좋습니다.',
  'trauma_lacerate': '크랙',
  'trauma_dropblock': '청킹',
  'trauma_puncture': '치핑',
  'trauma_scratch': '스크래치',
  'trauma_pinnate': '깃털형 마모',
  'txt_tire_age': '타이어 연령',
  'txt_tire appearance': '타이어 외관',
  'tire_installed': '타이어 장착',
  'abnormal': '안과 밖을 반대로 장착.',
  'endurance': '타이어 수명 추정',
  'endurance-warn': '타이어 남은 수명 {number}km',
  'endurance-zero': '타이어가 이미 한계에 달했으므로 즉시 교체하세요.',
  'estimated_value': '추정치',
  'abrasion': '마모',
  'remanent': '타이어 남은 수명 {days}일',
  'useful_life': '타이어 사용 수명 추정',
  'dot_explain': 'DOT：타이어 제조시간',
  'unit_km': '단위：km',
  'unit_year': '단위 : 년',
  'dot_no': '정확한 제조시간을 얻지 못했습니다',
  'made_time': '제조시간：{dot}({year}년 {week}주)',
  'exceed_five': '타이어 유효기한이 이미 5년을 초과했습니다',
  'inspector': '기술자',
  'txt_install_check': '설치 검사',
  'txt_install_ok': '장착 정상',
  'txt_pinnate_wear': '깃털형 마모',

  'LFtxt_tire_need_replace': '[좌측 전방 타이어] 타이어 심각한 마모, 타이어 교체 권장',
  'RFtxt_tire_need_replace': '[우측 전방 타이어] 타이어 심각한 마모, 타이어 교체 권장',
  'LBtxt_tire_need_replace': '[좌측 후방 타이어] 타이어 심각한 마모, 타이어 교체 권장',
  'RBtxt_tire_need_replace': '[우측 후방 타이어] 타이어 심각한 마모, 타이어 교체 권장',
  'FFtxt_tire_need_replace': '[앞축] 타이어 심각한 마모, 타이어 교체 권장',
  'BBtxt_tire_need_replace': '[뒤축] 타이어 심각한 마모, 타이어 교체 권장',


  'txt_pattern_wear': '비정상적 마모',
  'txt_uneven_center': '타이어 중간 마모 비정상, 타이어 공기압 검사를 받는 것이 좋습니다.',
  'LFtxt_uneven_center': '[좌측 전방 타이어] 타이어 중간 마모 비정상, 타이어 공기압 검사 권장',
  'RFtxt_uneven_center': '[우측 전방 타이어] 타이어 중간 마모 비정상, 타이어 공기압 검사 권장',
  'LBtxt_uneven_center': '[좌측 후방 타이어] 타이어 중간 마모 비정상, 타이어 공기압 검사 권장',
  'RBtxt_uneven_center': '[우측 후방 타이어] 타이어 중간 마모 비정상, 타이어 공기압 검사 권장',
  'FFtxt_uneven_center': '[앞축] 타이어 중간 마모 비정상, 타이어 공기압 검사 권장',
  'BBtxt_uneven_center': '[뒤축] 타이어 중간 마모 비정상, 타이어 공기압 검사 권장',

  'txt_uneven_out': '타이어 바깥쪽 마모 비정상, 휠 얼라인먼트 및 타이어 공기압 검사를 받는 것이 좋습니다.',
  'LFtxt_uneven_out': '[좌측 전방 타이어] 타이어 바깥쪽 마모 비정상, 휠 얼라인먼트 및 타이어 공기압 검사 권장',
  'RFtxt_uneven_out': '[우측 전방 타이어] 타이어 바깥쪽 마모 비정상, 휠 얼라인먼트 및 타이어 공기압 검사 권장',
  'LBtxt_uneven_out': '[좌측 후방 타이어] 타이어 바깥쪽 마모 비정상, 휠 얼라인먼트 및 타이어 공기압 검사 권장',
  'RBtxt_uneven_out': '[우측 후방 타이어] 타이어 바깥쪽 마모 비정상, 휠 얼라인먼트 및 타이어 공기압 검사 권장',
  'FFtxt_uneven_out': '[앞축] 타이어 바깥쪽 마모 비정상, 휠 얼라인먼트 및 타이어 공기압 검사 권장',
  'BBtxt_uneven_out': '[뒤축] 타이어 바깥쪽 마모 비정상, 휠 얼라인먼트 및 타이어 공기압 검사 권장',

  'LFtxt_pinnate_check': '[좌측 전방 타이어] 타이어에 깃털형 마모 발생, 동적 밸런스 및 휠 얼라인먼트, 서스펜션 시스템 검사 권장',
  'RFtxt_pinnate_check': '[우측 전방 타이어] 타이어에 깃털형 마모 발생, 동적 밸런스 및 휠 얼라인먼트, 서스펜션 시스템 검사 권장',
  'LBtxt_pinnate_check': '[좌측 후방 타이어] 타이어에 깃털형 마모 발생, 동적 밸런스 및 휠 얼라인먼트, 서스펜션 시스템 검사 권장',
  'RBtxt_pinnate_check': '[우측 후방 타이어] 타이어에 깃털형 마모 발생, 동적 밸런스 및 휠 얼라인먼트, 서스펜션 시스템 검사 권장',
  'FFtxt_pinnate_check': '[앞축] 타이어에 깃털형 마모 발생, 동적 밸런스 및 휠 얼라인먼트, 서스펜션 시스템 검사 권장',
  'BBtxt_pinnate_check': '[뒤축] 타이어에 깃털형 마모 발생, 동적 밸런스 및 휠 얼라인먼트, 서스펜션 시스템 검사 권장',

  'txt_uneven_inside': '타이어 안쪽 마모 비정상, 휠 얼라인먼트 및 타이어 공기압 검사를 받는 것이 좋습니다.',
  'LFtxt_uneven_inside': '[좌측 전방 타이어] 타이어 안쪽 마모 비정상, 휠 얼라인먼트 및 타이어 공기압 검사 권장',
  'RFtxt_uneven_inside': '[우측 전방 타이어] 타이어 안쪽 마모 비정상, 휠 얼라인먼트 및 타이어 공기압 검사 권장',
  'LBtxt_uneven_inside': '[좌측 후방 타이어] 타이어 안쪽 마모 비정상, 휠 얼라인먼트 및 타이어 공기압 검사 권장',
  'RBtxt_uneven_inside': '[우측 후방 타이어] 타이어 안쪽 마모 비정상, 휠 얼라인먼트 및 타이어 공기압 검사 권장',
  'FFtxt_uneven_inside': '[앞축] 타이어 안쪽 마모 비정상, 휠 얼라인먼트 및 타이어 공기압 검사 권장',
  'BBtxt_uneven_inside': '[뒤축] 타이어 안쪽 마모 비정상, 휠 얼라인먼트 및 타이어 공기압 검사 권장',

  'FFtxt_uneven_check': '[앞축] 타이어 마모 비정상, 동적 밸런스 및 휠 얼라인먼트 검사 권장',
  'BBtxt_uneven_check': '[뒤축] 타이어 마모 비정상, 동적 밸런스 및 휠 얼라인먼트 검사 권장',


  'LFtxt_tire_age_5': '[좌측 전방 타이어] 타이어 유효기한 5년 초과, 타이어 교체 권장',
  'RFtxt_tire_age_5': '[우측 전방 타이어] 타이어 유효기한 5년 초과, 타이어 교체 권장',
  'LBtxt_tire_age_5': '[좌측 후방 타이어] 타이어 유효기한 5년 초과, 타이어 교체 권장',
  'RBtxt_tire_age_5': '[우측 후방 타이어] 타이어 유효기한 5년 초과, 타이어 교체 권장',
  'FFtxt_tire_age_5': '[앞축] 타이어 유효기한 5년 초과, 타이어 교체 권장',
  'BBtxt_tire_age_5': '[뒤축] 타이어 유효기한 5년 초과, 타이어 교체 권장',

  'txt_appearance_check': '타이어가 손상되었습니다. 확인하고 유지보수하세요',
  'LFtxt_appearance_check': '[좌측 전방 타이어] 타이어 손상 발생, 확인하고 유지보수하세요.',
  'RFtxt_appearance_check': '[우측 전방 타이어] 타이어 손상 발생, 확인하고 유지보수하세요.',
  'LBtxt_appearance_check': '[좌측 후방 타이어] 타이어 손상 발생, 확인하고 유지보수하세요.',
  'RBtxt_appearance_check': '[우측 후방 타이어] 타이어 손상 발생, 확인하고 유지보수하세요.',
  'FFtxt_appearance_check': '[앞축] 타이어 손상 발생, 확인하고 유지보수하세요.',
  'BBtxt_appearance_check': '[뒤축] 타이어 손상 발생, 확인하고 유지보수하세요.',


  'LFtxt_install_abnormal_check': '[좌측 전방 타이어] 타이어 반대로 장착, 확인하고 처리하세요',
  'RFtxt_install_abnormal_check': '[우측 전방 타이어] 타이어 반대로 장착, 확인하고 처리하세요',
  'LBtxt_install_abnormal_check': '[좌측 후방 타이어] 타이어 반대로 장착, 확인하고 처리하세요',
  'RBtxt_install_abnormal_check': '[우측 후방 타이어] 타이어 반대로 장착, 확인하고 처리하세요',
  'FFtxt_install_abnormal_check': '[앞축] 타이어 반대로 장착, 확인하고 처리하세요',
  'BBtxt_install_abnormal_check': '[뒤축] 타이어 반대로 장착, 확인하고 처리하세요',

  'txt_uneven_bothside': '타이어 양쪽 마모 비정상, 휠 얼라인먼트 및 타이어 공기압 검사 권장',
  'LFtxt_uneven_bothside': '[좌측 전방 타이어] 타이어 양쪽 마모 비정상, 휠 얼라인먼트 및 타이어 공기압 검사 권장',
  'RFtxt_uneven_bothside': '[우측 전방 타이어] 타이어 양쪽 마모 비정상, 휠 얼라인먼트 및 타이어 공기압 검사 권장',
  'LBtxt_uneven_bothside': '[좌측 후방 타이어] 타이어 양쪽 마모 비정상, 휠 얼라인먼트 및 타이어 공기압 검사 권장',
  'RBtxt_uneven_bothside': '[우측 후방 타이어] 타이어 양쪽 마모 비정상, 휠 얼라인먼트 및 타이어 공기압 검사 권장',
  'FFtxt_uneven_bothside': '[앞축] 타이어 양쪽 마모 비정상, 휠 얼라인먼트 및 타이어 공기압 검사 권장',
  'BBtxt_uneven_bothside': '[뒤축] 타이어 양쪽 마모 비정상, 휠 얼라인먼트 및 타이어 공기압 검사 권장',

  'txt_wear_lookup': '타이어 마모 상태를 확인하세요',
  'LFtxt_wear_lookup': '[좌측 전방 타이어] 타이어 마모 상태를 확인하세요',
  'RFtxt_wear_lookup': '[우측 전방 타이어] 타이어 마모 상태를 확인하세요',
  'LBtxt_wear_lookup': '[좌측 후방 타이어] 타이어 마모 상태를 확인하세요',
  'RBtxt_wear_lookup': '[우측 후방 타이어] 타이어 마모 상태를 확인하세요',
  'FFtxt_wear_lookup': '[앞축] 타이어 마모 상태를 확인하세요',
  'BBtxt_wear_lookup': '[뒤축] 타이어 마모 상태를 확인하세요',

  'txt_appearance_lookup': '타이어 외관 상태를 확인하세요 ',
  'LFtxt_appearance_lookup': '[좌측 전방 타이어] 타이어 외관 상태를 확인하세요',
  'RFtxt_appearance_lookup': '[우측 전방 타이어] 타이어 외관 상태를 확인하세요',
  'LBtxt_appearance_lookup': '[좌측 후방 타이어] 타이어 외관 상태를 확인하세요',
  'RBtxt_appearance_lookup': '[우측 후방 타이어] 타이어 외관 상태를 확인하세요',
  'FFtxt_appearance_lookup': '[앞축] 타이어 외관 상태를 확인하세요',
  'BBtxt_appearance_lookup': '[뒤축] 타이어 외관 상태를 확인하세요',

  'txt_serious_trauma_check': '타이어에 심각한 손상 발생, 타이어 교체 권장',
  'LFtxt_serious_trauma_check': '[좌측 전방 타이어] 타이어에 심각한 손상 발생, 타이어 교체 권장',
  'RFtxt_serious_trauma_check': '[우측 전방 타이어] 타이어에 심각한 손상 발생, 타이어 교체 권장',
  'LBtxt_serious_trauma_check': '[좌측 후방 타이어] 타이어에 심각한 손상 발생, 타이어 교체 권장',
  'RBtxt_serious_trauma_check': '[우측 후방 타이어] 타이어에 심각한 손상 발생, 타이어 교체 권장',
  'FFtxt_serious_trauma_check': '[앞축] 타이어에 심각한 손상 발생, 타이어 교체 권장',
  'BBtxt_serious_trauma_check': '[뒤축] 타이어에 심각한 손상 발생, 타이어 교체 권장',

  'purp_tireservice': '타이어 서비스',
  'purp_vehicle_inspection': '차량 검사',
  'merchant': '사업자',
  'manager': '관리자',
  'assistant': '직원',

  'txt_chassis_check': '섀시 검사',
  'txt_chassis_ok': '섀시 검사 양호',
  'LFtxt_chassis_ok': '[좌측 전방 타이어] 섀시 검사 양호',
  'RFtxt_chassis_ok': '[우측 전방 타이어] 섀시 검사 양호',
  'LBtxt_chassis_ok': '[좌측 후방 타이어] 섀시 검사 양호',
  'RBtxt_chassis_ok': '[우측 후방 타이어] 섀시 검사 양호',
  'FFtxt_chassis_ok': '[앞축] 섀시 검사 양호',
  'BBtxt_chassis_ok': '[뒤축] 섀시 검사 양호',
  'txt_chassis_oil': '쇼크 업소버 누유, 추가 검사하고 필요한 경우 교체 권장',
  'LFtxt_chassis_oil': '[좌측 전방 타이어] 쇼크 업소버 누유, 추가 검사하고 필요한 경우 교체 권장',
  'RFtxt_chassis_oil': '[우측 전방 타이어] 쇼크 업소버 누유, 추가 검사하고 필요한 경우 교체 권장',
  'LBtxt_chassis_oil': '[좌측 후방 타이어] 쇼크 업소버 누유, 추가 검사하고 필요한 경우 교체 권장',
  'RBtxt_chassis_oil': '[우측 후방 타이어] 쇼크 업소버 누유, 추가 검사하고 필요한 경우 교체 권장',
  'FFtxt_chassis_oil': '[앞축] 쇼크 업소버 누유, 추가 검사하고 필요한 경우 교체 권장',
  'BBtxt_chassis_oil': '[뒤축] 쇼크 업소버 누유, 추가 검사하고 필요한 경우 교체 권장',
  'txt_chassis_aging': '엑슬 헤드 고무 노화, 교체 권장',
  'LFtxt_chassis_aging': '[좌측 전방 타이어] 엑슬 헤드 고무 노화, 교체 권장',
  'RFtxt_chassis_aging': '[우측 전방 타이어] 엑슬 헤드 고무 노화, 교체 권장',
  'LBtxt_chassis_aging': '[좌측 후방 타이어] 엑슬 헤드 고무 노화, 교체 권장',
  'RBtxt_chassis_aging': '[우측 후방 타이어] 엑슬 헤드 고무 노화, 교체 권장',
  'FFtxt_chassis_aging': '[앞축] 엑슬 헤드 고무 노화, 교체 권장',
  'BBtxt_chassis_aging': '[뒤축] 엑슬 헤드 고무 노화, 교체 권장',
  'awd': 'APP다운로드',

  'depth': '트레드 깊이',
  'usura': '편마모',
  'usura-l': '균일한 트레드 마모',
  'usura-m': '경미한 편마모',
  'usura-h': '심각한 편마모',

  'engine-hood': '엔진 보닛',
  'change-control': '변경 제어',
  'subtotal': '소계',

  'replace': '교체',
  'vedi': '참고사항 확인',
  'ok': '정상',

  'liquid': '워셔액',
  'level-control': '레벨 컨트롤',
  'air': '에어컨 시스템',
  'last-reload': '마지막 로딩',
  'absorber': '쇼크 업소버',
  'check-loss': '손상 육안 검사',
  'fluid': '라디에이터 액',
  'lighting': '조명',
  'verification': '검사 작업',
  'disc': '브레이크 디스크',
  'condition': '마모 상태',
  'oil': '엔진 오일',
  'wiper': '와이퍼',
  'pads': '브레이크 패드',
  'brake-fluid': '브레이크 액',
  'quality-control': '품질 관리',
  'battery': '배터리',
  'multimetro': '멀티미터 테스트',
  'control-quality': '품질 관리',
  'tightening': '동력계 조정',
  'buone': '정상',
  'lavoro': '작업 일정',
  'concordare': '대체 허용',
  'season_summer': '여름용 타이어',
  'season_winter': '겨울용 타이어',
  'wearecc': '마모 & 편마모',

  'inspection_list': '차량 검사 목록',
  'lights': '조명',
  'high_beam': '상향등',
  'low_beam': '하향등',
  'daytime': '주간주행등',
  'fog_light': '안개등',
  'break_light': '브레이크등',
  'turn_signals': '방향지시등',
  'backup_lights': '후진등',
  'hazards': '비상등',
  'glass': '유리',
  'windshield': '전면 유리',
  'backglass': '후면 유리',
  'right_glass': '우측 유리',
  'left_glass': '좌측 유리',
  'wipers_front': '전면 와이퍼',
  'wipers_rear': '후면 와이퍼',
  'under_hood': '엔진룸',
  'horn': '경적',
  'oil_level': '오일 레벨',
  'air_filter': '에어 필터',
  'trans_fluid': '엔진 오일',
  'break_fluid': '브레이크 액',
  'coolant_level': '부동액 레벨',
  'windshield_washer_fluid': '워셔액',
  'power_steering_fluid': '파워 스티어링 오일',
  'belts': '벨트',
  'hoses': '호스',
  'ac_system': '에어컨 시스템',
  // 'battery':'蓄电池',
  'under_vehicle': '섀시',
  'suspension': '서스펜션',
  'exhaust': '배기관',
  'break_lines': '브레이크 라인',
  'oil_drain_plug': '오일 드레인 플러그',
  'differential': '차이',
  'breaks': '브레이크',
  'le_fr_brake': '좌측 전방 브레이크',
  'ri_fr_brake': '우측 전방 브레이크',
  'le_re_brake': '좌측 후방 브레이크',
  'ri_re_brake': '우측 후방 브레이크',
  'parking_brake': '주차 브레이크',
  'interior': '내부',
  'cabin_filter': '에어컨 필터',
  'heating': '히터',
  'interior_lights': '인테리어 조명',
  'fr_defrost': '전면 제상',
  're_defrost': '후면 제상',
  'pressure': '타이어 공기압',
  'nor': '정상',
  'caution': '주의',
  'attention': '경고',

  'trauma_eattyre': '타이어 마모 비정상',
  'txt_uneven_eattyre': '타이어 마모 비정상, 동적 밸런스를 수행하고 휠 얼라인먼트 및 서스펜션 시스템 검사를 받는 것이 좋습니다.',
  'LFtxt_uneven_eattyre': '[좌측 전방 타이어] 타이어 마모 비정상, 동적 밸런스를 수행하고 휠 얼라인먼트 및 서스펜션 시스템 검사 권장',
  'RFtxt_uneven_eattyre': '[우측 전방 타이어] 타이어 마모 비정상, 동적 밸런스를 수행하고 휠 얼라인먼트 및 서스펜션 시스템 검사 권장',
  'LBtxt_uneven_eattyre': '[좌측 후방 타이어] 타이어 마모 비정상, 동적 밸런스를 수행하고 휠 얼라인먼트 및 서스펜션 시스템 검사 권장',
  'RBtxt_uneven_eattyre': '[우측 후방 타이어] 타이어 마모 비정상, 동적 밸런스를 수행하고 휠 얼라인먼트 및 서스펜션 시스템 검사 권장',
  'FFtxt_uneven_eattyre': '[앞축] 타이어 마모 비정상, 동적 밸런스를 수행하고 휠 얼라인먼트 및 서스펜션 시스템 검사 권장',
  'BBtxt_uneven_eattyre': '[뒤축] 타이어 마모 비정상, 동적 밸런스를 수행하고 휠 얼라인먼트 및 서스펜션 시스템 검사 권장',


  'txt_appearance_eattyre': '타이어 마모 비정상, 동적 밸런스를 수행하고 휠 얼라인먼트 및 서스펜션 시스템 검사를 받는 것이 좋습니다.',
  'LFtxt_appearance_eattyre': '[좌측 전방 타이어] 타이어 마모 비정상, 동적 밸런스를 수행하고 휠 얼라인먼트 및 서스펜션 시스템 검사 권장',
  'RFtxt_appearance_eattyre': '[우측 전방 타이어] 타이어 마모 비정상, 동적 밸런스를 수행하고 휠 얼라인먼트 및 서스펜션 시스템 검사 권장',
  'LBtxt_appearance_eattyre': '[좌측 후방 타이어] 타이어 마모 비정상, 동적 밸런스를 수행하고 휠 얼라인먼트 및 서스펜션 시스템 검사 권장',
  'RBtxt_appearance_eattyre': '[우측 후방 타이어] 타이어 마모 비정상, 동적 밸런스를 수행하고 휠 얼라인먼트 및 서스펜션 시스템 검사 권장',
  'FFtxt_appearance_eattyre': '[앞축] 타이어 마모 비정상, 동적 밸런스를 수행하고 휠 얼라인먼트 및 서스펜션 시스템 검사 권장',
  'BBtxt_appearance_eattyre': '[뒤축] 타이어 마모 비정상, 동적 밸런스를 수행하고 휠 얼라인먼트 및 서스펜션 시스템 검사 권장',


  'txt_chassis_eattyre': '타이어 마모 비정상, 휠 얼라인먼트 및 서스펜션 시스템 검사를 받는 것이 좋습니다.',
  'LFtxt_chassis_eattyre': '[좌측 전방 타이어] 타이어 마모 비정상, 휠 얼라인먼트 및 서스펜션 시스템 검사 권장',
  'RFtxt_chassis_eattyre': '[우측 전방 타이어] 타이어 마모 비정상, 휠 얼라인먼트 및 서스펜션 시스템 검사 권장',
  'LBtxt_chassis_eattyre': '[좌측 후방 타이어] 타이어 마모 비정상, 휠 얼라인먼트 및 서스펜션 시스템 검사 권장',
  'RBtxt_chassis_eattyre': '[우측 후방 타이어] 타이어 마모 비정상, 휠 얼라인먼트 및 서스펜션 시스템 검사 권장',
  'FFtxt_chassis_eattyre': '[앞축] 타이어 마모 비정상, 휠 얼라인먼트 및 서스펜션 시스템 검사 권장',
  'BBtxt_chassis_eattyre': '[뒤축] 타이어 마모 비정상, 휠 얼라인먼트 및 서스펜션 시스템 검사 권장',

  'txt_uneven_high_eattyre': '타이어의 심각한 비정상적 마모, 타이어를 교체하고 휠 얼라인먼트 및 서스펜션 시스템 검사를 받는 것이 좋습니다.',
  'LFtxt_uneven_high_eattyre': '[좌측 전방 타이어] 타이어의 심각한 비정상적 마모, 타이어 교체, 휠 얼라인먼트 및 서스펜션 시스템 검사 권장',
  'RFtxt_uneven_high_eattyre': '[우측 전방 타이어] 타이어의 심각한 비정상적 마모, 타이어 교체, 휠 얼라인먼트 및 서스펜션 시스템 검사 권장',
  'LBtxt_uneven_high_eattyre': '[좌측 후방 타이어] 타이어의 심각한 비정상적 마모, 타이어 교체, 휠 얼라인먼트 및 서스펜션 시스템 검사 권장',
  'RBtxt_uneven_high_eattyre': '[우측 후방 타이어] 타이어의 심각한 비정상적 마모, 타이어 교체, 휠 얼라인먼트 및 서스펜션 시스템 검사 권장',
  'FFtxt_uneven_high_eattyre': '[앞축] 타이어의 심각한 비정상적 마모, 타이어 교체, 휠 얼라인먼트 및 서스펜션 시스템 검사 권장',
  'BBtxt_uneven_high_eattyre': '[뒤축] 타이어의 심각한 비정상적 마모, 타이어 교체, 휠 얼라인먼트 및 서스펜션 시스템 검사 권장',


  'txt_appearance_high_eattyre': '타이어의 심각한 비정상적 마모, 동적 밸런스를 수행하고 휠 얼라인먼트 및 서스펜션 시스템 검사를 받는 것이 좋습니다.',
  'LFtxt_appearance_high_eattyre': '[좌측 전방 타이어] 타이어의 심각한 비정상적 마모, 동적 밸런스, 휠 얼라인먼트 및 서스펜션 시스템 검사 권장',
  'RFtxt_appearance_high_eattyre': '[우측 전방 타이어] 타이어의 심각한 비정상적 마모, 동적 밸런스, 휠 얼라인먼트 및 서스펜션 시스템 검사 권장',
  'LBtxt_appearance_high_eattyre': '[좌측 후방 타이어] 타이어의 심각한 비정상적 마모, 동적 밸런스, 휠 얼라인먼트 및 서스펜션 시스템 검사 권장',
  'RBtxt_appearance_high_eattyre': '[우측 후방 타이어] 타이어의 심각한 비정상적 마모, 동적 밸런스, 휠 얼라인먼트 및 서스펜션 시스템 검사 권장',
  'FFtxt_appearance_high_eattyre': '[앞축] 타이어의 심각한 비정상적 마모, 동적 밸런스, 휠 얼라인먼트 및 서스펜션 시스템 검사 권장',
  'BBtxt_appearance_high_eattyre': '[뒤축] 타이어의 심각한 비정상적 마모, 동적 밸런스, 휠 얼라인먼트 및 서스펜션 시스템 검사 권장',

  'txt_chassis_high_eattyre': '타이어의 심각한 비정상적 마모, 휠 얼라인먼트 및 서스펜션 시스템 검사를 받는 것이 좋습니다.',
  'LFtxt_chassis_high_eattyre': '[좌측 전방 타이어] 타이어의 심각한 비정상적 마모, 휠 얼라인먼트 및 서스펜션 시스템 검사 권장',
  'RFtxt_chassis_high_eattyre': '[우측 전방 타이어] 타이어의 심각한 비정상적 마모, 휠 얼라인먼트 및 서스펜션 시스템 검사 권장',
  'LBtxt_chassis_high_eattyre': '[좌측 후방 타이어] 타이어의 심각한 비정상적 마모, 휠 얼라인먼트 및 서스펜션 시스템 검사 권장',
  'RBtxt_chassis_high_eattyre': '[우측 후방 타이어] 타이어의 심각한 비정상적 마모, 휠 얼라인먼트 및 서스펜션 시스템 검사 권장',
  'FFtxt_chassis_high_eattyre': '[앞축] 타이어의 심각한 비정상적 마모, 휠 얼라인먼트 및 서스펜션 시스템 검사 권장',
  'BBtxt_chassis_high_eattyre': '[뒤축] 타이어의 심각한 비정상적 마모, 휠 얼라인먼트 및 서스펜션 시스템 검사 권장',

  'txt_chassis_uneven': '타이어의 비정상적 편마모, 휠 얼라인먼트 및 서스펜션 시스템 검사를 받는 것이 좋습니다.',

  'com': '상용차',
  'pass': '승용차',

  // 大车翻译
  'truck': '트렉터',
  'mount': '트레일러',
  'left': '좌측',
  'right': '우측',
  'undevice': '미검사',
  'axle': '축',
  'spare': '스페어 타이어',
  'nodata': '데이터 없음',

  'txt_tireplace': '타이어 보관지',
  'txt_goods_name': '제품명',
  'txt_season_summer': '여름용 타이어',
  'txt_season_winter': '겨울용 타이어',
  'txt_season_all': '사계절 타이어',
  'txt_season_other': '스페어 타이어',
  'txt_bolt': '볼트',
  'txt_yes': '네',
  'txt_no': '아니오',
}
