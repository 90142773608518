export default {
  'login':'登錄',
  'input_name':'請輸入用戶名',
  'input_password':'請輸入密碼',
  'login_su':'登錄成功',
  'role':'角色',
  'username':'用戶名',
  'password':'密碼',
  'search_btn': 'search',
  'store':'門店名稱',
  'number':'編號',
  'device_time':'檢測時間',
  'wear_co':'磨損情況(左前-右前-左後-右後)',
  'ecc_co':'偏磨情況(左前-右前-左後-右後)',
  'plate_no':'車牌號碼',
  'cus_ph':'客戶手機',
  'car_type':'汽車車型',
  'operation':'操作',
  'view':'查看詳情',
  'reports': '检测报告',
  'setting': '系统设置',

  'print':'列印(需勾選背景圖形)',
  'store_name':'服務門店',
  'address':'門店地址',
  'model':'車型',
  'mileage':'里程',
  'device':'補充診斷',
  'remark':'備註',
  'purpose':'到店目的',
  're':'檢測建議',
  'color_flag':'顏色標識',
  'wear':'整體磨損',
  'brake':'當前刹車距離提醒',
  'ecc':'異常磨損',
  'lf':'左前輪',
  'lr':'左後輪',
  'rf':'右前輪',
  'rr':'右後輪',
  'untest':'輪胎未檢測',
  'low':'良好狀態',
  'middle':'邊界狀態',
  'high':'立即更換',
  'avg':'平均值',
  'spec':"規格",
  'brand':'品牌',
  'ecc_avg':'花紋磨損均勻',
  'trauma':'輪胎外觀',
  'zc':'正常',
  'image':'zh',
  'qr':'掃描二維碼查看報告',
  'model_number':'掌上型輪胎檢測儀',
  'purp_wash':'洗車美容',
  'purp_alignment':'四輪定位',
  'purp_replace':'更換輪胎',
  'purp_maintain':'維修保養',
  'warn': '提醒：根據當前輪胎磨損情況，可能導致刹車距離增加',
  'trauma_normal':'正常',
  'trauma_bulge':'鼓包',
  'trauma_aging':'老化',

  'txt_lf_outworn':'左前胎外側比內側磨損嚴重',
  'txt_lf_innerworn':'左前胎內側比外側磨損嚴重',
  'txt_lf_bothsideworn':'左前胎兩側比中間磨損嚴重',
  'txt_lf_centreworn':'左前胎中間比兩側磨損嚴重',
  'txt_lb_outworn':'左後胎外側比內側磨損嚴重',
  'txt_lb_innerworn':'左後胎內側比外側磨損嚴重',
  'txt_lb_bothsideworn':'左後胎兩側比中間磨損嚴重',
  'txt_lb_centreworn':'左後胎中間比兩側磨損嚴重',
  'txt_rb_outworn':'右後胎外側比內側磨損嚴重',
  'txt_rb_innerworn': '右後胎內側比外側磨損嚴重',
  'txt_rb_bothsideworn': '右後胎兩側比中間磨損嚴重',
  'txt_rb_centreworn': '右後胎中間比兩側磨損嚴重',
  'txt_rf_outworn': '右前胎外側比內側磨損嚴重',
  'txt_rf_innerworn':  '右前胎內側比外側磨損嚴重',
  'txt_rf_bothsideworn': '右前胎兩側比中間磨損嚴重',
  'txt_rf_centreworn': '右前胎中間比兩側磨損嚴重' ,

  'txt_tire_need_replace':          '輪胎磨損嚴重，請立即更換',
  'txt_tire_boundary':              '輪胎磨損已處邊界狀態，請定期進行檢查',
  'txt_alignment_chassis':          '建議進一步進行四輪定位或底盤檢測',
  'txt_wear_uniform':               '輪胎磨損較均勻',
  'txt_do_further_check':           '請進一步檢查',
  'txt_uneven_check':                '輪胎磨損異常，建議檢查四輪定位及底盤件',
  'txt_tire_rotation':              '建議輪胎換位',
  'txt_wear_normal':                '輪胎磨損正常',
  'txt_tire':                       '輪胎',

  'time':'請選擇時間',
  'today':'今天',
  'yesterday':'昨天',
  'week':'本周',
  'month':'本月',
  'last_month':'上月',
  'year':'今年',
  'language':'請選擇語言',
  'zh':'中文',
  'en':'英語',
  'ma':'馬來語',
  'ge':'德語',
  'fr':'法語',
  'lt':'義大利語',
  'sp':'西班牙語',
  'tw':'繁體',
  'jp':'日語',
   'po':'波蘭',
  'download':'下載報告',
  'por':'葡萄牙語',
  'advise': '建議：輪胎存在偏磨情況，建議做進一步檢查',

  'txt_tire_age_5': '輪胎有效期已超過5年，建議更換輪胎;',
  'txt_tire_age_3': '輪胎有效期已超過3年，請定期進行檢查;',
  'txt_tire_age_ok': '輪胎年限良好;',
  'txt_tire_trauma_check': '輪胎面有外傷，建議做進一步檢查;',
  'txt_install_abnormal_check': '輪胎裝反，請確認處理;',
  'txt_tire_appearance_ok': '輪胎外觀良好;',
  'txt_pinnate_check': '輪胎存在羽狀磨損，建議對底盤件進行進一步檢查;',
  'trauma_lacerate': '割裂',
  'trauma_dropblock': '掉塊',
  'trauma_puncture': '穿刺',
  'trauma_scratch': '擦傷',
  'trauma_pinnate': '羽狀磨損',
  'txt_tire_age': '輪胎年限',
  'txt_tire appearance': '輪胎外觀',
  'tire_installed': '輪胎安裝',
  'abnormal': '內外裝反',
  'endurance': '輪胎續航里程估算',
  'endurance-warn': '剩餘續航里程為{number}km',
  'endurance-zero': '輪胎已達極限，請立即更換',
  'estimated_value': '預估值',
  'abrasion': '磨損',
  'remanent': '輪胎使用時間剩餘{days}天',
  'useful_life': '輪胎使用年限估算',
  'dot_explain': 'DOT：輪胎製造時間',
  'unit_km': '單位：km',
  'unit_year': '單位：年',
  'dot_no': '未獲取到生產時間',
  'made_time': '生產時間：{dot}({year}年第{week}周)',
  'exceed_five': '輪胎有效期已超過5年,請立即更換',
  'inspector': '幹部',
  'txt_install_check': '安裝檢查',
  'txt_install_ok': '安裝正常',
  'txt_pinnate_wear': '羽狀磨損',

  'LFtxt_tire_need_replace': '[左前輪]輪胎嚴重磨損，建議更換輪胎',
  'RFtxt_tire_need_replace': '[右前輪]輪胎嚴重磨損，建議更換輪胎',
  'LBtxt_tire_need_replace': '[左後輪]輪胎嚴重磨損，建議更換輪胎',
  'RBtxt_tire_need_replace': '[右後輪]輪胎嚴重磨損，建議更換輪胎',
  'FFtxt_tire_need_replace': '[前軸]輪胎嚴重磨損，建議更換輪胎',
  'BBtxt_tire_need_replace': '[後軸]輪胎嚴重磨損，建議更換輪胎',


  'txt_pattern_wear': '異常磨損',
  'txt_uneven_center': '輪胎中間磨損異常，建議檢查胎壓',
  'LFtxt_uneven_center': '[左前輪]輪胎中間磨損異常，建議檢查胎壓',
  'RFtxt_uneven_center': '[右前輪]輪胎中間磨損異常，建議檢查胎壓',
  'LBtxt_uneven_center': '[左後輪]輪胎中間磨損異常，建議檢查胎壓',
  'RBtxt_uneven_center': '[右後輪]輪胎中間磨損異常，建議檢查胎壓',
  'FFtxt_uneven_center': '[前軸]輪胎中間磨損異常，建議檢查胎壓',
  'BBtxt_uneven_center': '[後軸]輪胎中間磨損異常，建議檢查胎壓',

  'txt_uneven_out': '輪胎外側磨損異常，建議檢查四輪定位及胎壓',
  'LFtxt_uneven_out': '[左前輪]輪胎外側磨損異常，建議檢查四輪定位及胎壓',
  'RFtxt_uneven_out': '[右前輪]輪胎外側磨損異常，建議檢查四輪定位及胎壓',
  'LBtxt_uneven_out': '[左後輪]輪胎外側磨損異常，建議檢查四輪定位及胎壓',
  'RBtxt_uneven_out': '[右後輪]輪胎外側磨損異常，建議檢查四輪定位及胎壓',
  'FFtxt_uneven_out': '[前軸]輪胎外側磨損異常，建議檢查四輪定位及胎壓',
  'BBtxt_uneven_out': '[後軸]輪胎外側磨損異常，建議檢查四輪定位及胎壓',

  'LFtxt_pinnate_check':'[左前輪]輪胎存在羽狀磨損，建議對底盤件進行進一步檢查',
  'RFtxt_pinnate_check':'[右前輪]輪胎存在羽狀磨損，建議對底盤件進行進一步檢查',
  'LBtxt_pinnate_check':'[左後輪]輪胎存在羽狀磨損，建議對底盤件進行進一步檢查',
  'RBtxt_pinnate_check':'[右後輪]輪胎存在羽狀磨損，建議對底盤件進行進一步檢查',
  'FFtxt_pinnate_check':'[前軸]輪胎存在羽狀磨損，建議對底盤件進行進一步檢查',
  'BBtxt_pinnate_check':'[後軸]輪胎存在羽狀磨損，建議對底盤件進行進一步檢查',

  'txt_uneven_inside': '輪胎內側磨損異常，建議檢查四輪定位及胎壓',
  'LFtxt_uneven_inside': '[左前輪]輪胎內側磨損異常，建議檢查四輪定位及胎壓',
  'RFtxt_uneven_inside': '[右前輪]輪胎內側磨損異常，建議檢查四輪定位及胎壓',
  'LBtxt_uneven_inside': '[左後輪]輪胎內側磨損異常，建議檢查四輪定位及胎壓',
  'RBtxt_uneven_inside': '[右後輪]輪胎內側磨損異常，建議檢查四輪定位及胎壓',
  'FFtxt_uneven_inside': '[前軸]輪胎內側磨損異常，建議檢查四輪定位及胎壓',
  'BBtxt_uneven_inside': '[後軸]輪胎內側磨損異常，建議檢查四輪定位及胎壓',

  'FFtxt_uneven_check': '[前軸]輪胎磨損異常，建議檢查四輪定位及底盤件',
  'BBtxt_uneven_check': '[後軸]輪胎磨損異常，建議檢查四輪定位及底盤件',


  'LFtxt_tire_age_5': '[左前輪]輪胎有效期已超過5年，建議更換輪胎',
  'RFtxt_tire_age_5': '[右前輪]輪胎有效期已超過5年，建議更換輪胎',
  'LBtxt_tire_age_5': '[左後輪]輪胎有效期已超過5年，建議更換輪胎',
  'RBtxt_tire_age_5': '[右後輪]輪胎有效期已超過5年，建議更換輪胎',
  'FFtxt_tire_age_5': '[前軸]輪胎有效期已超過5年，建議更換輪胎',
  'BBtxt_tire_age_5': '[後軸]輪胎有效期已超過5年，建議更換輪胎',

  'txt_appearance_check':'輪胎存在外傷，請維修確認',
  'LFtxt_appearance_check':'[左前輪]輪胎存在外傷，請維修確認',
  'RFtxt_appearance_check':'[右前輪]輪胎存在外傷，請維修確認',
  'LBtxt_appearance_check':'[左後輪]輪胎存在外傷，請維修確認',
  'RBtxt_appearance_check':'[右後輪]輪胎存在外傷，請維修確認',
  'FFtxt_appearance_check':'[前軸]輪輪胎存在外傷，請維修確認',
  'BBtxt_appearance_check':'[後軸]輪胎存在外傷，請維修確認',


  'LFtxt_install_abnormal_check':'[左前輪]輪胎裝反，請確認處理',
  'RFtxt_install_abnormal_check':'[右前輪]輪胎裝反，請確認處理',
  'LBtxt_install_abnormal_check':'[左後輪]輪胎裝反，請確認處理',
  'RBtxt_install_abnormal_check':'[右後輪]輪胎裝反，請確認處理',
  'FFtxt_install_abnormal_check':'[前軸]輪胎裝反，請確認處理',
  'BBtxt_install_abnormal_check':'[後軸]輪胎裝反，請確認處理',

  'txt_uneven_bothside': '輪胎兩邊磨損異常，建議檢查四輪定位及胎壓',
  'LFtxt_uneven_bothside': '[左前輪]輪胎兩邊磨損異常，建議檢查四輪定位及胎壓',
  'RFtxt_uneven_bothside': '[右前輪]輪胎兩邊磨損異常，建議檢查四輪定位及胎壓',
  'LBtxt_uneven_bothside': '[左後輪]輪胎兩邊磨損異常，建議檢查四輪定位及胎壓',
  'RBtxt_uneven_bothside': '[右後輪]輪胎兩邊磨損異常，建議檢查四輪定位及胎壓',
  'FFtxt_uneven_bothside': '[前軸]輪胎兩邊磨損異常，建議檢查四輪定位及胎壓',
  'BBtxt_uneven_bothside': '[後軸]輪胎兩邊磨損異常，建議檢查四輪定位及胎壓',

  'txt_wear_lookup': '請檢查輪胎磨損情況',
  'LFtxt_wear_lookup': '[左前輪]請檢查輪胎磨損情況',
  'RFtxt_wear_lookup': '[右前輪]請檢查輪胎磨損情況',
  'LBtxt_wear_lookup': '[左後輪]請檢查輪胎磨損情況',
  'RBtxt_wear_lookup': '[右後輪]請檢查輪胎磨損情況',
  'FFtxt_wear_lookup': '[前軸]請檢查輪胎磨損情況',
  'BBtxt_wear_lookup': '[後軸]請檢查輪胎磨損情況',

  'txt_appearance_lookup': '請檢查輪胎外觀情況',
  'LFtxt_appearance_lookup': '[左前輪]請檢查輪胎外觀情況',
  'RFtxt_appearance_lookup': '[右前輪]請檢查輪胎外觀情況',
  'LBtxt_appearance_lookup': '[左後輪]請檢查輪胎外觀情況',
  'RBtxt_appearance_lookup': '[右後輪]請檢查輪胎外觀情況',
  'FFtxt_appearance_lookup': '[前軸]請檢查輪胎外觀情況',
  'BBtxt_appearance_lookup': '[後軸]請檢查輪胎外觀情況',

  'txt_serious_trauma_check': '輪胎存在嚴重外傷，建議更換輪胎',
  'LFtxt_serious_trauma_check': '[左前輪]輪胎存在嚴重外傷，建議更換輪胎',
  'RFtxt_serious_trauma_check': '[右前輪]輪胎存在嚴重外傷，建議更換輪胎',
  'LBtxt_serious_trauma_check': '[左後輪]輪胎存在嚴重外傷，建議更換輪胎',
  'RBtxt_serious_trauma_check': '[右後輪]輪胎存在嚴重外傷，建議更換輪胎',
  'FFtxt_serious_trauma_check': '[前軸]輪胎存在嚴重外傷，建議更換輪胎',
  'BBtxt_serious_trauma_check': '[後軸]輪胎存在嚴重外傷，建議更換輪胎',

  'purp_tireservice':'輪胎服務',
  'purp_vehicle_inspection':'車輛檢查',
  'merchant': '商戶',
  'manager': '店長',
  'assistant': '店員',

  'txt_chassis_check': '底盤檢查',
  'txt_chassis_ok': '底盤檢查良好',
  'LFtxt_chassis_ok': '[左前轮]底盤檢查良好',
  'RFtxt_chassis_ok': '[右前轮]底盤檢查良好',
  'LBtxt_chassis_ok': '[左后轮]底盤檢查良好',
  'RBtxt_chassis_ok': '[右后轮]底盤檢查良好',
  'FFtxt_chassis_ok': '[前轴]底盤檢查良好',
  'BBtxt_chassis_ok': '[后轴]底盤檢查良好',
  'txt_chassis_oil': '減震器漏油，建議做進壹步確認，必要時請更換處理',
  'LFtxt_chassis_oil': '[左前轮]減震器漏油，建議做進壹步確認，必要時請更換處理',
  'RFtxt_chassis_oil': '[右前轮]減震器漏油，建議做進壹步確認，必要時請更換處理',
  'LBtxt_chassis_oil': '[左后轮]減震器漏油，建議做進壹步確認，必要時請更換處理',
  'RBtxt_chassis_oil': '[右后轮]減震器漏油，建議做進壹步確認，必要時請更換處理',
  'FFtxt_chassis_oil': '[前轴]減震器漏油，建議做進壹步確認，必要時請更換處理',
  'BBtxt_chassis_oil': '[后轴]減震器漏油，建議做進壹步確認，必要時請更換處理',
  'txt_chassis_aging': '軸頭橡膠老化，建議進行更換處理',
   'LFtxt_chassis_aging': '[左前轮]軸頭橡膠老化，建議進行更換處理',
  'RFtxt_chassis_aging': '[右前轮]軸頭橡膠老化，建議進行更換處理',
  'LBtxt_chassis_aging': '[左后轮]軸頭橡膠老化，建議進行更換處理',
  'RBtxt_chassis_aging': '[右后轮]軸頭橡膠老化，建議進行更換處理',
  'FFtxt_chassis_aging': '[前轴]軸頭橡膠老化，建議進行更換處理',
  'BBtxt_chassis_aging': '[后轴]軸頭橡膠老化，建議進行更換處理',
  'awd': 'App下載',

  'depth': '沟槽深度',
  'usura': '偏磨',
  'usura-l': '花纹磨损均匀',
  'usura-m': '轻微偏磨',
  'usura-h': '严重偏磨',

  'engine-hood': '发动机罩',
  'change-control': '改变控制',
  'subtotal': '小计',

  'replace': '更换',
  'vedi': '查阅注释',
  'ok': '正常',

  'liquid': '洗涤液',
  'level-control': '级别控制',
  'air': '空调系统',
  'last-reload': '上次加载',
  'absorber': '减震器',
  'check-loss': '检查可见损失',
  'fluid': '散热器流体',
  'lighting': '灯光',
  'verification': '核查运作',
  'disc': '制动盘',
  'condition': '磨损状态',
  'oil': '发动机油',
  'wiper': '雨刮器',
  'pads': '刹车片',
  'brake-fluid': '刹车液体',
  'quality-control': '质量控制',
  'battery': '电池',
  'multimetro': '万用表测试',
   'control-quality': '质量控制',
   'tightening': '测功机拧紧',
  'buone': '正常',
  'lavoro': '工作排程',
  'concordare': '允许替换',
  'wearecc': '磨损 & 偏磨',

  'com':'商用車',
  'pass':'乘用車',

}
