import React, { Component } from 'react';
import "./css/Conditioner.scss"

class Conditioner extends Component{
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        let {title, note1, content} = this.props.data
        let returnNewContent = (text) =>{
           let strText = text ? text : '//'
           return strText.split('/')
        }
        return (
            <div className="cond">
              <div className="cond-left">
                  <p className="tle">{title}</p>
                  <p className="tcn">{note1}</p>
              </div>
              <div className="cond-right">
                  <p><span className="cond-sp">{returnNewContent(content)[0]}</span>/<span className="cond-sp">{returnNewContent(content)[1]}</span>/<span className="cond-sp">{returnNewContent(content)[2]}</span></p>
              </div>
            </div>

        )
    }

}
export default Conditioner;