import React, { Component } from 'react';
import intl from "react-intl-universal";
import "./css/Title.scss"
class Title extends Component{
    constructor(props) {
        super(props);
        this.state ={}
    }

    render() {
        return (
            <div>
                <div className="til-con">
                    <span className="til-le"></span> <span className="til-ri">{this.props.content}</span>
                </div>
            </div>
        )
    }

}

export default Title