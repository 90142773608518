export default {
  'login':'Schrijf in',
  'input_name':'Een gebruikersnaam invoeren',
  'input_password':'Voer een wachtwoord in',
  'login_su':'Login succesvol',
  'role':'Rol',
  'username':'Gebruikersnaam',
  'password':'Wachtwoord',
  'search_btn': 'search',
  'store':'Naam opslaan',
  'number':'Nummer',
  'device_time':'Testtijd',
  'wear_co':'Draag conditie (lf-rf-lr-rr)',
  'ecc_co':'Excentrische slijtage (lf-rf-lr-rr)',
  'plate_no':'Nummerplaat',
  'cus_ph':'Mobiele telefoon voor klanten',
  'car_type':'Model',
  'operation':'Werking',
  'view':'Details tonen',
  'reports': 'Inspection Reports',
  'setting': 'System Setting',

  'print':'Afdrukken',
  'store_name':'Service winkel',
  'address':'Adres opslaan',
  'model':'Model',
  'mileage':'Kilometerstand',
  'device':'Testuitrusting',
  'remark':'Opmerkingen',
  'purpose':'Bestemming',
  're':'Inspectie suggestie',
  'color_flag':'Identificatie van de kleur',
  'wear':'Totale slijtage',
  'brake':'Herinnering aan de huidige remafstand',
  'ecc':'Abnormale slijtage',
  'lf':'L-F',
  'lr':'L-R',
  'rf':'R-F',
  'rr':'R-R',
  'untest':'Band niet gedetecteerd',
  'low':'In goede staat',
  'middle':'Grensoverschrijdende staat',
  'high':'Onmiddellijk vervangen',
  'avg':'Gemiddelde waarde',
  'spec':"Specificaties",
  'brand':'Merk',
  'ecc_avg':'Het patroon wordt gelijkmatig gedragen.',
  'trauma':'Hoek banden',
  'zc':'Normaal',
  'image':'zh',
  'qr':'Scan het rapport van de QR-code',
  'model_number':'HH Tire Tread Depth Detector',
  'purp_wash':'Car wash en schoonheid',
  'purp_alignment':'Positie van vier wielen',
  'purp_replace':'Verander de band',
  'purp_maintain':'Onderhoud',
  'warn': 'Herinnering: de remafstand kan toenemen naar gelang van de huidige slijttoestand van de band',
  'trauma_normal':'Normal',
  'trauma_bulge':'Zwelling',
  'trauma_aging':'Veroudering',

  'txt_lf_outworn':'De buitenzijde van de linker voorste band is meer versleten dan de binnenzijde.',
  'txt_lf_innerworn':'De binnenzijde van de linker voorste band is meer versleten dan de buitenzijde.',
  'txt_lf_bothsideworn':'Beide kanten van de linker voorste band zijn meer versleten dan het midden.',
  'txt_lf_centreworn':'Het midden van de linker voorste band is meer versleten dan beide zijden.',
  'txt_lb_outworn':'De buitenzijde van de linker achterband is meer gedragen dan de binnenzijde.',
  'txt_lb_innerworn':'De binnenzijde van de linker achterband is meer versleten dan de buitenzijde.',
  'txt_lb_bothsideworn':'Beide kanten van de linker achterband zijn meer versleten dan het midden.',
  'txt_lb_centreworn':'Het midden van de linker achterband is meer versleten dan beide zijden.',
  'txt_rb_outworn':'De buitenzijde van de rechterachterband is meer versleten dan de binnenzijde.',
  'txt_rb_innerworn': 'De binnenzijde van de rechter achterband is meer versleten dan de buitenzijde.',
  'txt_rb_bothsideworn': 'De slijtage aan beide kanten van de rechter achterband is ernstiger dan die in het midden.',
  'txt_rb_centreworn': 'Het midden van de rechter achterband is meer versleten dan beide zijden.',
  'txt_rf_outworn': 'De buitenzijde van de rechter voorste band is meer versleten dan de binnenzijde.',
  'txt_rf_innerworn':  'De slijtage van beide zijden van de rechter voorste band is ernstiger dan die van de middelste band.',
  'txt_rf_bothsideworn': 'Het midden van de rechter voorband is meer versleten dan beide zijden.',
  'txt_rf_centreworn': 'Die Mitte des rechten Hinterreifens ist schlimmer als die beiden Seiten abgenutzt.' ,
  'txt_ecc_abnormal': 'Abnormale excentriek slijtage van banden',

  'txt_tire_need_replace':          'De band is ernstig versleten, vervang hem onmiddellijk.',
  'txt_tire_boundary':              'De band is ernstig gedragen. Gelieve onmiddellijk te vervangen. De band is in de grenstoestand. Controleer het regelmatig',
  'txt_alignment_chassis':          'Aanbevolen wordt om verdere vierwieluitlijning of chassistests uit te voeren',
  'txt_wear_uniform':               'De slijtage is gelijk',
  'txt_do_further_check':           'Controleer verder',
  'txt_uneven_check':                'Als de band abnormaal wordt gedragen, wordt aanbevolen om de dynamische balans, vierwieluitlijning en ophanging systeem te controleren',
  'txt_tire_rotation':              'Het wordt gesuggereerd dat de banden moeten worden gedraaid',
  'txt_wear_normal':                'Normale slijtage',
  'txt_tire':                       'band',

  'time':'Selecteer de tijd',
  'today':'Vandaag',
  'yesterday':'Gisteren',
  'week':'Deze week',
  'month':'Deze maand',
  'last_month':'Vorige maand',
  'year':'Dit jaar',
  'language':'Gelieve taal te kiezen',
  'zh':'Chinesisch',
  'en':'Englisch',
  'ma':'Malaiische Sprache',
  'ge':'Deutsch n',
  'fr':'Französisch n',
  'lt':'italienisch ',
  'sp':'Spanische',
  'tw':'Langzeichen',
  'jp':'Japanisch',
  'po':'Poland',
  'por':'Portugiesisch',
  'download':'Download rapport',
  'advise': 'Suggestie: de band heeft excentrieke slijtage, verdere inspectie wordt aanbevolen',

  'txt_tire_age_5': 'De band is geldig voor meer dan vijf jaar. Het wordt aanbevolen om de band te vervangen.',
  'txt_tire_age_3': 'De band is langer dan drie jaar geldig. Controleer het regelmatig;',
  'txt_tire_age_ok': 'Goede banden leven;',
  'txt_tire_trauma_check': 'Er is een trauma op het bandenprofiel, verdere inspectie wordt aanbevolen;',
  'txt_install_abnormal_check': 'De band is omgedraaid, bevestig de hantering.',
  'txt_tire_appearance_ok': 'Het uiterlijk van de band is goed',
  'txt_pinnate_check':'Het uiterlijk van de band is goed, en de band heeft veren slijtage. Het wordt aanbevolen om de dynamische balans, vierwielige uitlijning en ophanging systeem te controleren.',
  'trauma_lacerate': 'Splitsen',
  'trauma_dropblock': 'Laat een stuk vallen',
  'trauma_puncture': 'Punctie',
  'trauma_scratch': 'Schaafwonden',
  'trauma_pinnate': 'Slijtage van pinnaat',
  'txt_tire_age': 'Leven in banden',
  'txt_tire appearance': 'Hoek banden',
  'tire_installed': 'Montage van banden',
  'abnormal': 'Binnenuit achteruit',
  'endurance': 'Raming van het bandenbereik',
  'endurance-warn': 'De resterende kilometerstand is {number}km',
  'endurance-zero': 'De band heeft de limiet bereikt, vervang hem onmiddellijk.',
  'estimated_value': 'Geraamde waarde',
  'abrasion': 'Slijtage',
  'remanent': '{days} dagen voor gebruik van banden',
  'useful_life': 'Schatting van de levensduur van de banden',
   'dot_explain': 'DOT：Productietijd van banden',
  'unit_km': 'Bedrijf：km',
  'unit_year': 'Bedrijf：jaar',
  'dot_no': 'Niet verkregen productietijd',
  'made_time': 'Produktionszeit: {year} ({year} Jahr {week} Woche)',
  'exceed_five': 'De band is langer dan vijf jaar geldig, vervang hem onmiddellijk.',
  'inspector': 'Technicus',
  'txt_install_check': 'Inspectie van de installatie',
  'txt_install_ok': 'De installatie is normaal',
  'txt_pinnate_wear': 'Slijtage van Pinnaat',







  'LFtxt_tire_need_replace': '[L-F]De band wordt serieus gedragen. Het wordt aanbevolen om de band te vervangen',
  'RFtxt_tire_need_replace': '[R-F]De band wordt serieus gedragen. Het wordt aanbevolen om de band te vervangen',
  'LBtxt_tire_need_replace': '[L-R]De band wordt serieus gedragen. Het wordt aanbevolen om de band te vervangen',
  'RBtxt_tire_need_replace': '[R-R]De band wordt serieus gedragen. Het wordt aanbevolen om de band te vervangen',
  'FFtxt_tire_need_replace': '[F-F]De band wordt serieus gedragen. Het wordt aanbevolen om de band te vervangen',
  'BBtxt_tire_need_replace': '[B-B]De band wordt serieus gedragen. Het wordt aanbevolen om de band te vervangen',


  'txt_pattern_wear': 'Abnormale slijtage',
  'txt_uneven_center': 'Abnormale slijtage in het midden van de band. Het wordt aanbevolen om de bandendruk te controleren',
  'LFtxt_uneven_center': '[L-F]Abnormale slijtage in het midden van de band. Het wordt aanbevolen om de bandendruk te controleren',
  'RFtxt_uneven_center': '[R-F]Abnormale slijtage in het midden van de band. Het wordt aanbevolen om de bandendruk te controleren',
  'LBtxt_uneven_center': '[L-R]Abnormale slijtage in het midden van de band. Het wordt aanbevolen om de bandendruk te controleren',
  'RBtxt_uneven_center': '[R-R]Abnormale slijtage in het midden van de band. Het wordt aanbevolen om de bandendruk te controleren',
  'FFtxt_uneven_center': '[F-F]Abnormale slijtage in het midden van de band. Het wordt aanbevolen om de bandendruk te controleren',
  'BBtxt_uneven_center': '[B-B]Abnormale slijtage in het midden van de band. Het wordt aanbevolen om de bandendruk te controleren',

  'txt_uneven_out': 'De buitenzijde van de band wordt abnormaal gedragen. Het wordt aanbevolen om de vierwieluitlijning en bandenspanning te controleren',
  'LFtxt_uneven_out': '[L-F]De buitenzijde van de band wordt abnormaal gedragen. Het wordt aanbevolen om de vierwieluitlijning en bandenspanning te controleren',
  'RFtxt_uneven_out': '[R-F]De buitenzijde van de band wordt abnormaal gedragen. Het wordt aanbevolen om de vierwieluitlijning en bandenspanning te controleren',
  'LBtxt_uneven_out': '[L-R]De buitenzijde van de band wordt abnormaal gedragen. Het wordt aanbevolen om de vierwieluitlijning en bandenspanning te controleren',
  'RBtxt_uneven_out': '[R-R]De buitenzijde van de band wordt abnormaal gedragen. Het wordt aanbevolen om de vierwieluitlijning en bandenspanning te controleren',
  'FFtxt_uneven_out': '[F-F]De buitenzijde van de band wordt abnormaal gedragen. Het wordt aanbevolen om de vierwieluitlijning en bandenspanning te controleren',
  'BBtxt_uneven_out': '[B-B]De buitenzijde van de band wordt abnormaal gedragen. Het wordt aanbevolen om de vierwieluitlijning en bandenspanning te controleren',

  'LFtxt_pinnate_check':'[L-F]Er wordt voorgesteld om de dynamische balans, vierwieluitlijning en ophanging te controleren.',
  'RFtxt_pinnate_check':'[R-F]Er wordt voorgesteld om de dynamische balans, vierwieluitlijning en ophanging te controleren.',
  'LBtxt_pinnate_check':'[L-R]Er wordt voorgesteld om de dynamische balans, vierwieluitlijning en ophanging te controleren.',
  'RBtxt_pinnate_check':'[R-R]Er wordt voorgesteld om de dynamische balans, vierwieluitlijning en ophanging te controleren.',
  'FFtxt_pinnate_check':'[F-F]Er wordt voorgesteld om de dynamische balans, vierwieluitlijning en ophanging te controleren.',
  'BBtxt_pinnate_check':'[B-B]Er wordt voorgesteld om de dynamische balans, vierwieluitlijning en ophanging te controleren.',

  'txt_uneven_inside': 'Als de binnenzijde van de band abnormaal wordt gedragen, wordt aanbevolen om de vierwieluitlijning en bandenspanning te controleren',
  'LFtxt_uneven_inside': '[L-F]Als de binnenzijde van de band abnormaal wordt gedragen, wordt aanbevolen om de vierwieluitlijning en bandenspanning te controleren',
  'RFtxt_uneven_inside': '[R-F]Als de binnenzijde van de band abnormaal wordt gedragen, wordt aanbevolen om de vierwieluitlijning en bandenspanning te controleren',
  'LBtxt_uneven_inside': '[L-R]Als de binnenzijde van de band abnormaal wordt gedragen, wordt aanbevolen om de vierwieluitlijning en bandenspanning te controleren',
  'RBtxt_uneven_inside': '[R-R]Als de binnenzijde van de band abnormaal wordt gedragen, wordt aanbevolen om de vierwieluitlijning en bandenspanning te controleren',
  'FFtxt_uneven_inside': '[F-F]Als de binnenzijde van de band abnormaal wordt gedragen, wordt aanbevolen om de vierwieluitlijning en bandenspanning te controleren',
  'BBtxt_uneven_inside': '[B-B]Als de binnenzijde van de band abnormaal wordt gedragen, wordt aanbevolen om de vierwieluitlijning en bandenspanning te controleren',

  'FFtxt_uneven_check': '[F-F]Als de band abnormaal wordt gedragen, wordt aanbevolen om de dynamische balans, vierwieluitlijning en ophanging systeem te controleren',
  'BBtxt_uneven_check': '[B-B]Als de band abnormaal wordt gedragen, wordt aanbevolen om de dynamische balans, vierwieluitlijning en ophanging systeem te controleren',


  'LFtxt_tire_age_5': '[L-F]De band is geldig voor meer dan vijf jaar. Het wordt aanbevolen om de band te vervangen.',
  'RFtxt_tire_age_5': '[R-F]De band is geldig voor meer dan vijf jaar. Het wordt aanbevolen om de band te vervangen.',
  'LBtxt_tire_age_5': '[L-R]De band is geldig voor meer dan vijf jaar. Het wordt aanbevolen om de band te vervangen.',
  'RBtxt_tire_age_5': '[R-R]De band is geldig voor meer dan vijf jaar. Het wordt aanbevolen om de band te vervangen.',
  'FFtxt_tire_age_5': '[F-F]De band is geldig voor meer dan vijf jaar. Het wordt aanbevolen om de band te vervangen.',
  'BBtxt_tire_age_5':  '[B-B]De band is geldig voor meer dan vijf jaar. Het wordt aanbevolen om de band te vervangen.',

  'txt_appearance_check':'De band is beschadigd, repareer en bevestig.',
  'LFtxt_appearance_check':'[L-F]De band is beschadigd, repareer en bevestig.',
  'RFtxt_appearance_check':'[R-F]De band is beschadigd, repareer en bevestig.',
  'LBtxt_appearance_check':'[L-R]De band is beschadigd, repareer en bevestig.',
  'RBtxt_appearance_check':'[R-R]De band is beschadigd, repareer en bevestig.',
  'FFtxt_appearance_check':'[F-F]De band is beschadigd, repareer en bevestig.',
  'BBtxt_appearance_check':'[B-B]De band is beschadigd, repareer en bevestig.',


  'LFtxt_install_abnormal_check':'[L-F]De band is omgedraaid, bevestig de hantering.',
  'RFtxt_install_abnormal_check':'[R-F]De band is omgedraaid, bevestig de hantering.',
  'LBtxt_install_abnormal_check':'[L-R]De band is omgedraaid, bevestig de hantering.',
  'RBtxt_install_abnormal_check':'[R-R]De band is omgedraaid, bevestig de hantering.',
  'FFtxt_install_abnormal_check':'[F-F]De band is omgedraaid, bevestig de hantering.',
  'BBtxt_install_abnormal_check':'[B-B]De band is omgedraaid, bevestig de hantering.',

  'txt_uneven_bothside': 'Abnormale slijtage aan beide kanten van de band. Het wordt aanbevolen om de vierwieluitlijning en bandenspanning te controleren.',
  'LFtxt_uneven_bothside': '[L-F]Abnormale slijtage aan beide kanten van de band. Het wordt aanbevolen om de vierwieluitlijning en bandenspanning te controleren.',
  'RFtxt_uneven_bothside': '[R-F]Abnormale slijtage aan beide kanten van de band. Het wordt aanbevolen om de vierwieluitlijning en bandenspanning te controleren.',
  'LBtxt_uneven_bothside': '[L-R]Abnormale slijtage aan beide kanten van de band. Het wordt aanbevolen om de vierwieluitlijning en bandenspanning te controleren.',
  'RBtxt_uneven_bothside': '[R-R]Abnormale slijtage aan beide kanten van de band. Het wordt aanbevolen om de vierwieluitlijning en bandenspanning te controleren.',
  'FFtxt_uneven_bothside': '[F-F]Abnormale slijtage aan beide kanten van de band. Het wordt aanbevolen om de vierwieluitlijning en bandenspanning te controleren.',
  'BBtxt_uneven_bothside': '[B-B]Abnormale slijtage aan beide kanten van de band. Het wordt aanbevolen om de vierwieluitlijning en bandenspanning te controleren.',


  'txt_wear_lookup': 'Controleer de slijtage van de band',
  'LFtxt_wear_lookup': '[L-F]Controleer de slijtage van de band',
  'RFtxt_wear_lookup': '[R-F]Controleer de slijtage van de band',
  'LBtxt_wear_lookup': '[L-B]Controleer de slijtage van de band',
  'RBtxt_wear_lookup': '[R-B]Controleer de slijtage van de band',
  'FFtxt_wear_lookup': '[F-F]Controleer de slijtage van de band',
  'BBtxt_wear_lookup': '[B-B]Controleer de slijtage van de band',

  'txt_appearance_lookup': 'Controleer het uiterlijk van de band',
  'LFtxt_appearance_lookup': '[L-F]Controleer het uiterlijk van de band',
  'RFtxt_appearance_lookup': '[R-F]Controleer het uiterlijk van de band',
  'LBtxt_appearance_lookup': '[L-B]Controleer het uiterlijk van de band',
  'RBtxt_appearance_lookup': '[R-B]Controleer het uiterlijk van de band',
  'FFtxt_appearance_lookup': '[F-F]Controleer het uiterlijk van de band',
  'BBtxt_appearance_lookup': '[B-B]Controleer het uiterlijk van de band',

  'txt_serious_trauma_check': 'De band heeft ernstige verwondingen. Het wordt aanbevolen om de band te vervangen',
  'LFtxt_serious_trauma_check': '[L-F]De band heeft ernstige verwondingen. Het wordt aanbevolen om de band te vervangen',
  'RFtxt_serious_trauma_check': '[R-F]De band heeft ernstige verwondingen. Het wordt aanbevolen om de band te vervangen',
  'LBtxt_serious_trauma_check': '[L-B]De band heeft ernstige verwondingen. Het wordt aanbevolen om de band te vervangen',
  'RBtxt_serious_trauma_check': '[R-B]De band heeft ernstige verwondingen. Het wordt aanbevolen om de band te vervangen',
  'FFtxt_serious_trauma_check': '[F-F]De band heeft ernstige verwondingen. Het wordt aanbevolen om de band te vervangen',
  'BBtxt_serious_trauma_check': '[B-B]De band heeft ernstige verwondingen. Het wordt aanbevolen om de band te vervangen',

  'purp_tireservice':'Banden service',
  'purp_vehicle_inspection':'Inspectie van voertuigen',
  'merchant': 'Verkoper',
  'manager': 'Winkeleigenaar',
  'assistant': 'klerk',

  'txt_chassis_check': 'Inspectie chassis',
  'txt_chassis_ok': 'Het chassis is in goede staat.',
  'LFtxt_chassis_ok': '[L-F]Het chassis is in goede staat.',
  'RFtxt_chassis_ok': '[R-F]Het chassis is in goede staat.',
  'LBtxt_chassis_ok': '[L-B]Het chassis is in goede staat.',
  'RBtxt_chassis_ok': '[R-R]Het chassis is in goede staat.',
  'FFtxt_chassis_ok': '[F-F]Het chassis is in goede staat.',
  'BBtxt_chassis_ok': '[B-B]Het chassis is in goede staat.',
  'txt_chassis_oil': 'De schokdemper lekt olie. Het wordt aanbevolen om verdere bevestiging te maken en te vervangen indien nodig.',
  'LFtxt_chassis_oil': '[L-F]De schokdemper lekt olie. Het wordt aanbevolen om verdere bevestiging te maken en te vervangen indien nodig.',
  'RFtxt_chassis_oil': '[R-F]De schokdemper lekt olie. Het wordt aanbevolen om verdere bevestiging te maken en te vervangen indien nodig.',
  'LBtxt_chassis_oil': '[L-B]De schokdemper lekt olie. Het wordt aanbevolen om verdere bevestiging te maken en te vervangen indien nodig.',
  'RBtxt_chassis_oil': '[R-R]De schokdemper lekt olie. Het wordt aanbevolen om verdere bevestiging te maken en te vervangen indien nodig.',
  'FFtxt_chassis_oil': '[F-F]De schokdemper lekt olie. Het wordt aanbevolen om verdere bevestiging te maken en te vervangen indien nodig.',
  'BBtxt_chassis_oil': '[B-B]De schokdemper lekt olie. Het wordt aanbevolen om verdere bevestiging te maken en te vervangen indien nodig.',
  'txt_chassis_aging': 'Het rubber van de schacht wordt ouder, dus het wordt aanbevolen om het te vervangen.',
   'LFtxt_chassis_aging': '[L-F]Het rubber van de schacht wordt ouder, dus het wordt aanbevolen om het te vervangen.',
  'RFtxt_chassis_aging': '[R-F]Het rubber van de schacht wordt ouder, dus het wordt aanbevolen om het te vervangen.',
  'LBtxt_chassis_aging': '[L-B]Het rubber van de schacht wordt ouder, dus het wordt aanbevolen om het te vervangen.',
  'RBtxt_chassis_aging': '[R-R]Het rubber van de schacht wordt ouder, dus het wordt aanbevolen om het te vervangen.',
  'FFtxt_chassis_aging': '[F-F]Het rubber van de schacht wordt ouder, dus het wordt aanbevolen om het te vervangen.',
  'BBtxt_chassis_aging': '[B-B]Het rubber van de schacht wordt ouder, dus het wordt aanbevolen om het te vervangen.',
   'awd': 'App DownloadComment',
   'depth': 'Trench Depth',
    'usura': 'Eccentric Wear',
    'usura-l': 'The pattern is worn evenly',
    'usura-m': 'Slight eccentric wear',
    'usura-h': 'Severe eccentric wear',

    'engine-hood': 'MOTORKAP',
    'change-control': 'ANDERE CONTROLES',
    'subtotal': 'ONDER CHASSIS',

    'replace': 'VERVANGEN',
    'vedi': 'LEES NOTA',
    'ok': 'OK',

    'liquid': 'WASVLOEISTOF',
    'level-control': 'Controle niveau',
    'air': 'AIRCO',
    'last-reload': 'Laatste vulling',
    'absorber': 'SCHOKDEMPERS',
    'check-loss': 'ZICHTBARE LEKKEN Cotrole op zichtbare lekken',
    'fluid': 'KOELVLOEISTOF',
    'lighting': 'LICHTEN',
    'verification': 'Bewerking van verificatie',
    'disc': 'REMSCHIJVEN',
    'condition': 'Slijtageniveau',
    'oil': 'MOTOROLIE',
    'wiper': 'RUITENWISSERS',
    'pads': 'REMBLOKKEN',
    'brake-fluid': 'REMVLOEISTOF',
    'quality-control': 'Kwaliteitscontrole',
    'battery': 'BATTERIJ',
    'multimetro': 'Multimeter test',
    'control-quality': 'KWALITEITSCONTROLE',
    'tightening': 'Aandraaien met dynamometer gedaan door',
    'buone': 'ok',
    'lavoro': 'Werkplanning',
    'concordare': 'Vervanging toestaan',
     'season_summer': 'Zomer band',
     'season_winter': 'Winter band',

    'com':'Bedrijfsvoertuigen',
    'pass':'Passagierswagen',


}
