function filterContent(info) {
    if (info) {
        return {wear_degree: info.wear_degree, wear_data: info.wear_data}
    } else {
        return {wear_degree: '', wear_data: ''}
    }

}


function returnTireData(type, tires) {
    let data = null;
    switch (type) {
        case '4*2':
            data = [
                {
                    double: false,
                    lf: {
                        number: '1',
                        en_num: '1',
                        position: "011",
                        degree: filterContent(tires['011']).wear_degree,
                        avg: filterContent(tires['011']).wear_data
                    },
                    lb: {
                        number: '2',
                        en_num: '2',
                        position: "012",
                        degree: filterContent(tires['012']).wear_degree,
                        avg: filterContent(tires['012']).wear_data
                    }
                },
                {
                    double: true,
                    lfw: {
                        number: '3',
                        en_num: '3',
                        position: "021",
                        degree: filterContent(tires['021']).wear_degree,
                        avg: filterContent(tires['021']).wear_data
                    },
                    lf: {
                        number: '4',
                        en_num: '4',
                        position: "022",
                        degree: filterContent(tires['022']).wear_degree,
                        avg: filterContent(tires['022']).wear_data
                    },
                    lb: {
                        number: '5',
                        en_num: '5',
                        position: "023",
                        degree: filterContent(tires['023']).wear_degree,
                        avg: filterContent(tires['023']).wear_data
                    },
                    lbw: {
                        number: '6',
                        en_num: '6',
                        position: "024",
                        degree: filterContent(tires['024']).wear_degree,
                        avg: filterContent(tires['024']).wear_data
                    }
                },
                {
                    double: false,
                    lf: {
                        number: '1',
                        en_num: '1',
                        position: "B1",
                        degree: filterContent(tires['B1']).wear_degree,
                        avg: filterContent(tires['B1']).wear_data
                    },
                    lb: {
                        number: '2',
                        en_num: '2',
                        position: "B2",
                        degree: filterContent(tires['B2']).wear_degree,
                        avg: filterContent(tires['B2']).wear_data
                    }
                },
            ];
            break;
        case '6*2':
            data = [
                {
                    double: false,
                    lf: {
                        number: '1',
                        en_num: '1',
                        position: "011",
                        degree: filterContent(tires['011']).wear_degree,
                        avg: filterContent(tires['011']).wear_data
                    },
                    lb: {
                        number: '2',
                        en_num: '2',
                        position: "012",
                        degree: filterContent(tires['012']).wear_degree,
                        avg: filterContent(tires['012']).wear_data
                    }
                },
                {
                    double: false,
                    lf: {
                        number: '3',
                        en_num: '3',
                        position: "021",
                        degree: filterContent(tires['021']).wear_degree,
                        avg: filterContent(tires['021']).wear_data
                    },
                    lb: {
                        number: '4',
                        en_num: '4',
                        position: "022",
                        degree: filterContent(tires['022']).wear_degree,
                        avg: filterContent(tires['022']).wear_data
                    },
                },
                {
                    double: true,
                    lfw: {
                        number: '5',
                        en_num: '5',
                        position: "031",
                        degree: filterContent(tires['031']).wear_degree,
                        avg: filterContent(tires['031']).wear_data
                    },
                    lf: {
                        number: '6',
                        en_num: '6',
                        position: "032",
                        degree: filterContent(tires['032']).wear_degree,
                        avg: filterContent(tires['032']).wear_data
                    },
                    lb: {
                        number: '7',
                        en_num: '7',
                        position: "033",
                        degree: filterContent(tires['033']).wear_degree,
                        avg: filterContent(tires['033']).wear_data
                    },
                    lbw: {
                        number: '8',
                        en_num: '8',
                        position: "034",
                        degree: filterContent(tires['034']).wear_degree,
                        avg: filterContent(tires['034']).wear_data
                    }
                },
                {
                    double: false,
                    lf: {
                        number: '1',
                        en_num: '1',
                        position: "B1",
                        degree: filterContent(tires['B1']).wear_degree,
                        avg: filterContent(tires['B1']).wear_data
                    },
                    lb: {
                        number: '2',
                        en_num: '2',
                        position: "B2",
                        degree: filterContent(tires['B2']).wear_degree,
                        avg: filterContent(tires['B2']).wear_data
                    }
                },
            ];
            break
        case '6*2F':
            data = [
                {
                    double: false,
                    lf: {
                        number: '1',
                        en_num: '1',
                        position: "011",
                        degree: filterContent(tires['011']).wear_degree,
                        avg: filterContent(tires['011']).wear_data
                    },
                    lb: {
                        number: '2',
                        en_num: '2',
                        position: "012",
                        degree: filterContent(tires['012']).wear_degree,
                        avg: filterContent(tires['012']).wear_data
                    }
                },
                {
                    double: true,
                    lfw: {
                        number: '3',
                        en_num: '3',
                        position: "021",
                        degree: filterContent(tires['021']).wear_degree,
                        avg: filterContent(tires['021']).wear_data
                    },
                    lb: {
                        number: '4',
                        en_num: '4',
                        position: "022",
                        degree: filterContent(tires['022']).wear_degree,
                        avg: filterContent(tires['022']).wear_data
                    },
                    lf: {
                        number: '5',
                        en_num: '5',
                        position: "023",
                        degree: filterContent(tires['023']).wear_degree,
                        avg: filterContent(tires['023']).wear_data
                    },
                    lbw: {
                        number: '6',
                        en_num: '6',
                        position: "024",
                        degree: filterContent(tires['024']).wear_degree,
                        avg: filterContent(tires['024']).wear_data
                    },
                },
                {
                    double: true,
                    lfw: {
                        number: '7',
                        en_num: '7',
                        position: "031",
                        degree: filterContent(tires['031']).wear_degree,
                        avg: filterContent(tires['031']).wear_data
                    },
                    lf: {
                        number: '8',
                        en_num: '8',
                        position: "032",
                        degree: filterContent(tires['032']).wear_degree,
                        avg: filterContent(tires['032']).wear_data
                    },
                    lb: {
                        number: '9',
                        en_num: '9',
                        position: "033",
                        degree: filterContent(tires['033']).wear_degree,
                        avg: filterContent(tires['033']).wear_data
                    },
                    lbw: {
                        number: '10',
                        en_num: '10',
                        position: "034",
                        degree: filterContent(tires['034']).wear_degree,
                        avg: filterContent(tires['034']).wear_data
                    }
                },
                {
                    double: false,
                    lf: {
                        number: '1',
                        en_num: '1',
                        position: "B1",
                        degree: filterContent(tires['B1']).wear_degree,
                        avg: filterContent(tires['B1']).wear_data
                    },
                    lb: {
                        number: '2',
                        en_num: '2',
                        position: "B2",
                        degree: filterContent(tires['B2']).wear_degree,
                        avg: filterContent(tires['B2']).wear_data
                    }
                },
            ];
            break
        case '6*2FS':
            data = [
                {
                    double: false,
                    lf: {
                        number: '1',
                        en_num: '1',
                        position: "011",
                        degree: filterContent(tires['011']).wear_degree,
                        avg: filterContent(tires['011']).wear_data
                    },
                    lb: {
                        number: '2',
                        en_num: '2',
                        position: "012",
                        degree: filterContent(tires['012']).wear_degree,
                        avg: filterContent(tires['012']).wear_data
                    }
                },
                {
                    double: true,
                    lfw: {
                        number: '3',
                        en_num: '3',
                        position: "021",
                        degree: filterContent(tires['021']).wear_degree,
                        avg: filterContent(tires['021']).wear_data
                    },
                    lb: {
                        number: '4',
                        en_num: '4',
                        position: "022",
                        degree: filterContent(tires['022']).wear_degree,
                        avg: filterContent(tires['022']).wear_data
                    },
                    lf: {
                        number: '5',
                        en_num: '5',
                        position: "023",
                        degree: filterContent(tires['023']).wear_degree,
                        avg: filterContent(tires['023']).wear_data
                    },
                    lbw: {
                        number: '6',
                        en_num: '6',
                        position: "024",
                        degree: filterContent(tires['024']).wear_degree,
                        avg: filterContent(tires['024']).wear_data
                    },
                },
                {
                    double: false,
                    lf: {
                        number: '7',
                        en_num: '7',
                        position: "031",
                        degree: filterContent(tires['031']).wear_degree,
                        avg: filterContent(tires['031']).wear_data
                    },
                    lb: {
                        number: '8',
                        en_num: '8',
                        position: "032",
                        degree: filterContent(tires['032']).wear_degree,
                        avg: filterContent(tires['032']).wear_data
                    },
                },
                {
                    double: false,
                    lf: {
                        number: '1',
                        en_num: '1',
                        position: "B1",
                        degree: filterContent(tires['B1']).wear_degree,
                        avg: filterContent(tires['B1']).wear_data
                    },
                    lb: {
                        number: '2',
                        en_num: '2',
                        position: "B2",
                        degree: filterContent(tires['B2']).wear_degree,
                        avg: filterContent(tires['B2']).wear_data
                    }
                },
            ];
            break
        case '6*4':
            data = [
                {
                    double: false,
                    lf: {
                        number: '1',
                        en_num: '1',
                        position: "011",
                        degree: filterContent(tires['011']).wear_degree,
                        avg: filterContent(tires['011']).wear_data
                    },
                    lb: {
                        number: '2',
                        en_num: '2',
                        position: "012",
                        degree: filterContent(tires['012']).wear_degree,
                        avg: filterContent(tires['012']).wear_data
                    }
                },
                {
                    double: true,
                    lfw: {
                        number: '3',
                        en_num: '3',
                        position: "021",
                        degree: filterContent(tires['021']).wear_degree,
                        avg: filterContent(tires['021']).wear_data
                    },
                    lb: {
                        number: '4',
                        en_num: '4',
                        position: "022",
                        degree: filterContent(tires['022']).wear_degree,
                        avg: filterContent(tires['022']).wear_data
                    },
                    lf: {
                        number: '5',
                        en_num: '5',
                        position: "023",
                        degree: filterContent(tires['023']).wear_degree,
                        avg: filterContent(tires['023']).wear_data
                    },
                    lbw: {
                        number: '6',
                        en_num: '6',
                        position: "024",
                        degree: filterContent(tires['024']).wear_degree,
                        avg: filterContent(tires['024']).wear_data
                    },
                },
                {
                    double: true,
                    lfw: {
                        number: '7',
                        en_num: '7',
                        position: "031",
                        degree: filterContent(tires['031']).wear_degree,
                        avg: filterContent(tires['031']).wear_data
                    },
                    lf: {
                        number: '8',
                        en_num: '8',
                        position: "032",
                        degree: filterContent(tires['032']).wear_degree,
                        avg: filterContent(tires['032']).wear_data
                    },
                    lb: {
                        number: '9',
                        en_num: '9',
                        position: "033",
                        degree: filterContent(tires['033']).wear_degree,
                        avg: filterContent(tires['033']).wear_data
                    },
                    lbw: {
                        number: '10',
                        en_num: '10',
                        position: "034",
                        degree: filterContent(tires['034']).wear_degree,
                        avg: filterContent(tires['034']).wear_data
                    }
                },
                {
                    double: false,
                    lf: {
                        number: '1',
                        en_num: '1',
                        position: "B1",
                        degree: filterContent(tires['B1']).wear_degree,
                        avg: filterContent(tires['B1']).wear_data
                    },
                    lb: {
                        number: '2',
                        en_num: '2',
                        position: "B2",
                        degree: filterContent(tires['B2']).wear_degree,
                        avg: filterContent(tires['B2']).wear_data
                    }
                },
            ];
            break
        case '8*4':
            data = [
                {
                    double: false,
                    lf: {
                        number: '1',
                        en_num: '1',
                        position: "011",
                        degree: filterContent(tires['011']).wear_degree,
                        avg: filterContent(tires['011']).wear_data
                    },
                    lb: {
                        number: '2',
                        en_num: '2',
                        position: "012",
                        degree: filterContent(tires['012']).wear_degree,
                        avg: filterContent(tires['012']).wear_data
                    }
                },
                {
                    double: false,
                    lf: {
                        number: '3',
                        en_num: '3',
                        position: "021",
                        degree: filterContent(tires['021']).wear_degree,
                        avg: filterContent(tires['021']).wear_data
                    },
                    lb: {
                        number: '4',
                        en_num: '4',
                        position: "022",
                        degree: filterContent(tires['022']).wear_degree,
                        avg: filterContent(tires['022']).wear_data
                    },
                },
                {
                    double: true,
                    lfw: {
                        number: '5',
                        en_num: '5',
                        position: "031",
                        degree: filterContent(tires['031']).wear_degree,
                        avg: filterContent(tires['031']).wear_data
                    },
                    lf: {
                        number: '6',
                        en_num: '6',
                        position: "032",
                        degree: filterContent(tires['032']).wear_degree,
                        avg: filterContent(tires['032']).wear_data
                    },
                    lb: {
                        number: '7',
                        en_num: '7',
                        position: "033",
                        degree: filterContent(tires['033']).wear_degree,
                        avg: filterContent(tires['033']).wear_data
                    },
                    lbw: {
                        number: '8',
                        en_num: '8',
                        position: "034",
                        degree: filterContent(tires['034']).wear_degree,
                        avg: filterContent(tires['034']).wear_data
                    }
                },
                {
                    double: true,
                    lfw: {
                        number: '9',
                        en_num: '9',
                        position: "041",
                        degree: filterContent(tires['041']).wear_degree,
                        avg: filterContent(tires['041']).wear_data
                    },
                    lf: {
                        number: '10',
                        en_num: '10',
                        position: "042",
                        degree: filterContent(tires['042']).wear_degree,
                        avg: filterContent(tires['042']).wear_data
                    },
                    lb: {
                        number: '11',
                        en_num: '11',
                        position: "043",
                        degree: filterContent(tires['043']).wear_degree,
                        avg: filterContent(tires['043']).wear_data
                    },
                    lbw: {
                        number: '12',
                        en_num: '12',
                        position: "044",
                        degree: filterContent(tires['044']).wear_degree,
                        avg: filterContent(tires['044']).wear_data
                    }
                },
                {
                    double: false,
                    lf: {
                        number: '1',
                        en_num: '1',
                        position: "B1",
                        degree: filterContent(tires['B1']).wear_degree,
                        avg: filterContent(tires['B1']).wear_data
                    },
                    lb: {
                        number: '2',
                        en_num: '2',
                        position: "B2",
                        degree: filterContent(tires['B2']).wear_degree,
                        avg: filterContent(tires['B2']).wear_data
                    }
                },
            ];
            break
        case '4 WHEELS':
            data = [
                {
                    double: false,
                    lf: {
                        number: '1',
                        en_num: '1',
                        position: "011",
                        degree: filterContent(tires['011']).wear_degree,
                        avg: filterContent(tires['011']).wear_data
                    },
                    lb: {
                        number: '2',
                        en_num: '2',
                        position: "012",
                        degree: filterContent(tires['012']).wear_degree,
                        avg: filterContent(tires['012']).wear_data
                    }
                },
                {
                    double: false,
                    lf: {
                        number: '3',
                        en_num: '3',
                        position: "021",
                        degree: filterContent(tires['021']).wear_degree,
                        avg: filterContent(tires['021']).wear_data
                    },
                    lb: {
                        number: '4',
                        en_num: '4',
                        position: "022",
                        degree: filterContent(tires['022']).wear_degree,
                        avg: filterContent(tires['022']).wear_data
                    },
                }
            ];
            break
        case '6 WHEELS':
            data = [
                {
                    double: false,
                    lf: {
                        number: '1',
                        en_num: '1',
                        position: "011",
                        degree: filterContent(tires['011']).wear_degree,
                        avg: filterContent(tires['011']).wear_data
                    },
                    lb: {
                        number: '2',
                        en_num: '2',
                        position: "012",
                        degree: filterContent(tires['012']).wear_degree,
                        avg: filterContent(tires['012']).wear_data
                    }
                },
                {
                    double: true,
                    lfw: {
                        number: '3',
                        en_num: '3',
                        position: "021",
                        degree: filterContent(tires['021']).wear_degree,
                        avg: filterContent(tires['021']).wear_data
                    },
                    lb: {
                        number: '4',
                        en_num: '4',
                        position: "022",
                        degree: filterContent(tires['022']).wear_degree,
                        avg: filterContent(tires['022']).wear_data
                    },
                    lf: {
                        number: '5',
                        en_num: '5',
                        position: "023",
                        degree: filterContent(tires['023']).wear_degree,
                        avg: filterContent(tires['023']).wear_data
                    },
                    lbw: {
                        number: '6',
                        en_num: '6',
                        position: "024",
                        degree: filterContent(tires['024']).wear_degree,
                        avg: filterContent(tires['024']).wear_data
                    },
                }
            ];
            break
        case '2FS':
            data = [
                {
                    double: false,
                    lf: {
                        number: '1',
                        en_num: '1',
                        position: "011",
                        degree: filterContent(tires['011']).wear_degree,
                        avg: filterContent(tires['011']).wear_data
                    },
                    lb: {
                        number: '2',
                        en_num: '2',
                        position: "012",
                        degree: filterContent(tires['012']).wear_degree,
                        avg: filterContent(tires['012']).wear_data
                    }
                },
                {
                    double: false,
                    lf: {
                        number: '3',
                        en_num: '3',
                        position: "021",
                        degree: filterContent(tires['021']).wear_degree,
                        avg: filterContent(tires['021']).wear_data
                    },
                    lb: {
                        number: '4',
                        en_num: '4',
                        position: "022",
                        degree: filterContent(tires['022']).wear_degree,
                        avg: filterContent(tires['022']).wear_data
                    },
                },
                {
                    double: false,
                    lf: {
                        number: '1',
                        en_num: '1',
                        position: "B1",
                        degree: filterContent(tires['B1']).wear_degree,
                        avg: filterContent(tires['B1']).wear_data
                    },
                    lb: {
                        number: '2',
                        en_num: '2',
                        position: "B2",
                        degree: filterContent(tires['B2']).wear_degree,
                        avg: filterContent(tires['B2']).wear_data
                    }
                }
            ];
            break
        case '3FS':
            data = [
                {
                    double: false,
                    lf: {
                        number: '1',
                        en_num: '1',
                        position: "011",
                        degree: filterContent(tires['011']).wear_degree,
                        avg: filterContent(tires['011']).wear_data
                    },
                    lb: {
                        number: '2',
                        en_num: '2',
                        position: "012",
                        degree: filterContent(tires['012']).wear_degree,
                        avg: filterContent(tires['012']).wear_data
                    }
                },
                {
                    double: false,
                    lf: {
                        number: '3',
                        en_num: '3',
                        position: "021",
                        degree: filterContent(tires['021']).wear_degree,
                        avg: filterContent(tires['021']).wear_data
                    },
                    lb: {
                        number: '4',
                        en_num: '4',
                        position: "022",
                        degree: filterContent(tires['022']).wear_degree,
                        avg: filterContent(tires['022']).wear_data
                    },
                },
                {
                    double: false,
                    lf: {
                        number: '5',
                        en_num: '5',
                        position: "031",
                        degree: filterContent(tires['031']).wear_degree,
                        avg: filterContent(tires['031']).wear_data
                    },
                    lb: {
                        number: '6',
                        en_num: '6',
                        position: "032",
                        degree: filterContent(tires['032']).wear_degree,
                        avg: filterContent(tires['032']).wear_data
                    },
                },
                {
                    double: false,
                    lf: {
                        number: '1',
                        en_num: '1',
                        position: "B1",
                        degree: filterContent(tires['B1']).wear_degree,
                        avg: filterContent(tires['B1']).wear_data
                    },
                    lb: {
                        number: '2',
                        en_num: '2',
                        position: "B2",
                        degree: filterContent(tires['B2']).wear_degree,
                        avg: filterContent(tires['B2']).wear_data
                    }
                },
            ];
            break
        case '1 Axle':
            data = [
                {
                    double: false,
                    lf: {
                        number: '1',
                        en_num: 'LFO',
                        position: "011",
                        degree: filterContent(tires['011']).wear_degree,
                        avg: filterContent(tires['011']).wear_data
                    },
                    lb: {
                        number: '2',
                        en_num: 'LFI',
                        position: "012",
                        degree: filterContent(tires['012']).wear_degree,
                        avg: filterContent(tires['012']).wear_data
                    }
                },
                {
                    double: false,
                    lf: {
                        number: '3',
                        en_num: 'RFI',
                        position: "021",
                        degree: filterContent(tires['021']).wear_degree,
                        avg: filterContent(tires['021']).wear_data
                    },
                    lb: {
                        number: '4',
                        en_num: 'RFO',
                        position: "022",
                        degree: filterContent(tires['022']).wear_degree,
                        avg: filterContent(tires['022']).wear_data
                    },
                },
                {
                    double: false,
                    lf: {
                        number: '1',
                        en_num: '1',
                        position: "B1",
                        degree: filterContent(tires['B1']).wear_degree,
                        avg: filterContent(tires['B1']).wear_data
                    },
                    lb: {
                        number: '2',
                        en_num: '2',
                        position: "B2",
                        degree: filterContent(tires['B2']).wear_degree,
                        avg: filterContent(tires['B2']).wear_data
                    }
                },
            ];
            break
        case '2 Axle':
            data = [
                {
                    double: true,
                    lfw: {
                        number: '1',
                        en_num: 'LFO',
                        position: "011",
                        degree: filterContent(tires['011']).wear_degree,
                        avg: filterContent(tires['011']).wear_data
                    },
                    lf: {
                        number: '2',
                        en_num: 'LFI',
                        position: "012",
                        degree: filterContent(tires['012']).wear_degree,
                        avg: filterContent(tires['012']).wear_data
                    },
                    lb: {
                        number: '3',
                        en_num: 'RFI',
                        position: "013",
                        degree: filterContent(tires['013']).wear_degree,
                        avg: filterContent(tires['013']).wear_data
                    },
                    lbw: {
                        number: '4',
                        en_num: 'RFO',
                        position: "014",
                        degree: filterContent(tires['014']).wear_degree,
                        avg: filterContent(tires['014']).wear_data
                    }
                },
                {
                    double: true,
                    lfw: {
                        number: '5',
                        en_num: 'LRO',
                        position: "021",
                        degree: filterContent(tires['021']).wear_degree,
                        avg: filterContent(tires['021']).wear_data
                    },
                    lf: {
                        number: '6',
                        en_num: 'LRI',
                        position: "022",
                        degree: filterContent(tires['022']).wear_degree,
                        avg: filterContent(tires['022']).wear_data
                    },
                    lb: {
                        number: '7',
                        en_num: 'RRI',
                        position: "023",
                        degree: filterContent(tires['023']).wear_degree,
                        avg: filterContent(tires['023']).wear_data
                    },
                    lbw: {
                        number: '8',
                        en_num: 'RRO',
                        position: "024",
                        degree: filterContent(tires['024']).wear_degree,
                        avg: filterContent(tires['024']).wear_data
                    }
                },
                {
                    double: false,
                    lf: {
                        number: '1',
                        en_num: '1',
                        position: "B1",
                        degree: filterContent(tires['B1']).wear_degree,
                        avg: filterContent(tires['B1']).wear_data
                    },
                    lb: {
                        number: '2',
                        en_num: '2',
                        position: "B2",
                        degree: filterContent(tires['B2']).wear_degree,
                        avg: filterContent(tires['B2']).wear_data
                    }
                },
            ];
            break
        case '3 Axle':
            data = [
                {
                    double: true,
                    lfw: {
                        number: '1',
                        en_num: 'LFO',
                        position: "011",
                        degree: filterContent(tires['011']).wear_degree,
                        avg: filterContent(tires['011']).wear_data
                    },
                    lf: {
                        number: '2',
                        en_num: 'LFI',
                        position: "012",
                        degree: filterContent(tires['012']).wear_degree,
                        avg: filterContent(tires['012']).wear_data
                    },
                    lb: {
                        number: '3',
                        en_num: 'RFI',
                        position: "013",
                        degree: filterContent(tires['013']).wear_degree,
                        avg: filterContent(tires['013']).wear_data
                    },
                    lbw: {
                        number: '4',
                        en_num: 'RFO',
                        position: "014",
                        degree: filterContent(tires['014']).wear_degree,
                        avg: filterContent(tires['014']).wear_data
                    }
                },
                {
                    double: true,
                    lfw: {
                        number: '5',
                        en_num: 'LMO',
                        position: "021",
                        degree: filterContent(tires['021']).wear_degree,
                        avg: filterContent(tires['021']).wear_data
                    },
                    lf: {
                        number: '6',
                        en_num: 'LMI',
                        position: "022",
                        degree: filterContent(tires['022']).wear_degree,
                        avg: filterContent(tires['022']).wear_data
                    },
                    lb: {
                        number: '7',
                        en_num: 'RMI',
                        position: "023",
                        degree: filterContent(tires['023']).wear_degree,
                        avg: filterContent(tires['023']).wear_data
                    },
                    lbw: {
                        number: '8',
                        en_num: 'RMO',
                        position: "024",
                        degree: filterContent(tires['024']).wear_degree,
                        avg: filterContent(tires['024']).wear_data
                    }
                },
                {
                    double: true,
                    lfw: {
                        number: '9',
                        en_num: 'LRO',
                        position: "031",
                        degree: filterContent(tires['031']).wear_degree,
                        avg: filterContent(tires['031']).wear_data
                    },
                    lf: {
                        number: '10',
                        en_num: 'LRI',
                        position: "032",
                        degree: filterContent(tires['032']).wear_degree,
                        avg: filterContent(tires['032']).wear_data
                    },
                    lb: {
                        number: '11',
                        en_num: 'RRI',
                        position: "033",
                        degree: filterContent(tires['033']).wear_degree,
                        avg: filterContent(tires['033']).wear_data
                    },
                    lbw: {
                        number: '12',
                        en_num: 'RRO',
                        position: "034",
                        degree: filterContent(tires['034']).wear_degree,
                        avg: filterContent(tires['034']).wear_data
                    }
                },
                {
                    double: false,
                    lf: {
                        number: '1',
                        en_num: '1',
                        position: "B1",
                        degree: filterContent(tires['B1']).wear_degree,
                        avg: filterContent(tires['B1']).wear_data
                    },
                    lb: {
                        number: '2',
                        en_num: '2',
                        position: "B2",
                        degree: filterContent(tires['B2']).wear_degree,
                        avg: filterContent(tires['B2']).wear_data
                    }
                },
            ];
        case '4 Axle':
            data = [
                {
                    double: true,
                    lfw: {
                        number: '1',
                        en_num: 'LFO',
                        position: "011",
                        degree: filterContent(tires['011']).wear_degree,
                        avg: filterContent(tires['011']).wear_data
                    },
                    lf: {
                        number: '2',
                        en_num: 'LFI',
                        position: "012",
                        degree: filterContent(tires['012']).wear_degree,
                        avg: filterContent(tires['012']).wear_data
                    },
                    lb: {
                        number: '3',
                        en_num: 'RFI',
                        position: "013",
                        degree: filterContent(tires['013']).wear_degree,
                        avg: filterContent(tires['013']).wear_data
                    },
                    lbw: {
                        number: '4',
                        en_num: 'RFO',
                        position: "014",
                        degree: filterContent(tires['014']).wear_degree,
                        avg: filterContent(tires['014']).wear_data
                    }
                },
                {
                    double: true,
                    lfw: {
                        number: '5',
                        en_num: 'LMO',
                        position: "021",
                        degree: filterContent(tires['021']).wear_degree,
                        avg: filterContent(tires['021']).wear_data
                    },
                    lf: {
                        number: '6',
                        en_num: 'LMI',
                        position: "022",
                        degree: filterContent(tires['022']).wear_degree,
                        avg: filterContent(tires['022']).wear_data
                    },
                    lb: {
                        number: '7',
                        en_num: 'RMI',
                        position: "023",
                        degree: filterContent(tires['023']).wear_degree,
                        avg: filterContent(tires['023']).wear_data
                    },
                    lbw: {
                        number: '8',
                        en_num: 'RMO',
                        position: "024",
                        degree: filterContent(tires['024']).wear_degree,
                        avg: filterContent(tires['024']).wear_data
                    }
                },
                {
                    double: true,
                    lfw: {
                        number: '9',
                        en_num: 'LRO',
                        position: "031",
                        degree: filterContent(tires['031']).wear_degree,
                        avg: filterContent(tires['031']).wear_data
                    },
                    lf: {
                        number: '10',
                        en_num: 'LRI',
                        position: "032",
                        degree: filterContent(tires['032']).wear_degree,
                        avg: filterContent(tires['032']).wear_data
                    },
                    lb: {
                        number: '11',
                        en_num: 'RRI',
                        position: "033",
                        degree: filterContent(tires['033']).wear_degree,
                        avg: filterContent(tires['033']).wear_data
                    },
                    lbw: {
                        number: '12',
                        en_num: 'RRO',
                        position: "034",
                        degree: filterContent(tires['034']).wear_degree,
                        avg: filterContent(tires['034']).wear_data
                    }
                },
                {
                    double: true,
                    lfw: {
                        number: '13',
                        en_num: 'FLO',
                        position: "041",
                        degree: filterContent(tires['041']).wear_degree,
                        avg: filterContent(tires['041']).wear_data
                    },
                    lf: {
                        number: '14',
                        en_num: 'FLI',
                        position: "042",
                        degree: filterContent(tires['042']).wear_degree,
                        avg: filterContent(tires['042']).wear_data
                    },
                    lb: {
                        number: '15',
                        en_num: 'FRI',
                        position: "043",
                        degree: filterContent(tires['043']).wear_degree,
                        avg: filterContent(tires['043']).wear_data
                    },
                    lbw: {
                        number: '16',
                        en_num: 'FRO',
                        position: "044",
                        degree: filterContent(tires['044']).wear_degree,
                        avg: filterContent(tires['044']).wear_data
                    }
                },
                {
                    double: false,
                    lf: {
                        number: '1',
                        en_num: '1',
                        position: "B1",
                        degree: filterContent(tires['B1']).wear_degree,
                        avg: filterContent(tires['B1']).wear_data
                    },
                    lb: {
                        number: '2',
                        en_num: '2',
                        position: "B2",
                        degree: filterContent(tires['B2']).wear_degree,
                        avg: filterContent(tires['B2']).wear_data
                    }
                },
            ];


    }
    return data

}

export default returnTireData