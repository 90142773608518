import React, {Component} from "react";
import "../css/Lorry/Header.scss"
import QRCode from "qrcode.react";
import intl from "react-intl-universal";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    };

    render() {
        let {plate_no, logo, device_time, inspector, en_name, genre, uuid, language} = this.props.data;
        let returnType = (name) =>{
            if (name==='truck') {
                return intl.get('truck')
            }else {
                return intl.get('mount')
            }
        }
        ;
        return (
            <div className="header">
                <div className="he-logo"><img src= {logo ? logo : "https://static.test.taihapo.com.cn/group1/M00/01/69/rBNzcV7HdiSARD23AABqITDTqTU523.jpg"} alt='' style={{width: 186, objectFit: "contain"}}/></div>
                <div className="he-content">
                    <div>
                        <div><span className='hr-tt'>{intl.get('plate_no')}：</span><span className='hr-ct'>{plate_no}</span></div>
                         <div><span className='hr-tt'>{intl.get('device_time')}：</span><span className='hr-ct'>{device_time}</span></div>
                    </div>
                    <div>
                         <div><span className='hr-tt'>{intl.get('car_type')}：</span><span className='hr-ct'>{en_name}-{returnType(genre)}</span></div>
                         <div><span className='hr-tt'>{intl.get('inspector')}：</span><span className='hr-ct'>{inspector}</span></div>
                    </div>
                </div>
                 <div className="qrcode" >
                    <QRCode
                        value={`http://customer.taihapo.com.cn/reportcar/${uuid}?lang=${language}`}
                        size={85}
                    />
                    {/*<div*/}
                    {/*    className="qr">{intl.get('qr')}*/}
                    {/*</div>*/}
                 </div>
            </div>
        )

    }

}
export default Header