import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import intl from 'react-intl-universal';
import './index.scss';
import './api'
import App from './App';
import zh from './i18n/zh.js'
import en from './i18n/en.js'
import ma from './i18n/ma.js'
import ge from './i18n/ge.js'
import fr from './i18n/fr.js'
import lt from './i18n/lt.js'
import sp from './i18n/sp.js'
import tw from './i18n/tw.js'
import jp from './i18n/jp.js'
import po from './i18n/po.js'
import por from './i18n/por.js'
import nl from './i18n/nl.js'
import dk from './i18n/dk.js'
import th from './i18n/thai'
import vi from './i18n/viet'
import ko from './i18n/ko'
import * as serviceWorker from './serviceWorker';
import Cookies from 'universal-cookie';
import { BrowserRouter, Route } from 'react-router-dom'
const cookies = new Cookies();




// let lang = (navigator.languages && navigator.languages[0]) || navigator.language
let lang = cookies.get('language')
let  jsSrc =(navigator.language || navigator.browserLanguage).toLowerCase();
let returnLanguage = () => {
  jsSrc = (navigator.language || navigator.browserLanguage).toLowerCase();
  if (jsSrc.indexOf('zh') >= 0) {
    return 'zh'
  }
  if (jsSrc.indexOf('lt') >= 0) {
    return 'lt'
  }
  else {
    return 'en'
  }
};

intl.init({
  // currentLocale: lang.split('-')[0],
  currentLocale: lang ? lang : returnLanguage(),
  locales: {
    zh,
    en,
    ma,
    ge,
    fr,
    lt,
    sp,
    tw,
    jp,
    po,
    por,
    nl,
    dk,
    th,
    vi,
    ko,
  }
});



ReactDOM.render(
  <BrowserRouter>
    <Route path='/' component={App}></Route>
  </BrowserRouter>
  , document.getElementById('root'));

serviceWorker.unregister();
