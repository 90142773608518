export default {
  'login':'ログイン',
  'input_name':'ユーザ名を入力してください',
  'input_password':'パスワードを入力してください',
  'login_su':'ログイン成功',
  'role':'キャラクター',
  'username':'ユーザ名',
  'password':'パスワード',

  'store':'店舗名',
  'number':'番号付け',
  'device_time':'測定時間',
  'wear_co':'摩耗状況（左前-右前-左後-右後）',
  'ecc_co':'偏摩耗状況（左前-右前-左後-右後）',
  'plate_no':'ナンバープレート',
  'cus_ph':'お客様携帯',
  'car_type':'車のタイプ',
  'operation':'操作',
  'view':'詳細を表示',
  'search_btn': 'search',
  'reports': 'Inspection Reports',
  'setting': 'System Setting',
  'print':'印刷(背景画像をチェックしたい)',
  'store_name':'サービス店',
  'address':'店舗の住所',
  'model':'車種',
  'mileage':'道のり',
  'device':'検出装置',
  'remark':'コメント',
  'purpose':'目的地まで',
  're':'検査アドバイス',
  'color_flag':'色の識別',
  'wear':'全体磨耗',
  'brake':'現在のブレーキ距離アラーム',
  'ecc':'異常摩耗',
  'lf':'左前輪',
  'lr':'左後輪',
  'rf':'右前輪',
  'rr':'右後輪',
  'untest':'タイヤ未検出',
  'low':'良好な状態',
  'middle':'境界状態',
  'high':'すぐに交換します',
  'avg':'平均値',
  'spec':"規格",
  'brand':'ブランド',
  'ecc_avg':'模様がすり減る',
  'trauma':'タイヤの外観',
  'zc':'正常',
  'image':'zh',
  'qr':'コードをスキャンしてレポートを見る',
  'model_number':'手持ちタイヤ検知器',
  'purp_wash':'ビューティ',
  'purp_alignment':'四輪位置決め',
  'purp_replace':'タイヤを交換する',
  'purp_maintain':'メンテナンス',
  'warn': '注意：現在のタイヤの摩耗状況により、ブレーキの距離が増加する可能性があります。',
  'trauma_normal':'正常',
  'trauma_bulge':'太鼓のこぶ',
  'trauma_aging':'エージング',

  'txt_lf_outworn':'左前タイヤの外側の摩耗は内側よりひどい',
  'txt_lf_innerworn':'左前タイヤの内側の摩耗は外側よりひどい',
  'txt_lf_bothsideworn':'左前タイヤの両側の摩耗は真ん中よりひどい',
  'txt_lf_centreworn':'左前タイヤの真ん中の摩耗は両側よりひどい',
  'txt_lb_outworn':'左後タイヤの外側の摩耗は内側よりひどい',
  'txt_lb_innerworn':'左後タイヤの内側の摩耗は外側よりひどい"',
  'txt_lb_bothsideworn':'左後タイヤの両側の摩耗は真ん中よりひどい',
  'txt_lb_centreworn':'左後タイヤの真ん中の摩耗は両側よりひどい',
  'txt_rb_outworn':'右後タイヤの外側の摩耗は内側よりひどい',
  'txt_rb_innerworn': '右後タイヤの内側の摩耗は外側よりひどい"',
  'txt_rb_bothsideworn': '右後タイヤの両側の摩耗は真ん中よりひどい',
  'txt_rb_centreworn': '右後タイヤの真ん中の摩耗は両側よりひどい"',
  'txt_rf_outworn': '右前タイヤの外側の摩耗は内側よりひどい',
  'txt_rf_innerworn':  '右前タイヤの内側の摩耗は外側よりひどい',
  'txt_rf_bothsideworn': '右前タイヤの両側の摩耗は真ん中よりひどい"',
  'txt_rf_centreworn': '右前タイヤの真ん中の摩耗は両側よりひどい"' ,

  'txt_tire_need_replace':          'タイヤに磨耗があるので、直ちに交換してください。',
  'txt_tire_boundary':              'タイヤの摩耗はもう境界です。定期的にチェックしてください。',
  'txt_alignment_chassis':          'さらに四輪アライメントとシャーシ点検を実施してください',
  'txt_wear_uniform':               '単一のタイヤの摩耗は比較的に均一です',
  'txt_do_further_check':           'さらに点検してください"',
  'txt_uneven_check':                'タイヤの摩耗が比較的に均一で、タイヤの摩耗が異常です。四輪位置決めとシャーシの検査をお勧めします。',
  'txt_tire_rotation':              'タイヤの位置を変えてください',
  'txt_wear_normal':                'タイヤの摩耗は正常\n',
  'txt_tire':                       'タイヤ',

  'time':'時間を選んでください',
  'today':'今日',
  'yesterday':'昨日',
  'week':'今週',
  'month':'今月',
  'last_month':'先月',
  'year':'今年',
  'language':'言語を選択してください',
  'zh':'中国語',
  'en':'英語',
  'ma':'マレー語',
  'ge':'ドイツ語',
  'fr':'フランス語',
  'lt':'イタリア語',
  'sp':'スペイン語',
  'tw':'繁体字',
  'jp':'日本語',
  'po':'ポーランド',
  'por':'ポルトガル語',
  'download':'レポートをダウンロード',
  'advise': '提案：タイヤに偏りがあるので、さらに検査することを提案します。',
  'store_user':'Login as a store user?',
    'distributor':'Login as a dealer user?',
  'txt_tire_age_5': 'タイヤの有効期限が5年を超えました。タイヤの交換を勧めます;',
  'txt_tire_age_3': 'タイヤの有効期限が3年を超えました。定期的に検査してください。;',
  'txt_tire_age_ok': 'タイヤの年限が良い;',
  'txt_tire_trauma_check': 'タイヤの表面に外傷がありますので、さらに検査することをお勧めします。;',
  'txt_install_abnormal_check': 'タイヤの取り付けが逆です。処理を確認してください。;',
  'txt_tire_appearance_ok': 'タイヤの外観が良い;',
  'txt_pinnate_check': 'タイヤは羽のように磨耗しています。シャーシに対してさらに検査することを提案します。',
  'trauma_lacerate': '割れる',
  'trauma_dropblock': 'かたが落ちる',
  'trauma_puncture': 'とげを穿つ',
  'trauma_scratch': '擦り傷',
  'trauma_pinnate': '羽状磨耗',
  'txt_tire_age': 'タイヤの年限',
  'txt_tire appearance': 'タイヤの外観',
  'tire_installed': 'タイヤの取り付け',
  'abnormal': '内と外は反対だ',
  'endurance': 'タイヤの航続距離見積',
  'endurance-warn': '残りの航続距離は{number}kmです。',
  'endurance-zero': 'タイヤが限界に達しましたので、すぐに交換してください。',
  'estimated_value': '予想値',
  'abrasion': '磨耗する',
  'remanent': 'タイヤ使用時間が{days}日残っています。',
  'useful_life': 'タイヤ使用年限見積',
  'dot_explain': 'DOT：タイヤ製造時間',
  'unit_km': '単位：km',
  'unit_year': '単位：年',
  'dot_no': '生産時間が取得されていません。',
  'made_time': '生産時間：{dot}({year}年第{week}週)',
  'exceed_five': 'タイヤの有効期限が5年を超えました。すぐに交換してください。',
  'inspector': '技師',
  'txt_install_check': 'インストールチェック',
  'txt_install_ok': 'インストールが正常です',
  'txt_pinnate_wear': '羽状磨耗',

  'LFtxt_tire_need_replace': '[左前輪]タイヤの摩耗はひどいので、タイヤの交換をお勧めします',
  'RFtxt_tire_need_replace': '[右前輪]タイヤの摩耗はひどいので、タイヤの交換をお勧めします',
  'LBtxt_tire_need_replace': '[左後輪]タイヤの摩耗はひどいので、タイヤの交換をお勧めします',
  'RBtxt_tire_need_replace': '[右後輪]タイヤの摩耗はひどいので、タイヤの交換をお勧めします',
  'FFtxt_tire_need_replace': '[前軸]タイヤの摩耗はひどいので、タイヤの交換をお勧めします',
  'BBtxt_tire_need_replace': '[后軸]タイヤの摩耗はひどいので、タイヤの交換をお勧めします',


  'txt_pattern_wear': '異常摩耗',
  'txt_uneven_center': 'タイヤの中の摩耗は異常です。タイヤの空気圧を確認してください。',
  'LFtxt_uneven_center': '[左前輪]タイヤの中の摩耗は異常です。タイヤの空気圧を確認してください。',
  'RFtxt_uneven_center': '[右前輪]タイヤの中の摩耗は異常です。タイヤの空気圧を確認してください。',
  'LBtxt_uneven_center': '[左後輪]タイヤの中の摩耗は異常です。タイヤの空気圧を確認してください。',
  'RBtxt_uneven_center': '[右後輪]タイヤの中の摩耗は異常です。タイヤの空気圧を確認してください。',
  'FFtxt_uneven_center': '[前軸]タイヤの中の摩耗は異常です。タイヤの空気圧を確認してください。',
  'BBtxt_uneven_center': '[后軸]タイヤの中の摩耗は異常です。タイヤの空気圧を確認してください。',

  'txt_uneven_out': 'タイヤの外側の摩耗が異常で、四輪の位置決めとタイヤの圧力を確認することを提案します。',
  'LFtxt_uneven_out': '[左前輪]タイヤの外側の摩耗が異常で、四輪の位置決めとタイヤの圧力を確認することを提案します。',
  'RFtxt_uneven_out': '[右前輪]タイヤの外側の摩耗が異常で、四輪の位置決めとタイヤの圧力を確認することを提案します。',
  'LBtxt_uneven_out': '[左後輪]タイヤの外側の摩耗が異常で、四輪の位置決めとタイヤの圧力を確認することを提案します。',
  'RBtxt_uneven_out': '[右後輪]タイヤの外側の摩耗が異常で、四輪の位置決めとタイヤの圧力を確認することを提案します。',
  'FFtxt_uneven_out': '[前軸]タイヤの外側の摩耗が異常で、四輪の位置決めとタイヤの圧力を確認することを提案します。',
  'BBtxt_uneven_out': '[后軸]タイヤの外側の摩耗が異常で、四輪の位置決めとタイヤの圧力を確認することを提案します。',

  'LFtxt_pinnate_check':'[左前輪]タイヤに羽状摩耗はあるので、シャーシパーツの点検をお勧めします。',
  'RFtxt_pinnate_check':'[右前輪]タイヤに羽状摩耗はあるので、シャーシパーツの点検をお勧めします。',
  'LBtxt_pinnate_check':'[左後輪]タイヤに羽状摩耗はあるので、シャーシパーツの点検をお勧めします。',
  'RBtxt_pinnate_check':'[右後輪]タイヤに羽状摩耗はあるので、シャーシパーツの点検をお勧めします。',
  'FFtxt_pinnate_check':'[前軸]タイヤに羽状摩耗はあるので、シャーシパーツの点検をお勧めします。',
  'BBtxt_pinnate_check':'[后軸]タイヤに羽状摩耗はあるので、シャーシパーツの点検をお勧めします。',

  'txt_uneven_inside': 'タイヤの内側の摩耗が異常で、四輪の位置決めとタイヤの圧力を確認することを提案します。',
  'LFtxt_uneven_inside': '[左前輪]タイヤの内側の摩耗が異常で、四輪の位置決めとタイヤの圧力を確認することを提案します。',
  'RFtxt_uneven_inside': '[右前輪]タイヤの内側の摩耗が異常で、四輪の位置決めとタイヤの圧力を確認することを提案します。',
  'LBtxt_uneven_inside': '[左後輪]タイヤの内側の摩耗が異常で、四輪の位置決めとタイヤの圧力を確認することを提案します。',
  'RBtxt_uneven_inside': '[右後輪]タイヤの内側の摩耗が異常で、四輪の位置決めとタイヤの圧力を確認することを提案します。',
  'FFtxt_uneven_inside': '[前軸]タイヤの内側の摩耗が異常で、四輪の位置決めとタイヤの圧力を確認することを提案します。',
  'BBtxt_uneven_inside': '[后軸]タイヤの内側の摩耗が異常で、四輪の位置決めとタイヤの圧力を確認することを提案します。',

  'FFtxt_uneven_check': '[前軸]タイヤの磨耗が異常で、四輪の位置決めとシャーシを確認することを提案します。',
  'BBtxt_uneven_check': '[后軸]タイヤの磨耗が異常で、四輪の位置決めとシャーシを確認することを提案します。',


  'LFtxt_tire_age_5': '[左前輪]タイヤの有効期間が5年間を超えています。タイヤの交換をお勧めします。',
  'RFtxt_tire_age_5': '[右前輪]タイヤの有効期間が5年間を超えています。タイヤの交換をお勧めします。',
  'LBtxt_tire_age_5': '[左後輪]タイヤの有効期間が5年間を超えています。タイヤの交換をお勧めします。',
  'RBtxt_tire_age_5': '[右後輪]タイヤの有効期間が5年間を超えています。タイヤの交換をお勧めします。',
  'FFtxt_tire_age_5': '[前軸]タイヤの有効期間が5年間を超えています。タイヤの交換をお勧めします。',
  'BBtxt_tire_age_5': '[后軸]タイヤの有効期間が5年間を超えています。タイヤの交換をお勧めします。',

  'txt_appearance_check':'タイヤに外傷があるので、確認の上、修理してください。',
  'LFtxt_appearance_check':'[左前輪]タイヤに外傷があるので、確認の上、修理してください。',
  'RFtxt_appearance_check':'[右前輪]タイヤに外傷があるので、確認の上、修理してください。',
  'LBtxt_appearance_check':'[左後輪]タイヤに外傷があるので、確認の上、修理してください。',
  'RBtxt_appearance_check':'[右後輪]タイヤに外傷があるので、確認の上、修理してください。',
  'FFtxt_appearance_check':'[前軸]轮タイヤに外傷があるので、確認の上、修理してください。',
  'BBtxt_appearance_check':'[后軸]タイヤに外傷があるので、確認の上、修理してください。',


  'LFtxt_install_abnormal_check':'[左前輪]タイヤの取り付けが逆です。確認して処理してください。',
  'RFtxt_install_abnormal_check':'[右前輪]タイヤの取り付けが逆です。確認して処理してください。',
  'LBtxt_install_abnormal_check':'[左後輪]タイヤの取り付けが逆です。確認して処理してください。',
  'RBtxt_install_abnormal_check':'[右後輪]タイヤの取り付けが逆です。確認して処理してください。',
  'FFtxt_install_abnormal_check':'[前軸]タイヤの取り付けが逆です。確認して処理してください。',
  'BBtxt_install_abnormal_check':'[后軸]タイヤの取り付けが逆です。確認して処理してください。',

  'txt_uneven_bothside': 'タイヤの両側の摩耗が異常で、四輪の位置決めとタイヤの圧力を確認することを提案します。',
  'LFtxt_uneven_bothside': '[左前輪]タイヤの両側の摩耗が異常で、四輪の位置決めとタイヤの圧力を確認することを提案します。',
  'RFtxt_uneven_bothside': '[右前輪]タイヤの両側の摩耗が異常で、四輪の位置決めとタイヤの圧力を確認することを提案します。',
  'LBtxt_uneven_bothside': '[左後輪]タイヤの両側の摩耗が異常で、四輪の位置決めとタイヤの圧力を確認することを提案します。',
  'RBtxt_uneven_bothside': '[右後輪]タイヤの両側の摩耗が異常で、四輪の位置決めとタイヤの圧力を確認することを提案します。',
  'FFtxt_uneven_bothside': '[前軸]タイヤの両側の摩耗が異常で、四輪の位置決めとタイヤの圧力を確認することを提案します。',
  'BBtxt_uneven_bothside': '[后軸]タイヤの両側の摩耗が異常で、四輪の位置決めとタイヤの圧力を確認することを提案します。',

  'txt_wear_lookup': 'タイヤの摩耗状況を確認してください。',
  'LFtxt_wear_lookup': '[左前輪]タイヤの摩耗状況を確認してください。',
  'RFtxt_wear_lookup': '[右前輪]タイヤの摩耗状況を確認してください。',
  'LBtxt_wear_lookup': '[左後輪]タイヤの摩耗状況を確認してください。',
  'RBtxt_wear_lookup': '[右後輪]タイヤの摩耗状況を確認してください。',
  'FFtxt_wear_lookup': '[前軸]タイヤの摩耗状況を確認してください。',
  'BBtxt_wear_lookup': '[后軸]タイヤの摩耗状況を確認してください。',

  'txt_appearance_lookup': 'タイヤの外観を確認してください。',
  'LFtxt_appearance_lookup': '[左前輪]タイヤの外観を確認してください。',
  'RFtxt_appearance_lookup': '[右前輪]タイヤの外観を確認してください。',
  'LBtxt_appearance_lookup': '[左後輪]タイヤの外観を確認してください。',
  'RBtxt_appearance_lookup': '[右後輪]タイヤの外観を確認してください。',
  'FFtxt_appearance_lookup': '[前軸]タイヤの外観を確認してください。',
  'BBtxt_appearance_lookup': '[后軸]タイヤの外観を確認してください。',

  'txt_serious_trauma_check': 'タイヤに大きな外傷がありますので、タイヤの交換をおすすめします',
  'LFtxt_serious_trauma_check': '[左前輪]タイヤに大きな外傷がありますので、タイヤの交換をおすすめします',
  'RFtxt_serious_trauma_check': '[右前輪]タイヤに大きな外傷がありますので、タイヤの交換をおすすめします',
  'LBtxt_serious_trauma_check': '[左後輪]タイヤに大きな外傷がありますので、タイヤの交換をおすすめします',
  'RBtxt_serious_trauma_check': '[右後輪]タイヤに大きな外傷がありますので、タイヤの交換をおすすめします',
  'FFtxt_serious_trauma_check': '[前軸]タイヤに大きな外傷がありますので、タイヤの交換をおすすめします',
  'BBtxt_serious_trauma_check': '[后軸]タイヤに大きな外傷がありますので、タイヤの交換をおすすめします',

  'purp_tireservice':'タイヤサービス',
  'purp_vehicle_inspection':'車両検査',
  'merchant': '商户',
  'manager': '店長',
  'assistant': '店員',

  'txt_chassis_check': 'シャーシ検査',
  'txt_chassis_ok': 'シャーシの検査が良いです',
   'LFtxt_chassis_ok': '[L-F]シャーシの検査が良いです',
  'RFtxt_chassis_ok': '[R-F]シャーシの検査が良いです',
  'LBtxt_chassis_ok': '[L-B]シャーシの検査が良いです',
  'RBtxt_chassis_ok': '[R-R]シャーシの検査が良いです',
  'FFtxt_chassis_ok': '[F-F]シャーシの検査が良いです',
  'BBtxt_chassis_ok': '[B-B]シャーシの検査が良いです',
  'txt_chassis_oil': 'ダンパーから油が漏れていますので、さらに確認することをお勧めします。必要な時は交換してください',
   'LFtxt_chassis_oil': '[L-F]ダンパーから油が漏れていますので、さらに確認することをお勧めします。必要な時は交換してください',
  'RFtxt_chassis_oil': '[R-F]ダンパーから油が漏れていますので、さらに確認することをお勧めします。必要な時は交換してください',
  'LBtxt_chassis_oil': '[L-B]ダンパーから油が漏れていますので、さらに確認することをお勧めします。必要な時は交換してください',
  'RBtxt_chassis_oil': '[R-R]ダンパーから油が漏れていますので、さらに確認することをお勧めします。必要な時は交換してください',
  'FFtxt_chassis_oil': '[F-F]ダンパーから油が漏れていますので、さらに確認することをお勧めします。必要な時は交換してください',
  'BBtxt_chassis_oil': '[B-B]ダンパーから油が漏れていますので、さらに確認することをお勧めします。必要な時は交換してください',
  'txt_chassis_aging': 'ブッシュゴムの劣化は、交換処理を推奨します',
   'LFtxt_chassis_aging': '[L-F]ブッシュゴムの劣化は、交換処理を推奨します',
  'RFtxt_chassis_aging': '[R-F]ブッシュゴムの劣化は、交換処理を推奨します',
  'LBtxt_chassis_aging': '[L-B]ブッシュゴムの劣化は、交換処理を推奨します',
  'RBtxt_chassis_aging': '[R-R]ブッシュゴムの劣化は、交換処理を推奨します',
  'FFtxt_chassis_aging': '[F-F]ブッシュゴムの劣化は、交換処理を推奨します',
  'BBtxt_chassis_aging': '[B-B]ブッシュゴムの劣化は、交換処理を推奨します',
   'awd': 'App Download',

   'depth': 'Trench Depth',
    'usura': 'Eccentric Wear',
    'usura-l': 'The pattern is worn evenly',
    'usura-m': 'Slight eccentric wear',
    'usura-h': 'ひどい偏摩耗',

    'engine-hood': 'ENGINE BONNET',
    'change-control': 'CHANGE CONTROL',
    'subtotal': 'UNDERBODY',

    'replace': 'REPLACE',
    'vedi': 'READ NOTE',
    'ok': 'OK',

    'liquid': 'GLASS WASHING LIQUID',
    'level-control': 'Level control',
    'air': 'AIR CONDITIONER',
    'last-reload': 'Last recharge',
    'absorber': 'SHOCK ABSORBER',
    'check-loss': 'Check of visible leaks',
    'fluid': 'RADIATOR FLUID',
    'lighting': 'LIGHTS',
    'verification': 'Opeation check',
    'disc': 'BRAKE DISCS',
    'condition': 'Wearing',
    'oil': 'MOTOR OI',
    'wiper': 'WIPERS',
    'pads': 'BRAKE PADS',
    'brake-fluid': 'BRAKE FLUID',
    'quality-control': 'Quality check',
    'battery': 'BATTERY',
    'multimetro': 'Multimetro test',
    'control-quality': 'QUALITY CHECK',
    'tightening': 'Dynamometer tightening',
    'buone': 'ok',
    'lavoro': 'Work scheduling',
    'concordare': 'Allow substitution',

    'com':'商用車',
    'pass':'車に乗る',
}
