import React, { Component } from 'react';
import intl from "react-intl-universal";
import "./css/Appearance.scss";

class Appearance extends Component{
    constructor(props) {
        super(props);
        this.state ={};
    }
     render() {
        const getInstall = (content) =>{
            let install = '';
            if (content.includes('abnormal')) {
            install = {color: 'red', text: intl.get('abnormal')};
            return install;
        } else {
            install = {color: 'green', text: intl.get('trauma_normal')}
        }
            return install
    };
        return (
            <div>
                <span style={{fontWeight:"bold"}}>{intl.get('tire_installed')}:</span><span style={{color: getInstall(this.props.content).color, paddingLeft:10}}>{getInstall(this.props.content).text}</span>
            </div>
        )
     }
}

export default Appearance;