import React from "react";

export default {
  'login':'Login',
  'input_name':'Indtast dit navn',
  'input_password':'Indtast venligst din adgangskode',
  'login_su':'Login lykkedes',
  'role':'rolle',
  'username':'Bruger konto',
  'password':'Password',
  'search_btn': 'search',
  'reports': 'Inspection Reports',
  'setting': 'System Setting',
  'store':'Gem',
  'number':'Nummer',
  'device_time':'Påvisningstid',
  'wear_co':'Forhold til brug',
  'ecc_co':'Eksternt slid',
  'plate_no':'Nummerplade',
  'cus_ph':'Kundens mobilnummer',
  'car_type':'Køretøjs type',
  'operation':'Operere',
  'view':'Se mere',
  'manufacturer':'Fabrikant',
  'porduced_year':'Produktionsdatoen',

  'print':'trykke(Baggrundsgrafik skal kontrolleres)',
  'store_name':'Navn på forretning',
  'address':'Adresse',
  'model':'Køretøjs model',
  'mileage':'Kilometertal',
  'device':'Enhed',
  'remark':'Bemærkning',
  'purpose':'Formål',
  're':'Testanbefalinger',
  'color_flag':'Farveidentifikation',
  'wear':'Samlet slid',
  'brake':'Den aktuelle bremselængde',
  'ecc':'Unormalt slid',
  'lf': 'VF',
  'lr': 'VB',
  'rf': 'HF',
  'rr': 'HB',
  'untest':'Ikke inspiceret',
  'low':'Normal',
  'middle':'Grænse',
  'high':'Udskift',
  'avg':'Almindelig.',
  'spec':"Størrelse",
  'brand':'Fabrikat',
  'ecc_avg':'Mønsteret er jævnt slidt',
  'trauma':'Dæk beskadiget',
  'zc':'normal',
  'image':'dk',
  'qr':'Scan koden for at få vist rapporten',
  'model_number':'Håndholdt dækdetektor',
  'purp_wash':'Bilvask & klargøring',
  'purp_alignment':'Udmåling',
  'purp_replace':'Dæk skift',
  'purp_maintain':'Vedligeholdelse',
  'warn': 'minde：Afhængigt af det aktuelle dækslid kan dette resultere i en stigning i bremselængden',
  'trauma_normal':'normal',
  'trauma_bulge':'Udbulning',
  'trauma_aging':'Gamle',
  'damage':'Billeder af traumer',
  'dilVBe':'Tjek rapporten',

  'txt_lf_outworn':'Ydersiden af venstre fordæk er mere slidt end indersiden',
  'txt_lf_innerworn':'Ydersiden af venstre fordæk er mere slidt end indersiden',
  'txt_lf_bothsideworn':'Venstre fordæk bæres mere på begge sider end på midten',
  'txt_lf_centreworn':'Midten af venstre fordæk er mere slidt end siderne',
  'txt_lb_outworn':'Ydersiden af venstre bagdæk er mere slidt end indersiden',
  'txt_lb_innerworn':'Indersiden af venstre bagdæk er mere slidt end ydersiden',
  'txt_lb_bothsideworn':'Venstre bagdæk bæres dårligere på begge sider end på midten',
  'txt_lb_centreworn':'Midten af venstre bagdæk er mere slidt end siderne',
  'txt_rb_outworn':'Ydersiden af højre bagdæk er mere slidt end indersiden',
  'txt_rb_innerworn': 'Indersiden af højre bagdæk er mere slidt end ydersiden',
  'txt_rb_bothsideworn': 'Siderne af højre bagdæk er mere slidte end midten',
  'txt_rb_centreworn': 'Midten af højre bagdæk er mere slidt end siderne',
  'txt_rf_outworn': 'Ydersiden af højre fordæk er mere slidt end indersiden',
  'txt_rf_innerworn':  'Ydersiden af højre fordæk er mere slidt end indersiden',
  'txt_rf_bothsideworn': 'Højre fordæk bæres mere på begge sider end på midten',
  'txt_rf_centreworn': 'Midten af højre fordæk er mere slidt end siderne' ,
  'txt_ecc_abnormal': 'Dæk bias er unormal',

  'txt_tire_need_replace':          'Hvis dækkene er dårligt slidte, skal du straks udskifte dem',
  'txt_tire_boundary':              'Dæk slid er i en grænse tilstand, skal du kontrollere regelmæssigt',
  'txt_alignment_chassis':          'Yderligere firehjulspositionering eller chassistest anbefales',
  'txt_wear_uniform':               'Dæk slid er mere ensartet',
  'txt_do_further_check':           'Tjek venligst yderligere',
  'txt_uneven_check':                'Dækslid er unormalt, anbefales det at flytte balancen og firehjulspositionering, inspektion af affjedringssystem',
  'txt_tire_rotation':              'Dækskift anbefales',
  'txt_wear_normal':                'Dækslid er normalt',
  'txt_tire':                       'dæk',

  'time':'Vælg det klokkeslæt, hvor',
  'today':'I dag',
  'yesterday':'I går',
  'week':'Uge',
  'month':'Måned',
  'last_month':'Sidste måned',
  'year':'i år',
  'language':'Vælg et sprog',
  'zh':'中文',
  'en':'英语',
  'ma':'马来语',
  'ge':'德语',
  'fr':'法语',
  'lt':'意大利语',
  'sp':'西班牙语',
  'tw':'繁体',
  'jp':'日语',
  'po':'波兰语',
  'por':'葡萄牙语',
  'download':'Hent rapporten',
  'advise': 'forslag：Dækket er forudindtaget, og yderligere undersøgelse anbefales',

  'txt_tire_age_5': 'Dæk er gyldige i mere end 5 år, og det anbefales at ændre dem;',
  'txt_tire_age_3': 'Dæk er gyldige i mere end 3 år, tjek dem regelmæssigt;',
  'txt_tire_age_ok': 'Dæklivet er godt;',
  'txt_tire_trauma_check': 'Dækket har traumer, og yderligere undersøgelse anbefales',
  'txt_install_abnormal_check': 'Hvis dækkene er vendt, skal du bekræfte håndteringen;',
  'txt_tire_appearance_ok': 'Dækkene ser godt ud;',
  'txt_pinnate_check': 'Fjerslid på dæk anbefales, og firehjulspositionerings- og affjedringssystemer kontrolleres;',
  'trauma_lacerate': 'særskilt',
  'trauma_dropblock': 'Slip blokken',
  'trauma_puncture': 'Punktere',
  'trauma_scratch': 'skrabe',
  'trauma_pinnate': 'Fjer slid',
  'txt_tire_age': 'Dæk liv',
  'txt_tire appearance': 'Dæk beskadiget',
  'tire_installed': 'Installationskontrol',
  'abnormal': 'Inde og ude',
  'endurance': 'Anslået dæksortiment',
  'endurance-warn': 'Den resterende rækkevidde er{number}km',
  'endurance-zero': 'Dækket har nået sin grænse, skal du udskifte det straks',
  'estimated_value': 'Skøn',
  'abrasion': 'Slidte',
  'remanent': 'Der er {days} dage tilbage i dæklivet',
  'useful_life': 'Anslået dæklevetid',
  'dot_explain': 'DOT：Produktionstid for dæk',
  'unit_km': 'enhed：km',
  'unit_year': 'enhed：år',
  'dot_no': 'Den korrekte produktionstid blev ikke indhentet',
  'made_time': 'Produktionstid：{dot}(uge {week} i {year})',
  'exceed_five': 'Dæk er gyldige i mere end 5 år, skal du udskifte dem med det samme',
  'inspector': 'ingeniør',
  'txt_install_check': 'Kontrol af installationen',
  'txt_install_ok': 'Installationen er ok',
  'txt_pinnate_wear': 'Fjer slid',

  'LFtxt_tire_need_replace': '[VF]Dækkene er alvorligt slidte, og udskiftning anbefales',
  'RFtxt_tire_need_replace': '[HF]Dækkene er alvorligt slidte, og udskiftning anbefales',
  'LBtxt_tire_need_replace': '[VB]Dækkene er alvorligt slidte, og udskiftning anbefales',
  'RBtxt_tire_need_replace': '[HB]Dækkene er alvorligt slidte, og udskiftning anbefales',
  'FFtxt_tire_need_replace': '[F-F]Dækkene er alvorligt slidte, og udskiftning anbefales',
  'BBtxt_tire_need_replace': '[B-B]Dækkene er alvorligt slidte, og udskiftning anbefales',


  'txt_pattern_wear': 'Unormalt slid',
  'txt_uneven_center': 'Unormalt slid i midten af dækket, anbefales det at kontrollere dæktrykket',
  'LFtxt_uneven_center': '[VF]Unormalt slid i midten af dækket, anbefales det at kontrollere dæktrykket',
  'RFtxt_uneven_center': '[HF]Unormalt slid i midten af dækket, anbefales det at kontrollere dæktrykket',
  'LBtxt_uneven_center': '[VB]Unormalt slid i midten af dækket, anbefales det at kontrollere dæktrykket',
  'RBtxt_uneven_center': '[HB]Unormalt slid i midten af dækket, anbefales det at kontrollere dæktrykket',
  'FFtxt_uneven_center': '[F-F]Unormalt slid i midten af dækket, anbefales det at kontrollere dæktrykket',
  'BBtxt_uneven_center': '[B-B]Unormalt slid i midten af dækket, anbefales det at kontrollere dæktrykket',

  'txt_uneven_out': 'Unormalt slid på ydersiden af dækket, anbefales det at kontrollere firehjulspositionering og dæktryk',
  'LFtxt_uneven_out': '[VF]Unormalt slid på ydersiden af dækket, anbefales det at kontrollere firehjulspositionering og dæktryk',
  'RFtxt_uneven_out': '[HF]Unormalt slid på ydersiden af dækket, anbefales det at kontrollere firehjulspositionering og dæktryk',
  'LBtxt_uneven_out': '[VB]Unormalt slid på ydersiden af dækket, anbefales det at kontrollere firehjulspositionering og dæktryk',
  'RBtxt_uneven_out': '[HB]Unormalt slid på ydersiden af dækket, anbefales det at kontrollere firehjulspositionering og dæktryk',
  'FFtxt_uneven_out': '[F-F]Unormalt slid på ydersiden af dækket, anbefales det at kontrollere firehjulspositionering og dæktryk',
  'BBtxt_uneven_out': '[B-B]Unormalt slid på ydersiden af dækket, anbefales det at kontrollere firehjulspositionering og dæktryk',

  'LFtxt_pinnate_check':'[VF]Fjerslid på dæk anbefales, og firehjulspositionerings- og affjedringssystemer kontrolleres',
  'RFtxt_pinnate_check':'[HF]Fjerslid på dæk anbefales, og firehjulspositionerings- og affjedringssystemer kontrolleres',
  'LBtxt_pinnate_check':'[VB]Fjerslid på dæk anbefales, og firehjulspositionerings- og affjedringssystemer kontrolleres',
  'RBtxt_pinnate_check':'[HB]Fjerslid på dæk anbefales, og firehjulspositionerings- og affjedringssystemer kontrolleres',
  'FFtxt_pinnate_check':'[F-F]Fjerslid på dæk anbefales, og firehjulspositionerings- og affjedringssystemer kontrolleres',
  'BBtxt_pinnate_check':'[B-B]Fjerslid på dæk anbefales, og firehjulspositionerings- og affjedringssystemer kontrolleres',

  'txt_uneven_inside': 'Unormalt slid på indersiden af dækket, anbefales det at kontrollere firehjulspositionering og dæktryk',
  'LFtxt_uneven_inside': '[VF]Unormalt slid på indersiden af dækket, anbefales det at kontrollere firehjulspositionering og dæktryk',
  'RFtxt_uneven_inside': '[HF]Unormalt slid på indersiden af dækket, anbefales det at kontrollere firehjulspositionering og dæktryk',
  'LBtxt_uneven_inside': '[VB]Unormalt slid på indersiden af dækket, anbefales det at kontrollere firehjulspositionering og dæktryk',
  'RBtxt_uneven_inside': '[HB]Unormalt slid på indersiden af dækket, anbefales det at kontrollere firehjulspositionering og dæktryk',
  'FFtxt_uneven_inside': '[F-F]Unormalt slid på indersiden af dækket, anbefales det at kontrollere firehjulspositionering og dæktryk',
  'BBtxt_uneven_inside': '[B-B]Unormalt slid på indersiden af dækket, anbefales det at kontrollere firehjulspositionering og dæktryk',

  'FFtxt_uneven_check': '[F-F]Dækslid er unormalt, anbefales det at flytte balancen og firehjulspositionering, inspektion af affjedringssystem',
  'BBtxt_uneven_check': '[B-B]Dækslid er unormalt, anbefales det at flytte balancen og firehjulspositionering, inspektion af affjedringssystem',


  'LFtxt_tire_age_5': '[VF]Dæk er gyldige i mere end 5 år, og det anbefales at ændre dem',
  'RFtxt_tire_age_5': '[HF]Dæk er gyldige i mere end 5 år, og det anbefales at ændre dem',
  'LBtxt_tire_age_5': '[VB]Dæk er gyldige i mere end 5 år, og det anbefales at ændre dem',
  'RBtxt_tire_age_5': '[HB]Dæk er gyldige i mere end 5 år, og det anbefales at ændre dem',
  'FFtxt_tire_age_5': '[F-F]Dæk er gyldige i mere end 5 år, og det anbefales at ændre dem',
  'BBtxt_tire_age_5': '[B-B]Dæk er gyldige i mere end 5 år, og det anbefales at ændre dem',

  'txt_appearance_check':'Hvis der er traumer i dækket, bedes du bekræfte reparationen',
  'LFtxt_appearance_check':'[VF]Hvis der er traumer i dækket, bedes du bekræfte reparationen',
  'RFtxt_appearance_check':'[HF]Hvis der er traumer i dækket, bedes du bekræfte reparationen',
  'LBtxt_appearance_check':'[VB]Hvis der er traumer i dækket, bedes du bekræfte reparationen',
  'RBtxt_appearance_check':'[HB]Hvis der er traumer i dækket, bedes du bekræfte reparationen',
  'FFtxt_appearance_check':'[F-F]Hvis der er traumer i dækket, bedes du bekræfte reparationen',
  'BBtxt_appearance_check':'[B-B]Hvis der er traumer i dækket, bedes du bekræfte reparationen',


  'LFtxt_install_abnormal_check':'[VF]Hvis dækkene er vendt, skal du bekræfte håndteringen',
  'RFtxt_install_abnormal_check':'[HF]Hvis dækkene er vendt, skal du bekræfte håndteringen',
  'LBtxt_install_abnormal_check':'[VB]Hvis dækkene er vendt, skal du bekræfte håndteringen',
  'RBtxt_install_abnormal_check':'[HB]Hvis dækkene er vendt, skal du bekræfte håndteringen',
  'FFtxt_install_abnormal_check':'[F-F]Hvis dækkene er vendt, skal du bekræfte håndteringen',
  'BBtxt_install_abnormal_check':'[B-B]Hvis dækkene er vendt, skal du bekræfte håndteringen',

  'txt_uneven_bothside': 'Unormalt slid på begge sider af dækket, anbefales det at kontrollere firehjulspositionering og dæktryk',
  'LFtxt_uneven_bothside': '[VF]Unormalt slid på begge sider af dækket, anbefales det at kontrollere firehjulspositionering og dæktryk',
  'RFtxt_uneven_bothside': '[HF]Unormalt slid på begge sider af dækket, anbefales det at kontrollere firehjulspositionering og dæktryk',
  'LBtxt_uneven_bothside': '[VB]Unormalt slid på begge sider af dækket, anbefales det at kontrollere firehjulspositionering og dæktryk',
  'RBtxt_uneven_bothside': '[HB]Unormalt slid på begge sider af dækket, anbefales det at kontrollere firehjulspositionering og dæktryk',
  'FFtxt_uneven_bothside': '[F-F]Unormalt slid på begge sider af dækket, anbefales det at kontrollere firehjulspositionering og dæktryk',
  'BBtxt_uneven_bothside': '[B-B]Unormalt slid på begge sider af dækket, anbefales det at kontrollere firehjulspositionering og dæktryk',

  'txt_wear_lookup': 'Tjek dækslidet',
  'LFtxt_wear_lookup': '[VF]Tjek dækslidet',
  'RFtxt_wear_lookup': '[HF]Tjek dækslidet',
  'LBtxt_wear_lookup': '[VB]Tjek dækslidet',
  'RBtxt_wear_lookup': '[HB]Tjek dækslidet',
  'FFtxt_wear_lookup': '[F-F]Tjek dækslidet',
  'BBtxt_wear_lookup': '[B-B]Tjek dækslidet',

  'txt_appearance_lookup': 'Kontroller dækkenes udseende',
  'LFtxt_appearance_lookup': '[VF]Kontroller dækkenes udseende',
  'RFtxt_appearance_lookup': '[HF]Kontroller dækkenes udseende',
  'LBtxt_appearance_lookup': '[VB]Kontroller dækkenes udseende',
  'RBtxt_appearance_lookup': '[HB]Kontroller dækkenes udseende',
  'FFtxt_appearance_lookup': '[F-F]Kontroller dækkenes udseende',
  'BBtxt_appearance_lookup': '[B-B]Kontroller dækkenes udseende',

  'txt_serious_trauma_check': 'Dæk er alvorligt tilskadekomne, og udskiftning anbefales',
  'LFtxt_serious_trauma_check': '[VF]Dæk er alvorligt tilskadekomne, og udskiftning anbefales',
  'RFtxt_serious_trauma_check': '[HF]Dæk er alvorligt tilskadekomne, og udskiftning anbefales',
  'LBtxt_serious_trauma_check': '[VB]Dæk er alvorligt tilskadekomne, og udskiftning anbefales',
  'RBtxt_serious_trauma_check': '[HB]Dæk er alvorligt tilskadekomne, og udskiftning anbefales',
  'FFtxt_serious_trauma_check': '[F-F]Dæk er alvorligt tilskadekomne, og udskiftning anbefales',
  'BBtxt_serious_trauma_check': '[B-B]Dæk er alvorligt tilskadekomne, og udskiftning anbefales',

  'purp_tireservice':'Dæk service',
  'purp_vehicle_inspection':'Inspektion af køretøjer',
  'merchant': 'Virksomheder',
  'manager': 'Butikschef',
  'assistant': 'ekspedient',

  'txt_chassis_check': 'Kontrol af kabinet',
  'txt_chassis_ok': 'Chassiset er godt kontrolleret',
  'LFtxt_chassis_ok': '[VF]Chassiset er godt kontrolleret',
  'RFtxt_chassis_ok': '[HF]Chassiset er godt kontrolleret',
  'LBtxt_chassis_ok': '[VB]Chassiset er godt kontrolleret',
  'RBtxt_chassis_ok': '[HB]Chassiset er godt kontrolleret',
  'FFtxt_chassis_ok': '[F-F]Chassiset er godt kontrolleret',
  'BBtxt_chassis_ok': '[B-B]Chassiset er godt kontrolleret',
  'txt_chassis_oil': 'Lækage af støddæmperolie anbefales det at udføre yderligere bekræftelse, hvis det er nødvendigt, skal du udskifte behandlingen',
  'LFtxt_chassis_oil': '[VF]Lækage af støddæmperolie anbefales det at udføre yderligere bekræftelse, hvis det er nødvendigt, skal du udskifte behandlingen',
  'RFtxt_chassis_oil': '[HF]Lækage af støddæmperolie anbefales det at udføre yderligere bekræftelse, hvis det er nødvendigt, skal du udskifte behandlingen',
  'LBtxt_chassis_oil': '[VB]Lækage af støddæmperolie anbefales det at udføre yderligere bekræftelse, hvis det er nødvendigt, skal du udskifte behandlingen',
  'RBtxt_chassis_oil': '[HB]Lækage af støddæmperolie anbefales det at udføre yderligere bekræftelse, hvis det er nødvendigt, skal du udskifte behandlingen',
  'FFtxt_chassis_oil': '[F-F]Lækage af støddæmperolie anbefales det at udføre yderligere bekræftelse, hvis det er nødvendigt, skal du udskifte behandlingen',
  'BBtxt_chassis_oil': '[B-B]Lækage af støddæmperolie anbefales det at udføre yderligere bekræftelse, hvis det er nødvendigt, skal du udskifte behandlingen',
  'txt_chassis_aging': 'Akselgummi ældning, anbefales det at erstatte',
  'LFtxt_chassis_aging': '[VF]Akselgummi ældning, anbefales det at erstatte',
  'RFtxt_chassis_aging': '[HF]Akselgummi ældning, anbefales det at erstatte',
  'LBtxt_chassis_aging': '[VB]Akselgummi ældning, anbefales det at erstatte',
  'RBtxt_chassis_aging': '[HB]Akselgummi ældning, anbefales det at erstatte',
  'FFtxt_chassis_aging': '[F-F]Akselgummi ældning, anbefales det at erstatte',
  'BBtxt_chassis_aging': '[B-B]Akselgummi ældning, anbefales det at erstatte',
  'awd': 'APP Downloade',

  'depth': 'Rilledybde',
  'usura': 'Skæv slitage',
  'usura-l': 'Mønsteret er jævnt slidt',
  'usura-m': 'Lidt forudindtaget',
  'usura-h': 'Stærkt forudindtaget',

  'engine-hood': 'MOTORHJÆLM',
  'change-control': 'ANDRE KONTROLELEMENTER',
  'subtotal': 'UNDERVOGNEN',

  'replace': 'ERSTATTE',
  'vedi': 'SE NOTER',
  'ok': 'OK',

  'liquid': 'VINDUESPUDSER VÆSKE',
  'level-control': 'NIVEAUKONTROL',
  'air': 'KLIMAANLÆG',
  'last-reload': 'SIDSTE GENOPLADNING',
  'absorber': 'STØDDÆMPERE',
  'check-loss': 'TJEK SYNLIGE LÆKAGER',
  'fluid': 'RADIATORVÆSKE',
  'lighting': 'LYS',
  'verification': 'KONTROL AF DRIFTEN',
  'disc': 'BREMSESKIVER',
  'condition': 'SLIDTILSTAND',
  'oil': 'MOTOROLIE',
  'wiper': 'VINDUESVISKERE',
  'pads': 'BREMSEKLODSER',
  'brake-fluid': 'BREMSEVÆSKE',
  'quality-control': 'KVALITETSKONTROL',
  'battery': 'BATTERI',
  'multimetro': 'MULTIMETERTEST',
   'control-quality': 'KVALITETSKONTROL',
   'tightening': 'FASTSPÆNDING MED DYNAMOMETER UDFØRT AF',
  'buone': 'NORMAL',
  'lavoro': 'SE NOTER',
  'concordare': 'ERSTATTE',
  'season_summer': 'Sommer Dæk ',
  'season_winter': 'Winnter Dæk',
  'wearecc': 'Slitage mønster & Skæv slitage',

  'inspection_list':'Tjek listen over biler',
  'lights':'lampelys',
  'high_beam': 'Højlys',
  'low_beam':'Nearlight',
  'daytime': 'Daylights',
  'fog_light':'Tågelygter',
  'break_light':'Bremselys',
  'turn_signals':'Drej lyset',
  'backup_lights':'Baklys',
  'hazards':'Nødlys',
  'glass':'Glas',
  'windshield':'Forrude',
  'backglass':'Bagrude',
  'right_glass':'Højre glas',
  'left_glass':'Venstre glas',
  'wipers_front':'Vinduesvisker foran',
  'wipers_rear':'Vinduesvisker efter',
  'under_hood':'Motorrum',
  'horn':'horn',
  'oil_level':'Olieniveau',
  'air_filter':'Tomt filter',
  'trans_fluid':'Olie',
  'break_fluid':'Bremsevæske',
  'coolant_level':'Kølevæskeniveau',
  'windshield_washer_fluid':'Glas vand',
  'power_steering_fluid':'Styrevæske',
  'belts':'bælte',
  'hoses':'slange',
  'ac_system':'Klimaanlæg',
  // 'battery':'蓄电池',
  'under_vehicle':'chassis',
  'suspension':'Ophængning',
  'exhaust':'Udblæsningsrør',
  'break_lines':'Bremselinjer',
  'oil_drain_plug':'Sæt olieproppen',
  'differential':'forskel',
  'breaks':'bremse',
  'le_fr_brake':'Venstre forbremse',
  'ri_fr_brake':'Højre forbremse',
  'le_re_brake':'Venstre bagbremse',
  'ri_re_brake':'Højre bagbremse',
  'parking_brake':'Parkering',
  'interior':'indre',
  'cabin_filter':'Aktivt kulfilter',
  'heating':'Varmeudstyr',
  'interior_lights':'Indvendig belysning',
  'fr_defrost':'optø før',
  're_defrost':'Efter afrimning',
  'pressure': 'Dæktryk',
  'nor':'normal',
  'caution':'seddel',
  'attention':'advare',

  'trauma_eattyre': 'Spis barnet',
  'txt_uneven_eattyre': 'Dæk spiser dæk, anbefales det at afbalancere bevægelsen, samt firehjulspositionering, suspensionssysteminspektion',
  'LFtxt_uneven_eattyre': '[VF]Dæk spiser dæk, anbefales det at afbalancere bevægelsen, samt firehjulspositionering, suspensionssysteminspektion',
  'RFtxt_uneven_eattyre': '[HF]Dæk spiser dæk, anbefales det at afbalancere bevægelsen, samt firehjulspositionering, suspensionssysteminspektion',
  'LBtxt_uneven_eattyre': '[VB]Dæk spiser dæk, anbefales det at afbalancere bevægelsen, samt firehjulspositionering, suspensionssysteminspektion',
  'RBtxt_uneven_eattyre': '[HB]Dæk spiser dæk, anbefales det at afbalancere bevægelsen, samt firehjulspositionering, suspensionssysteminspektion',
  'FFtxt_uneven_eattyre': '[F-F]Dæk spiser dæk, anbefales det at afbalancere bevægelsen, samt firehjulspositionering, suspensionssysteminspektion',
  'BBtxt_uneven_eattyre': '[B-B]Dæk spiser dæk, anbefales det at afbalancere bevægelsen, samt firehjulspositionering, suspensionssysteminspektion',


  'txt_appearance_eattyre': 'Dæk spiser dæk, anbefales det at afbalancere bevægelsen, samt firehjulspositionering, suspensionssysteminspektion',
  'LFtxt_appearance_eattyre': '[VF]Dæk spiser dæk, anbefales det at afbalancere bevægelsen, samt firehjulspositionering, suspensionssysteminspektion',
  'RFtxt_appearance_eattyre': '[HF]Dæk spiser dæk, anbefales det at afbalancere bevægelsen, samt firehjulspositionering, suspensionssysteminspektion',
  'LBtxt_appearance_eattyre': '[VB]Dæk spiser dæk, anbefales det at afbalancere bevægelsen, samt firehjulspositionering, suspensionssysteminspektion',
  'RBtxt_appearance_eattyre': '[HB]Dæk spiser dæk, anbefales det at afbalancere bevægelsen, samt firehjulspositionering, suspensionssysteminspektion',
  'FFtxt_appearance_eattyre': '[F-F]Dæk spiser dæk, anbefales det at afbalancere bevægelsen, samt firehjulspositionering, suspensionssysteminspektion',
  'BBtxt_appearance_eattyre': '[B-B]Dæk spiser dæk, anbefales det at afbalancere bevægelsen, samt firehjulspositionering, suspensionssysteminspektion',


  'txt_chassis_eattyre': 'Dæk spiser dæk, anbefales det til firehjulet positionering, affjedringssysteminspektion',
  'LFtxt_chassis_eattyre': '[VF]Dæk spiser dæk, anbefales det til firehjulet positionering, affjedringssysteminspektion',
  'RFtxt_chassis_eattyre': '[HF]Dæk spiser dæk, anbefales det til firehjulet positionering, affjedringssysteminspektion',
  'LBtxt_chassis_eattyre': '[VB]Dæk spiser dæk, anbefales det til firehjulet positionering, affjedringssysteminspektion',
  'RBtxt_chassis_eattyre': '[HB]Dæk spiser dæk, anbefales det til firehjulet positionering, affjedringssysteminspektion',
  'FFtxt_chassis_eattyre': '[F-F]Dæk spiser dæk, anbefales det til firehjulet positionering, affjedringssysteminspektion',
  'BBtxt_chassis_eattyre': '[B-B]Dæk spiser dæk, anbefales det til firehjulet positionering, affjedringssysteminspektion',

  'txt_uneven_high_eattyre':'Dæk er alvorligt dæk-spise, anbefales det at udskifte dækkene, samt firehjulede positionering, suspension system inspektion',
  'LFtxt_uneven_high_eattyre': '[VF]Dæk er alvorligt dæk-spise, anbefales det at udskifte dækkene, samt firehjulede positionering, suspension system inspektion',
  'RFtxt_uneven_high_eattyre': '[HF]Dæk er alvorligt dæk-spise, anbefales det at udskifte dækkene, samt firehjulede positionering, suspension system inspektion',
  'LBtxt_uneven_high_eattyre': '[VB]Dæk er alvorligt dæk-spise, anbefales det at udskifte dækkene, samt firehjulede positionering, suspension system inspektion',
  'RBtxt_uneven_high_eattyre': '[HB]Dæk er alvorligt dæk-spise, anbefales det at udskifte dækkene, samt firehjulede positionering, suspension system inspektion',
  'FFtxt_uneven_high_eattyre': '[F-F]Dæk er alvorligt dæk-spise, anbefales det at udskifte dækkene, samt firehjulede positionering, suspension system inspektion',
  'BBtxt_uneven_high_eattyre': '[B-B]Dæk er alvorligt dæk-spise, anbefales det at udskifte dækkene, samt firehjulede positionering, suspension system inspektion',


  'txt_appearance_high_eattyre':'Dæk er alvorligt henter, dynamisk afbalancering anbefales, samt firehjulede positionering, suspension system inspektion',
  'LFtxt_appearance_high_eattyre': '[VF]Dæk er alvorligt henter, dynamisk afbalancering anbefales, samt firehjulede positionering, suspension system inspektion',
  'RFtxt_appearance_high_eattyre': '[HF]Dæk er alvorligt henter, dynamisk afbalancering anbefales, samt firehjulede positionering, suspension system inspektion',
  'LBtxt_appearance_high_eattyre': '[VB]Dæk er alvorligt henter, dynamisk afbalancering anbefales, samt firehjulede positionering, suspension system inspektion',
  'RBtxt_appearance_high_eattyre': '[HB]Dæk er alvorligt henter, dynamisk afbalancering anbefales, samt firehjulede positionering, suspension system inspektion',
  'FFtxt_appearance_high_eattyre': '[F-F]Dæk er alvorligt henter, dynamisk afbalancering anbefales, samt firehjulede positionering, suspension system inspektion',
  'BBtxt_appearance_high_eattyre': '[B-B]Dæk er alvorligt henter, dynamisk afbalancering anbefales, samt firehjulede positionering, suspension system inspektion',

  'txt_chassis_high_eattyre':'Dæk spiser alvorligt dæk, det anbefales til firehjulet positionering, suspensionssysteminspektion',
  'LFtxt_chassis_high_eattyre': '[VF]Dæk spiser alvorligt dæk, det anbefales til firehjulet positionering, suspensionssysteminspektion',
  'RFtxt_chassis_high_eattyre': '[HF]Dæk spiser alvorligt dæk, det anbefales til firehjulet positionering, suspensionssysteminspektion',
  'LBtxt_chassis_high_eattyre': '[VB]Dæk spiser alvorligt dæk, det anbefales til firehjulet positionering, suspensionssysteminspektion',
  'RBtxt_chassis_high_eattyre': '[HB]Dæk spiser alvorligt dæk, det anbefales til firehjulet positionering, suspensionssysteminspektion',
  'FFtxt_chassis_high_eattyre': '[F-F]Dæk spiser alvorligt dæk, det anbefales til firehjulet positionering, suspensionssysteminspektion',
  'BBtxt_chassis_high_eattyre': '[B-B]Dæk spiser alvorligt dæk, det anbefales til firehjulet positionering, suspensionssysteminspektion',

  'txt_chassis_uneven': 'Dæk er unormalt forudindtaget, anbefales det at kontrollere firehjuls positionering og affjedringssystem',

  'com':'Erhvervskøretøjer',
  'pass':'Personbil',

  // 大车翻译
  'truck': 'anhænger',
  'mount': 'påhængskøretøj',
  'left': 'Venstre',
  'right': 'Højre',
  'undevice': 'Blev ikke fundet',
  'axle': 'akse',
  'spare': 'reservehjul',
  'nodata': 'Ingen data på nuværende tidspunkt',
}
