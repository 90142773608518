export default {
  'login':'Wpisz się',
  'input_name':'Podaj nazwę użytkownika',
  'input_password':'Proszę podać hasło',
  'login_su':'Zalogowanie zakończone',
  'role':'rola',
  'username':'nazwa użytkownika',
  'password':'hasło',

  'search_btn': 'search',
  'store':'Zapisz nazwę',
  'number':'liczba',
  'device_time':'Czas detekcji',
  'wear_co':'Stan zużycia (lewy przód - prawy przód - lewy tył - prawy tył)',
  'ecc_co':'Stan zużycia ekscentrycznego (lewy przód - prawy przód - lewy tył - prawy tył)',
  'plate_no':'tablica rejestracyjna',
  'cus_ph':'Klient telefon komórkowy',
  'car_type':'Model pojazdu',
  'operation':'działanie',
  'view':'Szczegóły widoku',
  'reports': 'Inspection Reports',
  'setting': 'System Setting',

  'print':'Drukuj (sprawdź grafikę tła)',
  'store_name':'Sklep serwisowy',
  'address':'Adres sklepu',
  'model':'Wzór',
  'mileage':'Przebieg',
  'device':'Urządzenia badawcze',
  'remark':'uwagi',
  'purpose':'Przeznaczenie',
  're':'Propozycje dotyczące wykrywania',
  'color_flag':'Identyfikacja kolorów',
  'wear':'Ogólne zużycie eksploatacyjne',
  'brake':'Ostrzeżenie dotyczące aktualnej drogi hamowania ',
  'ecc':'Nieprawidłowe zużycie',
  'lf':'L-F',
  'lr':'L-R',
  'rf':'R-F',
  'rr':'R-R',
  'untest':'Nie wykryto żadnej opony',
  'low':'W dobrym stanie',
  'middle':'W stanie sparowanym',
  'high':'Należy niezwłocznie wymienić',
  'avg':'Wartość średnia',
  'spec':"Specyfikacje",
  'brand':'Marka',
  'ecc_avg':'Jednolite zużycie wzoru',
  'trauma':'Wygląd opony',
  'zc':'normalne',
  'image':'zh',
  'qr':'Skanowanie kodu QR do wyświetlenia raportu',
  'model_number':'HH Tire Tread Depth Detector',
  'purp_wash':'Mycie samochodu i dekoracje samochodowe',
  'purp_alignment':'Ustawienie czterech kół',
  'purp_replace':'Zmień oponę.',
  'purp_maintain':'Konserwacja',
  'warn': 'Przypominaj：W zależności od bieżącego zużycia opon, może to spowodować zwiększenie odległości hamowania',
  'trauma_normal':'normalne',
  'trauma_bulge':'Pęcherz',
  'trauma_aging':'Zużycie',

  'txt_lf_outworn':'Zewnętrzna strona lewego przedniego koła zużywa się poważniej niż strona wewnętrzna',
  'txt_lf_innerworn':'Wewnętrzna strona lewego przedniego koła jest zużyta poważniej niż strona zewnętrzna',
  'txt_lf_bothsideworn':'Obydwie ściany boczne lewego przedniego koła są zużyte poważniej niż bieżnik',
  'txt_lf_centreworn':'Bieżnik lewego przedniego koła jest zużyty poważniej niż obie ściany boczne',
  'txt_lb_outworn':'Zewnętrzna strona lewego tylnego koła zużywa się poważniej niż strona wewnętrzna',
  'txt_lb_innerworn':'Strona wewnętrzna lewego tylnego koła jest zużyta poważniej niż strona zewnętrzna',
  'txt_lb_bothsideworn':'Obie ściany boczne lewego tylnego koła są zużyte poważniej niż bieżnik',
  'txt_lb_centreworn':'Bieżnik lewego tylnego koła jest zużyty poważniej niż obie ściany boczne',
  'txt_rb_outworn':'Zewnętrzna strona prawego tylnego koła jest zużyta poważniej niż strona wewnętrzna',
  'txt_rb_innerworn': 'Wewnętrzna strona prawego tylnego koła jest zużyta poważniej niż strona zewnętrzna',
  'txt_rb_bothsideworn': 'Obydwie ściany boczne prawego tylnego koła są zużyte poważniej niż bieżnik',
  'txt_rb_centreworn': 'Bieżnik prawego tylnego koła jest zużyty poważniej niż obie ściany boczne',
  'txt_rf_outworn': 'Zewnętrzna strona prawego przedniego koła zużywa się poważniej niż strona wewnętrzna',
  'txt_rf_innerworn':  'Wewnętrzna strona prawego przedniego koła jest zużyta poważniej niż strona zewnętrzna',
  'txt_rf_bothsideworn': 'Obydwie ściany boczne prawego przedniego koła są zużyte poważniej niż bieżnik',
  'txt_rf_centreworn': 'Bieżnik prawego przedniego koła jest zużyty poważniej niż obie ściany boczne' ,

  'txt_tire_need_replace':          'Opona jest poważnie zużyta. Proszę ją natychmiast wymienić.',
  'txt_tire_boundary':              'Opona jest zużyta do stanu granicznego, należy ją regularnie sprawdzać',
  'txt_alignment_chassis':          'Zalecamy dalsze sprawdzanie osiowania czterech kół lub badania podwozia',
  'txt_wear_uniform':               'Ubranie opon jest wyrównane.',
  'txt_do_further_check':           'Więcej informacji',
  'txt_uneven_check':                'Jeżeli opona jest uszkodzona nieprawidłowo, zaleca się sprawdzenie ustawień na cztery koła i części podwozia.',
  'txt_tire_rotation':              'Zalecamy obracanie opon',
  'txt_wear_normal':                'Normalne zużycie opon',
  'txt_tire':                       'opona',

  'time':'Proszę wybrać czas',
  'today':'Dziś',
  'yesterday':'Wczoraj',
  'week':'W tym tygodniu',
  'month':'W tym miesiącu',
  'last_month':'W zeszłym miesiącu',
  'year':'W tym roku',
  'language':'Proszę wybrać język',
  'zh':'chiński',
  'en':'AngielskiName',
  'ma':'Nieprawidłowe',
  'ge':'Niemiecki',
  'fr':'Francuski',
  'lt':'Włoskie',
  'sp':'Hiszpański',
  'tw':'tradycyjny chiński',
  'jp':'Japoński',
   'po':'Polska',
  'por':'Portugalski',
  'download':'Raport pobierania',
  'advise': 'Propozycje：Jest ekscentryczne zużycie w oponie, zaleca się dalszą kontrolę',

  'txt_tire_age_5': 'Opona była używana przez ponad 5 lat po wygaśnięciu jej ważności, zalecamy jej wymianę;',
  'txt_tire_age_3': 'Opona jest ważna dłużej niż trzy lata. Proszę ją regularnie sprawdzać.;',
  'txt_tire_age_ok': 'Opona nie przekroczyła swojej granicy wieku;',
  'txt_tire_trauma_check': 'Na bieżniku jest uraz, zaleca się dalszą kontrolę.;',
  'txt_install_abnormal_check': 'Opony są odwrócone, proszę potwierdzić, aby obsłużyć;',
  'txt_tire_appearance_ok': 'Opona jest w dobrym stanie;',
  'txt_pinnate_check': 'Proponuje się sprawdzić równowagę dynamiczną, ustawienie czterokołowe i układ zawieszenia;',
  'trauma_lacerate': 'Podziel',
  'trauma_dropblock': 'Rzuć kawałek',
  'trauma_puncture': 'nakłucie',
  'trauma_scratch': 'Otarcia',
  'trauma_pinnate': 'Zużycie piór',
  'txt_tire_age': 'Życie opon',
  'txt_tire appearance': 'Wygląd opon',
  'tire_installed': 'Instalacja opon',
  'abnormal': 'Wewnątrz na odwrót.',
  'endurance': 'Oszacowanie przebiegu wytrzymałościowego opony',
  'endurance-warn': 'Pozostały kilometr to{number}km',
  'endurance-zero': 'Opona osiągnęła limit, proszę ją natychmiast wymienić.',
  'estimated_value': 'szacunkowo',
  'abrasion': 'otarcie',
  'remanent': 'Pozostały czas pracy opony {days} dni',
  'useful_life': 'Oszacowanie okresu eksploatacji opon',
  'dot_explain': 'DOT：Czas produkcji opon',
  'unit_km': 'Firma：km',
  'unit_year': 'Firma：rok',
  'dot_no': 'Nie uzyskano czasu produkcji',
  'made_time': 'Czas produkcji：{dot} ({week} tydzień {year})',
  'exceed_five': 'Opona jest ważna dłużej niż pięć lat, proszę ją natychmiast wymienić.',
  'inspector': 'technik',
  'txt_install_check': 'Wykrywanie instalacji',
  'txt_install_ok': 'Normalny montaż opon ',
  'txt_pinnate_wear': 'Zużycie piór',

  'LFtxt_tire_need_replace': '[L-F]Opona jest poważnie zużyta, zalecamy jej wymianę.',
  'RFtxt_tire_need_replace': '[R-F]Opona jest poważnie zużyta, zalecamy jej wymianę.',
  'LBtxt_tire_need_replace': '[L-R]Opona jest poważnie zużyta, zalecamy jej wymianę.',
  'RBtxt_tire_need_replace': '[R-R]Opona jest poważnie zużyta, zalecamy jej wymianę.',
  'FFtxt_tire_need_replace': '[F-F]Opona jest poważnie zużyta, zalecamy jej wymianę.',
  'BBtxt_tire_need_replace': '[B-B]Opona jest poważnie zużyta, zalecamy jej wymianę.',


  'txt_pattern_wear': 'Nieprawidłowe zużycie',
  'txt_uneven_center': 'Nienormalne zużycie bieżnika opony, zalecamy sprawdzenie ciśnienia w oponie',
  'LFtxt_uneven_center': '[L-F]Nienormalne zużycie bieżnika opony, zalecamy sprawdzenie ciśnienia w oponie',
  'RFtxt_uneven_center': '[R-F]Nienormalne zużycie bieżnika opony, zalecamy sprawdzenie ciśnienia w oponie',
  'LBtxt_uneven_center': '[L-R]Nienormalne zużycie bieżnika opony, zalecamy sprawdzenie ciśnienia w oponie',
  'RBtxt_uneven_center': '[R-R]Nienormalne zużycie bieżnika opony, zalecamy sprawdzenie ciśnienia w oponie',
  'FFtxt_uneven_center': '[F-F]Nienormalne zużycie bieżnika opony, zalecamy sprawdzenie ciśnienia w oponie',
  'BBtxt_uneven_center': '[B-B]Nienormalne zużycie bieżnika opony, zalecamy sprawdzenie ciśnienia w oponie',

  'txt_uneven_out': 'Zewnętrzna strona opony jest znormalnie zużyta. Zaleca się sprawdzić ustawienie czterech kół i ciśnienie opony',
  'LFtxt_uneven_out': '[L-F]Zewnętrzna strona opony jest znormalnie zużyta. Zaleca się sprawdzić ustawienie czterech kół i ciśnienie opony',
  'RFtxt_uneven_out': '[R-F]Zewnętrzna strona opony jest znormalnie zużyta. Zaleca się sprawdzić ustawienie czterech kół i ciśnienie opony',
  'LBtxt_uneven_out': '[L-R]Zewnętrzna strona opony jest znormalnie zużyta. Zaleca się sprawdzić ustawienie czterech kół i ciśnienie opony',
  'RBtxt_uneven_out': '[R-R]Zewnętrzna strona opony jest znormalnie zużyta. Zaleca się sprawdzić ustawienie czterech kół i ciśnienie opony',
  'FFtxt_uneven_out': '[F-F]Zewnętrzna strona opony jest znormalnie zużyta. Zaleca się sprawdzić ustawienie czterech kół i ciśnienie opony',
  'BBtxt_uneven_out': '[B-B]Zewnętrzna strona opony jest znormalnie zużyta. Zaleca się sprawdzić ustawienie czterech kół i ciśnienie opony',

  'LFtxt_pinnate_check':'[L-F]Proponuje się sprawdzić równowagę dynamiczną, ustawienie czterech kół i układ zawieszenia.',
  'RFtxt_pinnate_check':'[R-F]Proponuje się sprawdzić równowagę dynamiczną, ustawienie czterech kół i układ zawieszenia.',
  'LBtxt_pinnate_check':'[L-R]Proponuje się sprawdzić równowagę dynamiczną, ustawienie czterech kół i układ zawieszenia.',
  'RBtxt_pinnate_check':'[R-R]Proponuje się sprawdzić równowagę dynamiczną, ustawienie czterech kół i układ zawieszenia.',
  'FFtxt_pinnate_check':'[F-F]Proponuje się sprawdzić równowagę dynamiczną, ustawienie czterech kół i układ zawieszenia.',
  'BBtxt_pinnate_check':'[B-B]Proponuje się sprawdzić równowagę dynamiczną, ustawienie czterech kół i układ zawieszenia.',

  'txt_uneven_inside': 'Jeśli wewnętrzna strona opony jest znormalnie zużyta, zaleca się sprawdzenie ustawienia i ciśnienia w oponach.',
  'LFtxt_uneven_inside': '[L-F]Jeśli wewnętrzna strona opony jest znormalnie zużyta, zaleca się sprawdzenie ustawienia i ciśnienia w oponach.',
  'RFtxt_uneven_inside': '[R-F]Jeśli wewnętrzna strona opony jest znormalnie zużyta, zaleca się sprawdzenie ustawienia i ciśnienia w oponach.',
  'LBtxt_uneven_inside': '[L-R]Jeśli wewnętrzna strona opony jest znormalnie zużyta, zaleca się sprawdzenie ustawienia i ciśnienia w oponach.',
  'RBtxt_uneven_inside': '[R-R]Jeśli wewnętrzna strona opony jest znormalnie zużyta, zaleca się sprawdzenie ustawienia i ciśnienia w oponach.',
  'FFtxt_uneven_inside': '[F-F]Jeśli wewnętrzna strona opony jest znormalnie zużyta, zaleca się sprawdzenie ustawienia i ciśnienia w oponach.',
  'BBtxt_uneven_inside': '[B-B]Jeśli wewnętrzna strona opony jest znormalnie zużyta, zaleca się sprawdzenie ustawienia i ciśnienia w oponach.',

  'FFtxt_uneven_check': '[F-F]Jeżeli opona jest uszkodzona nieprawidłowo, zaleca się sprawdzenie ustawień na cztery koła i części podwozia.',
  'BBtxt_uneven_check': '[B-B]Jeżeli opona jest uszkodzona nieprawidłowo, zaleca się sprawdzenie ustawień na cztery koła i części podwozia.',


  'LFtxt_tire_age_5': '[L-F]Opona była używana przez ponad 5 lat po wygaśnięciu jej ważności, zalecamy jej wymianę',
  'RFtxt_tire_age_5': '[R-F]Opona była używana przez ponad 5 lat po wygaśnięciu jej ważności, zalecamy jej wymianę',
  'LBtxt_tire_age_5': '[L-R]Opona była używana przez ponad 5 lat po wygaśnięciu jej ważności, zalecamy jej wymianę',
  'RBtxt_tire_age_5': '[R-R]Opona była używana przez ponad 5 lat po wygaśnięciu jej ważności, zalecamy jej wymianę',
  'FFtxt_tire_age_5': '[F-F]Opona była używana przez ponad 5 lat po wygaśnięciu jej ważności, zalecamy jej wymianę',
  'BBtxt_tire_age_5': '[B-B]Opona była używana przez ponad 5 lat po wygaśnięciu jej ważności, zalecamy jej wymianę',

  'txt_appearance_check':'Uraz na oponie, proszę naprawić i potwierdzić',
  'LFtxt_appearance_check':'[L-F]Uraz na oponie, proszę naprawić i potwierdzić',
  'RFtxt_appearance_check':'[R-F]Uraz na oponie, proszę naprawić i potwierdzić',
  'LBtxt_appearance_check':'[L-R]Uraz na oponie, proszę naprawić i potwierdzić',
  'RBtxt_appearance_check':'[R-R]Uraz na oponie, proszę naprawić i potwierdzić',
  'FFtxt_appearance_check':'[F-F]Uraz na oponie, proszę naprawić i potwierdzić',
  'BBtxt_appearance_check':'[B-B]Uraz na oponie, proszę naprawić i potwierdzić',


  'LFtxt_install_abnormal_check':'[L-F]Opony są odwrócone, proszę potwierdzić, aby obsłużyć',
  'RFtxt_install_abnormal_check':'[R-F]Opony są odwrócone, proszę potwierdzić, aby obsłużyć',
  'LBtxt_install_abnormal_check':'[L-R]Opony są odwrócone, proszę potwierdzić, aby obsłużyć',
  'RBtxt_install_abnormal_check':'[R-R]Opony są odwrócone, proszę potwierdzić, aby obsłużyć',
  'FFtxt_install_abnormal_check':'[F-F]Opony są odwrócone, proszę potwierdzić, aby obsłużyć',
  'BBtxt_install_abnormal_check':'[B-B]Opony są odwrócone, proszę potwierdzić, aby obsłużyć',

  'txt_uneven_bothside': 'Nieprawidłowe zużycie po obu stronach opony. Zaleca się sprawdzenie ustawienia i ciśnienia w oponach.',
  'LFtxt_uneven_bothside': '[L-F]Nieprawidłowe zużycie po obu stronach opony. Zaleca się sprawdzenie ustawienia i ciśnienia w oponach.',
  'RFtxt_uneven_bothside': '[R-F]Nieprawidłowe zużycie po obu stronach opony. Zaleca się sprawdzenie ustawienia i ciśnienia w oponach.',
  'LBtxt_uneven_bothside': '[L-R]Nieprawidłowe zużycie po obu stronach opony. Zaleca się sprawdzenie ustawienia i ciśnienia w oponach.',
  'RBtxt_uneven_bothside': '[R-R]Nieprawidłowe zużycie po obu stronach opony. Zaleca się sprawdzenie ustawienia i ciśnienia w oponach.',
  'FFtxt_uneven_bothside': '[F-F]Nieprawidłowe zużycie po obu stronach opony. Zaleca się sprawdzenie ustawienia i ciśnienia w oponach.',
  'BBtxt_uneven_bothside': '[B-B]Nieprawidłowe zużycie po obu stronach opony. Zaleca się sprawdzenie ustawienia i ciśnienia w oponach.',

  'txt_wear_lookup': 'Proszę sprawdzić stan zużycia opon',
  'LFtxt_wear_lookup': '[L-F]Proszę sprawdzić stan zużycia opon',
  'RFtxt_wear_lookup': '[R-F]Proszę sprawdzić stan zużycia opon',
  'LBtxt_wear_lookup': '[L-R]Proszę sprawdzić stan zużycia opon',
  'RBtxt_wear_lookup': '[R-R]Proszę sprawdzić stan zużycia opon',
  'FFtxt_wear_lookup': '[F-F]Proszę sprawdzić stan zużycia opon',
  'BBtxt_wear_lookup': '[R-R]Proszę sprawdzić stan zużycia opon',

  'txt_appearance_lookup': 'Proszę sprawdzić stan urazu opony',
  'LFtxt_appearance_lookup': '[L-F]Proszę sprawdzić stan urazu opony',
  'RFtxt_appearance_lookup': '[R-F]Proszę sprawdzić stan urazu opony',
  'LBtxt_appearance_lookup': '[L-R]Proszę sprawdzić stan urazu opony',
  'RBtxt_appearance_lookup': '[R-R]Proszę sprawdzić stan urazu opony',
  'FFtxt_appearance_lookup': '[F-F]Proszę sprawdzić stan urazu opony',
  'BBtxt_appearance_lookup': '[B-B]Proszę sprawdzić stan urazu opony',

  'txt_serious_trauma_check': 'Poważne urazy na oponie, zalecamy wymianę opony',
  'LFtxt_serious_trauma_check': '[L-F]Poważne urazy na oponie, zalecamy wymianę opony',
  'RFtxt_serious_trauma_check': '[R-F]Poważne urazy na oponie, zalecamy wymianę opony',
  'LBtxt_serious_trauma_check': '[L-R]Poważne urazy na oponie, zalecamy wymianę opony',
  'RBtxt_serious_trauma_check': '[R-R]Poważne urazy na oponie, zalecamy wymianę opony',
  'FFtxt_serious_trauma_check': '[F-F]Poważne urazy na oponie, zalecamy wymianę opony',
  'BBtxt_serious_trauma_check': '[B-B]Poważne urazy na oponie, zalecamy wymianę opony',

  'purp_tireservice':'Serwis opon',
  'purp_vehicle_inspection':'Kontrola pojazdu',
  'merchant': 'handlowiec',
  'manager': 'kierownik sklepu',
  'assistant': 'numer pracownika',

  'txt_chassis_check': 'Kontrola podwozia',
  'txt_chassis_ok': 'Podwozie jest w dobrym stanie.',
  'LFtxt_chassis_ok': '[L-F]Podwozie jest w dobrym stanie.',
  'RFtxt_chassis_ok': '[R-F]Podwozie jest w dobrym stanie.',
  'LBtxt_chassis_ok': '[L-B]Podwozie jest w dobrym stanie.',
  'RBtxt_chassis_ok': '[R-R]Podwozie jest w dobrym stanie.',
  'FFtxt_chassis_ok': '[F-F]Podwozie jest w dobrym stanie.',
  'BBtxt_chassis_ok': '[B-B]Podwozie jest w dobrym stanie.',
  'txt_chassis_oil': 'Wstrząsający pochłaniacz wycieka oleju. Zaleca się dalsze potwierdzenie i zastąpienie go w razie potrzeby.',
  'LFtxt_chassis_oil': '[L-F]Wstrząsający pochłaniacz wycieka oleju. Zaleca się dalsze potwierdzenie i zastąpienie go w razie potrzeby.',
  'RFtxt_chassis_oil': '[R-F]Wstrząsający pochłaniacz wycieka oleju. Zaleca się dalsze potwierdzenie i zastąpienie go w razie potrzeby.',
  'LBtxt_chassis_oil': '[L-B]Wstrząsający pochłaniacz wycieka oleju. Zaleca się dalsze potwierdzenie i zastąpienie go w razie potrzeby.',
  'RBtxt_chassis_oil': '[R-R]Wstrząsający pochłaniacz wycieka oleju. Zaleca się dalsze potwierdzenie i zastąpienie go w razie potrzeby.',
  'FFtxt_chassis_oil': '[F-F]Wstrząsający pochłaniacz wycieka oleju. Zaleca się dalsze potwierdzenie i zastąpienie go w razie potrzeby.',
  'BBtxt_chassis_oil': '[B-B]Wstrząsający pochłaniacz wycieka oleju. Zaleca się dalsze potwierdzenie i zastąpienie go w razie potrzeby.',
  'txt_chassis_aging': 'Gumka głowy wału się starzeje, więc zaleca się go zastąpić',
  'LFtxt_chassis_aging': '[L-F]Gumka głowy wału się starzeje, więc zaleca się go zastąpić',
  'RFtxt_chassis_aging': '[R-F]Gumka głowy wału się starzeje, więc zaleca się go zastąpić',
  'LBtxt_chassis_aging': '[L-B]Gumka głowy wału się starzeje, więc zaleca się go zastąpić',
  'RBtxt_chassis_aging': '[R-R]Gumka głowy wału się starzeje, więc zaleca się go zastąpić',
  'FFtxt_chassis_aging': '[F-F]Gumka głowy wału się starzeje, więc zaleca się go zastąpić',
  'BBtxt_chassis_aging': '[B-B]Gumka głowy wału się starzeje, więc zaleca się go zastąpić',
  'awd': 'App Download',

   'depth': 'Trench Depth',
    'usura': 'Eccentric Wear',
    'usura-l': 'The pattern is worn evenly',
    'usura-m': 'Slight eccentric wear',
    'usura-h': 'Severe eccentric wear',

    'engine-hood': 'ENGINE BONNET',
    'change-control': 'CHANGE CONTROL',
    'subtotal': 'UNDERBODY',

    'replace': 'REPLACE',
    'vedi': 'READ NOTE',
    'ok': 'OK',

    'liquid': 'GLASS WASHING LIQUID',
    'level-control': 'Level control',
    'air': 'AIR CONDITIONER',
    'last-reload': 'Last recharge',
    'absorber': 'SHOCK ABSORBER',
    'check-loss': 'Check of visible leaks',
    'fluid': 'RADIATOR FLUID',
    'lighting': 'LIGHTS',
    'verification': 'Opeation check',
    'disc': 'BRAKE DISCS',
    'condition': 'Wearing',
    'oil': 'MOTOR OI',
    'wiper': 'WIPERS',
    'pads': 'BRAKE PADS',
    'brake-fluid': 'BRAKE FLUID',
    'quality-control': 'Quality check',
    'battery': 'BATTERY',
    'multimetro': 'Multimetro test',
    'control-quality': 'QUALITY CHECK',
    'tightening': 'Dynamometer tightening',
    'buone': 'ok',
    'lavoro': 'Work scheduling',
    'concordare': 'Allow substitution',

    'com':'Commercial',
    'pass':'Passenger',
}
