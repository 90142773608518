import React, {Component} from "react";
import intl from 'react-intl-universal';
import QRCode from "qrcode.react";
import "./css/TyrehubHeader.scss";
class TyrehubHeader extends Component{
    constructor(props) {
        super(props);
        this.state ={};
    }


    render() {
        let {uuid, language, logo, inspector, purpose, remark, car, plate_no, odometer, store_name, address, device_time, model, recordtype}=this.props.data;
        return (
            <div className="ty-head">
                <div className="ty-head-qr">
                         <QRCode  value={`http://customer.taihapo.com.cn/report/${uuid}/?lang=${language}`} size={105}/>
                         <div style={{textAlign:"center", width:105}}>{intl.get('qr')}</div>
                </div>
                <div className="ty-head-top">
                        <img src={logo ? logo : require("../assets/logo.png")} alt="" style={{width: 186, objectFit: "contain"}}/>
                        <p><span>{intl.get('store')}:</span><span style={{paddingLeft:15}}>{store_name}</span></p>
                        <p><span>{intl.get('address')}:</span><span style={{paddingLeft:15}}>{address}</span></p>
                </div>
                {recordtype === 'full' ? <div className="ty-head-bottom">
                    <div className="head-bottom-con">
                        <div>
                            <div><span className="ct">{intl.get('plate_no')}</span><span className="cn">{plate_no.toUpperCase()}</span></div>
                            <div><span className="ct">{intl.get('device_time')}</span><span className="cn">{device_time}</span></div>
                            <div><span className="ct">{intl.get('device')}</span><span className="cn">{model}</span></div>
                            <div><span className="ct">{intl.get('purpose')}</span><span className="cn">{purpose}</span></div>
                        </div>
                        <div>
                             <div><span className="ct">{intl.get('model')}</span><span className="cn">{car}</span></div>
                             <div><span className="ct">{intl.get('mileage')}</span><span className="cn">{odometer}</span></div>
                             <div><span className="ct">{intl.get('inspector')}</span><span className="cn">{inspector}</span></div>
                             <div><span className="ct">{intl.get('remark')}</span><span className="cn">{remark}</span></div>
                        </div>
                    </div>
                </div> : ''}

        </div>)
    }

}
export default TyrehubHeader;