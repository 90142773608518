import React, {Component} from "react";
import "./css/CormachWear.scss"
import Title from "./Title";
import intl from "react-intl-universal";
import CormachWearCar from "./CormachWearCar";
import UnitWear from "./UnitWear";

class CormachWear extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }


    render() {
        let {tyre_lb, tyre_lf, tyre_rb, tyre_rf, wear_threshold, wear_lf_data, wear_rf_data, wear_lb_data, wear_rb_data, ecc_wear_lf_pos, ecc_wear_rf_pos, ecc_wear_lb_pos, ecc_wear_rb_pos, ecc_wear_lf_degree, ecc_wear_rf_degree, ecc_wear_lb_degree, ecc_wear_rb_degree, season,
        model_number, lf_pressure, rf_pressure, lb_pressure, rb_pressure} = this.props.data;
        // 返回阈值
        const returnThreshold = (value) => {

            return value / 10 + 'mm'

        };

        return (
            <div className="wear">
                <div className="wear-one">
                    <Title content= {intl.get('wearecc')}/>
                </div>
                <div className="wear-two">
                    {/*<img  src={require("../assets/lt_wco.png")} alt=''/>*/}
                    <div style={{position: 'relative'}}>
                        <img
                            style={{
                                width: 750,
                                marginTop: 10,
                                height: 40,
                            }}
                            src={require("../assets/wear-lev.png")}
                        />
                        <div style={{
                            position: 'absolute',
                            top: 10,
                            left: 50,
                            right: 50,
                            display: "flex",
                            justifyContent: "space-between"
                        }}>
                            <div style={{fontSize: 14, color: 'white', textAlign: 'center'}}>
                                <p>{intl.get('high')}</p>
                                <p>＜{returnThreshold(wear_threshold.low)}</p>
                            </div>
                            <div style={{fontSize: 14, color: 'white', textAlign: 'center'}}>
                                <p>{intl.get('middle')}</p>
                                <p>{returnThreshold(wear_threshold.low)}-{returnThreshold(wear_threshold.high)}</p>
                            </div>
                            <div style={{fontSize: 14, color: 'white', textAlign: 'center'}}>
                                <p>{intl.get('low')}</p>
                                <p>≥{returnThreshold(wear_threshold.high)}</p>
                            </div>
                        </div>
                    </div>


                </div>


                <div className="wear-three">
                    <div className="wear-lf">
                        <UnitWear data={{
                            pos: intl.get('lf'),
                            wear_threshold: wear_threshold,
                            wear_data: wear_lf_data,
                            ecc_degree: ecc_wear_lf_degree,
                            ecc_pos: ecc_wear_lf_pos,
                            brand: tyre_lf.brand,
                            spec: tyre_lf.spec,
                            season: season.LF,
                            model: model_number,
                            pressure: lf_pressure
                        }}/>
                    </div>
                    <div className="wear-rf">
                        <UnitWear data={{
                            pos: intl.get('rf'),
                            wear_threshold: wear_threshold,
                            wear_data: wear_rf_data,
                            ecc_degree: ecc_wear_rf_degree,
                            ecc_pos: ecc_wear_rf_pos,
                            brand: tyre_rf.brand,
                            spec: tyre_rf.spec,
                            season: season.RF,
                            model: model_number,
                            pressure: rf_pressure
                        }}/>
                    </div>
                    
                   
                    <div className="wear-cen">
                        <CormachWearCar/>
                    </div>
                    <div className="wear-lb">
                        <UnitWear data={{
                            pos: intl.get('lr'),
                            wear_threshold: wear_threshold,
                            wear_data: wear_lb_data,
                            ecc_degree: ecc_wear_lb_degree,
                            ecc_pos: ecc_wear_lb_pos,
                            brand: tyre_lb.brand,
                            spec: tyre_lb.spec,
                            season: season.LB,
                            model: model_number,
                            pressure: lb_pressure
                        }}/>
                    </div>
                    <div className="wear-rb">
                        <UnitWear data={{
                            pos: intl.get('rr'),
                            wear_threshold: wear_threshold,
                            wear_data: wear_rb_data,
                            ecc_degree: ecc_wear_rb_degree,
                            ecc_pos: ecc_wear_rb_pos,
                            brand: tyre_rb.brand,
                            spec: tyre_rb.spec,
                            season: season.RB,
                            model: model_number,
                            pressure: rb_pressure
                        }}/>
                    </div>

                </div>
            </div>
        )
    }

}

export default CormachWear;