import React, {Component} from "react";
import "../css/Lorry/CarSketch.scss"
import SingleTyre from "./SingleTyre";
import DoubleTyre from "./DoubleTyre";
import intl from "react-intl-universal";
class CarSketch extends Component{
    constructor(props) {
        super(props);
        this.state ={

        }
    };

    render() {
        let {type, tires} = this.props.data;
        const returnCarImage = (type) => {
            if (type === "truck") {
                return require("../../assets/lorry/truck.png")
            }
            else {
                 return require("../../assets/lorry/mount.png")
            }
        };


        return (
            <div className="sketch">
                <span className="sk-left">{intl.get('right')}</span>
                <span className="sk-right">{intl.get('left')}</span>
                <img  className="car" src={returnCarImage(type)} style={{width: 420, height: 150, margin:"auto"}}/>
                <div  className="pos-tire">
                        {tires.map((item) =>(
                      item.double === false ? <SingleTyre data = {item}/> : <DoubleTyre data = {item} />
                  ))}
                </div>
                <div className="gl"></div>
            </div>
        )
    }
}

export default CarSketch