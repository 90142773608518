/* eslint-disable default-case */
import React, {Component} from "react";
import intl from 'react-intl-universal';
import "./DetailNew.scss";
import QRCode from "qrcode.react";
import {Button} from "antd";
import _ from "lodash";
import Cormach_header from "../../components/Cormach_header";
import CarInspection from "../../components/CarInspection";

class Detail extends Component {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
        this.state = {
            report: null,
            ecc_advise: null
        };
    }

    componentDidMount() {

        this.setState({
            report: this.props.data
        });
    }

    render() {
        if (this.state.report) {
            let {
                logo,
                uuid,
                brake_type,
                car,
                language,
                plate_no,
                device_time,
                odometer,
                mobile,
                remark,
                purpose,
                store,
                model_number,
                model_name,
                conclusion,
                suggestion,
                wear_lf_degree,
                wear_rf_degree,
                wear_lb_degree,
                wear_rb_degree,
                ecc_wear_lf_degree,
                ecc_wear_rf_degree,
                ecc_wear_lb_degree,
                ecc_wear_rb_degree,
                transposition,
                wear_lf_data,
                wear_rf_data,
                wear_lb_data,
                wear_rb_data,
                wear_lf_value,
                wear_rf_value,
                wear_lb_value,
                wear_rb_value,
                tyre_lb,
                tyre_lf,
                tyre_rb,
                tyre_rf,
                inspector,
                create_time,
                inspections,
                tyreunit,
                wear_threshold,
                season,
                tireplace,
                goodsname,
                tireremark,
                bolt,
                trauma_lf_img,trauma_rf_img,trauma_lb_img,trauma_rb_img
            } = this.state.report;
            tyre_lb = tyre_lb ? tyre_lb : ",,/R ,trauma_normal,,"
            tyre_lf = tyre_lf ? tyre_lf : ",,/R ,trauma_normal,,"
            tyre_rb = tyre_rb ? tyre_rb : ",,/R ,trauma_normal,,"
            tyre_rf = tyre_rf ? tyre_rf : ",,/R ,trauma_normal,,"
            
            //  返回到店目的
            const objective = (param) => {
                switch (param) {
                    case 'purp_wash':
                        return intl.get('purp_wash');

                    case 'purp_alignment':
                        return intl.get('purp_alignment');

                    case 'purp_replace':
                        return intl.get('purp_replace');

                    case 'purp_maintain':
                        return intl.get('purp_maintain');
                    case 'purp_tireservice':

                        return intl.get('purp_tireservice');
                    case 'purp_vehicle_inspection':
                        return intl.get('purp_vehicle_inspection');
                    default:
                        return param.split("other")[1]
                }

            };
            //刹车距离百分比
            const returnPercent = (parameter) => {
                switch (parameter) {
                    case 1:
                        return '0%';
                    case 2:
                        return '3%';
                    case 3:
                        return '6%';
                    case 4:
                        return '10%';
                    case 5:
                        return '14%';
                    case 6:
                        return '20%';
                    case 7:
                        return '27%';
                    case 8:
                        return '39%';
                    case 9:
                        return '55%';
                    case 10:
                        return '87%';
                }

            };

            // 时间格式化
            const formatDate = (date, fmt) => {
                var currentDate = new Date(date);
                var o = {
                    "M+": currentDate.getMonth() + 1, //月份
                    "d+": currentDate.getDate(), //日
                    "h+": currentDate.getHours(), //小时
                    "m+": currentDate.getMinutes(), //分
                    "s+": currentDate.getSeconds(), //秒
                    "q+": Math.floor((currentDate.getMonth() + 3) / 3), //季度
                    "S": currentDate.getMilliseconds() //毫秒
                };
                if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (currentDate.getFullYear() + "").substr(4 - RegExp.$1.length));
                for (var k in o)
                    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
                return fmt;
            };


            // 返回阈值
            const returnThreshold = (value, unit) => {
                if (unit === 'unit_in') {
                    return ((value / 10) * 1.25984).toFixed(1) + '/32in'
                } else {
                    return value / 10 + 'mm'
                }
            };

            return (
                <div className="report">
                    <Button
                        className="noprint"
                        onClick={() => {
                            window.print();
                        }}
                    >
                        {intl.get("print")}
                    </Button>
                    {this.state.report && (
                        <div className="card">
                            {/* 顶部信息 */}
                            {   <div>
                                    <div className="report-head">
                                        <div>
                                            <img
                                                src={logo ? logo : require("../../assets/logo.png")}
                                                alt=""
                                                style={{width: 186, objectFit: "contain"}}
                                            />
                                            <p style={{marginTop: 8}}>
                                                <span className="tit">{intl.get('store')}：</span>
                                                <span className="cnt">{store.name}</span>
                                            </p>
                                            <p>
                                                <span className="tit">{intl.get('address')}：</span>
                                                <span className="cnt">{`${
                                                    store.address
                                                }`}</span>
                                            </p>
                                        </div>

                                        {
                                            <div className="qrcode" style={{
                                                // display: 'flex',
                                                // alignItems: 'left',
                                                // zIndex: "101"
                                            }}>
                                                <QRCode
                                                    value={`http://customer.taihapo.com.cn/report/${uuid}/?lang=${language}`}
                                                    size={105}
                                                />
                                                <div
                                                    className="qr">{intl.get('qr')}</div>
                                            </div>}
                                    </div>
                                    <div className="report-item section-1">
                                        <div className="info">
                                            <div className="item">
                                                <span className="tit">{intl.get('plate_no')}</span>
                                                <span className="cnt">{plate_no.toUpperCase()}</span>
                                            </div>
                                            <div className="item">
                                                <span className="tit">{intl.get('cus_ph')}</span>
                                                <span
                                                    className="cnt">{mobile}</span>
                                            </div>
                                        </div>
                                        <div className="info">
                                            <div className="item">
                                                <span className="tit">{intl.get('device_time')}</span>
                                                <span
                                                    className="cnt">{intl.options.currentLocale !== 'lt' ? device_time.replace("T", " ") : formatDate(device_time, 'dd/MM/yyyy hh:mm:ss')}</span>
                                            </div>
                                            {model_number == 'taiwan.tyrescan.ts004' ? <div className="item">
                                                    <span className="tit">{intl.get('inspector')}</span>
                                                    <span className="cnt">{inspector}</span>
                                                </div>
                                                : <div className="item">
                                                    <span className="tit">{intl.get('model')}</span>
                                                    <span className="cnt">{car ? car : "-"}</span>
                                                </div>
                                            }
                                        </div>
                                        <div className="info">
                                            <div className="item">
                                                <span className="tit">{intl.get('inspector')}</span>
                                                <span className="cnt">{inspector}</span>
                                            </div>
                                            {model_number == 'taiwan.tyrescan.ts004' ? <div className="item">
                                                    <span className="tit">{intl.get('remark')}</span>
                                                    <span className="cnt">{remark ? remark : ''} </span>
                                                </div> :
                                                <div className="item">
                                                    <span className="tit">{intl.get('mileage')}</span>
                                                    <span
                                                        className="cnt">{odometer ? tyreunit === 'unit_mm' ? odometer + "km" : odometer + "mile" : ''} </span>
                                                </div>
                                            }
                                        </div>
                                        {
                                            <div className="info">
                                                <div className="item">
                                                    <span className="tit">{intl.get('purpose')}</span>
                                                    <span className="cnt">{purpose ? objective(purpose) : "-"}</span>
                                                </div>
                                                <div className="item">
                                                    <span className="tit">{intl.get('txt_tireplace')}</span>
                                                    <span className="cnt">{tireplace ? tireplace : "-"}</span>
                                                </div>
                                            </div>
                                        }
                                        <div className="info">                                                
                                                <div className="item">
                                                    <span className="tit">{intl.get('remark')}</span>
                                                    <span className="cnt">{remark ? remark : ''} </span>
                                                </div>
                                        </div>
                                    </div>
                                </div>}
                            <div className="report-item section-2">
                                <div style={{flex: 1}}>
                                    <span className="title">{intl.get('re')}</span>
                                    {
                                        model_number == 'taiwan.tyrescan.ts004' ? <ul>
                                            <li style={{fontSize: 15}}><p>
                                                <span>{typeSuggestion(suggestion[0].title)}：</span><RenderHot
                                                text={returnSuggestion(suggestion[0].describe)}/>
                                            </p></li>
                                        </ul> : <ul>
                                            {suggestion.map((item, index) => ((item.title === 'txt_chassis_check') && (model_number !== ('michelin.tyrescan.ts004' || 'michelin.tyrescan.ts005')) ? '' :
                                                    <li key={index} style={{fontSize: 15}}>
                                                        <p>
                                                            <span>{typeSuggestion(item.title)}：</span><RenderHot
                                                            text={returnSuggestion(item.describe)}/>
                                                        </p>
                                                    </li>
                                            ))}
                                        </ul>
                                    }
                                </div>
                            </div>
                            <div className="report-item section-4">
                                <p className="title"><span>{intl.get('wear')}</span></p>
                                <div style={{position: 'relative'}}>
                                    <img
                                        style={{
                                            width: 750,
                                            marginTop: 10,
                                            height: 40,
                                        }}
                                        src={require("../../assets/wear-lev.png")}
                                    />
                                    <div style={{
                                        position: 'absolute',
                                        top: 10,
                                        left: 50,
                                        right: 50,
                                        display: "flex",
                                        justifyContent: "space-between"
                                    }}>
                                        <div style={{fontSize: 14, color: 'white', textAlign: 'center'}}>
                                            <p>{intl.get('high')}</p>
                                            <p>＜{returnThreshold(wear_threshold.low, tyreunit)}</p>
                                        </div>
                                        <div style={{fontSize: 14, color: 'white', textAlign: 'center'}}>
                                            <p>{intl.get('middle')}</p>
                                            <p>{returnThreshold(wear_threshold.low, tyreunit)}-{returnThreshold(wear_threshold.high, tyreunit)}</p>
                                        </div>
                                        <div style={{fontSize: 14, color: 'white', textAlign: 'center'}}>
                                            <p>{intl.get('low')}</p>
                                            <p>≥{returnThreshold(wear_threshold.high, tyreunit)}</p>
                                        </div>
                                    </div>
                                </div>

                                {/*<img*/}
                                {/*    style={{*/}
                                {/*        width: 750,*/}
                                {/*        marginTop: 10,*/}
                                {/*        height: 40,*/}
                                {/*        // objectFit: "contain"*/}
                                {/*    }}*/}
                                {/*    src={tyreunit === 'unit_in' ? require("../../assets/namtip.png") : returnColorFlag()}*/}
                                {/*    alt=""*/}
                                {/*/>*/}
                                <div className="block" style={{marginTop: 10}}>
                                    <img
                                        className="car"
                                        src={require("../../assets/car.png")}
                                        alt=""
                                    />
                                    <TyreColor
                                        status={{
                                            lf: {
                                                color: wear_lf_degree
                                            },
                                            rf: {
                                                color: wear_rf_degree
                                            },
                                            lb: {
                                                color: wear_lb_degree
                                            },
                                            rb: {
                                                color: wear_rb_degree
                                            }
                                        }}
                                    />
                                    <Tyre
                                        context={this}
                                        tyreunit={tyreunit}
                                        data={{
                                            lf: {
                                                data: wear_lf_data ? wear_lf_data.split(",") : null,
                                                color: wear_lf_degree,
                                                info: tyre_lf,
                                                season: season && season.LF ? season.LF : '',
                                                goodsname: goodsname && goodsname.LF ? goodsname.LF : '',
                                                tireremark: tireremark && tireremark.LF ? tireremark.LF : '',
                                                bolt: bolt && bolt.LF ? bolt.LF : '',
                                            },
                                            rf: {
                                                data: wear_rf_data ? wear_rf_data.split(",") : null,
                                                color: wear_rf_degree,
                                                info: tyre_rf,
                                                season: season && season.RF ? season.RF : '',
                                                goodsname: goodsname && goodsname.RF ? goodsname.RF : '',
                                                tireremark: tireremark && tireremark.RF ? tireremark.RF : '',
                                                bolt: bolt && bolt.RF ? bolt.RF : '',
                                            },
                                            lb: {
                                                data: wear_lb_data ? wear_lb_data.split(",") : null,
                                                color: wear_lb_degree,
                                                info: tyre_lb,
                                                season: season && season.LB ? season.LB : '',
                                                goodsname: goodsname && goodsname.LB ? goodsname.LB : '',
                                                tireremark: tireremark && tireremark.LB ? tireremark.LB : '',
                                                bolt: bolt && bolt.LB ? bolt.LB : '',
                                            },
                                            rb: {
                                                data: wear_rb_data ? wear_rb_data.split(",") : null,
                                                color: wear_rb_degree,
                                                info: tyre_rb,
                                                season: season && season.RB ? season.RB : '',
                                                goodsname: goodsname && goodsname.RB ? goodsname.RB : '',
                                                tireremark: tireremark && tireremark.RB ? tireremark.RB : '',
                                                bolt: bolt && bolt.RB ? bolt.RB : '',
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            {/* 四轮定位 */}
                            <div
                                className={model_number != 'taiwan.tyrescan.ts004' ? "report-item section-5" : "report-item section-0"}>
                                <p className="title"><span>{intl.get('ecc')}</span></p>
                                <div style={{position: 'relative'}}>
                                    <img
                                        style={{
                                            width: 750,
                                            marginTop: 10,
                                            height: 40,
                                        }}
                                        src={require("../../assets/wear-lev.png")}
                                    />
                                    <div style={{
                                        position: 'absolute',
                                        top: 10,
                                        left: 50,
                                        right: 50,
                                        display: "flex",
                                        justifyContent: "space-between"
                                    }}>
                                        <div style={{fontSize: 22, color: 'white', textAlign: 'center'}}>
                                            <p>{intl.get('usura-h')}</p>
                                        </div>
                                        <div style={{fontSize: 22, color: 'white', textAlign: 'center'}}>
                                            <p>{intl.get('middle')}</p>
                                        </div>
                                        <div style={{fontSize: 22, color: 'white', textAlign: 'center'}}>
                                            <p>{intl.get('low')}</p>
                                        </div>
                                    </div>
                                </div>


                                {/*<img*/}
                                {/*    style={{*/}
                                {/*        width: 750,*/}
                                {/*        // marginLeft: 80,*/}
                                {/*        height: 40,*/}
                                {/*        // objectFit: "contain"*/}
                                {/*    }}*/}
                                {/*    src={returnColorFlag('ecc')}*/}
                                {/*    alt=""*/}
                                {/*/>*/}

                                <div className="block">
                                    <Car
                                        data={{
                                            lf: {data: ecc_wear_lf_degree, tyre: tyre_lf},
                                            rf: {data: ecc_wear_rf_degree, tyre: tyre_rf},
                                            lb: {data: ecc_wear_lb_degree, tyre: tyre_lb},
                                            rb: {data: ecc_wear_rb_degree, tyre: tyre_rb},
                                        }}
                                        conclusion={conclusion}
                                        suggestion={suggestion}
                                        transposition={transposition}
                                    />
                                </div>
                                {/*<div style={{*/}
                                {/*    display: conclusion.length === 0 || conclusion[0].title === 'txt_wear_uniform;' ? 'none' : 'block',*/}
                                {/*    marginTop: '80px'*/}
                                {/*}}>{this.state.ecc_advise !== null ? this.state.ecc_advise : intl.get('advise')}</div>*/}
                            </div>
                            <div
                                className={model_number != 'taiwan.tyrescan.ts004' ? "report-item section-7" : "report-item section-0"}>
                                <p className="title">{intl.get('trauma')}</p>
                                <p className="tire-install"
                                   style={{fontWeight: 800}}>{intl.get('tire_installed')}：<span
                                    style={{color: tireInstall([tyre_lb, tyre_lf, tyre_rb, tyre_rf]).color}}>{tireInstall([tyre_lb, tyre_lf, tyre_rb, tyre_rf]).text}</span>
                                </p>
                                <div className="block">
                                    <TyreInjery {...this.state.report} />
                                </div>
                            </div>
                            <div
                                className={model_number != 'taiwan.tyrescan.ts004' ? "report-item section-11" : "report-item section-0"}>
                                {/* <p className="title">{intl.get('endurance')}</p>
                                <div className="black">
                                    <Range
                                        model_number={model_number}
                                        tyre_lb={tyre_lb}
                                        tyre_lf={tyre_lf}
                                        tyre_rb={tyre_rb}
                                        tyre_rf={tyre_rf}
                                        wear_lf_degree={wear_lf_degree}
                                        wear_rf_degree={wear_rf_degree}
                                        wear_lb_degree={wear_lb_degree}
                                        wear_rb_degree={wear_rb_degree}
                                        wear_lf_value={wear_lf_value}
                                        wear_rf_value={wear_rf_value}
                                        wear_lb_value={wear_lb_value}
                                        wear_rb_value={wear_rb_value}
                                    />
                                </div> */}
                            </div>
                            <div
                                className={model_number != 'taiwan.tyrescan.ts004' ? "report-item section-9" : "report-item section-0"}>
                                <p className="title" style={{marginTop: 30}}>{intl.get('useful_life')}
                                    <span style={{fontSize: '5', display: 'inline'}}>({intl.get('dot_explain')})</span>
                                </p>
                                <div className="black">
                                    <Dot_year
                                        model_number={model_number}
                                        tyre_lb={tyre_lb}
                                        tyre_lf={tyre_lf}
                                        tyre_rb={tyre_rb}
                                        tyre_rf={tyre_rf}
                                        wear_lf_degree={wear_lf_degree}
                                        wear_rf_degree={wear_rf_degree}
                                        wear_lb_degree={wear_lb_degree}
                                        wear_rb_degree={wear_rb_degree}
                                        wear_lf_value={wear_lf_value}
                                        wear_rf_value={wear_rf_value}
                                        wear_lb_value={wear_lb_value}
                                        wear_rb_value={wear_rb_value}
                                        create_time={create_time}
                                    />
                                </div>

                            </div>
                            <div
                                className={model_number != 'taiwan.tyrescan.ts004' ? "report-item section-6" : "report-item section-0"}>
                                <p className="title">{intl.get('brake')}</p>
                                <BrakeBar level={brake_type}/>
                                <div>{intl.get('warn') + returnPercent(brake_type)}（{intl.get('estimated_value')}）</div>
                            </div>
                            {['acura.tyrescan.ts004', 'acura.tyrescan.ts005'].includes(model_number) ?
                                <div className="report-item section-8">
                                    <div className="block">
                                        <img
                                            src={require("../../assets/acura-logo (2).png")}
                                            alt=""
                                            style={{width: 300, height: 40}}
                                        />
                                    </div>
                                </div> : ''
                            }
                        </div>
                    )}
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

//返回多个模块的建议类别
const typeSuggestion = (type) => {
    switch (type) {
        case 'txt_wear_overall':
            return intl.get('wear');
        case 'txt_pattern_wear':
            return intl.get('ecc');
        case 'txt_tire_age':
            return intl.get('txt_tire_age');
        case 'txt_install_check':
            return intl.get('txt_install_check');
        case 'txt_tire appearance':
            return intl.get('txt_tire appearance');
        case 'txt_chassis_check':
            return intl.get('txt_chassis_check');
        default:
            return intl.get('txt_tire appearance');
    }
};
const returnSuggestion = (result, model) => {
    let _list = result.split(';');
    _list.forEach(function (value, index) {
        if (value === 'txt_wear_normal') {
            _list[index] = intl.get('txt_wear_normal')
        } else if (value === 'txt_tire_rotation') {
            _list[index] = intl.get('txt_tire_rotation')
        } else if (value === 'txt_uneven_check') {
            _list[index] = intl.get('txt_uneven_check')
        } else if (value === 'txt_do_further_check') {
            _list[index] = intl.get('txt_do_further_check')
        } else if (value === 'txt_wear_uniform') {
            _list[index] = intl.get('txt_wear_uniform')
        } else if (value === 'txt_alignment_chassis') {
            _list[index] = intl.get('txt_alignment_chassis')
        } else if (value === 'txt_tire_boundary') {
            _list[index] = intl.get('txt_tire_boundary')
        } else if (value === 'txt_tire_need_replace') {
            _list[index] = intl.get('txt_tire_need_replace')
        } else if (value === 'txt_tire_age_5') {
            _list[index] = intl.get('txt_tire_age_5')
        } else if (value === 'txt_tire_age_3') {
            _list[index] = intl.get('txt_tire_age_3')
        } else if (value === 'txt_tire_age_ok') {
            _list[index] = intl.get('txt_tire_age_ok')
        } else if (value === 'txt_tire_trauma_check') {
            _list[index] = intl.get('txt_tire_trauma_check')
        } else if (value === 'txt_install_abnormal_check') {
            _list[index] = intl.get('txt_install_abnormal_check')
        } else if (value === 'txt_install_ok') {
            _list[index] = intl.get('txt_install_ok')
        } else if (value === 'txt_tire_appearance_ok') {
            _list[index] = intl.get('txt_tire_appearance_ok')
        } else if (value === 'txt_pinnate_check') {
            _list[index] = intl.get('txt_pinnate_check')
        } else if (value === 'LFtxt_tire_need_replace') {
            _list[index] = intl.get('LFtxt_tire_need_replace')
        } else if (value === 'RFtxt_tire_need_replace') {
            _list[index] = intl.get('RFtxt_tire_need_replace')
        } else if (value === 'LBtxt_tire_need_replace') {
            _list[index] = intl.get('LBtxt_tire_need_replace')
        } else if (value === 'RBtxt_tire_need_replace') {
            _list[index] = intl.get('RBtxt_tire_need_replace')
        } else if (value === 'FFtxt_tire_need_replace') {
            _list[index] = intl.get('FFtxt_tire_need_replace')
        } else if (value === 'BBtxt_tire_need_replace') {
            _list[index] = intl.get('BBtxt_tire_need_replace')
        } else if (value === 'txt_uneven_center') {
            _list[index] = intl.get('txt_uneven_center')
        } else if (value === 'LFtxt_uneven_center') {
            _list[index] = intl.get('LFtxt_uneven_center')
        } else if (value === 'RFtxt_uneven_center') {
            _list[index] = intl.get('RFtxt_uneven_center')
        } else if (value === 'LBtxt_uneven_center') {
            _list[index] = intl.get('LBtxt_uneven_center')
        } else if (value === 'RBtxt_uneven_center') {
            _list[index] = intl.get('RBtxt_uneven_center')
        } else if (value === 'FFtxt_uneven_center') {
            _list[index] = intl.get('FFtxt_uneven_center')
        } else if (value === 'BBtxt_uneven_center') {
            _list[index] = intl.get('BBtxt_uneven_center')
        } else if (value === 'txt_uneven_out') {
            _list[index] = intl.get('txt_uneven_out')
        } else if (value === 'LFtxt_uneven_out') {
            _list[index] = intl.get('LFtxt_uneven_out')
        } else if (value === 'RFtxt_uneven_out') {
            _list[index] = intl.get('RFtxt_uneven_out')
        } else if (value === 'LBtxt_uneven_out') {
            _list[index] = intl.get('LBtxt_uneven_out')
        } else if (value === 'RBtxt_uneven_out') {
            _list[index] = intl.get('RBtxt_uneven_out')
        } else if (value === 'FFtxt_uneven_out') {
            _list[index] = intl.get('BBtxt_uneven_out')
        } else if (value === 'txt_pinnate_check ') {
            _list[index] = intl.get('txt_pinnate_check')
        } else if (value === 'LFtxt_pinnate_check') {
            _list[index] = intl.get('LFtxt_pinnate_check')
        } else if (value === 'RFtxt_pinnate_check') {
            _list[index] = intl.get('RFtxt_pinnate_check')
        } else if (value === 'LBtxt_pinnate_check') {
            _list[index] = intl.get('LBtxt_pinnate_check')
        } else if (value === 'RBtxt_pinnate_check') {
            _list[index] = intl.get('RBtxt_pinnate_check')
        } else if (value === 'FFtxt_pinnate_check ') {
            _list[index] = intl.get('FFtxt_pinnate_check')
        } else if (value === 'BBtxt_pinnate_check ') {
            _list[index] = intl.get('BBtxt_pinnate_check')
        } else if (value === 'txt_uneven_inside') {
            _list[index] = intl.get('txt_uneven_inside')
        } else if (value === 'LFtxt_uneven_inside') {
            _list[index] = intl.get('LFtxt_uneven_inside')
        } else if (value === 'RFtxt_uneven_inside') {
            _list[index] = intl.get('RFtxt_uneven_inside')
        } else if (value === 'LBtxt_uneven_inside') {
            _list[index] = intl.get('LBtxt_uneven_inside')
        } else if (value === 'RBtxt_uneven_inside') {
            _list[index] = intl.get('RBtxt_uneven_inside')
        } else if (value === 'FFtxt_uneven_inside') {
            _list[index] = intl.get('FFtxt_uneven_inside')
        } else if (value === 'BBtxt_uneven_inside') {
            _list[index] = intl.get('BBtxt_uneven_inside')
        } else if (value === 'FFtxt_uneven_check') {
            _list[index] = intl.get('FFtxt_uneven_check')
        } else if (value === 'BBtxt_uneven_check') {
            _list[index] = intl.get('BBtxt_uneven_check')
        } else if (value === 'LFtxt_tire_age_5') {
            _list[index] = intl.get('LFtxt_tire_age_5')
        } else if (value === 'RFtxt_tire_age_5') {
            _list[index] = intl.get('RFtxt_tire_age_5')
        } else if (value === 'LBtxt_tire_age_5') {
            _list[index] = intl.get('LBtxt_tire_age_5')
        } else if (value === 'RBtxt_tire_age_5') {
            _list[index] = intl.get('RBtxt_tire_age_5')
        } else if (value === 'FFtxt_tire_age_5') {
            _list[index] = intl.get('FFtxt_tire_age_5')
        } else if (value === 'BBtxt_tire_age_5') {
            _list[index] = intl.get('BBtxt_tire_age_5')
        } else if (value === 'txt_appearance_check') {
            _list[index] = intl.get('txt_appearance_check')
        } else if (value === 'LFtxt_appearance_check') {
            _list[index] = intl.get('LFtxt_appearance_check')
        } else if (value === 'RFtxt_appearance_check') {
            _list[index] = intl.get('RFtxt_appearance_check')
        } else if (value === 'LBtxt_appearance_check') {
            _list[index] = intl.get('LBtxt_appearance_check')
        } else if (value === 'RBtxt_appearance_check') {
            _list[index] = intl.get('RBtxt_appearance_check')
        } else if (value === 'FFtxt_appearance_check') {
            _list[index] = intl.get('FFtxt_appearance_check')
        } else if (value === 'BBtxt_appearance_check') {
            _list[index] = intl.get('BBtxt_appearance_check')
        } else if (value === 'LFtxt_install_abnormal_check') {
            _list[index] = intl.get('LFtxt_install_abnormal_check')
        } else if (value === 'RFtxt_install_abnormal_check') {
            _list[index] = intl.get('RFtxt_install_abnormal_check')
        } else if (value === 'LBtxt_install_abnormal_check') {
            _list[index] = intl.get('LBtxt_install_abnormal_check')
        } else if (value === 'RBtxt_install_abnormal_check') {
            _list[index] = intl.get('RBtxt_install_abnormal_check')
        } else if (value === 'FFtxt_install_abnormal_check') {
            _list[index] = intl.get('FFtxt_install_abnormal_check')
        } else if (value === 'BBtxt_install_abnormal_check') {
            _list[index] = intl.get('BBtxt_install_abnormal_check')
        } else if (value === 'txt_uneven_bothside') {
            _list[index] = intl.get('txt_uneven_bothside')
        } else if (value === 'LFtxt_uneven_bothside') {
            _list[index] = intl.get('LFtxt_uneven_bothside')
        } else if (value === 'RFtxt_uneven_bothside') {
            _list[index] = intl.get('RFtxt_uneven_bothside')
        } else if (value === 'RBtxt_uneven_bothside') {
            _list[index] = intl.get('RBtxt_uneven_bothside')
        } else if (value === 'LBtxt_uneven_bothside') {
            _list[index] = intl.get('LBtxt_uneven_bothside')
        } else if (value === 'FFtxt_uneven_bothside') {
            _list[index] = intl.get('FFtxt_uneven_bothside')
        } else if (value === 'BBtxt_uneven_bothside') {
            _list[index] = intl.get('BBtxt_uneven_bothside')
        } else if (value === 'txt_wear_lookup') {
            _list[index] = intl.get('txt_wear_lookup')
        } else if (value === 'LFtxt_wear_lookup') {
            _list[index] = intl.get('LFtxt_wear_lookup')
        } else if (value === 'RFtxt_wear_lookup') {
            _list[index] = intl.get('RFtxt_wear_lookup')
        } else if (value === 'LBtxt_wear_lookup') {
            _list[index] = intl.get('LBtxt_wear_lookup')
        } else if (value === 'RBtxt_wear_lookup') {
            _list[index] = intl.get('RBtxt_wear_lookup')
        } else if (value === 'FFtxt_wear_lookup') {
            _list[index] = intl.get('FFtxt_wear_lookup')
        } else if (value === 'BBtxt_wear_lookup') {
            _list[index] = intl.get('BBtxt_wear_lookup')
        } else if (value === 'txt_appearance_lookup') {
            _list[index] = intl.get('txt_appearance_lookup')
        } else if (value === 'LFtxt_appearance_lookup') {
            _list[index] = intl.get('LFtxt_appearance_lookup')
        } else if (value === 'RFtxt_appearance_lookup') {
            _list[index] = intl.get('RFtxt_appearance_lookup')
        } else if (value === 'LBtxt_appearance_lookup') {
            _list[index] = intl.get('LBtxt_appearance_lookup')
        } else if (value === 'RBtxt_appearance_lookup') {
            _list[index] = intl.get('RBtxt_appearance_lookup')
        } else if (value === 'FFtxt_appearance_lookup') {
            _list[index] = intl.get('FFtxt_appearance_lookup')
        } else if (value === 'BBtxt_appearance_lookup') {
            _list[index] = intl.get('BBtxt_appearance_lookup')
        } else if (value === 'txt_serious_trauma_check') {
            _list[index] = intl.get('txt_serious_trauma_check')
        } else if (value === 'LFtxt_serious_trauma_check') {
            _list[index] = intl.get('LFtxt_serious_trauma_check')
        } else if (value === 'RFtxt_serious_trauma_check') {
            _list[index] = intl.get('RFtxt_serious_trauma_check')
        } else if (value === 'LBtxt_serious_trauma_check') {
            _list[index] = intl.get('LBtxt_serious_trauma_check')
        } else if (value === 'RBtxt_serious_trauma_check') {
            _list[index] = intl.get('RBtxt_serious_trauma_check')
        } else if (value === 'FFtxt_serious_trauma_check') {
            _list[index] = intl.get('FFtxt_serious_trauma_check')
        } else if (value === 'BBtxt_serious_trauma_check') {
            _list[index] = intl.get('BBtxt_serious_trauma_check')
        } else if (value === 'txt_chassis_ok') {
            _list[index] = intl.get('txt_chassis_ok')
        } else if (value === 'LFtxt_chassis_ok') {
            _list[index] = intl.get('LFtxt_chassis_ok')
        } else if (value === 'RFtxt_chassis_ok') {
            _list[index] = intl.get('RFtxt_chassis_ok')
        } else if (value === 'LBtxt_chassis_ok') {
            _list[index] = intl.get('LBtxt_chassis_ok')
        } else if (value === 'RBtxt_chassis_ok') {
            _list[index] = intl.get('RBtxt_chassis_ok')
        } else if (value === 'FFtxt_chassis_ok') {
            _list[index] = intl.get('FFtxt_chassis_ok')
        } else if (value === 'BBtxt_chassis_ok') {
            _list[index] = intl.get('BBtxt_chassis_ok')
        } else if (value === 'txt_chassis_oil') {
            _list[index] = intl.get('txt_chassis_oil')
        } else if (value === 'LFtxt_chassis_oil') {
            _list[index] = intl.get('LFtxt_chassis_oil')
        } else if (value === 'RFtxt_chassis_oil') {
            _list[index] = intl.get('RFtxt_chassis_oil')
        } else if (value === 'LBtxt_chassis_oil') {
            _list[index] = intl.get('LBtxt_chassis_oil')
        } else if (value === 'RBtxt_chassis_oil') {
            _list[index] = intl.get('RBtxt_chassis_oil')
        } else if (value === 'FFtxt_chassis_oil') {
            _list[index] = intl.get('FFtxt_chassis_oil')
        } else if (value === 'BBtxt_chassis_oil') {
            _list[index] = intl.get('BBtxt_chassis_oil')
        } else if (value === 'txt_chassis_aging') {
            _list[index] = intl.get('txt_chassis_aging')
        } else if (value === 'LFtxt_chassis_aging') {
            _list[index] = intl.get('LFtxt_chassis_aging')
        } else if (value === 'RFtxt_chassis_aging') {
            _list[index] = intl.get('RFtxt_chassis_aging')
        } else if (value === 'LBtxt_chassis_aging') {
            _list[index] = intl.get('LBtxt_chassis_aging')
        } else if (value === 'RBtxt_chassis_aging') {
            _list[index] = intl.get('RBtxt_chassis_aging')
        } else if (value === 'FFtxt_chassis_aging') {
            _list[index] = intl.get('FFtxt_chassis_aging')
        } else if (value === 'BBtxt_chassis_aging') {
            _list[index] = intl.get('BBtxt_chassis_aging')
        } else if (value === 'txt_uneven_eattyre') {
            _list[index] = intl.get('txt_uneven_eattyre')
        } else if (value === 'LFtxt_uneven_eattyre') {
            _list[index] = intl.get('LFtxt_uneven_eattyre')
        } else if (value === 'RFtxt_uneven_eattyre') {
            _list[index] = intl.get('RFtxt_uneven_eattyre')
        } else if (value === 'LBtxt_uneven_eattyre') {
            _list[index] = intl.get('LBtxt_uneven_eattyre')
        } else if (value === 'RBtxt_uneven_eattyre') {
            _list[index] = intl.get('RBtxt_uneven_eattyre')
        } else if (value === 'FFtxt_uneven_eattyre') {
            _list[index] = intl.get('FFtxt_uneven_eattyre')
        } else if (value === 'BBtxt_uneven_eattyre') {
            _list[index] = intl.get('BBtxt_uneven_eattyre')
        } else if (value === 'txt_appearance_eattyre') {
            _list[index] = intl.get('txt_appearance_eattyre')
        } else if (value === 'LFtxt_appearance_eattyre') {
            _list[index] = intl.get('LFtxt_appearance_eattyre')
        } else if (value === 'RFtxt_appearance_eattyre') {
            _list[index] = intl.get('RFtxt_appearance_eattyre')
        } else if (value === 'LBtxt_appearance_eattyre') {
            _list[index] = intl.get('LBtxt_appearance_eattyre')
        } else if (value === 'RBtxt_appearance_eattyre') {
            _list[index] = intl.get('RBtxt_appearance_eattyre')
        } else if (value === 'FFtxt_appearance_eattyre') {
            _list[index] = intl.get('FFtxt_appearance_eattyre')
        } else if (value === 'BBtxt_appearance_eattyre') {
            _list[index] = intl.get('BBtxt_appearance_eattyre')
        } else if (value === 'txt_chassis_eattyre') {
            _list[index] = intl.get('txt_chassis_eattyre')
        } else if (value === 'LFtxt_chassis_eattyre') {
            _list[index] = intl.get('LFtxt_chassis_eattyre')
        } else if (value === 'RFtxt_chassis_eattyre') {
            _list[index] = intl.get('RFtxt_chassis_eattyre')
        } else if (value === 'LBtxt_chassis_eattyre') {
            _list[index] = intl.get('LBtxt_chassis_eattyre')
        } else if (value === 'RBtxt_chassis_eattyre') {
            _list[index] = intl.get('RBtxt_chassis_eattyre')
        } else if (value === 'FFtxt_chassis_eattyre') {
            _list[index] = intl.get('FFtxt_chassis_eattyre')
        } else if (value === 'BBtxt_chassis_eattyre') {
            _list[index] = intl.get('BBtxt_chassis_eattyre')
        } else if (value === 'txt_uneven_high_eattyre') {
            _list[index] = intl.get('txt_uneven_high_eattyre')
        } else if (value === 'LFtxt_uneven_high_eattyre') {
            _list[index] = intl.get('LFtxt_uneven_high_eattyre')
        } else if (value === 'RFtxt_uneven_high_eattyre') {
            _list[index] = intl.get('RFtxt_uneven_high_eattyre')
        } else if (value === 'LBtxt_uneven_high_eattyre') {
            _list[index] = intl.get('LBtxt_uneven_high_eattyre')
        } else if (value === 'RBtxt_uneven_high_eattyre') {
            _list[index] = intl.get('RBtxt_uneven_high_eattyre')
        } else if (value === 'FFtxt_uneven_high_eattyre') {
            _list[index] = intl.get('FFtxt_uneven_high_eattyre')
        } else if (value === 'BBtxt_uneven_high_eattyre') {
            _list[index] = intl.get('BBtxt_uneven_high_eattyre')
        } else if (value === 'txt_appearance_high_eattyre') {
            _list[index] = intl.get('txt_appearance_high_eattyre')
        } else if (value === 'LFtxt_appearance_high_eattyre') {
            _list[index] = intl.get('LFtxt_appearance_high_eattyre')
        } else if (value === 'RFtxt_appearance_high_eattyre') {
            _list[index] = intl.get('RRFtxt_appearance_high_eattyre')
        } else if (value === 'LBtxt_appearance_high_eattyre') {
            _list[index] = intl.get('LBtxt_appearance_high_eattyre')
        } else if (value === 'RBtxt_appearance_high_eattyre') {
            _list[index] = intl.get('RBtxt_appearance_high_eattyre')
        } else if (value === 'FFtxt_appearance_high_eattyre') {
            _list[index] = intl.get('FFtxt_appearance_high_eattyre')
        } else if (value === 'BBtxt_appearance_high_eattyre') {
            _list[index] = intl.get('BBtxt_appearance_high_eattyre')
        } else if (value === 'txt_chassis_high_eattyre') {
            _list[index] = intl.get('txt_chassis_high_eattyre')
        } else if (value === 'LFtxt_chassis_high_eattyre') {
            _list[index] = intl.get('LFtxt_chassis_high_eattyre')
        } else if (value === 'RFtxt_chassis_high_eattyre') {
            _list[index] = intl.get('RFtxt_chassis_high_eattyre')
        } else if (value === 'LBtxt_chassis_high_eattyre') {
            _list[index] = intl.get('LBtxt_chassis_high_eattyre')
        } else if (value === 'RBtxt_chassis_high_eattyre') {
            _list[index] = intl.get('RBtxt_chassis_high_eattyre')
        } else if (value === 'FFtxt_chassis_high_eattyre') {
            _list[index] = intl.get('FFtxt_chassis_high_eattyre')
        } else if (value === 'BBtxt_chassis_high_eattyre') {
            _list[index] = intl.get('BBtxt_chassis_high_eattyre')
        } else if (value === 'txt_chassis_uneven') {
            _list[index] = intl.get('txt_chassis_uneven')
        }

    });


    let _str = _list.join(';')
    return _str
};

// 建议字体标红

const RenderHot = props => {
    let str = props.text
    let replace_arr = ['严重磨损', '异常', '严重', '左前轮', '左后轮', '右前轮', '右后轮', '前轴', '后轴', '中间', '外侧', '羽状', '装反', '内侧', '5年', '严重外伤', '请检查轮胎磨损情况', '请检查轮胎外观情况', '减震器漏油']
    for (var i = 0, len = replace_arr.length; i < len; i++) {
        str = str.replace(new RegExp(replace_arr[i], "g"), '<*>' + replace_arr[i] + '<*>')
    }
    let nameArray = str.split('<*>');
    let ar = [];
    let styles = {
        hot: {color: 'red'},
        de: {color: 'black'},
    }
    for (let i = 0; i < nameArray.length; i++) {
        ar.push(<span key={i}
                      style={replace_arr.indexOf(nameArray[i]) !== -1 ? styles.hot : styles.de}>{nameArray[i]}</span>);
    }
    return (
        <span>
                {ar.map((item) => {
                    return (item);
                })}
                </span>
    );
}


// 整体磨损
const Tyre = props => {
    const tyreunit = props.tyreunit
    const _data = props.data;
    _data.lf.position = {left: 30, top: 30};
    _data.rf.position = {left: 330, top: 30};
    _data.lb.position = {left: 30, top: 160};
    _data.rb.position = {left: 330, top: 160};
    _data.lf.desc = {right: 110};
    _data.rf.desc = {left: 140};
    _data.lb.desc = {right: 110, top: 30};
    _data.rb.desc = {left: 140, top: 30};

    // 返回轮胎位置
    const returnTyrePosition = key => {
        switch (key) {
            case "lf":
                return intl.get('lf');
            case "lb":
                return intl.get('lr');
            case "rf":
                return intl.get('rf');
            case "rb":
                return intl.get('rr');
        }
    };

    const returnBolt = data => {
        if (!data) {
          return ''
        } else {
          return intl.get('txt_' + data)
        }
    }

    // 返回轮胎状态
    const returnTyreStatus = (data, status) => {
        if (data === null) {
            return {
                text: intl.get('untest'),
                color: "#FF5230"
            };
        }
        switch (status) {
            case "high":
                return {
                    text: intl.get('high'),
                    color: "#FF5230"
                };
            case "middle":
                return {
                    text: intl.get('middle'),
                    color: "#ffcc00"
                };
            case "low":
                return {
                    text: intl.get('low'),
                    color: "#36B37E"
                };
        }
    };

    // 返回轮胎平均值
    const returnTyrePoint = data => {
        let _obj = [];
        let _newObj = [];
        if (data) {
            _obj = _.chunk(data, 4);
            _obj = _obj.map(item => {
                _newObj.push(item[1]);
            });
            _newObj = _newObj.map(item => _.toNumber((item / 10).toFixed(1)));
        }
        return _newObj;
    };

    const returnList = (data) => {
        let wear_list = [];
        if (data) {
            for (let i = 0; i < data.length / 4; i++) {
                let value = data[4 * i + 3] / 10;
                if (tyreunit === 'unit_in') {
                    wear_list.push((value * 1.25984).toFixed(1))
                } else {
                    wear_list.push(value)
                }
            }
            return wear_list
        }
    };

    // 返回 信息
    const returnTyreInfo = (data, index) => {
        if (data) {
            return data.split(/,|，/)[index] ? data.split(/,|，/)[index] : "-";
        }
        return "-";
    };

    const returnTyre = (status, data) => {
        let _data;
        if (!data) {
            _data = 1;
        } else {
            _data = data.length / 4;
        }
        if (status === "high") {
            return (
                <>
                    {_data >= 4 && (
                        <img
                            className="tyre"
                            src={require("../../assets/tyre-red-1.png")}
                            alt=""
                        />
                    )}
                    {_data === 3 && (
                        <img
                            className="tyre"
                            src={require("../../assets/tyre-red-2.png")}
                            alt=""
                        />
                    )}
                    {_data === 2 && (
                        <img
                            className="tyre"
                            src={require("../../assets/tyre-red-3.png")}
                            alt=""
                        />
                    )}
                    {_data === 1 && (
                        <img
                            className="tyre"
                            src={require("../../assets/tyre-default.png")}
                            alt=""
                        />
                    )}
                </>
            );
        }
        if (status === "middle") {
            return (
                <>
                    {_data >= 4 && (
                        <img
                            className="tyre"
                            src={require("../../assets/tyre-yellow-1.png")}
                            alt=""
                        />
                    )}
                    {_data === 3 && (
                        <img
                            className="tyre"
                            src={require("../../assets/tyre-yellow-2.png")}
                            alt=""
                        />
                    )}
                    {_data === 2 && (
                        <img
                            className="tyre"
                            src={require("../../assets/tyre-yellow-3.png")}
                            alt=""
                        />
                    )}
                    {_data === 1 && (
                        <img
                            className="tyre"
                            src={require("../../assets/tyre-default.png")}
                            alt=""
                        />
                    )}
                </>
            );
        }
        if (status === "low") {
            return (
                <>
                    {_data >= 4 && (
                        <img
                            className="tyre"
                            src={require("../../assets/tyre-green-1.png")}
                            alt=""
                        />
                    )}
                    {_data === 3 && (
                        <img
                            className="tyre"
                            src={require("../../assets/tyre-green-2.png")}
                            alt=""
                        />
                    )}
                    {_data === 2 && (
                        <img
                            className="tyre"
                            src={require("../../assets/tyre-green-3.png")}
                            alt=""
                        />
                    )}
                    {_data === 1 && (
                        <img
                            className="tyre"
                            src={require("../../assets/tyre-default.png")}
                            alt=""
                        />
                    )}
                </>
            );
        }
    };

    // 状态量化
    let transform = (statu) => {
        let setStyle = {backgroundColor: "#170def", display: "inline-block", fontSize: "5px", color: '#fff'};
        if (statu === 'low') {
            return {
                "value": 1,
                "text": intl.get('low'),
                "style": setStyle
            }
        } else if (statu === 'middle') {
            return {
                "value": 2,
                "text": intl.get('middle'),
                "style": setStyle
            }
        } else {
            return {
                "value": 3,
                "text": intl.get('high'),
                "style": setStyle
            }
        }
    };

    const returnSeason = data => {
        if (!data) {
          return ''
        } else {
          return intl.get('txt_' + data)
        }
      }

    // 返回轮胎DOT状态
    const returnDotStatus = (tyredata, index) => {
        var data = returnTyreInfo(tyredata, index);
        if ((data === null) || (data.length !== 4)) {
            return {
                color: '#FF5230'
            };
        }
        var date = new Date();

        var year = date.getFullYear();
        var month = (date.getMonth() + 1);
        var day = date.getDate();
        var daysnow = year * 365 + month * 30 + day;

        var tmpdata = data.slice(2);
        var tmpweek = data;
        var tmpdays = (2000 + Number(tmpdata)) * 365 + (Number(tmpweek) - Number(tmpdata)) / 100 * 7;

        if (daysnow > (tmpdays + 365 * 5)) {
            return {
                color: '#FF5230'
            };
        } else if (daysnow > (tmpdays + 365 * 3)) {
            return {
                color: '#ffcc00'
            };
        } else {
            return {
                color: '#36B37E'
            };
        }
    };

    let style = {
        fontSize: 3,
        display: tyreunit === 'unit_in' ? 'block' : 'None',
        // width: 95,
        textAlign: 'center',
        fontWeight: 600
    };

    return (
        <div>
            {Object.entries(_data).map(item => (
                <div className="car-info" style={item[1].position} key={item[0]}>
                    <div className="car-info_card">
                        <div className="desc" style={item[1].desc} key={item[0]}>
                            <p>
                            <span className="desc_label"
                                style={{color: returnTyreStatus(item[1].data, item[1].color).color}}>
                            {returnTyrePosition(item[0])}：
                            </span>
                            {returnSeason(item[1].season)}
                            </p>
                            <p>
                                <span className="desc_label">{intl.get("brand")}：</span>
                                {/* {returnTyrePoint(item[1].data).length > 0
                  ? _.mean(returnTyrePoint(item[1].data)).toFixed(1)
                  : "-"} */}
                                {returnTyreInfo(item[1].info, 1)}
                            </p>
                            <p>
                                <span className="desc_label">{intl.get('spec')}：</span>
                                {returnTyreInfo(item[1].info, 2)}
                            </p>
                            <p style={{width: '300px'}}>
                                <span className="desc_label">{intl.get('abrasion')}：</span>
                                <span
                                    style={{
                                        fontWeight: "bold",
                                        color: returnTyreStatus(item[1].data, item[1].color).color
                                    }}
                                >
                                 {returnTyreStatus(item[1].data, item[1].color).text}
                                </span>
                            </p>
                            <p>
                                <span className="desc_label">D O T：</span>
                                <span style={returnDotStatus(item[1].info, 0)}>{returnTyreInfo(item[1].info, 0)}</span>
                            </p>
                            <p>
                                <span className="desc_label">{intl.get('txt_goods_name')}：</span>
                                {item[1].goodsname}
                            </p>
                            <p>
                                <span className="desc_label">{intl.get('txt_bolt')}：</span>
                                {returnBolt(item[1].bolt)}
                            </p>
                            <p>
                                <span className="desc_label">{intl.get('remark')}：</span>
                                {item[1].tireremark}
                            </p>
                        </div>
                        <div className="car-tyre">
                            {returnTyre(item[1].color, item[1].data)}
                        </div>
                    </div>
                    <p className="car-point">
                        {returnList(item[1].data).map((point, index) => (
                            <span key={index}>{point}</span>
                        ))}
                    </p>
                    <p style={style}>Tread Depth in 32 nds</p>
                </div>
            ))}
        </div>
    );
};

// 刹车距离组件
const BrakeBar = props => {
    let level = props.level;
    let images = [
        require(`../../assets/item_2_1.png`),
        require(`../../assets/item_2_2.png`),
        require(`../../assets/item_2_3.png`),
        require(`../../assets/item_2_4.png`),
        require(`../../assets/item_2_5.png`),
        require(`../../assets/item_2_6.png`),
        require(`../../assets/item_2_7.png`),
        require(`../../assets/item_2_8.png`),
        require(`../../assets/item_2_9.png`),
        require(`../../assets/item_2_10.png`)
    ];


    return (
        // eslint-disable-next-line jsx-a11y/alt-text
        <img
            style={{width: "105%", marginTop: 8, marginBottom: 8, marginLeft: -22}}
            src={images[level - 1]}
        />
    );
};

// DOT 年限提醒
const Dot_year = props => {
    let model_number = props.model_number
    let tyre_lf = props.tyre_lf;
    let tyre_lb = props.tyre_lb;
    let tyre_rf = props.tyre_rf;
    let tyre_rb = props.tyre_rb;
    let lf_degree = props.wear_lf_degree;
    let rf_degree = props.wear_rf_degree;
    let lb_degree = props.wear_lb_degree;
    let rb_degree = props.wear_rb_degree;
    let lf_value = props.wear_lf_value;
    let rf_value = props.wear_rf_value;
    let lb_value = props.wear_lb_value;
    let rb_value = props.wear_rb_value;
    let create_time = props.create_time;
    let made_year;
    let made_weke;

    const returnImage = (site, num) => {
        let lang = intl.options.currentLocale
        if (lang === 'zh') {
            return require("../../assets/" + "z" + site + "_2_" + num + ".png")
        } else if (lang === 'lt') {
            return require("../../assets/" + site + "_itdot_" + num + ".png")
        } else if (model_number === 'dt.tyrescan.ts004') {
            return require("../../assets/" + site + "-md" + num + ".png")
        } else {
            return require("../../assets/" + site + "_dot_" + num + ".png")
        }

    };
    // 返回轮胎使用年限提醒
    const returnLifetime = (tyre, site, degree, value) => {
        let trauma = tyre.split(',')[3]
        let trauma_lever = tyre.split(',')[5]
        let itemDot = tyre.split(',')[0];
        if (trauma !== 'trauma_normal') {
            if (trauma_lever === 'damage_high') {
                return {
                    url: returnImage(site, 20),
                    text: intl.get('txt_serious_trauma_check'),
                    made_time: itemDot ? intl.get('made_time', {
                        dot: itemDot,
                        year: Number(itemDot.slice(2)) + 2000,
                        week: Number(itemDot.slice(0, 2))
                    }) : intl.get('dot_no')
                }
            }
        }

        if (degree === 'high') {
            return {
                url: returnImage(site, 20),
                text: intl.get('txt_tire_need_replace'),
                made_time: itemDot ? intl.get('made_time', {
                    dot: itemDot,
                    year: Number(itemDot.slice(2)) + 2000,
                    week: Number(itemDot.slice(0, 2))
                }) : intl.get('dot_no')
            }
        }

        let lifetime = 0
        if (itemDot) {
            made_year = Number(itemDot.slice(2)) + 2000
            made_weke = Number(itemDot.slice(0, 2))
            let year = Number(itemDot.slice(2)) + 2000;
            let thatYearOfDay = (itemDot.length > 3 ? Number(itemDot.slice(0, 2)) : Number(itemDot.slice(0, 1))) * 7;
            let current_year = new Date(create_time).getFullYear();
            let currentYearOFday = Math.ceil((new Date(create_time) - new Date(new Date(create_time).getFullYear().toString())) / (24 * 60 * 60 * 1000)) + 1
            var RemainingDays = (currentYearOFday - thatYearOfDay) + (current_year - year) * 365;
            let totalTime = model_number === 'dt.tyrescan.ts004' ? 2919 : 1820
            lifetime = totalTime - RemainingDays;
            // console.log(RemainingDays)

            if (year > current_year) {
                return {
                    url: returnImage(site, 1),
                    text: intl.get('dot_no'),
                    made_time: ''
                }
            }
             if (year === current_year && thatYearOfDay > currentYearOFday) {
                return {
                    url: returnImage(site, 1),
                    text: intl.get('dot_no'),
                    made_time: ''
                }
            }
        }
        let de_time
        if (lifetime !== 0) {
            let value_a = value - 25;
            let value_b = (80 - value) / RemainingDays;
            de_time = value_a / value_b;
            let time_one = parseInt(lifetime - de_time < 0 ? lifetime : de_time);
            lifetime = value < 65 ? time_one : lifetime
        }


        if (!itemDot) {
            return {
                url: returnImage(site, 1),
                text: intl.get('dot_no'),
                made_time: ''
            }
        } else {
            if (model_number !== 'dt.tyrescan.ts004') {
                if (lifetime <= 0) {
                    return {
                        url: returnImage(site, 20),
                        text: de_time <= 0 ? intl.get('remanent', {days: lifetime}) : intl.get('exceed_five'),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                } else if (0 < lifetime && lifetime <= 91) {
                    return {
                        url: returnImage(site, 19),
                        text: intl.get('remanent', {days: lifetime}),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                } else if (91 < lifetime && lifetime <= 182) {
                    return {
                        url: returnImage(site, 18),
                        text: intl.get('remanent', {days: lifetime}),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                } else if (182 < lifetime && lifetime <= 273) {
                    return {
                        url: returnImage(site, 17),
                        text: intl.get('remanent', {days: lifetime}),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                } else if (273 < lifetime && lifetime <= 364) {
                    return {
                        url: returnImage(site, 16),
                        text: intl.get('remanent', {days: lifetime}),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                } else if (364 < lifetime && lifetime <= 455) {
                    return {
                        url: returnImage(site, 15),
                        text: intl.get('remanent', {days: lifetime}),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                } else if (455 < lifetime && lifetime <= 546) {
                    return {
                        url: returnImage(site, 14),
                        text: intl.get('remanent', {days: lifetime}),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                } else if (546 < lifetime && lifetime <= 637) {
                    return {
                        url: returnImage(site, 13),
                        text: intl.get('remanent', {days: lifetime}),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                } else if (637 < lifetime && lifetime <= 728) {
                    return {
                        url: returnImage(site, 12),
                        text: intl.get('remanent', {days: lifetime}),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                } else if (728 < lifetime && lifetime <= 819) {
                    return {
                        url: returnImage(site, 11),
                        text: intl.get('remanent', {days: lifetime}),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                } else if (819 < lifetime && lifetime <= 910) {
                    return {
                        url: returnImage(site, 10),
                        text: intl.get('remanent', {days: lifetime}),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                } else if (910 < lifetime && lifetime <= 1001) {
                    return {
                        url: returnImage(site, 9),
                        text: intl.get('remanent', {days: lifetime}),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                } else if (1001 < lifetime && lifetime <= 1092) {
                    return {
                        url: returnImage(site, 8),
                        text: intl.get('remanent', {days: lifetime}),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                } else if (1092 < lifetime && lifetime <= 1183) {
                    return {
                        url: returnImage(site, 7),
                        text: intl.get('remanent', {days: lifetime}),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                } else if (1183 < lifetime && lifetime <= 1274) {
                    return {
                        url: returnImage(site, 6),
                        text: intl.get('remanent', {days: lifetime}),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                } else if (1274 < lifetime && lifetime <= 1365) {
                    return {
                        url: returnImage(site, 5),
                        text: intl.get('remanent', {days: lifetime}),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                } else if (1365 < lifetime && lifetime <= 1456) {
                    return {
                        url: returnImage(site, 4),
                        text: intl.get('remanent', {days: lifetime}),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                } else if (1456 < lifetime && lifetime <= 1547) {
                    return {
                        url: returnImage(site, 3),
                        text: intl.get('remanent', {days: lifetime}),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                } else if (1547 < lifetime && lifetime <= 1638) {
                    return {
                        url: returnImage(site, 2),
                        text: intl.get('remanent', {days: lifetime}),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                } else if (1638 < lifetime && lifetime <= 1729) {
                    return {
                        url: returnImage(site, 1),
                        text: intl.get('remanent', {days: lifetime}),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                } else if (1729 < lifetime && lifetime <= 1820) {
                    return {
                        url: returnImage(site, 1),
                        text: intl.get('remanent', {days: lifetime}),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                } else {
                    return {
                        url: returnImage(site, 1),
                        text: intl.get('remanent', {days: 1820}),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                }
            } else {
                if (lifetime <= 0) {
                    return {
                        url: returnImage(site, 20),
                        text: de_time <= 0 ? intl.get('remanent', {days: lifetime}) : intl.get('exceed_five'),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                } else if (0 < lifetime && lifetime <= 182) {
                    return {
                        url: returnImage(site, 19),
                        text: intl.get('remanent', {days: lifetime}),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                } else if (182 < lifetime && lifetime <= 364) {
                    return {
                        url: returnImage(site, 18),
                        text: intl.get('remanent', {days: lifetime}),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                } else if (364 < lifetime && lifetime <= 546) {
                    return {
                        url: returnImage(site, 17),
                        text: intl.get('remanent', {days: lifetime}),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                } else if (546 < lifetime && lifetime <= 729) {
                    return {
                        url: returnImage(site, 16),
                        text: intl.get('remanent', {days: lifetime}),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                } else if (729 < lifetime && lifetime <= 875) {
                    return {
                        url: returnImage(site, 15),
                        text: intl.get('remanent', {days: lifetime}),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                } else if (875 < lifetime && lifetime <= 1021) {
                    return {
                        url: returnImage(site, 14),
                        text: intl.get('remanent', {days: lifetime}),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                } else if (1021 < lifetime && lifetime <= 1167) {
                    return {
                        url: returnImage(site, 13),
                        text: intl.get('remanent', {days: lifetime}),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                } else if (1167 < lifetime && lifetime <= 1313) {
                    return {
                        url: returnImage(site, 12),
                        text: intl.get('remanent', {days: lifetime}),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                } else if (1313 < lifetime && lifetime <= 1459) {
                    return {
                        url: returnImage(site, 11),
                        text: intl.get('remanent', {days: lifetime}),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                } else if (1459 < lifetime && lifetime <= 1605) {
                    return {
                        url: returnImage(site, 10),
                        text: intl.get('remanent', {days: lifetime}),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                } else if (1605 < lifetime && lifetime <= 1751) {
                    return {
                        url: returnImage(site, 9),
                        text: intl.get('remanent', {days: lifetime}),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                } else if (1751 < lifetime && lifetime <= 1897) {
                    return {
                        url: returnImage(site, 8),
                        text: intl.get('remanent', {days: lifetime}),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                } else if (1897 < lifetime && lifetime <= 2043) {
                    return {
                        url: returnImage(site, 7),
                        text: intl.get('remanent', {days: lifetime}),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                } else if (2043 < lifetime && lifetime <= 2189) {
                    return {
                        url: returnImage(site, 6),
                        text: intl.get('remanent', {days: lifetime}),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                } else if (2189 < lifetime && lifetime <= 2335) {
                    return {
                        url: returnImage(site, 5),
                        text: intl.get('remanent', {days: lifetime}),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                } else if (2335 < lifetime && lifetime <= 2481) {
                    return {
                        url: returnImage(site, 4),
                        text: intl.get('remanent', {days: lifetime}),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                } else if (2481 < lifetime && lifetime <= 2627) {
                    return {
                        url: returnImage(site, 3),
                        text: intl.get('remanent', {days: lifetime}),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                } else if (2627 < lifetime && lifetime <= 2773) {
                    return {
                        url: returnImage(site, 2),
                        text: intl.get('remanent', {days: lifetime}),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                } else if (2773 < lifetime && lifetime <= 2919) {
                    return {
                        url: returnImage(site, 1),
                        text: intl.get('remanent', {days: lifetime}),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                } else {
                    return {
                        url: returnImage(site, 1),
                        text: intl.get('remanent', {days: 2119}),
                        made_time: intl.get('made_time', {dot: itemDot, year: made_year, week: made_weke})
                    }
                }

            }
        }
    }

    return (
        <div>
            <div className="dot-lf">
                <span>{intl.get('lf')}：</span><br/>
                <span style={{
                    width: 260,
                    display: 'inline-block'
                }}>{returnLifetime(tyre_lf, 'l', lf_degree, lf_value).made_time}</span><br/>
                <span style={{
                    width: 260,
                    display: 'inline-block'
                }}>{returnLifetime(tyre_lf, 'l', lf_degree, lf_value).text}</span><br/>
                <img className="dot-year" src={returnLifetime(tyre_lf, 'l', lf_degree, lf_value).url}/>
            </div>
            <div className="dot-rf">
                <span>{intl.get('rf')}：</span><br/>
                <span style={{
                    width: 260,
                    display: 'inline-block'
                }}>{returnLifetime(tyre_rf, 'r', rf_degree, rf_value).made_time}</span><br/>
                <span style={{
                    width: 260,
                    display: 'inline-block'
                }}>{returnLifetime(tyre_rf, 'r', rf_degree, rf_value).text}</span><br/>
                <img className="dot-year" src={returnLifetime(tyre_rf, 'r', rf_degree, rf_value).url}/>
            </div>
            <img className="dot-car" src={require("../../assets/car.png")} alt=""/>
            <div className="dot-lb">
                <span>{intl.get('lr')}：</span><br/>
                <span style={{
                    width: 260,
                    display: 'inline-block'
                }}>{returnLifetime(tyre_lb, 'l', lb_degree, lb_value).made_time}</span><br/>
                <span style={{
                    width: 260,
                    display: 'inline-block'
                }}>{returnLifetime(tyre_lb, 'l', lb_degree, lb_value).text}</span><br/>
                <img className="dot-year" src={returnLifetime(tyre_lb, 'l', lb_degree, lb_value).url}/>
            </div>
            <div className="dot-rb">
                <span>{intl.get('rr')}：</span><br/>
                <span style={{
                    width: 260,
                    display: 'inline-block'
                }}>{returnLifetime(tyre_rb, 'r', rb_degree, rb_value).made_time}</span><br/>
                <span style={{
                    width: 260,
                    display: 'inline-block'
                }}>{returnLifetime(tyre_rb, 'r', rb_degree, rb_value).text}</span><br/>
                <img className="dot-year" src={returnLifetime(tyre_rb, 'r', rb_degree, rb_value).url}/>
            </div>
        </div>
    )
}


//续航
const Range = props => {
    let model_number = props.model_number
    let wear_lf_value = props.wear_lf_value;
    let wear_rf_value = props.wear_rf_value;
    let wear_lb_value = props.wear_lb_value;
    let wear_rb_value = props.wear_rb_value;

    let tyre_lf = props.tyre_lf;
    let tyre_lb = props.tyre_lb;
    let tyre_rf = props.tyre_rf;
    let tyre_rb = props.tyre_rb;
    let lf_degree = props.wear_lf_degree;
    let rf_degree = props.wear_rf_degree;
    let lb_degree = props.wear_lb_degree;
    let rb_degree = props.wear_rb_degree;

    // 返回左边轮胎续航里程图
    const returnMileageL = (depth, tyre, degree) => {
        let result = (depth - 25) * 1000
        let max_mileage = 50000
        let mileage = result > 0 ? result : 0;
        let trauma = tyre.split(',')[3]
        let trauma_lever = tyre.split(',')[5]
        if (trauma !== 'trauma_normal') {
            if (trauma_lever === 'damage_high') {
                return {
                    mileage: mileage, url: require(`../../assets/l_2_10.png`),
                    text: intl.get('txt_serious_trauma_check')
                }
            }
        }

        if (degree === 'high') {
            return {
                mileage: mileage, url: require(`../../assets/l_2_10.png`),
                text: intl.get('txt_tire_need_replace')
            }
        }

        if (depth / 10 < 1.6) {
            return {
                mileage: mileage, url: require(`../../assets/l_2_10.png`),
                text: mileage !== 0 ? intl.get('endurance-warn', {number: mileage}) : intl.get('endurance-zero')
            }
        } else if (depth / 10 > 1.6 && depth / 10 <= 2.4) {
            return {
                mileage: mileage, url: require(`../../assets/l_2_8.png`),
                text: mileage !== 0 ? intl.get('endurance-warn', {number: mileage}) : intl.get('endurance-zero')
            }
        } else if (depth / 10 > 2.4 && depth / 10 <= 3.2) {
            return {
                mileage: mileage, url: require(`../../assets/l_2_7.png`),
                text: mileage !== 0 ? intl.get('endurance-warn', {number: mileage}) : intl.get('endurance-zero')
            }
        } else if (depth / 10 > 3.2 && depth / 10 <= 4.0) {
            return {
                mileage: mileage, url: require(`../../assets/l_2_6.png`),
                text: mileage !== 0 ? intl.get('endurance-warn', {number: mileage}) : intl.get('endurance-zero')
            }
        } else if (depth / 10 > 4.0 && depth / 10 <= 4.8) {
            return {
                mileage: mileage, url: require(`../../assets/l_2_5.png`),
                text: mileage !== 0 ? intl.get('endurance-warn', {number: mileage}) : intl.get('endurance-zero')
            }
        } else if (depth / 10 > 4.8 && depth / 10 <= 5.6) {
            return {
                mileage: mileage, url: require(`../../assets/l_2_4.png`),
                text: mileage !== 0 ? intl.get('endurance-warn', {number: mileage}) : intl.get('endurance-zero')
            }
        } else if (depth / 10 > 5.6 && depth / 10 <= 6.4) {
            return {
                mileage: mileage, url: require(`../../assets/l_2_3.png`),
                text: mileage !== 0 ? intl.get('endurance-warn', {number: mileage}) : intl.get('endurance-zero')
            }
        } else if (depth / 10 > 6.4 && depth / 10 <= 7.1) {
            return {
                mileage: mileage, url: require(`../../assets/l_2_2.png`),
                text: mileage !== 0 ? intl.get('endurance-warn', {number: mileage}) : intl.get('endurance-zero')
            }
        } else {
            return {
                mileage: mileage < max_mileage ? mileage : max_mileage, url: require(`../../assets/l_2_1.png`),
                text: mileage !== 0 ? intl.get('endurance-warn', {number: mileage < max_mileage ? mileage : max_mileage}) : intl.get('endurance-zero')
            }
        }
    };

    // 返回右边边轮胎续航里程图
    const returnMileageR = (depth, tyre, degree) => {
        let result = (depth - 25) * 1000
        let max_mileage = 50000
        let mileage = result > 0 ? result : 0;
        let trauma = tyre.split(',')[3]
        let trauma_lever = tyre.split(',')[5]
        if (trauma !== 'trauma_normal') {
            if (trauma_lever === 'damage_high') {
                return {
                    mileage: mileage, url: require(`../../assets/r_2_10.png`),
                    text: intl.get('txt_serious_trauma_check')
                }
            }
        }

        if (degree === 'high') {
            return {
                mileage: mileage, url: require(`../../assets/r_2_10.png`),
                text: intl.get('txt_tire_need_replace')
            }
        }
        if (depth / 10 < 1.6) {
            return {
                mileage: mileage, url: require(`../../assets/r_2_10.png`),
                text: mileage !== 0 ? intl.get('endurance-warn', {number: mileage}) : intl.get('endurance-zero')
            }
        } else if (depth / 10 > 1.6 && depth / 10 <= 2.4) {
            return {
                mileage: mileage, url: require(`../../assets/r_2_8.png`),
                text: mileage !== 0 ? intl.get('endurance-warn', {number: mileage}) : intl.get('endurance-zero')
            }
        } else if (depth / 10 > 2.4 && depth / 10 <= 3.2) {
            return {
                mileage: mileage, url: require(`../../assets/r_2_7.png`),
                text: mileage !== 0 ? intl.get('endurance-warn', {number: mileage}) : intl.get('endurance-zero')
            }
        } else if (depth / 10 > 3.2 && depth / 10 <= 4.0) {
            return {
                mileage: mileage, url: require(`../../assets/r_2_6.png`),
                text: mileage !== 0 ? intl.get('endurance-warn', {number: mileage}) : intl.get('endurance-zero')
            }
        } else if (depth / 10 > 4.0 && depth / 10 <= 4.8) {
            return {
                mileage: mileage, url: require(`../../assets/r_2_5.png`),
                text: mileage !== 0 ? intl.get('endurance-warn', {number: mileage}) : intl.get('endurance-zero')
            }
        } else if (depth / 10 > 4.8 && depth / 10 <= 5.6) {
            return {
                mileage: mileage, url: require(`../../assets/r_2_4.png`),
                text: mileage !== 0 ? intl.get('endurance-warn', {number: mileage}) : intl.get('endurance-zero')
            }
        } else if (depth / 10 > 5.6 && depth / 10 <= 6.4) {
            return {
                mileage: mileage, url: require(`../../assets/r_2_3.png`),
                text: mileage !== 0 ? intl.get('endurance-warn', {number: mileage}) : intl.get('endurance-zero')
            }
        } else if (depth / 10 > 6.4 && depth / 10 <= 7.1) {
            return {
                mileage: mileage, url: require(`../../assets/r_2_2.png`),
                text: mileage !== 0 ? intl.get('endurance-warn', {number: mileage}) : intl.get('endurance-zero')
            }
        } else {
            return {
                mileage: mileage < max_mileage ? mileage : max_mileage, url: require(`../../assets/r_2_1.png`),
                text: mileage !== 0 ? intl.get('endurance-warn', {number: mileage < max_mileage ? mileage : max_mileage}) : intl.get('endurance-zero')
            }
        }
    };

    return (
        // eslint-disable-next-line jsx-a11y/alt-text
        <div>
            <div className="endurance-lf">
                <span>{intl.get('lf')}：</span><br/>
                <span style={{
                    width: 260,
                    display: 'inline-block'
                }}>{returnMileageL(wear_lf_value, tyre_lf, lf_degree).text}</span><br/>
                <img className="endurance-tyre" src={returnMileageL(wear_lf_value, tyre_lf, lf_degree).url} alt=""/>
            </div>
            <div className="endurance-rf">
                <span>{intl.get('rf')}：</span><br/>
                <span style={{
                    width: 260,
                    display: 'inline-block'
                }}>{returnMileageR(wear_rf_value, tyre_rf, rf_degree).text}</span><br/>
                <img className="endurance-tyre" src={returnMileageR(wear_rf_value, tyre_rf, rf_degree).url} alt=""/>
            </div>
            <img className="endurance-car" src={require("../../assets/car.png")} alt="" style={{}}/>
            <div className="endurance-lb">
                <span>{intl.get('lr')}：</span><br/>
                <span style={{
                    width: 260,
                    display: 'inline-block'
                }}>{returnMileageL(wear_lb_value, tyre_lb, lb_degree).text}</span><br/>
                <img className="endurance-tyre" src={returnMileageL(wear_lb_value, tyre_lb, lb_degree).url} alt=""/>
            </div>
            <div className="endurance-rb">
                <span>{intl.get('rr')}：</span><br/>
                <span style={{
                    width: 260,
                    display: 'inline-block'
                }}>{returnMileageR(wear_rb_value, tyre_rb, rb_degree).text}</span><br/>
                <img className="endurance-tyre" src={returnMileageR(wear_rb_value, tyre_rb, rb_degree).url} alt=""/>
            </div>
        </div>
    );
};


// 花纹磨损
// 轮子颜色顺序 左前，右前，左后，右后
// low, 绿色
// middle，黄色
// high，红色
const Car = props => {
    let _data = {};
    if (props.data) {
        _data = props.data;
        _data.lf.position = {left: 185, top: -30};
        _data.rf.position = {left: 285, top: -30};
        _data.lb.position = {left: 185, top: 110};
        _data.rb.position = {left: 285, top: 110};
        _data.lf.desc = {
            position: {right: 0},
            title: intl.get('ecc_avg')
        };
        _data.rf.desc = {
            position: {left: 70},
            title: intl.get('ecc_avg')
        };
        _data.lb.desc = {
            position: {right: 0},
            title: intl.get('ecc_avg')
        };
        _data.rb.desc = {
            position: {left: 70},
            title: intl.get('ecc_avg')
        };
    }
    if (Object.prototype.toString.call(props.conclusion) === "[object Array]") {
        props.conclusion.forEach(item => {
            if (item.title.indexOf('LF') > -1) {
                switch (item.title.split('F')[1]) {
                    case "txt_lf_outworn":
                        _data.lf.desc.title = intl.get("txt_lf_outworn");
                        break
                    case "txt_lf_innerworn":
                        _data.lf.desc.title = intl.get("txt_lf_innerworn");
                        break
                    case "txt_lf_bothsideworn":
                        _data.lf.desc.title = intl.get("txt_lf_bothsideworn");
                        break
                    case "txt_lf_centreworn":
                        _data.lf.desc.title = intl.get("txt_lf_centreworn");
                        break
                    case "txt_pinnate_wear":
                        _data.lf.desc.title = intl.get("txt_pinnate_wear");
                        break
                    default:
                        _data.lf.desc.title = item.title.split('F')[1]
                }
            }
            if (item.title.indexOf('RF') > -1) {
                switch (item.title.split('F')[1]) {
                    case "txt_rf_outworn":
                        _data.rf.desc.title = intl.get("txt_rf_outworn");
                        break
                    case "txt_rf_innerworn":
                        _data.rf.desc.title = intl.get("txt_rf_innerworn");
                        break
                    case "txt_rf_bothsideworn":
                        _data.rf.desc.title = intl.get("txt_rf_bothsideworn");
                        break
                    case "txt_rf_centreworn":
                        _data.rf.desc.title = intl.get("txt_rf_centreworn");
                        break
                    case "txt_pinnate_wear":
                        _data.rf.desc.title = intl.get("txt_pinnate_wear");
                        break
                    default:
                        _data.rf.desc.title = item.title.split('F')[1]
                }
            }
            if (item.title.indexOf('LB') > -1) {
                switch (item.title.split('B')[1]) {
                    case "txt_lb_outworn":
                        _data.lb.desc.title = intl.get("txt_lb_outworn");
                        break
                    case "txt_lb_innerworn":
                        _data.lb.desc.title = intl.get("txt_lb_innerworn");
                        break
                    case "txt_lb_bothsideworn":
                        _data.lb.desc.title = intl.get("txt_lb_bothsideworn");
                        break
                    case "txt_lb_centreworn":
                        _data.lb.desc.title = intl.get("txt_lb_centreworn");
                        break
                    case "txt_pinnate_wear":
                        _data.lb.desc.title = intl.get("txt_pinnate_wear");
                        break
                    default:
                        _data.lb.desc.title = item.title.split('B')[1]

                }
            }
            if (item.title.indexOf('RB') > -1) {
                switch (item.title.split('B')[1]) {
                    case "txt_rb_outworn":
                        _data.rb.desc.title = intl.get("txt_rb_outworn");
                        break
                    case "txt_rb_innerworn":
                        _data.rb.desc.title = intl.get("txt_rb_innerworn");
                        break
                    case "txt_rb_bothsideworn":
                        _data.rb.desc.title = intl.get("txt_rb_bothsideworn");
                        break
                    case "txt_rb_centreworn":
                        _data.rb.desc.title = intl.get("txt_rb_centreworn");
                        break
                    case "txt_pinnate_wear":
                        _data.rb.desc.title = intl.get("txt_pinnate_wear");
                        break
                    default:
                        _data.rb.desc.title = item.title.split('B')[1]
                }
            }
            if (item.title.startsWith('BB')) {
                _data.lb.desc.title = intl.get('txt_ecc_abnormal')
                _data.rb.desc.title = intl.get('txt_ecc_abnormal')
                _data.lb.data = 'high'
                _data.rb.data = 'high'
            }
            if (item.title.startsWith('FF')) {
                _data.lf.desc.title = intl.get('txt_ecc_abnormal')
                _data.rf.desc.title = intl.get('txt_ecc_abnormal')
                _data.lf.data = 'high'
                _data.rf.data = 'high'
            }


        });
    }

    // 吃胎联动
    if (_data.lf.tyre.split(',')[3].indexOf("eattyre") !== -1) {
        _data.lf.desc.title = intl.get("txt_ecc_abnormal");
    }
    if (_data.rf.tyre.split(',')[3].indexOf("eattyre") !== -1) {
        _data.rf.desc.title = intl.get("txt_ecc_abnormal");
    }
    if (_data.lb.tyre.split(',')[3].indexOf("eattyre") !== -1) {
        _data.lb.desc.title = intl.get("txt_ecc_abnormal");
    }
    if (_data.rb.tyre.split(',')[3].indexOf("eattyre") !== -1) {
        _data.rb.desc.title = intl.get("txt_ecc_abnormal");
    }

    let suggestion = props.suggestion.toString()
    if (suggestion.indexOf("FFtxt_uneven_check") !== -1) {
        _data.lf.desc.title = intl.get("txt_ecc_abnormal");
        _data.rf.desc.title = intl.get("txt_ecc_abnormal");
    }
    if (suggestion.indexOf("BBtxt_uneven_check") !== -1) {
        _data.lb.desc.title = intl.get("txt_ecc_abnormal");
        _data.rb.desc.title = intl.get("txt_ecc_abnormal");

    }


    // 返回轮胎颜色
    const returnColor = (data, tyre) => {
        if (data === null) {
            return;
        }

        if (tyre.split(',')[3].indexOf("eattyre") != -1) {

            if (tyre.split(',')[5] === 'damage_high') {
                return {
                    color: "#FF5230",
                    img: require("../../assets/tyre-high.png")
                }
            } else {
                return {
                    color: "#FFAD26",
                    img: require("../../assets/tyre-middle.png")
                }
            }

        }
        return data === "low"
            ? {
                color: "#36B37E",
                img: require("../../assets/tyre-low.png")
            }
            : data === "middle"
                ? {
                    color: "#FFAD26",
                    img: require("../../assets/tyre-middle.png")
                }
                : {
                    color: "#FF5230",
                    img: require("../../assets/tyre-high.png")
                };
    };

    // 返回轮胎位置
    const returnTyrePosition = key => {
        switch (key) {
            case "lf":
                return intl.get("lf");
            case "lb":
                return intl.get("lr");
            case "rf":
                return intl.get("rf");
            case "rb":
                return intl.get("rr");
        }
    };

    return (
        <div style={{marginTop: 40, marginBottom: 40}}>
            <img className="frame" src={require("../../assets/car.png")} alt=""/>
            {Object.entries(_data).map(item => (
                <div className="tyre" key={item[0]} style={item[1].position}>
                    <div className="desc" style={item[1].desc.position}>
                        {returnTyrePosition(item[0])}：
                        <p style={{color: returnColor(item[1].data, item[1].tyre).color}}>
                            {item[1].desc.title}
                        </p>
                    </div>
                    <img src={returnColor(item[1].data, item[1].tyre).img} alt=""/>
                </div>
            ))
            }
            {props.transposition && (
                <div style={{position: "absolute", top: 0, left: 0}}>
                    {(props.transposition.includes("LF2RF") ||
                        props.transposition.includes("LB2RB")) && (
                        <img
                            src={require("../../assets/huanwei_h.png")}
                            alt=""
                            style={{position: "absolute", left: 230, top: 180, width: 40}}
                        />
                    )}
                    {(props.transposition.includes("LF2RB") ||
                        props.transposition.includes("RB2LF")) && (
                        <img
                            src={require("../../assets/huanwei_x_left.png")}
                            alt=""
                            style={{position: "absolute", left: 220, top: 40, height: 60}}
                        />
                    )}
                    {(props.transposition.includes("RF2LB") ||
                        props.transposition.includes("LB2RF")) && (
                        <img
                            src={require("../../assets/huanwei_x_right.png")}
                            alt=""
                            style={{position: "absolute", left: 220, top: 40, height: 60}}
                        />
                    )}
                    {props.transposition.includes("LF2LB") && (
                        <img
                            src={require("../../assets/huanwei_v.png")}
                            alt=""
                            style={{position: "absolute", left: 192, top: 58, height: 30}}
                        />
                    )}
                    {props.transposition.includes("RF2RB") && (
                        <img
                            src={require("../../assets/huanwei_v.png")}
                            alt=""
                            style={{position: "absolute", left: 294, top: 58, height: 30}}
                        />
                    )}
                </div>
            )}
            {/* TODO:添加总结 */}
        </div>
    );
};

// 轮胎颜色
const TyreColor = props => {
    const _data = props.status;
    _data.lf.position = {left: 180, top: 30};
    _data.rf.position = {left: 290, top: 30};
    _data.lb.position = {left: 180, top: 135};
    _data.rb.position = {left: 290, top: 135};

    // 返回轮胎状态
    const returnTyreStatus = status => {

        switch (status) {
            case "high":
                return (
                    <img
                        style={{objectFit: "contain"}}
                        src={require("../../assets/tyre-red.png")}
                        alt=""
                    />
                );
            case "middle":
                return (
                    <img
                        style={{objectFit: "contain"}}
                        src={require("../../assets/tyre-yellow.png")}
                        alt=""
                    />
                );
            case "low":
                return (
                    <img
                        style={{objectFit: "contain"}}
                        src={require("../../assets/tyre-green.png")}
                        alt=""
                    />
                );
        }
    };

    return (
        <div style={{left: 0, top: 0, position: "absolute", zIndex: 0}}>
            {Object.entries(_data).map((item, index) => (
                <div
                    style={Object.assign(item[1].position, {
                        position: "absolute"
                    })}
                    key={index}
                >
                    {returnTyreStatus(item[1].color)}
                </div>
            ))}
        </div>
    );
};

// 返回轮胎安装状况
const tireInstall = (arg) => {
    let install = '';
    for (let i = 0; i < arg.length; i++) {
        if (arg[i].includes('abnormal')) {
            install = {color: 'red', text: intl.get('abnormal')};
            return install;
        } else {
            install = {color: 'green', text: intl.get('trauma_normal')}
        }
    }
    ;
    return install
};

const TyreInjery = props => {
    let imgs_lf =null,imgs_rf =null,imgs_lb =null,imgs_rb =null;
    if (props.trauma_lf_img&&props.trauma_lf_img.indexOf("img1")>=0 && props.trauma_lf_img.indexOf("img2")>0 && props.trauma_lf_img.indexOf("img3")>0){
        imgs_lf = JSON.parse(props.trauma_lf_img)
    }
    if (props.trauma_rf_img&&props.trauma_rf_img.indexOf("img1")>=0 && props.trauma_rf_img.indexOf("img2")>0 && props.trauma_rf_img.indexOf("img3")>0){
        imgs_rf = JSON.parse(props.trauma_rf_img)
    }
    if (props.trauma_lb_img&&props.trauma_lb_img.indexOf("img1")>=0 && props.trauma_lb_img.indexOf("img2")>0 && props.trauma_lb_img.indexOf("img3")>0){
        imgs_lb = JSON.parse(props.trauma_lb_img)
    }
    if (props.trauma_rb_img&&props.trauma_rb_img.indexOf("img1")>=0 && props.trauma_rb_img.indexOf("img2")>0 && props.trauma_rb_img.indexOf("img3")>0){
        imgs_rb = JSON.parse(props.trauma_rb_img)
    }
    // 返回轮胎外伤类型
    const trauma_type = (type) => {
        let _list = type.split(';');
        _list.forEach(function (value, index) {
            if (value === 'trauma_normal') {
                _list[index] = intl.get('trauma_normal')
            } else if (value === 'trauma_bulge') {
                _list[index] = intl.get('trauma_bulge')
            } else if (value === 'trauma_aging') {
                _list[index] = intl.get('trauma_aging')
            } else if (value === 'trauma_lacerate') {
                _list[index] = intl.get('trauma_lacerate')
            } else if (value === 'trauma_dropblock') {
                _list[index] = intl.get('trauma_dropblock')
            } else if (value === 'trauma_puncture') {
                _list[index] = intl.get('trauma_puncture')
            } else if (value === 'trauma_scratch') {
                _list[index] = intl.get('trauma_scratch')
            } else if (value === 'trauma_pinnate') {
                _list[index] = intl.get('trauma_pinnate')
            } else if (value === 'trauma_eattyre') {
                _list[index] = intl.get('trauma_eattyre')
            } else {
                return type.split('other')[1]
            }
        })
        let _str = _list.join(';')
        return _str
    };

    const injerytyre = props => {
        if (props) {
            var col
            if (props.split(",").length === 6) {
                if (props.split(",")[5] === 'damage_high') {
                    col = '#FF5230'
                } else if (props.split(",")[5] === 'damage_low') {
                    col = '#ffcc00'
                } else {
                    col = '#36B37E'
                }
            } else {
                if (props.split(",")[3] !== "trauma_normal") {
                    col = '#FF5230'
                } else {
                    col = '#36B37E'
                }

            }

            return (<span
                style={{color: col}}>{trauma_type(props.split(",")[3])}</span>)
        }
        return (<span style={{color: "green"}}>{intl.get('zc')}</span>)
    };

    // 返回轮胎
    const site_list = {
        lf: {position: "absolute", left: 180, top: 70, zIndex: 9},
        rf: {position: "absolute", right: 180, top: 70, zIndex: 9},
        lb: {position: "absolute", left: 180, top: 170, zIndex: 9},
        rb: {position: "absolute", right: 180, top: 170, zIndex: 9},
    };

// 返回轮胎颜色
    const injerycolor = (info, site) => {

        if (info) {
            var src
            if (info.split(",").length === 6) {
                if (info.split(",")[5] === 'damage_high') {
                    src = require("../../assets/tyre-red.png")
                } else if (info.split(",")[5] === 'damage_low') {
                    src = require("../../assets/tyre-yellow.png")
                } else {
                    src = require("../../assets/tyre-green.png")
                }
            } else {
                if (info.split(",")[3] !== "trauma_normal") {
                    src = require("../../assets/tyre-red.png")
                } else {
                    src = require("../../assets/tyre-green.png")
                }

            }
            return (<img
                    src={src}
                    alt="" style={site}/>
            )
        }
        ;
        return (<img src={require("../../assets/tyre-green.png")} alt="" style={site}/>)
    };

      // 外伤正常
  const returnNomaimg = () => {

    return (<img
        style={{
            width: 150,
            height: 100
          }}
        resizemode="contain"
        src={require('../../assets/normal.png')} 
        alt=""/>
    );

  };
  // 轮胎图片
  const returnTyre = (data) => {

    if (data) {
      if (data.indexOf("img1")>=0 && data.indexOf("img2")>0 && data.indexOf("img3")>0){
        let imgjson = JSON.parse(data)
        return (
            <div>
            <a href={imgjson.img1}>
            <img
              style={{
                width: 50, height: 90
              }}
              resizemode="cover"
              src={imgjson.img1==''?require("../../assets/noimage.png"):imgjson.img1} />
            </a>
            <a href={imgjson.img2}>
              <img
              style={{
                width: 50, height: 90,
              }}
              resizemode="cover"
              src={imgjson.img2==''?require("../../assets/noimage.png"):imgjson.img2} />
              </a>
              <a href={imgjson.img3}>
              <img
              style={{
                width: 50, height: 90,
              }}
              resizemode="cover"
              src={imgjson.img3==''?require("../../assets/noimage.png"):imgjson.img3} />
              </a>
          </div>);
      }else{
        return (<img
          style={{
            width: 150,
            height: 100
          }}
          resizemode="contain"
          src={data} />);
      }
      
    } else {
      return (<div></div>)
    }

  };

    return (
    <div className="injery">
        {(props.tyre_lf)  &&
            (<div>
                <div>{injerycolor(props.tyre_lf, site_list.lf)}</div>
                <div className="lf" style={{position: "absolute", left: 5, top: 30, width: "150px", textAlign: "left"}}>
                    <p>{intl.get('lf')}:<br/>
                        <span>{injerytyre(props.tyre_lf)}</span>
                    </p>
                    
                    <div className="img_lf">
                        {props.tyre_lf.split(',')[3] !== "trauma_normal" ? returnTyre(props.trauma_lf_img) : returnNomaimg() }
                    </div>
                </div>
            </div>)
        }

        {(props.tyre_rf) &&
            (<div>
                <div>{injerycolor(props.tyre_rf, site_list.rf)}</div>
                <div className="rf"
                 style={{position: "absolute", right: 5, top: 30, width: "150px", textAlign: "left"}}>
                    <p>{intl.get('rf')}:<br/>
                        <span>{injerytyre(props.tyre_rf)}</span>
                    </p>
                    <div className="img_rf">
                        {props.tyre_rf.split(',')[3] !== "trauma_normal" ? returnTyre(props.trauma_rf_img) : returnNomaimg() }
                    </div>
                </div>

                <div className="zx">
                    <img className="car"
                        src={require("../../assets/car.png")}
                        alt=""
                    />
                </div>
            </div>)
        }   
        {(props.tyre_lb) &&
            (<div>
                <div>{injerycolor(props.tyre_lb, site_list.lb)}</div>
                <div className="lb"
                    style={{position: "absolute", left: 5, top: 200, width: "150px", textAlign: "left"}}>
                    <p>{intl.get('lr')}:<br/>
                        <span>{injerytyre(props.tyre_lb)}</span>
                    </p>
                    <div className="img_lb">
                        {props.tyre_lb.split(',')[3] !== "trauma_normal" ? returnTyre(props.trauma_lb_img) : returnNomaimg() }
                    </div>
                </div>
            </div>)
        }
        {(props.tyre_rb) &&
            (<div>
                <div>{injerycolor(props.tyre_rb, site_list.rb)}</div>
                <div className="rb"
                    style={{position: "absolute", right: 5, top: 200, width: "150px", textAlign: "left"}}>
                    <p>{intl.get('rr')}:<br/>
                        <span>{injerytyre(props.tyre_rb)}</span>
                    </p>
                    <div className="img_rb">
                        {props.tyre_rb.split(',')[3] !== "trauma_normal" ? returnTyre(props.trauma_rb_img) : returnNomaimg() }
                    </div>
                </div>
            </div>)
        }
 
    </div>
    )
};

export default Detail;
