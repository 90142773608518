import React, { Component } from 'react';
import { Card, Form, Layout, Input, text,Button, message, Select, Divider} from 'antd';
import './Setting.scss';
import Cookies from 'universal-cookie';
import intl from 'react-intl-universal';
import { Link } from "react-router-dom";
const cookies = new Cookies();
const { Option } = Select;

const { Header, Content, } = Layout;
class SettingDealer extends Component {
  constructor(props){
    super(props);
    this.submit = this.submit.bind(this);
    this.state = {
      isShow:"none",
      inputWidth:"100%",
      areaCode:'86',
      color1:"#006dd3",
      color2:"#A8A8A8",
      domain : global.Api.domain_url(),
      store: null,
    }
  }

  componentDidMount(){
    this.getStore();
  }

  getStore(){
    global.Api.getUser().then(res=>{
      if (res.data.code === 0) {
        let _data = res.data.data;
        // this.setState({store:_data.store,logo:_data.store.logo,});
        // if(this.props.location.pathname === '/login'){
        //   this.props.history.push('/reports')
        // }
        this.props.form.setFieldsValue({
          id: _data.user.id,
          uid: _data.user.mobile,
          name: _data.user.name,
          address: _data.user.address,          
          email: _data.user.email,
          telephone: _data.user.contact,
        })
      }
    })
  }

  handleChange = (value) => {
  value = Number(value);
  return value
  }

handleChangeOversea = (value) => {
  this.setState({
    areaCode:value
  })
}
  showSelect = (code)=>{
    if(code===0){
      this.setState({
            isShow:"none",
            inputWidth:"100%",
            color1:"#006dd3",
            color2:"#A8A8A8",
      })
    }else {
      this.setState(
          {
            isShow:"inline-block",
            inputWidth:"70%",
            color1:"#A8A8A8",
            color2:"#006dd3"
        }
      )
    }

  }

  submit(e){
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        global.Api.updateMerchant(values).then(res=>{
          if(res.data.code === 0){
            let _data = res.data.data;
            this.props.form.setFieldsValue({
              id: _data.user.id,
              uid: _data.user.mobile,
              name: _data.user.name,
              address: _data.user.address,          
              email: _data.user.email,
              telephone: _data.user.contact,
            })   
            message.info('Submit successfully!');        
            return
          }
          message.error(res.data.message);
        })
      }
    });
  }

  goback(){
    window.history.back();
  }
  logout = () => {
    cookies.remove('tyrehub-pc');
    this.props.history.push('/login');
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    
    return (
      <Layout className="reports">
        <Header style={{position: 'fixed', zIndex: 1, width: '100%',height:'120px',backgroundColor:'#3385ff',color:"#fff",}}>
          <div style={{marginRight:"20px",color:"#fff"}} >
          <span style={{fontSize:"20px",color:"#fff"}}>Handy Tyre Scanner</span>
          {/* <img class="logo img-fluid" src="https://mobilhubdiagnostics.com/img/logo-1667829529.jpg" alt='' width="228" height="38" /> */}
          </div>
          <Button type="link" style={{marginLeft:"10px",color:"#fff",fontSize:'15px',fontWeight:'500',}} ><Link to="/dashboarddealer">{intl.get('dashboard')}</Link></Button>
          <Button type="link" style={{marginLeft:"10px",color:"#fff",fontSize:'15px',fontWeight:'500',}} ><Link to="/dealerlist">{intl.get('reports')}</Link></Button>
          {/* <Button type="link" style={{marginLeft:"10px",color:"#fff",fontSize:'15px',fontWeight:'500',}} ><Link to="/devices">{intl.get('devices')}</Link></Button> */}
          <Button type="link" style={{marginLeft:"10px",color:"#fff",fontSize:'15px',fontWeight:'500',}} ><Link to="/storelist">{intl.get('storemanagement')}</Link></Button>
          
          <Button type="link" style={{marginRight:"10px",color:"#fff",fontSize:'15px',fontWeight:'500',}} ><Link to="/settingdealer">{intl.get('setting')}</Link></Button>
        </Header>
        <Content style={{ padding: '0 50px', marginTop:'110px'}}>
          <div className="setting">
            {/* <Button type="primary" style={{position:"absolute",left:"20px",top:"18px"}} onClick={this.downloadApp}>{intl.get('awd')}</Button> */}
            <Card title={intl.get('setting')} style={{ width: 1000 }}>
            <div style={{ alignItems: 'right',justifyContent:'right',display:'flex' }}>
                <Button type="link" style={{marginRight:"10px",fontSize:'13px',fontWeight:'300',}} ><Link to="/changepassworddealer">Change Password</Link></Button>
                <Button type="link" style={{marginRight:"10px",fontSize:'13px',fontWeight:'300',}} onClick={this.logout}>Logout</Button>
            </div>
            <Form onSubmit={this.submit}>
              <Form.Item>
                <text>ID </text>
                
                {getFieldDecorator('id', {
                  rules: [{ required: true, message: '' }],
                })(
                <Input readOnly='readOnly' />
                )}
              </Form.Item>

              <Form.Item>
                <text>UID </text>
                
                {getFieldDecorator('uid', {
                  rules: [{ required: true, message: '' }],
                })(
                <Input readOnly='readOnly' />
                )}
              </Form.Item>
              <Form.Item>
                <text>Name</text>
                {getFieldDecorator('name', {
                  rules: [{ required: true, message: 'please input name' }],
                })(
                <Input readOnly='readOnly' />
                )}
              </Form.Item>
              <Form.Item>
                <text>Address</text>
                {getFieldDecorator('address', {
                  rules: [{ required: true, message: 'please input address' }],
                })(
                <Input readOnly='readOnly' />
                )}
              </Form.Item>
              <Form.Item>
                <text>Telephone</text>
                {getFieldDecorator('telephone', {
                  rules: [{ required: true, message: 'please input telephone' }],
                })(
                <Input readOnly='readOnly' />
                )}
              </Form.Item>
              <Form.Item>
                <text>Email</text>
                {getFieldDecorator('email', {
                  rules: [{ required: false, message: 'please input email' }],
                })(
                <Input />
                )}
              </Form.Item>          
              
              {/* <Form.Item>
                <text>Store Logo</text> 
                <Button type="link" style={{marginLeft:"30px",color:"##000",fontSize:'15px',}} ><Link to="/uploadfile">Upload a new Logo</Link></Button>
        
                {getFieldDecorator('logo', {
                  rules: [{ required: false, message: 'store logo' }],
                })(
                <img src={this.state.logo} alt="image"/>
                )}
              </Form.Item> */}


              <Form.Item>
                <Button type="primary" htmlType="submit" style={{marginLeft:"0%",width:'45%'}}>
                {intl.get('confirm')}
                </Button>
                <Button type="primary" onClick={this.goback} style={{marginLeft:"10%",width:'45%'}}>
                {intl.get('cancel')}
                </Button>
              </Form.Item>
            </Form>
            </Card>
          </div>
        </Content>
      </Layout>
    );
  }
}

export default Form.create()(SettingDealer);
