export default {
  'login':'Login',
  'input_name':'Indicar um Nome de utilizador',
  'input_password':'Digite a senha',
  'login_su':'Login BEM sucedido',
  'role':'Papel',
  'username':'Nome do utilizador',
  'password':'Senha',

  'search_btn': 'search',
  'store':'Armazenar nome',
  'number':'Número',
  'device_time':'Período de detecção ',
  'wear_co':'Estado de desgaste (dianteiro esquerdo-dianteiro direito-traseiro esquerdo-traseiro direito)',
  'ecc_co':'Estado de desgaste excêntrico (dianteiro esquerdo-dianteiro direito-traseiro esquerdo-traseiro direito)',
  'plate_no':'Placa de licença',
  'cus_ph':'Telemóvel do cliente',
  'car_type':'Modelo de veículo',
  'operation':'Operar',
  'view':'Ver detalhes',
  'reports': 'Inspection Reports',
  'setting': 'System Setting',

  'print':'Imprimir (verificar gráficos de fundo)',
  'store_name':'Loja de serviços',
  'address':'Endereço da loja',
  'model':'Modelo',
  'mileage':'Milhagem',
  'device':'Equipamento de ensaio',
  'remark':'Observações',
  'purpose':'Destino',
  're':'Sugestão de inspeção',
  'color_flag':'Identificação de cores',
  'wear':'General waste',
  'brake':'Lembrete de distância de frenagem atual',
  'ecc':'Abnormal wear',
  'lf':'L-F',
  'lr':'L-R',
  'rf':'R-F',
  'rr':'R-R',
  'untest':'Pneu não detetado',
  'low':'Estado bom',
  'middle':'Estao limite',
  'high':'Substitui agora',
  'avg':'Valor médio',
  'spec':"Especificação ",
  'brand':'Marca',
  'ecc_avg':'Desgaste uniforme do padrão',
  'trauma':'Aparência dos pneus',
  'zc':'normal',
  'image':'zh',
  'qr':'Digitalizar o código QR para ver o relatório',
  'model_number':'HH Tire Tread Depth Detector',
  'purp_wash':'Beleza e lavagem de veículo',
  'purp_alignment':'Alinhador de quatro rodas',
  'purp_replace':'Substituição de pneu',
  'purp_maintain':'Reparo e manutenção',
  'warn': 'Recordação: a distância de travagem Pode aumentar de acordo com o estado actual de desgaste DOS pneus',
  'trauma_normal':'normal',
  'trauma_bulge':'Protuberância',
  'trauma_aging':'Envelhecimento',

  'txt_lf_outworn':'O lado externo do pneu dianteiro esquerdo está mais gasto do que o lado interno',
  'txt_lf_innerworn':'O lado interno do pneu dianteiro esquerdo está mais gasto do que o lado externo',
  'txt_lf_bothsideworn':'Os dois lados do pneu dianteiro esquerdo está mais gasto do que o meio',
  'txt_lf_centreworn':'O meio do pneu dianteiro esquerdo está mais gasto do que os dois lados',
  'txt_lb_outworn':'O lado externo do pneu traseiro esquerdo está mais gasto do que o lado interno',
  'txt_lb_innerworn':'O lado interno do pneu traseiro esquerdo está mais gasto do que o lado externo',
  'txt_lb_bothsideworn':'Os dois lados do pneu traseiro esquerdo está mais gasto do que o meio',
  'txt_lb_centreworn':'O meio do pneu traseiro esquerdo está mais gasto do que os dois lados',
  'txt_rb_outworn':'O lado externo do pneu traseiro direito está mais gasto do que o lado interno',
  'txt_rb_innerworn': 'O lado interno do pneu traseiro direito está mais gasto do que o lado externo',
  'txt_rb_bothsideworn': 'Os dois lados do pneu traseiro direito está mais gasto do que o meio',
  'txt_rb_centreworn': 'O meio do pneu traseiro direito está mais gasto do que os dois lados',
  'txt_rf_outworn': 'O lado externo do pneu dianteiro dierito está mais gasto do que o lado interno',
  'txt_rf_innerworn':  'O lado interno do pneu dianteiro direito está mais gasto do que o lado externo',
  'txt_rf_bothsideworn': 'Os dois lados do pneu dianteiro direito está mais gasto do que o meio',
  'txt_rf_centreworn': 'O meio do pneu dianteiro direito está mais gasto do que os dois lados' ,

  'txt_tire_need_replace':          'O pneu está seriamente usado. Por favor, substituí-lo imediatamente',
  'txt_tire_boundary':              'O desgaste do pneu está no estado limite, verifique-o regularmente',
  'txt_alignment_chassis':          'Recomenda-se alinhamento nas quatro rodas ou inspeção do chassi',
  'txt_wear_uniform':               'O desgaste do pneu é Mesmo',
  'txt_do_further_check':           'Por favor, verifique mais.',
  'txt_uneven_check':                'Se o pneu for usado de forma anormal, recomenda- se que se verifique o alinhamento Das Quatro rodas e as partes do chassis',
  'txt_tire_rotation':              'Sugere-se que OS pneus devem ser Rodados',
  'txt_wear_normal':                'Desgaste noral de pneus',
  'txt_tire':                       'Pneu',

  'time':'Escolhe hora',
  'today':'Hoje',
  'yesterday':'Ontem',
  'week':'Nesta semana',
  'month':'Neste mês',
  'last_month':'Mês anterior',
  'year':'Neste ano',
  'language':'Escolhe idioma',
  'zh':'Chinês',
  'en':'Inglês',
  'ma':'Malaia',
  'ge':'Alemão',
  'fr':'Francês',
  'lt':'Italiano',
  'sp':'Espanhol',
  'tw':'Caractere tradicional chinês',
  'jp':'Japonês',
  'po':'Polônia',
  'por':'Português',
  'download':'Relatório de Download',
  'advise': 'Sugestão: o pneu TEM desgaste excêntrico, é recomendada UMA Nova inspeção',

  'txt_tire_age_5': 'O prazo de validade dos pneus ultrapassou 5 anos, recomenda-se substituir os pneus',
  'txt_tire_age_3': 'O pneu é válido por Mais de três anos. Verifique-o regularmente.',
  'txt_tire_age_ok': 'Boa Vida de pneu.',
  'txt_tire_trauma_check': 'Há trauma Na esteira do pneu, é recomendada UMA inspeção Mais aprofundada',
  'txt_install_abnormal_check': 'Os pneus estão invertidos, confirme o tratamento',
  'txt_tire_appearance_ok': 'Aparência boa dos pneus;',
  'txt_pinnate_check': 'Sugere-se que se verifique o equilíbrio dinâmico, o alinhamento Das Quatro rodas e o sistema de suspensão',
  'trauma_lacerate': '割裂',
  'trauma_dropblock': 'Largue um pedaço.',
  'trauma_puncture': 'Perfuração',
  'trauma_scratch': 'Abrasões',
  'trauma_pinnate': 'Desgaste pinnate\'',
  'txt_tire_age': 'Vida DOS pneus',
  'txt_tire appearance': 'Aparência dos pneus',
  'tire_installed': 'Instalação de pneus',
  'abnormal': 'Dentro para fora reverso',
  'endurance': 'Estimativa de quilometragem de pneu',
  'endurance-warn': 'A Gama restante é de {number}km',
  'endurance-zero': 'O pneu atingiu o limite, por favor, substituí-lo imediatamente',
  'estimated_value': 'Valor estimado',
  'abrasion': 'Abrasão',
  'remanent': '{days} Dias restantes para utilização de pneus',
  'useful_life': 'Estimativa Da Vida útil DOS pneus',
  'dot_explain': 'DOT：Tempo de Fabrico de pneus',
  'unit_km': 'Empresa：km',
  'unit_year': 'Empresa：ANO',
  'dot_no': 'Tempo de produção não obtido',
  'made_time': 'Tempo de produção: {dot} ({week} do {year})',
  'exceed_five': 'O prazo de validade dos pneus ultrapassou 5 anos, recomenda-se substituir os pneus',
  'inspector': 'Técnico',
  'txt_install_check': 'Verificação para instalação',
  'txt_install_ok': 'A instalação é normal',
  'txt_pinnate_wear': 'Desgaste pinnate\'',

  'LFtxt_tire_need_replace': '[L-F]O pneu está muito gasto, recomenda-se substituir o pneu',
  'RFtxt_tire_need_replace': '[R-F]O pneu está muito gasto, recomenda-se substituir o pneu',
  'LBtxt_tire_need_replace': '[L-R]O pneu está muito gasto, recomenda-se substituir o pneu',
  'RBtxt_tire_need_replace': '[R-R]O pneu está muito gasto, recomenda-se substituir o pneu',
  'FFtxt_tire_need_replace': '[F-F]O pneu está muito gasto, recomenda-se substituir o pneu',
  'BBtxt_tire_need_replace': '[B-B]O pneu está muito gasto, recomenda-se substituir o pneu',


  'txt_pattern_wear': 'Abnormal wear',
  'txt_uneven_center': 'Desgaste anormal no meio do pneu, recomenda-se verificar a pressão do pneu',
  'LFtxt_uneven_center': '[L-F]Desgaste anormal no meio do pneu, recomenda-se verificar a pressão do pneu',
  'RFtxt_uneven_center': '[R-F]Desgaste anormal no meio do pneu, recomenda-se verificar a pressão do pneu',
  'LBtxt_uneven_center': '[L-R]Desgaste anormal no meio do pneu, recomenda-se verificar a pressão do pneu',
  'RBtxt_uneven_center': '[R-R]Desgaste anormal no meio do pneu, recomenda-se verificar a pressão do pneu',
  'FFtxt_uneven_center': '[F-F]Desgaste anormal no meio do pneu, recomenda-se verificar a pressão do pneu',
  'BBtxt_uneven_center': '[B-B]Desgaste anormal no meio do pneu, recomenda-se verificar a pressão do pneu',

  'txt_uneven_out': 'O Lado externo do pneu é usado anormalmente. Recomenda- se que verifique o alinhamento Das Quatro rodas e a pressão DOS pneus',
  'LFtxt_uneven_out': '[L-F]O Lado externo do pneu é usado anormalmente. Recomenda- se que verifique o alinhamento Das Quatro rodas e a pressão DOS pneus',
  'RFtxt_uneven_out': '[R-F]O Lado externo do pneu é usado anormalmente. Recomenda- se que verifique o alinhamento Das Quatro rodas e a pressão DOS pneus',
  'LBtxt_uneven_out': '[L-R]O Lado externo do pneu é usado anormalmente. Recomenda- se que verifique o alinhamento Das Quatro rodas e a pressão DOS pneus',
  'RBtxt_uneven_out': '[R-R]O Lado externo do pneu é usado anormalmente. Recomenda- se que verifique o alinhamento Das Quatro rodas e a pressão DOS pneus',
  'FFtxt_uneven_out': '[F-F]O Lado externo do pneu é usado anormalmente. Recomenda- se que verifique o alinhamento Das Quatro rodas e a pressão DOS pneus',
  'BBtxt_uneven_out': '[B-B]O Lado externo do pneu é usado anormalmente. Recomenda- se que verifique o alinhamento Das Quatro rodas e a pressão DOS pneus',

  'LFtxt_pinnate_check':'[L-F]Os pneus apresentam desgaste pinnate. Recomenda-se verificar o ',
  'RFtxt_pinnate_check':'[R-F]Os pneus apresentam desgaste pinnate. Recomenda-se verificar o ',
  'LBtxt_pinnate_check':'[L-R]Os pneus apresentam desgaste pinnate. Recomenda-se verificar o ',
  'RBtxt_pinnate_check':'[R-R]Os pneus apresentam desgaste pinnate. Recomenda-se verificar o ',
  'FFtxt_pinnate_check':'[F-F]Os pneus apresentam desgaste pinnate. Recomenda-se verificar o ',
  'BBtxt_pinnate_check':'[B-B]Os pneus apresentam desgaste pinnate. Recomenda-se verificar o ',

  'txt_uneven_inside': 'Se o Lado interno do pneu for usado anormalmente, recomenda- se que verifique o alinhamento Das Quatro rodas e a pressão DOS pneus',
  'LFtxt_uneven_inside': '[L-F]Se o Lado interno do pneu for usado anormalmente, recomenda- se que verifique o alinhamento Das Quatro rodas e a pressão DOS pneus',
  'RFtxt_uneven_inside': '[R-F]Se o Lado interno do pneu for usado anormalmente, recomenda- se que verifique o alinhamento Das Quatro rodas e a pressão DOS pneus',
  'LBtxt_uneven_inside': '[L-R]Se o Lado interno do pneu for usado anormalmente, recomenda- se que verifique o alinhamento Das Quatro rodas e a pressão DOS pneus',
  'RBtxt_uneven_inside': '[R-R]Se o Lado interno do pneu for usado anormalmente, recomenda- se que verifique o alinhamento Das Quatro rodas e a pressão DOS pneus',
  'FFtxt_uneven_inside': '[F-F]Se o Lado interno do pneu for usado anormalmente, recomenda- se que verifique o alinhamento Das Quatro rodas e a pressão DOS pneus',
  'BBtxt_uneven_inside': '[B-B]Se o Lado interno do pneu for usado anormalmente, recomenda- se que verifique o alinhamento Das Quatro rodas e a pressão DOS pneus',

  'FFtxt_uneven_check': '[F-F]Se o pneu for usado de forma anormal, recomenda- se que se verifique o alinhamento Das Quatro rodas e as partes do chassis',
  'BBtxt_uneven_check': '[B-B]Se o pneu for usado de forma anormal, recomenda- se que se verifique o alinhamento Das Quatro rodas e as partes do chassis',


  'LFtxt_tire_age_5': '[L-F]O prazo de validade dos pneus ultrapassou 5 anos, recomenda-se substituir os pneus',
  'RFtxt_tire_age_5': '[R-F]O prazo de validade dos pneus ultrapassou 5 anos, recomenda-se substituir os pneus',
  'LBtxt_tire_age_5': '[L-R]O prazo de validade dos pneus ultrapassou 5 anos, recomenda-se substituir os pneus',
  'RBtxt_tire_age_5': '[R-R]O prazo de validade dos pneus ultrapassou 5 anos, recomenda-se substituir os pneus',
  'FFtxt_tire_age_5': '[F-F]O prazo de validade dos pneus ultrapassou 5 anos, recomenda-se substituir os pneus',
  'BBtxt_tire_age_5': '[B-B]O prazo de validade dos pneus ultrapassou 5 anos, recomenda-se substituir os pneus',

  'txt_appearance_check':'Existe traumao no pneu, confirme o reparo',
  'LFtxt_appearance_check':'[L-F]Existe traumao no pneu, confirme o reparo',
  'RFtxt_appearance_check':'[R-F]Existe traumao no pneu, confirme o reparo',
  'LBtxt_appearance_check':'[L-R]Existe traumao no pneu, confirme o reparo',
  'RBtxt_appearance_check':'[R-R]Existe traumao no pneu, confirme o reparo',
  'FFtxt_appearance_check':'[F-F]轮Existe traumao no pneu, confirme o reparo',
  'BBtxt_appearance_check':'[B-B]Existe traumao no pneu, confirme o reparo',


  'LFtxt_install_abnormal_check':'[L-F]Os pneus estão invertidos, confirme o tratamento',
  'RFtxt_install_abnormal_check':'[R-F]Os pneus estão invertidos, confirme o tratamento',
  'LBtxt_install_abnormal_check':'[L-R]Os pneus estão invertidos, confirme o tratamento',
  'RBtxt_install_abnormal_check':'[R-R]Os pneus estão invertidos, confirme o tratamento',
  'FFtxt_install_abnormal_check':'[F-F]Os pneus estão invertidos, confirme o tratamento',
  'BBtxt_install_abnormal_check':'[B-B]Os pneus estão invertidos, confirme o tratamento',

  'txt_uneven_bothside': 'Desgaste anormal EM Ambos OS lados do pneu. Recomenda- se verificar o alinhamento Das Quatro rodas e a pressão DOS pneus',
  'LFtxt_uneven_bothside': '[L-F]Desgaste anormal EM Ambos OS lados do pneu. Recomenda- se verificar o alinhamento Das Quatro rodas e a pressão DOS pneus',
  'RFtxt_uneven_bothside': '[R-F]Desgaste anormal EM Ambos OS lados do pneu. Recomenda- se verificar o alinhamento Das Quatro rodas e a pressão DOS pneus',
  'LBtxt_uneven_bothside': '[L-R]Desgaste anormal EM Ambos OS lados do pneu. Recomenda- se verificar o alinhamento Das Quatro rodas e a pressão DOS pneus',
  'RBtxt_uneven_bothside': '[R-R]Desgaste anormal EM Ambos OS lados do pneu. Recomenda- se verificar o alinhamento Das Quatro rodas e a pressão DOS pneus',
  'FFtxt_uneven_bothside': '[F-F]Desgaste anormal EM Ambos OS lados do pneu. Recomenda- se verificar o alinhamento Das Quatro rodas e a pressão DOS pneus',
  'BBtxt_uneven_bothside': '[B-B]Desgaste anormal EM Ambos OS lados do pneu. Recomenda- se verificar o alinhamento Das Quatro rodas e a pressão DOS pneus',

  'txt_wear_lookup': 'Verifique desgaste dos pneus',
  'LFtxt_wear_lookup': '[L-F]Verifique desgaste dos pneus',
  'RFtxt_wear_lookup': '[R-F]Verifique desgaste dos pneus',
  'LBtxt_wear_lookup': '[L-R]Verifique desgaste dos pneus',
  'RBtxt_wear_lookup': '[R-R]Verifique desgaste dos pneus',
  'FFtxt_wear_lookup': '[F-F]Verifique desgaste dos pneus',
  'BBtxt_wear_lookup': '[B-B]Verifique desgaste dos pneus',

  'txt_appearance_lookup': 'Verifique aparência dos pneus',
  'LFtxt_appearance_lookup': '[L-F]Verifique aparência dos pneus',
  'RFtxt_appearance_lookup': '[R-F]Verifique aparência dos pneus',
  'LBtxt_appearance_lookup': '[L-R]Verifique aparência dos pneus',
  'RBtxt_appearance_lookup': '[R-R]Verifique aparência dos pneus',
  'FFtxt_appearance_lookup': '[F-F]Verifique aparência dos pneus',
  'BBtxt_appearance_lookup': '[B-B]Verifique aparência dos pneus',

  'txt_serious_trauma_check': 'Existe traumao grave no pneu, recomenda-se substituir pneu',
  'LFtxt_serious_trauma_check': '[L-F]Existe traumao grave no pneu, recomenda-se substituir pneu',
  'RFtxt_serious_trauma_check': '[R-F]Existe traumao grave no pneu, recomenda-se substituir pneu',
  'LBtxt_serious_trauma_check': '[L-R]Existe traumao grave no pneu, recomenda-se substituir pneu',
  'RBtxt_serious_trauma_check': '[R-R]Existe traumao grave no pneu, recomenda-se substituir pneu',
  'FFtxt_serious_trauma_check': '[F-F]Existe traumao grave no pneu, recomenda-se substituir pneu',
  'BBtxt_serious_trauma_check': '[B-B]Existe traumao grave no pneu, recomenda-se substituir pneu',

  'purp_tireservice':'Serviços de pneus',
  'purp_vehicle_inspection':'Inspecção do veículo',
  'merchant': 'Mercador',
  'manager': 'Proprietário',
  'assistant': 'Escriturário',

  'txt_chassis_check': 'Inspecção do chassis',
  'txt_chassis_ok': 'O chassis está EM Boas condições.',
  'LFtxt_chassis_ok': '[L-F]O chassis está EM Boas condições.',
  'RFtxt_chassis_ok': '[R-F]O chassis está EM Boas condições.',
  'LBtxt_chassis_ok': '[L-R]O chassis está EM Boas condições.',
  'RBtxt_chassis_ok': '[R-R]O chassis está EM Boas condições.',
  'FFtxt_chassis_ok': '[F-F]O chassis está EM Boas condições.',
  'BBtxt_chassis_ok': '[B-B]O chassis está EM Boas condições.',
  'txt_chassis_oil': 'Recomenda- se a confirmação e substituição do óleo pelo absorvente de choque. Se necessário',
  'LFtxt_chassis_oil': '[L-F]Recomenda- se a confirmação e substituição do óleo pelo absorvente de choque. Se necessário',
  'RFtxt_chassis_oil': '[R-F]Recomenda- se a confirmação e substituição do óleo pelo absorvente de choque. Se necessário',
  'LBtxt_chassis_oil': '[L-R]Recomenda- se a confirmação e substituição do óleo pelo absorvente de choque. Se necessário',
  'RBtxt_chassis_oil': '[R-R]Recomenda- se a confirmação e substituição do óleo pelo absorvente de choque. Se necessário',
  'FFtxt_chassis_oil': '[F-F]Recomenda- se a confirmação e substituição do óleo pelo absorvente de choque. Se necessário',
  'BBtxt_chassis_oil': '[B-B]Recomenda- se a confirmação e substituição do óleo pelo absorvente de choque. Se necessário',
  'txt_chassis_aging': 'A BORRACHA Da cabeça do eixo está envelhecendo, por is so é recomendado substituí-lo',
  'LFtxt_chassis_aging': '[L-F]A BORRACHA Da cabeça do eixo está envelhecendo, por is so é recomendado substituí-lo',
  'RFtxt_chassis_aging': '[R-F]A BORRACHA Da cabeça do eixo está envelhecendo, por is so é recomendado substituí-lo',
  'LBtxt_chassis_aging': '[L-R]A BORRACHA Da cabeça do eixo está envelhecendo, por is so é recomendado substituí-lo',
  'RBtxt_chassis_aging': '[R-R]A BORRACHA Da cabeça do eixo está envelhecendo, por is so é recomendado substituí-lo',
  'FFtxt_chassis_aging': '[F-F]A BORRACHA Da cabeça do eixo está envelhecendo, por is so é recomendado substituí-lo',
  'BBtxt_chassis_aging': '[B-B]A BORRACHA Da cabeça do eixo está envelhecendo, por is so é recomendado substituí-lo',
   'awd': 'App Download',

   'depth': 'Trench Depth',
    'usura': 'Eccentric Wear',
    'usura-l': 'The pattern is worn evenly',
    'usura-m': 'Slight eccentric wear',
    'usura-h': 'Severe eccentric wear',

    'engine-hood': 'ENGINE BONNET',
    'change-control': 'CHANGE CONTROL',
    'subtotal': 'UNDERBODY',

    'replace': 'REPLACE',
    'vedi': 'READ NOTE',
    'ok': 'OK',

    'liquid': 'GLASS WASHING LIQUID',
    'level-control': 'Level control',
    'air': 'AIR CONDITIONER',
    'last-reload': 'Last recharge',
    'absorber': 'SHOCK ABSORBER',
    'check-loss': 'Check of visible leaks',
    'fluid': 'RADIATOR FLUID',
    'lighting': 'LIGHTS',
    'verification': 'Opeation check',
    'disc': 'BRAKE DISCS',
    'condition': 'Wearing',
    'oil': 'MOTOR OI',
    'wiper': 'WIPERS',
    'pads': 'BRAKE PADS',
    'brake-fluid': 'BRAKE FLUID',
    'quality-control': 'Quality check',
    'battery': 'BATTERY',
    'multimetro': 'Multimetro test',
    'control-quality': 'QUALITY CHECK',
    'tightening': 'Dynamometer tightening',
    'buone': 'ok',
    'lavoro': 'Work scheduling',
    'concordare': 'Allow substitution',

    'com':'Commercial',
    'pass':'Passenger',

}
