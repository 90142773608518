import React, {Component} from "react";
import "./css/TyrehubEcc.scss";
import TyrehubTitle from "./TyrehubTitle";
import intl from "react-intl-universal";
import TyrehubCar from "./TyrehubCar";

class TyrehubEcc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ecc_advise: null
        };
    }

    render() {
        const returnConclusion = (conclusion) => {
            const decon = intl.get("ecc_avg")
            let conclu = {lf: decon, rf: decon, lb: decon, rb: decon};
            if (conclusion) {
                conclusion.forEach((item) => {
                    if (item.title.indexOf('LF') > -1) {
                        switch (item.title.split('F')[1]) {
                            case "txt_lf_outworn":
                                conclu.lf = intl.get("txt_lf_outworn");
                                break
                            case "txt_lf_innerworn":
                                conclu.lf = intl.get("txt_lf_innerworn");
                                break
                            case "txt_lf_bothsideworn":
                                conclu.lf = intl.get("txt_lf_bothsideworn");
                                break
                            case "txt_lf_centreworn":
                                conclu.lf = intl.get("txt_lf_centreworn");
                                break
                            case "txt_pinnate_wear":
                                conclu.lf = intl.get("txt_pinnate_wear");
                                break
                            default:
                                conclu.lf = item.title.split('F')[1]
                        }

                    }
                    if (item.title.indexOf('RF') > -1) {
                        switch (item.title.split('F')[1]) {
                            case "txt_rf_outworn":
                                conclu.rf = intl.get("txt_rf_outworn");
                                break
                            case "txt_rf_innerworn":
                                conclu.rf = intl.get("txt_rf_innerworn");
                                break
                            case "txt_rf_bothsideworn":
                                conclu.rf = intl.get("txt_rf_bothsideworn");
                                break
                            case "txt_rf_centreworn":
                                conclu.rf = intl.get("txt_rf_centreworn");
                                break
                            case "txt_pinnate_wear":
                                conclu.rf = intl.get("txt_pinnate_wear");
                                break
                            default:
                                conclu.rf = item.title.split('F')[1]
                        }
                    }
                    if (item.title.indexOf('LB') > -1) {
                        switch (item.title.split('B')[1]) {
                            case "txt_lb_outworn":
                                conclu.lb = intl.get("txt_lb_outworn");
                                break
                            case "txt_lb_innerworn":
                                conclu.lb = intl.get("txt_lb_innerworn");
                                break
                            case "txt_lb_bothsideworn":
                                conclu.lb = intl.get("txt_lb_bothsideworn");
                                break
                            case "txt_lb_centreworn":
                                conclu.lb = intl.get("txt_lb_centreworn");
                                break
                            case "txt_pinnate_wear":
                                conclu.lb = intl.get("txt_pinnate_wear");
                                break
                            default:
                                conclu.lb = item.title.split('B')[1]

                        }
                    }
                    if (item.title.indexOf('RB') > -1) {
                        switch (item.title.split('B')[1]) {
                            case "txt_rb_outworn":
                                conclu.rb = intl.get("txt_rb_outworn");
                                break
                            case "txt_rb_innerworn":
                                conclu.rb = intl.get("txt_rb_innerworn");
                                break
                            case "txt_rb_bothsideworn":
                                conclu.rb = intl.get("txt_rb_bothsideworn");
                                break
                            case "txt_rb_centreworn":
                                conclu.rb = intl.get("txt_rb_centreworn");
                                break
                            case "txt_pinnate_wear":
                                conclu.rb = intl.get("txt_pinnate_wear");
                                break
                            default:
                                conclu.rb = item.title.split('B')[1]
                        }
                    }

                });
            }

            return conclu;
        };
        const returnColorFlag = () => {
            const lang = intl.options.currentLocale;
            switch (lang) {
                case 'tw':
                    return require("../assets/tw_wco.png");
                case 'zh':
                    return require("../assets/wear.png");
                case 'en':
                    return require("../assets/entip.png");
                case 'ma':
                    return require("../assets/matip.png");
                case 'ge':
                    return require("../assets/getip.png");
                case 'fr':
                    return require("../assets/frtip.png");
                case 'sp':
                    return require("../assets/sptip.png");
                case 'lt':
                    return require("../assets/lttip.png");
                case 'jp':
                    return require("../assets/jptip.png");
            }
        };

        const returnColor = (degree) => {
            switch (degree) {
                case 'low':
                    return {color: 'green'};
                case 'middle':
                    return {color: '#ffad26'};
                case 'high':
                    return {color: 'red'};
                default:
                    return {color: 'green'};
            }
        };

        let {lf_degree, rf_degree, lb_degree, rb_degree, conclusion} = this.props.data;
        return (
            <div className="ty-ecc">
                <div><TyrehubTitle content={intl.get('ecc')}/></div>
                {/*<div className="ty-ecc-color"><img src={returnColorFlag()} alt=''/></div>*/}
                                         <div style={{position: 'relative'}}>
                                    <img
                                        style={{
                                            width: 750,
                                            marginTop: 10,
                                            height: 40,
                                        }}
                                        src={require("../assets/wear-lev.png")}
                                    />
                                    <div  style={{position: 'absolute', top:10, left:50, right:50,display: "flex", justifyContent: "space-between"}}>
                                        <div style={{fontSize:20, color:'white', textAlign: 'center'}}>
                                            <p>{intl.get('usura-h')}</p>
                                        </div>
                                        <div style={{fontSize:20, color:'white', textAlign: 'center'}}>
                                            <p>{intl.get('middle')}</p>
                                        </div>
                                        <div style={{fontSize:20, color:'white', textAlign: 'center'}}>
                                            <p>{intl.get('low')}</p>
                                        </div>
                                    </div>
                                </div>





                <div className="ty-ecc-con">
                    <div className="ecc-lf">
                        <p>{intl.get('lf')}:</p>
                        <p style={returnColor(lf_degree)}>{returnConclusion(conclusion).lf}</p>

                    </div>
                    <div className="ecc-rf">
                        <p>{intl.get('rf')}:</p>
                        <p style={returnColor(rf_degree)}>{returnConclusion(conclusion).rf}</p>

                    </div>
                    <div className="ecc-cen">
                        <TyrehubCar data={this.props.data}/>
                    </div>
                    <div className="ecc-lb">
                        <p>{intl.get('lr')}:</p>
                        <p style={returnColor(lb_degree)}>{returnConclusion(conclusion).lb}</p>

                    </div>
                    <div className="ecc-rb">
                        <p>{intl.get('rr')}:</p>
                        <p style={returnColor(rb_degree)}>{returnConclusion(conclusion).rb}</p>
                    </div>
                </div>
                <div style={{
                    display: conclusion.length === 0 || conclusion[0].title === 'txt_wear_uniform;' ? 'none' : 'block',
                    marginTop: '60px'
                }}>{this.state.ecc_advise !== null ? this.state.ecc_advise : intl.get('advise')}</div>

            </div>
        )
    }

}

export default TyrehubEcc;
