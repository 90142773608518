import React, {Component} from "react";
import intl from 'react-intl-universal';
import "./Tyrehub.scss";
import TyrehubHeader from "../../../components/TyrehubHeader";
import TyrehubWear from "../../../components/TyrehubWear";
import TyrehubEcc from "../../../components/TyrehubEcc";
import TyrehubSuggest from "../../../components/TyrehubSuggest";
import {Button} from "antd";

class Tyrehub extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        intl.options.currentLocale = 'en';
    }

    render() {
        let {reportData} = this.props.data;
        let {
            logo,
            uuid,
            car,
            plate_no,
            device_time,
            odometer,
            remark,
            purpose,
            store,
            model_number,
            model_name,
            conclusion,
            suggestion,
            wear_lf_degree,
            wear_rf_degree,
            wear_lb_degree,
            wear_rb_degree,
            ecc_wear_lf_degree,
            ecc_wear_rf_degree,
            ecc_wear_lb_degree,
            ecc_wear_rb_degree,
            wear_lf_data,
            wear_rf_data,
            wear_lb_data,
            wear_rb_data,
            tyre_lb,
            tyre_lf,
            tyre_rb,
            tyre_rf,
            inspector,
            language,
            recordtype
        } = reportData;
        tyre_lb = tyre_lb ? tyre_lb : ",,/R ,trauma_normal,,";
        tyre_lf = tyre_lf ? tyre_lf : ",,/R ,trauma_normal,,";
        tyre_rb = tyre_rb ? tyre_rb : ",,/R ,trauma_normal,,";
        tyre_rf = tyre_rf ? tyre_rf : ",,/R ,trauma_normal,,";

        const parserTyre = (tyre) => {
            let info_data = {dot: ' ', brand: ' ', spec: ' ', trauma: ' ', install: ' '};
            let infos = tyre.split(',');
            info_data.dot = infos[0];
            info_data.brand = infos[1];
            info_data.spec = infos[2];
            info_data.trauma = infos[3];
            info_data.install = infos[4];
            info_data.degree = infos[5];
            return info_data
        };

        let headData = {
            uuid: uuid,
            logo: logo,
            language:language,
            plate_no: plate_no,
            inspector: inspector,
            purpose: intl.get(purpose),
            remark: remark,
            car: car,
            odometer: odometer,
            store_name: store.name,
            address: store.address,
            device_time: device_time,
             recordtype: recordtype,
            model: model_name !== 'michelin.tyrescan.ts003' || 'michelin.tyrescan.ts004' ? intl.get('model_number') : model_name
        };
        let sugData = {
            suggestion: suggestion
        };
        let eccData = {
            lf_degree: ecc_wear_lf_degree,
            rf_degree: ecc_wear_rf_degree,
            lb_degree: ecc_wear_lb_degree,
            rb_degree: ecc_wear_rb_degree,
            ecc: 'ecc',
            conclusion: conclusion
        };
        let wearData = {
            lf_degree: wear_lf_degree,
            rf_degree: wear_rf_degree,
            lb_degree: wear_lb_degree,
            rb_degree: wear_rb_degree,
            model: model_number,
            lf_data: wear_lf_data,
            rf_data: wear_rf_data,
            lb_data: wear_lb_data,
            rb_data: wear_rb_data,
            lf_tyre: parserTyre(tyre_lf),
            rf_tyre: parserTyre(tyre_rf),
            lb_tyre: parserTyre(tyre_lb),
            rb_tyre: parserTyre(tyre_rb)
        };

        return (
            <div className='Ty-report'>
                   <Button
                        className="Ty-noprint"
                        onClick={() => {
                            window.print();
                        }}
                    >
                        {intl.get("print")}
                    </Button>
                <div className="Ty-card">
                    <div className="sec-1"><TyrehubHeader data={headData}/></div>
                    <div className="sec-2"><TyrehubSuggest data={sugData}/>
                    </div>
                    <div className="sec-3"><TyrehubWear data={wearData}/></div>
                    <div className="sec-4"><TyrehubEcc data={eccData}/></div>
                </div>
            </div>
        )
    }
}

export default Tyrehub;