import React, {Component} from "react";
import "../css/Lorry/Suggestion.scss"
import intl from "react-intl-universal";


class Suggestion extends Component{
    constructor(props) {
        super(props);
        this.state = {}
    };

    render() {
        let {tires} = this.props.data;

        const returnQuestion = () => {
               let tyre_arr = [];
               for (var x in tires){
                   let item = tires[x];
                   if (item.wear_degree === 'high'){
                       tyre_arr.push(item.number)
                   }
               }
               let suggestion = intl.get('txt_wear_normal');

               if (tyre_arr.length !== 0) {
                   suggestion = tyre_arr.join(',') +',' + intl.get('txt_tire_need_replace')
               }
               return suggestion
        };
        returnQuestion();
        return (
          <div className="suggestion">
             <div className="su-content">
                  <div className="lor-title"><span className="ic"></span><span>{intl.get('re')}</span></div>
                  <div style={{paddingLeft: 20, marginTop: 10}}>{returnQuestion()}</div>
             </div>
          </div>
        )
    }
}

export default Suggestion