import React, {Component} from "react";
import "./css/WearData.scss"

class WearData extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let {values, threshold} = this.props.data
        const returnColor = (va) => {
            if (parseInt(va) < threshold.low) {
                return {color: 'red'}
            }
            if (parseInt(va) > threshold.high) {
                return {color: 'green'}
            } else {
                return {color: '#ffca11'}
            }

        };
        return (
            <div className="we-da">
                <div className="we-da-co">
                    {
                        values.map((item, index) => (
                                <div className="we-num" key={index}>
                                <span style={returnColor(item)}>{item / 10}</span>
                            </div>
                        ))
                    }
                </div>
            </div>
        )
    }
}

export default WearData;