import React, {Component} from 'react';
import "./css/Car.scss"


class Car extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {

        let {lf, lb, rf, rb} = this.props.data;

        let returnColor = (content, degree) => {
            if (degree === 'high') {
                return require("../assets/tyre-high.png");
            } else {
                switch (content) {
                    case 'low':
                        return require("../assets/tyre-low.png");
                    case 'middle':
                        return require("../assets/tyre-middle.png");
                    case  'high':
                        return require("../assets/tyre-high.png");
                    default:
                        return require("../assets/tyre-low.png");
                }
            }

        };

        return (
            <div className="car">
                <img className="car-lf" src={returnColor(lf.degree, lf.damage)} alt=""/>
                <img className="car-lb" src={returnColor(lb.degree, lb.damage)} alt=""/>
                <img className="car-cen" src={require("../assets/car.png")} alt=""/>
                <img className="car-rf" src={returnColor(rf.degree, rf.damage)} alt=""/>
                <img className="car-rb" src={returnColor(rb.degree, rb.damage)} alt=""/>
            </div>
        )
    }
}

export default Car;