import React, {Component} from 'react';
import intl from "react-intl-universal";
import Car from "./Car";
import "./css/DillTraumatism.scss"

class DillTraumatism extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const trauma_url = require("../assets/normal.png");
        const wu_url = require("../assets/wu.png");

        let {images, tyre_lf, tyre_rf, tyre_lb, tyre_rb} = this.props.data;
        const returnColor = (content, degree) => {
                if (degree === 'damage_high') {
                    return {color: 'red', degree: 'high', damage: 'high'}
                }
                if (content) {
                    if (content === 'trauma_normal') {
                        return {color: 'green', degree: 'low', damage: 'low'}
                    } else {
                        return {color: '#ffca11', degree: 'middle', damage: 'middle'}
                    }
                } else {
                    return {color: 'green', degree: 'low', damage: 'low'}
                }
            }
        ;

        const returnImage = (img, trauma) => {
            if (trauma !== 'trauma_normal') {
                if (img) {
                    if (img.indexOf("img1")>=0 && img.indexOf("img2")>0 && img.indexOf("img3")>0){
                        let imgjson = JSON.parse(img)
                        return (
                            <div>
                                <img className="dill-tra-img-sub" src={imgjson.img1} alt=""/>
                                <img className="dill-tra-img-sub" src={imgjson.img2} alt=""/>
                                <img className="dill-tra-img-sub" src={imgjson.img3} alt=""/>
                            </div>
                        )
                    }else
                        return <img className="dill-tra-img" src={img} alt=""/>
                } else {
                    return <img className="dill-tra-img" src={wu_url} alt=""/>
                }
            }
            else {
                return <img className="dill-tra-img" src={trauma_url} alt=""/>
            }
        };

        const trauma_type = (type) => {
        // console.log(type);
        if(type){
            let _list = type.split(';');
        _list.forEach(function (value, index) {
            if (value === 'trauma_normal') {
                _list[index] = intl.get('trauma_normal')
            } else if (value === 'trauma_bulge') {
                _list[index] = intl.get('trauma_bulge')
            } else if (value === 'trauma_aging') {
                _list[index] = intl.get('trauma_aging')
            } else if (value === 'trauma_lacerate') {
                _list[index] = intl.get('trauma_lacerate')
            } else if (value === 'trauma_dropblock') {
                _list[index] = intl.get('trauma_dropblock')
            } else if (value === 'trauma_puncture') {
                _list[index] = intl.get('trauma_puncture')
            } else if (value === 'trauma_scratch') {
                _list[index] = intl.get('trauma_scratch')
            } else if (value === 'trauma_pinnate') {
                _list[index] = intl.get('trauma_pinnate')
            } else if (value === 'trauma_eattyre') {
                _list[index] = intl.get('trauma_eattyre')
            } else {
                return type.split('other')[1]
            }
        })
        let _str = _list.join(';')
        return _str
        }else {
            return  intl.get('trauma_normal')
        }
    };



        return (
            <div style={{marginTop:15}}>
                <div style={{marginLeft:15}}><span className="title">{intl.get('damage')}</span></div>
                <div>
                    <div className="dill-tra-con">
                        <div className="dill-tra-lf">
                            <p>{intl.get('lf')}:</p>
                            <div style={{marginBottom:15}}>{returnImage(images.trauma_lf_img, tyre_lf.trauma)}</div>
                        </div>
                        <div className="dill-tra-rf">
                            <p>{intl.get('rf')}:</p>
                            <div>{returnImage(images.trauma_rf_img, tyre_rf.trauma)}</div>
                        </div>
                        <div className="dill-tra-con-cen"><Car data={{
                            lf: returnColor(tyre_lf.trauma, tyre_lf.degree),
                            lb: returnColor(tyre_lb.trauma, tyre_lb.degree),
                            rf: returnColor(tyre_rf.trauma, tyre_rf.degree),
                            rb: returnColor(tyre_rb.trauma, tyre_rb.degree)
                        }}/></div>
                        <div className="dill-tra-lb">
                            <p>{intl.get('lr')}:</p>
                            <div>{returnImage(images.trauma_lb_img, tyre_lb.trauma)}</div>
                        </div>
                        <div className="dill-tra-rb">
                            <p>{intl.get('rr')}:</p>
                            <div style={{marginBottom:15}}>{returnImage(images.trauma_rb_img, tyre_rb.trauma)}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DillTraumatism;


