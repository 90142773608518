import React, { Component } from 'react';
import "./css/ControlQuality.scss"
class ControlQuality extends Component{
    constructor(props) {
        super(props);
        this.state ={}
    }
    render() {
        let {title, note1, content} = this.props.data
        return (
            <div className="conq">
              <div className="conq-left">
                  <p className="tle">{title}</p>
                  <p className="tcn">{note1}</p>
              </div>
              <div className="conq-right">
                  <p><span className="conq-sp">{content}</span></p>
              </div>
            </div>
        )
    }

}
export default ControlQuality;