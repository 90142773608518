import React, {Component} from "react";
import intl from 'react-intl-universal';
import "./css/QuickSuggest.scss";
import TyrehubTitle from "./TyrehubTitle";

class QuickSuggest extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let {suggestion, plateImg, device_time} = this.props.data;

        const typeSuggestion = (type) => {
            switch (type) {
                case 'txt_wear_overall':
                    return intl.get('wear');
                case 'txt_pattern_wear':
                    return intl.get('ecc');
                case 'txt_tire_age':
                    return intl.get('txt_tire_age');
                case 'txt_install_check':
                    return intl.get('txt_install_check');
                case 'txt_tire appearance':
                    return intl.get('txt_tire appearance');
                case 'txt_chassis_check':
                    return intl.get('txt_chassis_check');
                default:
                    return intl.get('txt_tire appearance');
            }
        };
        const returnSuggestion = (result) => {
            let _list = result.split(';');
            _list.forEach(function (value, index) {
                if (value === 'txt_wear_normal') {
                    _list[index] = intl.get('txt_wear_normal')
                } else if (value === 'txt_tire_rotation') {
                    _list[index] = intl.get('txt_tire_rotation')
                } else if (value === 'txt_uneven_check') {
                    _list[index] = intl.get('txt_uneven_check')
                } else if (value === 'txt_do_further_check') {
                    _list[index] = intl.get('txt_do_further_check')
                } else if (value === 'txt_wear_uniform') {
                    _list[index] = intl.get('txt_wear_uniform')
                } else if (value === 'txt_alignment_chassis') {
                    _list[index] = intl.get('txt_alignment_chassis')
                } else if (value === 'txt_tire_boundary') {
                    _list[index] = intl.get('txt_tire_boundary')
                } else if (value === 'txt_tire_need_replace') {
                    _list[index] = intl.get('txt_tire_need_replace')
                } else if (value === 'txt_tire_age_5') {
                    _list[index] = intl.get('txt_tire_age_5')
                } else if (value === 'txt_tire_age_3') {
                    _list[index] = intl.get('txt_tire_age_3')
                } else if (value === 'txt_tire_age_ok') {
                    _list[index] = intl.get('txt_tire_age_ok')
                } else if (value === 'txt_tire_trauma_check') {
                    _list[index] = intl.get('txt_tire_trauma_check')
                } else if (value === 'txt_install_abnormal_check') {
                    _list[index] = intl.get('txt_install_abnormal_check')
                } else if (value === 'txt_install_ok') {
                    _list[index] = intl.get('txt_install_ok')
                } else if (value === 'txt_tire_appearance_ok') {
                    _list[index] = intl.get('txt_tire_appearance_ok')
                } else if (value === 'txt_pinnate_check') {
                    _list[index] = intl.get('txt_pinnate_check')
                } else if (value === 'LFtxt_tire_need_replace') {
                    _list[index] = intl.get('LFtxt_tire_need_replace')
                } else if (value === 'RFtxt_tire_need_replace') {
                    _list[index] = intl.get('RFtxt_tire_need_replace')
                } else if (value === 'LBtxt_tire_need_replace') {
                    _list[index] = intl.get('LBtxt_tire_need_replace')
                } else if (value === 'RBtxt_tire_need_replace') {
                    _list[index] = intl.get('RBtxt_tire_need_replace')
                } else if (value === 'FFtxt_tire_need_replace') {
                    _list[index] = intl.get('FFtxt_tire_need_replace')
                } else if (value === 'BBtxt_tire_need_replace') {
                    _list[index] = intl.get('BBtxt_tire_need_replace')
                } else if (value === 'txt_uneven_center') {
                    _list[index] = intl.get('txt_uneven_center')
                } else if (value === 'LFtxt_uneven_center') {
                    _list[index] = intl.get('LFtxt_uneven_center')
                } else if (value === 'RFtxt_uneven_center') {
                    _list[index] = intl.get('RFtxt_uneven_center')
                } else if (value === 'LBtxt_uneven_center') {
                    _list[index] = intl.get('LBtxt_uneven_center')
                } else if (value === 'RBtxt_uneven_center') {
                    _list[index] = intl.get('RBtxt_uneven_center')
                } else if (value === 'FFtxt_uneven_center') {
                    _list[index] = intl.get('FFtxt_uneven_center')
                } else if (value === 'BBtxt_uneven_center') {
                    _list[index] = intl.get('BBtxt_uneven_center')
                } else if (value === 'txt_uneven_out') {
                    _list[index] = intl.get('txt_uneven_out')
                } else if (value === 'LFtxt_uneven_out') {
                    _list[index] = intl.get('LFtxt_uneven_out')
                } else if (value === 'RFtxt_uneven_out') {
                    _list[index] = intl.get('RFtxt_uneven_out')
                } else if (value === 'LBtxt_uneven_out') {
                    _list[index] = intl.get('LBtxt_uneven_out')
                } else if (value === 'RBtxt_uneven_out') {
                    _list[index] = intl.get('RBtxt_uneven_out')
                } else if (value === 'FFtxt_uneven_out') {
                    _list[index] = intl.get('BBtxt_uneven_out')
                } else if (value === 'txt_pinnate_check ') {
                    _list[index] = intl.get('txt_pinnate_check')
                } else if (value === 'LFtxt_pinnate_check') {
                    _list[index] = intl.get('LFtxt_pinnate_check')
                } else if (value === 'RFtxt_pinnate_check') {
                    _list[index] = intl.get('RFtxt_pinnate_check')
                } else if (value === 'LBtxt_pinnate_check') {
                    _list[index] = intl.get('LBtxt_pinnate_check')
                } else if (value === 'RBtxt_pinnate_check ') {
                    _list[index] = intl.get('RBtxt_pinnate_check')
                } else if (value === 'FFtxt_pinnate_check ') {
                    _list[index] = intl.get('FFtxt_pinnate_check')
                } else if (value === 'BBtxt_pinnate_check ') {
                    _list[index] = intl.get('BBtxt_pinnate_check')
                } else if (value === 'txt_uneven_inside') {
                    _list[index] = intl.get('txt_uneven_inside')
                } else if (value === 'LFtxt_uneven_inside') {
                    _list[index] = intl.get('LFtxt_uneven_inside')
                } else if (value === 'RFtxt_uneven_inside') {
                    _list[index] = intl.get('RFtxt_uneven_inside')
                } else if (value === 'LBtxt_uneven_inside') {
                    _list[index] = intl.get('LBtxt_uneven_inside')
                } else if (value === 'RBtxt_uneven_inside') {
                    _list[index] = intl.get('RBtxt_uneven_inside')
                } else if (value === 'FFtxt_uneven_inside') {
                    _list[index] = intl.get('FFtxt_uneven_inside')
                } else if (value === 'BBtxt_uneven_inside') {
                    _list[index] = intl.get('BBtxt_uneven_inside')
                } else if (value === 'FFtxt_uneven_check') {
                    _list[index] = intl.get('FFtxt_uneven_check')
                } else if (value === 'BBtxt_uneven_check') {
                    _list[index] = intl.get('BBtxt_uneven_check')
                } else if (value === 'LFtxt_tire_age_5') {
                    _list[index] = intl.get('LFtxt_tire_age_5')
                } else if (value === 'RFtxt_tire_age_5') {
                    _list[index] = intl.get('RFtxt_tire_age_5')
                } else if (value === 'LBtxt_tire_age_5') {
                    _list[index] = intl.get('LBtxt_tire_age_5')
                } else if (value === 'RBtxt_tire_age_5') {
                    _list[index] = intl.get('RBtxt_tire_age_5')
                } else if (value === 'FFtxt_tire_age_5') {
                    _list[index] = intl.get('FFtxt_tire_age_5')
                } else if (value === 'BBtxt_tire_age_5') {
                    _list[index] = intl.get('BBtxt_tire_age_5')
                } else if (value === 'txt_appearance_check') {
                    _list[index] = intl.get('txt_appearance_check')
                } else if (value === 'LFtxt_appearance_check') {
                    _list[index] = intl.get('LFtxt_appearance_check')
                } else if (value === 'RFtxt_appearance_check') {
                    _list[index] = intl.get('RFtxt_appearance_check')
                } else if (value === 'LBtxt_appearance_check') {
                    _list[index] = intl.get('LBtxt_appearance_check')
                } else if (value === 'RBtxt_appearance_check') {
                    _list[index] = intl.get('RBtxt_appearance_check')
                } else if (value === 'FFtxt_appearance_check') {
                    _list[index] = intl.get('FFtxt_appearance_check')
                } else if (value === 'BBtxt_appearance_check') {
                    _list[index] = intl.get('BBtxt_appearance_check')
                } else if (value === 'LFtxt_install_abnormal_check') {
                    _list[index] = intl.get('LFtxt_install_abnormal_check')
                } else if (value === 'RFtxt_install_abnormal_check') {
                    _list[index] = intl.get('RFtxt_install_abnormal_check')
                } else if (value === 'LBtxt_install_abnormal_check') {
                    _list[index] = intl.get('LBtxt_install_abnormal_check')
                } else if (value === 'RBtxt_install_abnormal_check') {
                    _list[index] = intl.get('RBtxt_install_abnormal_check')
                } else if (value === 'FFtxt_install_abnormal_check') {
                    _list[index] = intl.get('FFtxt_install_abnormal_check')
                } else if (value === 'BBtxt_install_abnormal_check') {
                    _list[index] = intl.get('BBtxt_install_abnormal_check')
                } else if (value === 'txt_uneven_bothside') {
                    _list[index] = intl.get('txt_uneven_bothside')
                } else if (value === 'LFtxt_uneven_bothside') {
                    _list[index] = intl.get('LFtxt_uneven_bothside')
                } else if (value === 'RFtxt_uneven_bothside') {
                    _list[index] = intl.get('RFtxt_uneven_bothside')
                } else if (value === 'RBtxt_uneven_bothside') {
                    _list[index] = intl.get('RBtxt_uneven_bothside')
                } else if (value === 'LBtxt_uneven_bothside') {
                    _list[index] = intl.get('LBtxt_uneven_bothside')
                } else if (value === 'FFtxt_uneven_bothside') {
                    _list[index] = intl.get('FFtxt_uneven_bothside')
                } else if (value === 'BBtxt_uneven_bothside') {
                    _list[index] = intl.get('BBtxt_uneven_bothside')
                } else if (value === 'txt_wear_lookup') {
                    _list[index] = intl.get('txt_wear_lookup')
                } else if (value === 'LFtxt_wear_lookup') {
                    _list[index] = intl.get('LFtxt_wear_lookup')
                } else if (value === 'RFtxt_wear_lookup') {
                    _list[index] = intl.get('RFtxt_wear_lookup')
                } else if (value === 'LBtxt_wear_lookup') {
                    _list[index] = intl.get('LBtxt_wear_lookup')
                } else if (value === 'RBtxt_wear_lookup') {
                    _list[index] = intl.get('RBtxt_wear_lookup')
                } else if (value === 'FFtxt_wear_lookup') {
                    _list[index] = intl.get('FFtxt_wear_lookup')
                } else if (value === 'BBtxt_wear_lookup') {
                    _list[index] = intl.get('BBtxt_wear_lookup')
                } else if (value === 'txt_appearance_lookup') {
                    _list[index] = intl.get('txt_appearance_lookup')
                } else if (value === 'LFtxt_appearance_lookup') {
                    _list[index] = intl.get('LFtxt_appearance_lookup')
                } else if (value === 'RFtxt_appearance_lookup') {
                    _list[index] = intl.get('RFtxt_appearance_lookup')
                } else if (value === 'LBtxt_appearance_lookup') {
                    _list[index] = intl.get('LBtxt_appearance_lookup')
                } else if (value === 'RBtxt_appearance_lookup') {
                    _list[index] = intl.get('RBtxt_appearance_lookup')
                } else if (value === 'FFtxt_appearance_lookup') {
                    _list[index] = intl.get('FFtxt_appearance_lookup')
                } else if (value === 'BBtxt_appearance_lookup') {
                    _list[index] = intl.get('BBtxt_appearance_lookup')
                } else if (value === 'txt_serious_trauma_check') {
                    _list[index] = intl.get('txt_serious_trauma_check')
                } else if (value === 'LFtxt_serious_trauma_check') {
                    _list[index] = intl.get('LFtxt_serious_trauma_check')
                } else if (value === 'RFtxt_serious_trauma_check') {
                    _list[index] = intl.get('RFtxt_serious_trauma_check')
                } else if (value === 'LBtxt_serious_trauma_check') {
                    _list[index] = intl.get('LBtxt_serious_trauma_check')
                } else if (value === 'RBtxt_serious_trauma_check') {
                    _list[index] = intl.get('RBtxt_serious_trauma_check')
                } else if (value === 'FFtxt_serious_trauma_check') {
                    _list[index] = intl.get('FFtxt_serious_trauma_check')
                } else if (value === 'BBtxt_serious_trauma_check') {
                    _list[index] = intl.get('BBtxt_serious_trauma_check')
                } else if (value === 'txt_chassis_ok') {
                    _list[index] = intl.get('txt_chassis_ok')
                } else if (value === 'txt_chassis_oil') {
                    _list[index] = intl.get('txt_chassis_oil')
                } else if (value === 'txt_chassis_aging') {
                    _list[index] = intl.get('txt_chassis_aging')
                }

            });
            let _str = _list.join(';');
            return _str
        };

        const RenderHot = props => {
            let str = props.text;
            let replace_arr = ['严重磨损', '异常', '左前轮', '左后轮', '右前轮', '右后轮', '前轴', '后轴', '中间', '外侧', '羽状', '装反', '内侧', '5年', '严重外伤', '请检查轮胎磨损情况', '请检查轮胎外观情况'];
            for (var i = 0, len = replace_arr.length; i < len; i++) {
                str = str.replace(new RegExp(replace_arr[i], "g"), '<*>' + replace_arr[i] + '<*>')
            }
            let nameArray = str.split('<*>');
            let ar = [];
            let styles = {
                hot: {color: 'red'},
                de: {color: 'black'},
            };
            for (let i = 0; i < nameArray.length; i++) {
                ar.push(<span key={i}
                              style={replace_arr.indexOf(nameArray[i]) !== -1 ? styles.hot : styles.de}>{nameArray[i]}</span>);
            }
            return (
                <span>
                {ar.map((item) => {
                    return (item);
                })}
                </span>
            );
        };
        return (
           <div className="quick-sug">
               <div>
                   <img src={plateImg ? plateImg : require("../assets/1634537575.jpg")}  alt="" style={{ width:180, height:135, objectFit: "contain"}} />
                   <div style={{fontWeight:600}}><span>{intl.get('device_time')}：</span><span>{device_time.replace('T',' ')}</span></div>
               </div>
                <div className="tyq-sug">
                <div style={{flex: 1}}>
                    <div><TyrehubTitle content={intl.get('re')}/></div>
                    <ul>
                        {suggestion.map((item, index) => (
                            item.title==='txt_wear_overall' || item.title==='txt_pattern_wear' ?
                                 <li key={index} style={{fontSize: 15}}><p>
                                <span>{typeSuggestion(item.title)}：</span><RenderHot
                                text={returnSuggestion(item.describe)}/>
                            </p></li> : ''
                        ))}
                    </ul>
                </div>

            </div>
           </div>
        )
    }

}

export default QuickSuggest;