import React, {Component} from "react";
import "../css/Lorry/SingleTire.scss"
import _ from "lodash";
import intl from "react-intl-universal";
class SingleTire extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    };


    render() {
        let TireStyle = {
            lf: {position: 'absolute', top: 30, left: 255},
            ax: {position: 'absolute', top: 80, left: 261, width: 220},
            lb: {position: 'absolute', top: 30, right: 283}
        };

        let {item, unit} = this.props.data;
        let {lf, lb} = item;
        let returnColor = (degree) => {
            switch (degree) {
                case 'high':
                    return {url: require("../../assets/lorry/r-l.png"), color: "#FF5230", text: intl.get('high')};
                case 'low' || 'middle':
                    return {url: require("../../assets/lorry/y-l.png"), color: "#36B37E", text: intl.get('low')};
                default:
                    return {url: require("../../assets/lorry/hui-l.png"), color: "#c2c2c2", text: intl.get('undevice')};
            }

        };

        let lang = intl.options.currentLocale;

        // 返回轮胎平均值
        const returnTyrePoint = data => {
            let num_arr = []
            let str_arr = data.split(',')
            for (let i = 0; i <str_arr.length ; i++) {
                if (i % 4 == 3) {
                    num_arr.push(parseInt(str_arr[i]))
                }

            }
            if (num_arr.length > 2){
                let _max = Math.max.apply(null, num_arr)
                let _min = Math.min.apply(null, num_arr)
                num_arr.splice(num_arr.indexOf(_max), 1)
                num_arr.splice(num_arr.indexOf(_min), 1)
            }

            let sum = 0 ;
            num_arr.forEach((j) =>{ sum += j});
            let avg = ((sum / num_arr.length) / 10).toFixed(1);
            avg = unit == 'unit_in' ? (avg *  1.25984).toFixed(1)  + ' 1/32in' : avg + ' mm'
            return avg

        };


        return (
            <div>
                <div className="st">
                    <div className="st-up">
                        <span style={{position: 'absolute', top: 5, left: 245}}>{intl.get('left')}</span>
                        <span style={{position: 'absolute', top: 5, right: 275}}>{intl.get('right')}</span>
                        <span style={{
                            position: 'absolute',
                            top: 60,
                            left: 360
                        }}>{lf.position.slice(0, 1) === 'B' ? intl.get('spare') : lf.position.slice(1, 2) + intl.get('axle')}</span>
                        <img src={require("../../assets/lorry/axle-2c.png")} style={TireStyle.ax} alt=""/>
                        <img src={returnColor(lf.degree).url} style={TireStyle.lf} alt=""/>
                        <img src={returnColor(lb.degree).url} style={TireStyle.lb} alt=""/>
                        <span style={{position: 'absolute', top: 75, left: 275}}>{lang != 'zh'?lf.en_num:lf.number}</span>
                        <span style={{position: 'absolute', top: 75, right: 305}}>{lang != 'zh'?lb.en_num:lb.number}</span>
                    </div>
                    <div className="st-down">
                        <div className="st-do-le">
                            <p><span className="num"
                                     style={{backgroundColor: returnColor(lf.degree).color}}>{lf.number}</span></p>
                            <p><span style={{color: returnColor(lf.degree).color}}>{returnColor(lf.degree).text}</span>
                            </p>
                            <p><span>{intl.get('avg')}：</span><span
                                style={{color: returnColor(lf.degree).color}}>{lf.degree ? returnTyrePoint(lf.avg) : intl.get('nodata')}</span>
                            </p>
                        </div>
                        <div>
                            <hr style={{height: 60, border: "0.1px dashed #eee"}}/>
                        </div>
                        <div className="st-do-ri">
                            <p><span className="num"
                                     style={{backgroundColor: returnColor(lb.degree).color}}>{lb.number}</span></p>
                            <p><span style={{color: returnColor(lb.degree).color}}>{returnColor(lb.degree).text}</span>
                            </p>
                            <p><span
                                style={{color: returnColor(lb.degree).color}}>{lb.degree ? returnTyrePoint(lb.avg) : intl.get('nodata')}</span><span>：{intl.get('avg')}</span>
                            </p>
                        </div>
                    </div>
                </div>
                {lf.position.slice(0, 1) === 'B' ? '' : <hr/>}
            </div>
        )
    }


}

export default SingleTire