import React, { Component } from "react";
import Cookies from 'universal-cookie';
import intl from 'react-intl-universal';

export class Redirect extends Component {
  constructor( props ){
    super();
    this.state = { 
        ...props,
        domain : global.Api.domain_url(),
        lang : intl.options.currentLocale,
     };
  }
  componentWillMount(){
    if (this.state.lang === 'ge'){
        this.setState({
            lang:'de'
        })
      }else if (this.state.lang === 'sp'){
          this.setState({
              lang:'es'
          })
      }else if (this.state.lang === 'ma'){
        this.setState({
            lang:'ms'
        })
      }else if (this.state.lang === 'lt'){
        this.setState({
            lang:'it'
        })
      }else if (this.state.lang === 'tw'){
        this.setState({
            lang:'zh_TW'
        })
      }else if (this.state.lang === 'jp'){
        this.setState({
            lang:'ja'
        })
      }else if (this.state.lang === 'po'){
        this.setState({
            lang:'pl'
        })
      }else if (this.state.lang === 'por'){
        this.setState({
            lang:'pt'
        })
      }else if (this.state.lang === 'dk'){
        this.setState({
            lang:'da'
        })
      }
     this.openReport(this.props.match.params.uuid);
  }

  openReport(uuid){
    if (this.state.domain ==='iptirescanner.com'){
      window.location.href = `http://customer.${this.state.domain}/report/${uuid}/?lang=${this.state.lang}`;
    //   window.open(`http://customer.${this.state.domain}/report/${uuid}/?lang=${this.state.lang}`);
    }
    else
      window.location.href = `https://customer.${this.state.domain}/report/${uuid}/?lang=${this.state.lang}`;
    //   window.open(`https://customer.${this.state.domain}/report/${uuid}/?lang=${this.state.lang}`);
  }
  render(){
    return (<section>loading...</section>);
  }
}
 
export default Redirect;