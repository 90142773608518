import React, {Component} from 'react';
import "./Dill.scss"
import DillHeader from "../../components/DillHeader";
import intl from "react-intl-universal";
import Dillwear from "../../components/Dillwear";
import {Button} from "antd";
import DillTraumatism from "../../components/DillTraumatism";


class Dill extends Component {
    constructor(props) {
        super(props);
        this.state = {report: null};
    }

    componentDidMount() {
        this.getRecordDetail();
    }

    // ��ȡ��ⱨ������
    getRecordDetail() {
        global.Api.getReportsEmail(this.props.uuid).then(res => {

            if (res.data.code === 0) {
                this.setState({
                    report: res.data.data.report
                });
            } else {
                console.log(res.data.message);
            }
        });
    }

    render() {
        if (this.state.report) {
            let {
                logo,
                plate_no,
                device_time,
                car,
                uuid,
                purpose,
                model_number,
                wear_threshold,
                wear_lf_degree,
                wear_rf_degree,
                wear_lb_degree,
                wear_rb_degree,
                wear_lf_data,
                wear_rf_data,
                wear_lb_data,
                wear_rb_data,
                tyre_lb,
                tyre_lf,
                tyre_rb,
                tyre_rf,
                trauma_lf_img,
                trauma_rf_img,
                trauma_lb_img,
                trauma_rb_img,
                season,
                language,
                tyreunit,
                manufacturer,
                porduced_year
            } = this.state.report;
            tyre_lb = tyre_lb ? tyre_lb : ",,/R ,trauma_normal,,";
            tyre_lf = tyre_lf ? tyre_lf : ",,/R ,trauma_normal,,";
            tyre_rb = tyre_rb ? tyre_rb : ",,/R ,trauma_normal,,";
            tyre_rf = tyre_rf ? tyre_rf : ",,/R ,trauma_normal,,";
            season = season ? season : {LF: '', RF: '', LB: '', RB: ''};

            let images = {
                trauma_lf_img: trauma_lf_img,
                trauma_rf_img: trauma_rf_img,
                trauma_lb_img: trauma_lb_img,
                trauma_rb_img: trauma_rb_img
            };
            const parserTyre = (tyre) => {
                let info_data = {dot: ' ', brand: ' ', spec: ' ', trauma: ' ', install: ' ', degree: ' '};
                let infos = tyre.split(',');
                info_data.dot = infos[0];
                info_data.brand = infos[1];
                info_data.spec = infos[2];
                info_data.trauma = infos[3];
                info_data.install = infos[4];
                info_data.degree = infos[5];
                return info_data
            };

            let headerData = {
                logo: logo,
                uuid:uuid,
                language: language,
                device_time: device_time,
                plate: plate_no,
                purpose: purpose,
                manufacturer: manufacturer,
                car: car,
                porduced_year: porduced_year,
            };
            let wearData = {
                lf_degree: wear_lf_degree,
                rf_degree: wear_rf_degree,
                lb_degree: wear_lb_degree,
                rb_degree: wear_rb_degree,
                model: model_number,
                lf_data: wear_lf_data,
                rf_data: wear_rf_data,
                lb_data: wear_lb_data,
                rb_data: wear_rb_data,
                lf_tyre: parserTyre(tyre_lf),
                rf_tyre: parserTyre(tyre_rf),
                lb_tyre: parserTyre(tyre_lb),
                rb_tyre: parserTyre(tyre_rb),
                wear_threshold:wear_threshold,
                tyreunit:tyreunit,
            };

            let traumatismData = {
                tyre_lb: parserTyre(tyre_lb),
                tyre_lf: parserTyre(tyre_lf),
                tyre_rb: parserTyre(tyre_rb),
                tyre_rf: parserTyre(tyre_rf),
                images: images
            };


            return (
                <div className="dill-report">
                    <Button
                        className="dill-noprint"
                        onClick={() => {
                            window.print();
                        }}
                    >
                        {intl.get("print")}
                    </Button>
                    <div className="dill-card">
                        <div style={{marginBottom: 15}}>
                            <DillHeader data={headerData}/>
                        </div>
                        <div><Dillwear data={wearData}/></div>
                        <div>
                            <DillTraumatism data={traumatismData}/>
                        </div>

                    </div>
                </div>
            )
        } else return (<div></div>)
    }
}

export default Dill;