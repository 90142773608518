import React, {Component} from "react";
import intl from 'react-intl-universal';
import "./css/TyrehubWear.scss"
import TyrehubTitle from "./TyrehubTitle";
import TyrehubCar from "./TyrehubCar";

class TyrehubWear extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {lf_degree, rf_degree, lb_degree, rb_degree, model, lf_data, rf_data, lb_data, rb_data, lf_tyre, rf_tyre, lb_tyre, rb_tyre} = this.props.data;


        let style = {
            fontSize: 3,
            display: model === 'nam.tyrescan.ts004' ? 'block' : 'None',
            width: 95,
            textAlign: 'center',
            fontWeight: 600
        };
        const returnList = (data) => {
            let wear_list = [];
            if (data) {
            for (let i = 0; i < data.split(',').length/4; i++) {
                let value = data.split(',')[4*i+3] / 10;
                if (model === 'nam.tyrescan.ts004'){
                     wear_list.push((value * 1.25984).toFixed(1))
                }else {
                    wear_list.push(value)
                }
            }
             return wear_list}
            };

        const returnColorFlag = () => {
            const lang = intl.options.currentLocale;
            switch (lang) {
                case 'tw':
                    return require("../assets/tw_wco.png");
                case 'zh':
                    return require("../assets/zh_wco.png");
                case 'en':
                    return require("../assets/en_wco.png");
                case 'ma':
                    return require("../assets/me_wco.png");
                case 'ge':
                    return require("../assets/de_wco.png");
                case 'fr':
                    return require("../assets/frtip.png");
                case 'sp':
                    return require("../assets/sptip.png");
                case 'lt':
                    return require("../assets/lt_wco.png");
                case 'jp':
                    return require("../assets/jp_wco.png");
            }
        };

        const returnWear = (degree) => {
            if (degree === 'low') {
                return {color: 'green', text: intl.get('low')}
            }
            if (degree === 'middle') {
                return {color: '#ffad26', text: intl.get('middle')}
            }
            if (degree === 'high') {
                return {color: 'red', text: intl.get('high')}
            } else {
                return {color: 'green', text: intl.get('low')}
            }

        };

        const returnTire = (degree, data) => {
            if (data) {
                let le = data.split(',').length/4;
                let col= 'green';
                if (degree === 'middle'){
                    col = 'yellow'
                }
                 if (degree === 'high'){
                    col = 'red'
                }
                return require("../assets/tyre-" + col + "-" + (5-le) + ".png");
            } else {
                return require("../assets/tyre-green-1.png");
            }
        };

        return (
            <div className="ty-wear">
                <div><TyrehubTitle content={intl.get('wear')}/></div>
                <div className="ty-color"><img
                    src={model === "nam.tyrescan.ts004" ? require("../assets/namtip.png") : returnColorFlag()} alt=''/>
                </div>
                <div className="ty-wear-con">
                    <div className="ty-wear-lf">
                        <div className="con-le">
                            <div><span className="con-le-tit" style={{color: returnWear(lf_degree).color}}>{intl.get('lf')}:</span><span
                                className="con-le-con"></span></div>
                            <div><span className="con-le-tit">{intl.get('brand')}:</span><span
                                className='con-le-con'>{lf_tyre.brand}</span></div>
                            <div><span className="con-le-tit">{intl.get('spec')}:</span><span
                                className='con-le-con'>{lf_tyre.spec}</span></div>
                            <div><span className="con-le-tit">{intl.get('abrasion')}:</span><span
                                className='con-le-con'
                                style={{color: returnWear(lf_degree).color}}>{returnWear(lf_degree).text}</span></div>
                            <div><span className="con-le-tit">D O T:</span><span
                                className='con-le-con'>{lf_tyre.dot}</span></div>
                        </div>
                        <div className="con-ri">
                            <div><img className="con-tire" src={returnTire(lf_degree, lf_data)} alt=""/></div>
                            <div className="con-num">{returnList(lf_data).map((item, index) => (
                                <span key={index}>{item}</span>
                            ))}</div>
                            <div style={style}>Tread Depth in 32 nds</div>
                        </div>
                    </div>
                    <div className="ty-wear-rf">
                        <div className="con-ri">
                            <div><img className="con-tire" src={returnTire(rf_degree, rf_data)} alt=""/></div>
                            <div className="con-num">{returnList(rf_data).map((item, index) => (
                                <span key={index}>{item}</span>
                            ))}</div>
                            <div style={style}>Tread Depth in 32 nds</div>
                        </div>
                        <div className="con-le">
                            <div><span className="con-le-tit" style={{color: returnWear(rf_degree).color}}>{intl.get('rf')}:</span><span
                                className="con-le-con"></span></div>
                            <div><span className="con-le-tit">{intl.get('brand')}:</span><span
                                className='con-le-con'>{rf_tyre.brand}</span></div>
                            <div><span className="con-le-tit">{intl.get('spec')}:</span><span
                                className='con-le-con'>{rf_tyre.spec}</span></div>
                            <div><span className="con-le-tit">{intl.get('abrasion')}:</span><span
                                className='con-le-con'
                                style={{color: returnWear(rf_degree).color}}>{returnWear(rf_degree).text}</span></div>
                            <div><span className="con-le-tit">D O T:</span><span
                                className='con-le-con'>{rf_tyre.dot}</span></div>
                        </div>

                    </div>
                    <div className="ty-wear-cen">
                        <TyrehubCar data={this.props.data}/>
                    </div>
                    <div className="ty-wear-lb">
                        <div className="con-le">
                            <div><span className="con-le-tit" style={{color: returnWear(lb_degree).color}}>{intl.get('lr')}:</span><span
                                className="con-le-con"></span></div>
                            <div><span className="con-le-tit">{intl.get('brand')}:</span><span
                                className='con-le-con'>{lb_tyre.brand}</span></div>
                            <div><span className="con-le-tit">{intl.get('spec')}:</span><span
                                className='con-le-con'>{lb_tyre.spec}</span></div>
                            <div><span className="con-le-tit">{intl.get('abrasion')}:</span><span
                                className='con-le-con'
                                style={{color: returnWear(lb_degree).color}}>{returnWear(lb_degree).text}</span></div>
                            <div><span className="con-le-tit">D O T:</span><span
                                className='con-le-con'>{lb_tyre.dot}</span></div>
                        </div>
                        <div className="con-ri">
                            <div><img className="con-tire" src={returnTire(lb_degree, lb_data)} alt=""/></div>
                            <div className="con-num">{returnList(lb_data).map((item, index) => (
                                <span key={index}>{item}</span>
                            ))}</div>
                            <div style={style}>Tread Depth in 32 nds</div>
                        </div>
                    </div>
                    <div className="ty-wear-rb">
                        <div className="con-ri">
                            <div><img className="con-tire" src={returnTire(rb_degree, rb_data)} alt=""/></div>
                            <div className="con-num">{returnList(rb_data).map((item, index) => (
                                <span key={index}>{item}</span>
                            ))}</div>
                            <div style={style}>Tread Depth in 32 nds</div>
                        </div>
                        <div className="con-le">
                            <div><span className="con-le-tit"  style={{color: returnWear(rb_degree).color}}>{intl.get('rr')}:</span><span
                                className="con-le-con"></span></div>
                            <div><span className="con-le-tit">{intl.get('brand')}:</span><span
                                className='con-le-con'>{rb_tyre.brand}</span></div>
                            <div><span className="con-le-tit">{intl.get('spec')}:</span><span
                                className='con-le-con'>{rb_tyre.spec}</span></div>
                            <div><span className="con-le-tit">{intl.get('abrasion')}:</span><span
                                className='con-le-con'
                                style={{color: returnWear(rb_degree).color}}>{returnWear(rb_degree).text}</span></div>
                            <div><span className="con-le-tit">D O T:</span><span
                                className='con-le-con'>{rb_tyre.dot}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default TyrehubWear;