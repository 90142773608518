import React, {Component} from 'react';
import "./css/CarInspection.scss";
import intl from "react-intl-universal";
import CheckItem from "./CheckItem";
import Annotation from "./Annotation";
import ControlQuality from "./ControlQuality";
import Conditioner from "./Conditioner";

class CarInspection extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {

        let {battery, brake_disc, brake_fluid, brake_pads, conditioning_system, engine_oil, lighting, quality_control, radiator_fluid, shock_absorber, washing_liquid, wiper} =  this.props.inspections
        // let inspections = this.state.inspections
        return (
            <div className='inspection'>
                <div className="in-up">
                    <div className="in-up-co">
                        <div className="up-model"><span className="up-yellow"></span><span className="up-con">{intl.get('engine-hood')}</span>
                        </div>
                        <div className="up-model"><span className="up-yellow"></span><span className="up-con">{intl.get('change-control')}</span>
                        </div>
                        <div className="up-model"><span className="up-yellow"></span><span className="up-con">{intl.get('subtotal')}</span>
                        </div>
                    </div>
                </div>
                <div className="in-down">
                    <div className="down-one">
                        <div className="down-one-item">
                            <div className="one-item-co">
                                <CheckItem data={{"title": intl.get('liquid'), "note1": intl.get('level-control'), "degree": washing_liquid}}/>
                                <Conditioner data={{"title": intl.get('air'), "note1": intl.get('last-reload'), "content": conditioning_system}}/>
                                <CheckItem data={{"title": intl.get('absorber'), "note1": intl.get('check-loss'), "degree": shock_absorber}}/>
                            </div>
                            <hr/>
                        </div>
                        <div className="down-one-item">
                            <div className="one-item-co">
                                <CheckItem data={{"title": intl.get('fluid'), "note1": intl.get('level-control'), "degree": radiator_fluid}}/>
                                <CheckItem data={{"title": intl.get('lighting'), "note1": intl.get('verification'), "degree": lighting}}/>
                                <CheckItem data={{"title": intl.get('disc'), "note1": intl.get('condition'), "degree": brake_disc}}/>
                            </div>
                            <hr/>
                        </div>
                        <div className="down-one-item">
                            <div className="one-item-co">
                                <CheckItem data={{"title": intl.get('oil'), "note1": intl.get('level-control'), "degree": engine_oil}}/>
                                <CheckItem data={{"title": intl.get('wiper'), "note1": intl.get('condition'), "degree": wiper}}/>
                                <CheckItem data={{"title": intl.get('pads'), "note1": intl.get('condition'), "degree": brake_pads}}/>
                            </div>
                            <hr/>
                        </div>
                    </div>
                    <div className="down-two">
                        <div className="part-one">
                            <CheckItem data={{"title": intl.get('brake-fluid'), "note1": intl.get('quality-control'), "degree": brake_fluid}}/>
                            <hr/>
                            <CheckItem data={{"title": intl.get('battery'), "note1": intl.get('multimetro'), "degree": battery}}/>
                            <hr/>
                        </div>
                        <div className="part-two">
                            <ControlQuality data={{"title": intl.get('control-quality'), "note1": intl.get('tightening'), "content": quality_control}}/>
                        </div>
                        <div className="part-three">
                            <Annotation/>
                        </div>

                    </div>

                </div>
            </div>
        )
    }

}

export default CarInspection;