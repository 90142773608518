import React, {Component} from 'react';
import "./css/Dillwear.scss"
import intl from "react-intl-universal";
import TyrehubCar from "./TyrehubCar";



class Dillwear extends Component{
        constructor(props) {
        super(props);
        this.state = {};
    };
        render() {
         let {lf_degree, rf_degree, lb_degree, rb_degree, model, lf_data, rf_data, lb_data, rb_data, lf_tyre, rf_tyre, lb_tyre, rb_tyre, wear_threshold, tyreunit} = this.props.data;

         // 返回轮胎沟槽数据
         const returnList = (data) => {
            let wear_list = [];
            if (data) {
            for (let i = 0; i < data.split(',').length/4; i++) {
                let value = data.split(',')[4*i+3] / 10;
                if (tyreunit === 'unit_in'){
                     wear_list.push((value * 1.25984).toFixed(1))
                }else {
                    wear_list.push(value)
                }
            }
             return wear_list}
            };

          // 返回描叙
             const returnWear = (degree) => {
            if (degree === 'low') {
                return {color: 'green', text: intl.get('low')}
            }
            if (degree === 'middle') {
                return {color: '#ffad26', text: intl.get('middle')}
            }
            if (degree === 'high') {
                return {color: 'red', text: intl.get('high')}
            } else {
                return {color: 'green', text: intl.get('low')}
            }

        };
         //返回轮胎图片
         const returnTire = (degree, data) => {
            if (data) {
                let le = data.split(',').length/4;
                let col= 'green';
                if (degree === 'middle'){
                    col = 'yellow'
                }
                 if (degree === 'high'){
                    col = 'red'
                }
                return require("../assets/tyre-" + col + "-" + (5-le) + ".png");
            } else {
                return require("../assets/tyre-green-1.png");
            }
        };



         // 返回外伤
         const returnTtaumationType = (data) =>{
             if (data){
                 if (data.search("trauma_other") != -1){
                     data = data.replace(/trauma_other/, '')
                 }
                 let tra_list = data.split(';')
                 if (tra_list.length !== 0){
                     console.log(tra_list);
                     return tra_list;
                 }
             }
             return [];
         };

          // 返回阈值
         const returnThreshold = (value, unit) =>{
                if (unit==='unit_in'){
                    return  ((value / 10) * 1.25984).toFixed(1)  + '/32in'
                }else {
                    return value / 10 + 'mm'
                }
            };

            return (
                <div style={{marginTop:15}}>
                    <div style={{marginLeft:15}}><span className="title">{intl.get('dill-re')}</span></div>
                    <div style={{position: 'relative'}}>
                      <img style={{width: 750, marginTop: 5, height: 40,}} src={require("../assets/wear-lev.png")}/>
                                           <div  style={{position: 'absolute', top:5, left:50, right:50,display: "flex", justifyContent: "space-between"}}>
                                        <div style={{fontSize:14, color:'white', textAlign: 'center'}}>
                                            <p>{intl.get('high')}</p>
                                            <p>＜{returnThreshold(wear_threshold.low, tyreunit)}</p>
                                        </div>
                                        <div style={{fontSize:14, color:'white', textAlign: 'center'}}>
                                            <p>{intl.get('middle')}</p>
                                            <p>{returnThreshold(wear_threshold.low, tyreunit)}-{returnThreshold(wear_threshold.high, tyreunit)}</p>
                                        </div>
                                        <div style={{fontSize:14, color:'white', textAlign: 'center'}}>
                                            <p>{intl.get('low')}</p>
                                            <p>≥{returnThreshold(wear_threshold.high, tyreunit)}</p>
                                        </div>
                                    </div>
                    </div>
                    <div className="dill-wear-con">
                        <div className="dill-wear-lf">
                            <div><span>{intl.get('lf')}</span></div>
                            <div><span>{intl.get('abrasion')}：</span><span style={{color: returnWear(lf_degree).color}}> {returnWear(lf_degree).text}</span></div>
                            <div>
                                <img className="con-tire" src={returnTire(lf_degree, lf_data)} alt=""/>
                                <div className="con-num">{returnList(lf_data).map((item, index) => (
                                <span key={index}>{item}</span>
                            ))}</div>
                                </div>
                            <div>
                                 {returnTtaumationType(lf_tyre.trauma)&&returnTtaumationType(lf_tyre.trauma)[0]!=='trauma_normal'? returnTtaumationType(lf_tyre.trauma).map((item, index)=>(
                                    <div  key={index}><input  type="checkbox"   checked="checked" readOnly={true}/> <span style={{color:'#0075ff'}}>{item}</span></div>
                                 )) : <div  style={{color:'green'}}>{intl.get('trauma_normal')}</div>}
                            </div>
                        </div>
                        <div className="dill-wear-rf">
                            <div><span>{intl.get('rf')}</span></div>
                            <div><span>{intl.get('abrasion')}：</span><span style={{color: returnWear(rf_degree).color}}>{returnWear(rf_degree).text}</span></div>
                            <div>
                                <img className="con-tire" src={returnTire(rf_degree, rf_data)} alt=""/>
                                <div className="con-num">{returnList(rf_data).map((item, index) => (
                                <span key={index}>{item}</span>
                            ))}</div>
                            </div>
                            <div>
                                 {returnTtaumationType(lf_tyre.trauma)&&returnTtaumationType(rf_tyre.trauma)[0]!=='trauma_normal'? returnTtaumationType(rf_tyre.trauma).map((item, index)=>(
                                    <div  key={index}><input  type="checkbox"   checked="checked" readOnly={true}/> <span style={{color:'#0075ff'}}>{item}</span></div>
                                 )) : <div  style={{color:'green'}}>{intl.get('trauma_normal')}</div>}
                            </div>

                        </div>
                        <div className="dill-wear-cen">
                            <TyrehubCar data={this.props.data}/>
                        </div>
                        <div className="dill-wear-lb">
                            <div><span>{intl.get('lr')}</span></div>
                            <div><span>{intl.get('abrasion')}：</span><span style={{color: returnWear(lb_degree).color}}>{returnWear(lb_degree).text}</span></div>
                            <div>
                               <img className="con-tire" src={returnTire(lb_degree, lb_data)} alt=""/>
                               <div className="con-num">{returnList(lb_data).map((item, index) => (
                                <span key={index}>{item}</span>
                            ))}</div>
                            </div>
                            <div>
                                 {returnTtaumationType(lf_tyre.trauma)&&returnTtaumationType(lb_tyre.trauma)[0]!=='trauma_normal'? returnTtaumationType(lb_tyre.trauma).map((item, index)=>(
                                    <div  key={index}><input  type="checkbox"   checked="checked" readOnly={true}/> <span style={{color:'#0075ff'}}>{item}</span></div>
                                 )) : <div  style={{color:'green'}}>{intl.get('trauma_normal')}</div>}
                            </div>
                        </div>
                        <div className="dill-wear-rb">
                            <div><span>{intl.get('rr')}</span></div>
                            <div><span>{intl.get('abrasion')}：</span><span style={{color: returnWear(rb_degree).color}}>{returnWear(rb_degree).text}</span></div>
                            <div>
                                <img className="con-tire" src={returnTire(rb_degree, rb_data)} alt=""/>
                               <div className="con-num">{returnList(rb_data).map((item, index) => (
                                <span key={index}>{item}</span>
                            ))}</div>
                            </div>
                            <div>
                                 {returnTtaumationType(lf_tyre.trauma)&&returnTtaumationType(rb_tyre.trauma)[0]!=='trauma_normal'? returnTtaumationType(rb_tyre.trauma).map((item, index)=>(
                                    <div  key={index}><input  type="checkbox"   checked="checked" readOnly={true}/> <span style={{color:'#0075ff'}}>{item}</span></div>
                                 )) : <div  style={{color:'green'}}>{intl.get('trauma_normal')}</div>}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }


};

export default Dillwear;