import React, {Component} from 'react';
import "./Cornach.scss"
import Cormach_header from "../../../components/Cormach_header";
import CarInspection from "../../../components/CarInspection";
import intl from "react-intl-universal";
import Traumatism from "../../../components/Traumatism";
import CormachWear from "../../../components/CormachWear";
import {Button} from "antd";


class Cormach extends Component {
    constructor(props) {
        super(props);
        this.state = {report: null};
        // intl.options.currentLocale = 'lt'
    }

    componentDidMount() {
        this.getRecordDetail();
    }

    // 获取检测报告详情
    getRecordDetail() {
        global.Api.getReportsEmail(this.props.match.params.uuid).then(res => {
            if (res.data.code === 0) {
                this.setState({
                    report: res.data.data.report
                });
            } else {
                console.log(res.data.message);
            }
        });
    }

    render() {

        if (this.state.report) {
            let {
                logo,
                uuid,
                brake_type,
                car,
                plate_no,
                device_time,
                odometer,
                remark,
                purpose,
                store,
                model_number,
                model_name,
                conclusion,
                suggestion,
                wear_threshold,
                wear_lf_degree,
                wear_rf_degree,
                wear_lb_degree,
                wear_rb_degree,
                ecc_wear_lf_pos,
                ecc_wear_rf_pos,
                ecc_wear_lb_pos,
                ecc_wear_rb_pos,
                ecc_wear_lf_degree,
                ecc_wear_rf_degree,
                ecc_wear_lb_degree,
                ecc_wear_rb_degree,
                transposition,
                wear_lf_data,
                wear_rf_data,
                wear_lb_data,
                wear_rb_data,
                wear_lf_value,
                wear_rf_value,
                wear_lb_value,
                wear_rb_value,
                tyre_lb,
                tyre_lf,
                tyre_rb,
                tyre_rf,
                trauma_lf_img,
                trauma_rf_img,
                trauma_lb_img,
                trauma_rb_img,
                inspector,
                inspections,
                season,
                mileageUnit,
            } = this.state.report;
            tyre_lb = tyre_lb ? tyre_lb : ",,/R ,trauma_normal,,";
            tyre_lf = tyre_lf ? tyre_lf : ",,/R ,trauma_normal,,";
            tyre_rb = tyre_rb ? tyre_rb : ",,/R ,trauma_normal,,";
            tyre_rf = tyre_rf ? tyre_rf : ",,/R ,trauma_normal,,";
            season = season ? season : {LF:'',RF:'', LB:'', RB:''};
            mileageUnit = mileageUnit ? mileageUnit : 'KM';
            
            let images = {
                trauma_lf_img: trauma_lf_img,
                trauma_rf_img: trauma_rf_img,
                trauma_lb_img: trauma_lb_img,
                trauma_rb_img: trauma_rb_img
            };
            const parserTyre = (tyre) => {
                let info_data = {dot: ' ', brand: ' ', spec: ' ', trauma: ' ', install: ' '};
                let infos = tyre.split(',');
                info_data.dot = infos[0];
                info_data.brand = infos[1];
                info_data.spec = infos[2];
                info_data.trauma = infos[3];
                info_data.install = infos[4];
                info_data.degree = infos[5];
                return info_data
            };

            let WearEccData = {
                tyre_lb:parserTyre(tyre_lb),
                tyre_lf:parserTyre(tyre_lf),
                tyre_rb:parserTyre(tyre_rb),
                tyre_rf:parserTyre(tyre_rf),
                wear_threshold: wear_threshold,
                wear_lf_data:wear_lf_data,
                wear_rf_data:wear_rf_data,
                wear_lb_data:wear_lb_data,
                wear_rb_data:wear_rb_data,
                ecc_wear_lf_pos:ecc_wear_lf_pos,
                ecc_wear_rf_pos:ecc_wear_rf_pos,
                ecc_wear_lb_pos:ecc_wear_lb_pos,
                ecc_wear_rb_pos:ecc_wear_rb_pos,
                ecc_wear_lf_degree: ecc_wear_lf_degree,
                ecc_wear_rf_degree: ecc_wear_rf_degree,
                ecc_wear_lb_degree: ecc_wear_lb_degree,
                ecc_wear_rb_degree: ecc_wear_rb_degree,
                season: season,
                model_number: model_number,
                lf_pressure: inspections.fl_pressure,
                rf_pressure: inspections.fr_pressure,
                lb_pressure: inspections.lb_pressure,
                rb_pressure: inspections.rb_pressure,
            };


            return (
                <div className="cn-report">
                    <Button
                        className="cn-noprint"
                        onClick={() => {
                            window.print();
                        }}
                    >
                        {intl.get("print")}
                    </Button>
                    <div className="cn-card">
                        <div>
                            <Cormach_header data={{
                            logo: logo,
                            store_name: store.name,
                            store_address: store.address,
                            device_time: device_time,
                            plate: plate_no,
                            purpose: purpose,
                            checker: inspector,
                            odometer: odometer,
                            remark: remark,
                            mileageUnit: mileageUnit,
                            }}/>
                        </div>
                       <div>
                       {inspections && inspections.washing_liquid && <CarInspection inspections={inspections}/>}
                       </div>
                       <div> <CormachWear data={WearEccData}/></div>
                        <div>
                            <Traumatism data={{
                            tyre_lb: parserTyre(tyre_lb),
                            tyre_lf: parserTyre(tyre_lf),
                            tyre_rb: parserTyre(tyre_rb),
                            tyre_rf: parserTyre(tyre_rf),
                            images: images
                        }}/>
                        </div>
                    </div>
                </div>
            )
        } else return (<div></div>)
    }
}

export default Cormach;