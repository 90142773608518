import React from "react";

export default {
  'login': 'ล็อกอิน',
  'input_name': 'โปรดป้อนชื่อผู้ใช้',
  'input_password': 'โปรดป้อนรหัสผ่าน',
  'login_su': 'ล็อกอินเรียบร้อยแล้ว',
  'role': 'ตัวละคร',
  'username': 'ชื่อผู้ใช้',
  'password': 'รหัสผ่าน',

  'search_btn': 'search',
  'store': 'ชื่อร้านค้า',
  'number': 'หมายเลข',
  'device_time': 'ตรวจสอบเวลา',
  'wear_co': 'การสึกหรอ (ด้านหน้าซ้าย - ด้านหน้า - ด้านหลังซ้าย - ด้านหลัง - ด้านหลัง - ด้านขวา)',
  'ecc_co': 'กรณีการบดแบบเบ้ (ด้านหน้าซ้าย - ด้านหน้า - ด้านหลังซ้าย - ด้านหลัง - ด้านขวา)',
  'plate_no': 'เลขทะเบียน',
  'cus_ph': 'ข้อมูลติดต่อ',
  'car_type': 'ประเภทรถ',
  'operation': 'ปฏิบัติการ',
  'view': 'ดูรายละเอียด',
  'manufacturer': 'ผู้ผลิต',
  'porduced_year': 'วัน ที่ผลิต',
  'reports': 'Inspection Reports',
  'setting': 'System Setting',
  'print': 'พิมพ์ (หากต้องการตรวจสอบกราฟิกพื้นหลัง)',
  'store_name': 'บริการลูกค้า',
  'address': 'ที่อยู่ประตู',
  'model': 'ประเภทรถ',
  'mileage': 'ระยะเวลา',
  'device': 'ตรวจสอบอุปกรณ์',
  'remark': 'หมายเหตุ',
  'purpose': 'ถึงจุดประสงค์ของร้าน',
  're': 'คำแนะนำในการตรวจสอบ',
  'color_flag': 'การแสดงตัวสี',
  'wear': 'การสึกหรอโดยรวม',
  'brake': 'แจ้งเตือนระยะห่างเบรคปัจจุบัน',
  'ecc': 'การสึกหรออย่างผิดปกติ',
  'lf': 'ล้อหน้าด้านซ้าย',
  'lr': 'ล้อหลังด้านซ้าย',
  'rf': 'ล้อหน้าด้านขวา',
  'rr': 'ล้อหลังด้านขวา',
  'untest': 'ยางไม่ได้ตรวจสอบ',
  'low': 'อยู่ในสภาพดี',
  'middle': 'ตำแหน่ง:',
  'high': 'เปลี่ยนใหม่ทันที',
  'avg': 'ค่าเฉลี่ย',
  'spec': "รายละเอียด",
  'brand': 'ยี่ห้อ',
  'ecc_avg': 'ลายดอกไม้ก็ดูไม่เท่ากัน',
  'trauma': 'รูปลักษณ์ยางรถ',
  'zc': 'ปกติ',
  'image': 'Thai',
  'qr': 'ตรวจสอบระยะห่างระยะไกลเพื่อดูรายงาน',
  'model_number': 'ตรวจสอบยางรถยนต์มือถือ',
  'purp_wash': 'ล้างรถความงาม',
  'purp_alignment': 'ตำแหน่งสี่ล้อ',
  'purp_replace': 'เปลี่ยนยาง',
  'purp_maintain': 'ซ่อมบำรุง',
  'warn': 'เตือนความจำ: เนื่องจากการสึกหรอของยางในปัจจุบันอาจทำให้ระยะเบรคเพิ่มขึ้น',
  'trauma_normal': 'ปกติ',
  'trauma_bulge': 'กระเป๋า',
  'trauma_aging': 'แก่',
  'damage': 'บาดเจ็บสาหัสรูปภาพ',
  'dill-re': 'ตรวจสอบรายงาน',

  'txt_lf_outworn': 'ยางด้านหน้าด้านซ้ายมีการสึกหรอมากกว่า ด้านใน',
  'txt_lf_innerworn': 'ยางด้านหน้าด้านซ้ายมีการสึกหรอมากกว่า ด้านนอก',
  'txt_lf_bothsideworn': 'ยางด้านหน้าด้านซ้ายมีการสึกหรอมากกว่า ตรงกลาง',
  'txt_lf_centreworn': 'ตรงกลางของยางด้านหน้าซ้ายมีการสึกหรอทั้งสองด้าน',
  'txt_lb_outworn': 'ยางด้านหลังด้านข้างมีการสึกหรอมากกว่า ด้านใน',
  'txt_lb_innerworn': 'ด้านหลังของยางด้านในทรุดหนักกว่า ด้านนอก',
  'txt_lb_bothsideworn': 'ยางหลังซ้ายมีการสึกหรอมากกว่า ตรงกลาง',
  'txt_lb_centreworn': 'ตรงกลางของยางด้านหลังมีการสึกหรอทั้งสองด้าน',
  'txt_rb_outworn': 'ยางด้านหลังด้านขวามีการสึกหรอมากกว่า ด้านใน',
  'txt_rb_innerworn': 'ด้านหลังของยางด้านในมีการสึกหรอมากกว่า ด้านนอก',
  'txt_rb_bothsideworn': 'ยางด้านหลังด้านขวามีการสึกหรอกลาง',
  'txt_rb_centreworn': 'ตรงกลางของยางด้านขวามีการสึกหรอทั้งสองด้าน',
  'txt_rf_outworn': 'ยางด้านหน้าด้านขวามีการสึกหรอมากกว่า ด้านใน',
  'txt_rf_innerworn':  'ยางด้านหน้าด้านขวามีการสึกหรอมากกว่า ด้านนอก',
  'txt_rf_bothsideworn': 'ยางด้านหน้าด้านขวามีการสึกหรอกลาง',
  'txt_rf_centreworn': 'ตรงกลางของยางด้านหน้าขวามีการสึกหรอทั้งสองด้าน' ,
  'txt_ecc_abnormal': 'ยางรถบดละเอียดผิดปกติ',

  'txt_tire_need_replace':          'ยางเสื่อมสภาพอย่างรุนแรงโปรดเปลี่ยนใหม่ทันที',
  'txt_tire_boundary':              'ยางเสื่อมสภาพแล้วกรุณาตรวจสอบเป็นประจำ',
  'txt_alignment_chassis':          'แนะนำให้ตรวจสอบตำแหน่งสี่ล้อเพิ่มเติม',
  'txt_wear_uniform':               'ยางเสื่อมสภาพค่อนข้างดี',
  'txt_do_further_check':           'โปรดตรวจสอบเพิ่มเติม',
  'txt_uneven_check':                'ยางเสื่อมสภาพผิดปกติแนะนำให้เคลื่อน ที่สมดุล และตำแหน่งสี่ล้อ',
  'txt_tire_rotation':              'แนะนำให้เปลี่ยนยาง',
  'txt_wear_normal':                'ยางแบนปกติ',
  'txt_tire':                       'ยางรถ',

  'time':'โปรดเลือกเวลา',
  'today':'วันนี้',
  'yesterday':'เมื่อวานนี้',
  'week':'สัปดาห์นี้',
  'month':'เดือนนี้',
  'last_month':'เดือนก่อน',
  'year':'ปีนี้',
  'language':'โปรดเลือกภาษา',
  'zh':'中文',
  'en':'English',
  'ma':'Bahasa Melayu',
  'ge':'Deutsch',
  'fr':'Français',
  'lt':'Italiano',
  'sp':'Español',
  'tw':'繁體中文',
  'jp':'にほんご',
  'po':'Polski',
  'por':'Português',
  'thai':'ข้อความ',
  'viet':'Việt nam',
  'download': 'ดาวน์โหลดรายงาน',
  'advise': 'คำแนะนำ: ยางมีปัญหาการรั่วไหลแนะนำให้ตรวจสอบเพิ่มเติม',

  'txt_tire_age_5': 'ยางหมดอายุเกิน 5 ปีแนะนำให้เปลี่ยนยาง;',
  'txt_tire_age_3': 'ยางหมดอายุมากกว่า 3 ปีให้ตรวจสอบเป็นประจำ;',
  'txt_tire_age_ok': 'อายุยาง ที่ดี;;',
  'txt_tire_trauma_check': 'มีอาการบาดเจ็บ ที่หน้ายางแนะนำให้ตรวจสอบเพิ่มเติม;',
  'txt_install_abnormal_check': 'ยืนยันการประมวลผลยางรถยนต์;',
  'txt_tire_appearance_ok': 'ยางดูดี;;',
  'txt_pinnate_check': 'ยางมีรอยครูด และแนะนำให้เคลื่อน ที่สมดุล และตรวจสอบระบบสี่ล้อ;',
  'trauma_lacerate': 'รอยแยก',
  'trauma_dropblock': 'บล็อก',
  'trauma_puncture': 'เจาะรู',
  'trauma_scratch': 'รอยขีดข่วน',
  'trauma_pinnate': 'การสึกหรอของสี',
  'txt_tire_age': 'อายุยางรถ',
  'txt_tire appearance': 'รูปลักษณ์ยางรถ',
  'tire_installed': 'ติดตั้งยางรถ',
  'abnormal': 'เข้า และออก',
  'endurance': 'การประมาณการของระยะทางยาง',
  'endurance-warn': 'ระยะทาง ที่เหลืออยู่คือ {number}km',
  'endurance-zero': 'ยางมีขีด จำกัด โปรดเปลี่ยนใหม่ทันที',
  'estimated_value': 'ประมาณค่าล่วงหน้า',
  'abrasion': 'การสึกหรอ',
  'remanent': 'เวลา ที่เหลือในการใช้ยาง',
  'useful_life': 'การประมาณอายุของยาง',
  'dot_explain': 'ระยะเวลาการผลิตยาง',
  'unit_km': 'หน่วย:',
  'unit_year': 'หน่วย:',
  'dot_no': 'ไม่ได้รับเวลาการผลิต ที่ถูกต้อง',
  'made_time': 'เวลาผลิต:',
  'exceed_five': 'ยางมีอายุมากกว่า 5 ปี',
  'inspector': 'ช่างเทคนิค',
  'txt_install_check': 'ติดตั้งการตรวจสอบ',
  'txt_install_ok': 'ติดตั้งปกติ',
  'txt_pinnate_wear': 'การสึกหรอของสี',

  'LFtxt_tire_need_replace': 'ยางเสื่อมสภาพอย่างรุนแรงแนะนำให้เปลี่ยนยาง',
  'RFtxt_tire_need_replace': 'ยางเสื่อมสภาพอย่างรุนแรงแนะนำให้เปลี่ยนยาง',
  'LBtxt_tire_need_replace': 'ยางเสื่อมสภาพอย่างรุนแรงแนะนำให้เปลี่ยนยาง',
  'RBtxt_tire_need_replace': 'ยางเสื่อมสภาพอย่างรุนแรงแนะนำให้เปลี่ยนยาง',
  'FFtxt_tire_need_replace': 'ยางเสื่อมสภาพอย่างรุนแรงแนะนำให้เปลี่ยนยาง',
  'BBtxt_tire_need_replace': 'ยางเสื่อมสภาพอย่างรุนแรงแนะนำให้เปลี่ยนยาง',


  'txt_pattern_wear': 'การสึกหรออย่างผิดปกติ',
  'txt_uneven_center': 'ยางเสื่อมสภาพผิดปกติระหว่า งล้อแนะนำให้ตรวจสอบแรงดันยาง',
  'LFtxt_uneven_center': 'ยางเสื่อมสภาพผิดปกติระหว่า งล้อแนะนำให้ตรวจสอบแรงดันยาง',
  'RFtxt_uneven_center': 'ยางเสื่อมสภาพผิดปกติระหว่า งล้อแนะนำให้ตรวจสอบแรงดันยาง',
  'LBtxt_uneven_center': 'ยางเสื่อมสภาพผิดปกติระหว่า งล้อแนะนำให้ตรวจสอบแรงดันยาง',
  'RBtxt_uneven_center': 'ยางเสื่อมสภาพผิดปกติระหว่า งล้อแนะนำให้ตรวจสอบแรงดันยาง',
  'FFtxt_uneven_center': 'ยางเสื่อมสภาพผิดปกติระหว่า งล้อแนะนำให้ตรวจสอบแรงดันยาง',
  'BBtxt_uneven_center': 'ยางเสื่อมสภาพผิดปกติระหว่า งล้อแนะนำให้ตรวจสอบแรงดันยาง',

  'txt_uneven_out': 'ยางเสื่อมสภาพผิดปกติแนะนำให้ตรวจสอบตำแหน่งล้อสี่ล้อ และแรงดันยาง',
  'LFtxt_uneven_out': 'ยางเสื่อมสภาพผิดปกติแนะนำให้ตรวจสอบตำแหน่งล้อสี่ล้อ และแรงดันยาง',
  'RFtxt_uneven_out': 'ยางเสื่อมสภาพผิดปกติแนะนำให้ตรวจสอบตำแหน่งล้อสี่ล้อ และแรงดันยาง',
  'LBtxt_uneven_out': 'ยางเสื่อมสภาพผิดปกติแนะนำให้ตรวจสอบตำแหน่งล้อสี่ล้อ และแรงดันยาง',
  'RBtxt_uneven_out': 'ยางเสื่อมสภาพผิดปกติแนะนำให้ตรวจสอบตำแหน่งล้อสี่ล้อ และแรงดันยาง',
  'FFtxt_uneven_out': 'ยางเสื่อมสภาพผิดปกติแนะนำให้ตรวจสอบตำแหน่งล้อสี่ล้อ และแรงดันยาง',
  'BBtxt_uneven_out': 'ยางเสื่อมสภาพผิดปกติแนะนำให้ตรวจสอบตำแหน่งล้อสี่ล้อ และแรงดันยาง',

  'LFtxt_pinnate_check':'ยางมีรอยครูด และคำแนะนำในการเคลื่อน ที่สมดุล และการตรวจสอบระบบสี่ล้อ',
  'RFtxt_pinnate_check':'ยางมีรอยครูด และคำแนะนำในการเคลื่อน ที่สมดุล และการตรวจสอบระบบสี่ล้อ',
  'LBtxt_pinnate_check':'ยางมีรอยครูด และคำแนะนำในการเคลื่อน ที่สมดุล และการตรวจสอบระบบสี่ล้อ',
  'RBtxt_pinnate_check':'ยางมีรอยครูด และคำแนะนำในการเคลื่อน ที่สมดุล และการตรวจสอบระบบสี่ล้อ',
  'FFtxt_pinnate_check':'ยางมีรอยครูด และคำแนะนำในการเคลื่อน ที่สมดุล และการตรวจสอบระบบสี่ล้อ',
  'BBtxt_pinnate_check':'ยางมีรอยครูด และคำแนะนำในการเคลื่อน ที่สมดุล และการตรวจสอบระบบสี่ล้อ',

  'txt_uneven_inside': 'ความผิดปกติของยางล้อรถแนะนำให้ตรวจสอบตำแหน่งล้อสี่ล้อ และแรงดันยาง',
  'LFtxt_uneven_inside': 'ความผิดปกติของยางล้อรถแนะนำให้ตรวจสอบตำแหน่งล้อสี่ล้อ และแรงดันยาง',
  'RFtxt_uneven_inside': 'ความผิดปกติของยางล้อรถแนะนำให้ตรวจสอบตำแหน่งล้อสี่ล้อ และแรงดันยาง',
  'LBtxt_uneven_inside': 'ความผิดปกติของยางล้อรถแนะนำให้ตรวจสอบตำแหน่งล้อสี่ล้อ และแรงดันยาง',
  'RBtxt_uneven_inside': 'ความผิดปกติของยางล้อรถแนะนำให้ตรวจสอบตำแหน่งล้อสี่ล้อ และแรงดันยาง',
  'FFtxt_uneven_inside': 'ความผิดปกติของยางล้อรถแนะนำให้ตรวจสอบตำแหน่งล้อสี่ล้อ และแรงดันยาง',
  'BBtxt_uneven_inside': 'ความผิดปกติของยางล้อรถแนะนำให้ตรวจสอบตำแหน่งล้อสี่ล้อ และแรงดันยาง',

  'FFtxt_uneven_check': 'ยางเสื่อมสภาพผิดปกติแนะนำให้เคลื่อน ที่สมดุล และตำแหน่งสี่ล้อ',
  'BBtxt_uneven_check': 'ยางเสื่อมสภาพผิดปกติแนะนำให้เคลื่อน ที่สมดุล และตำแหน่งสี่ล้อ',


  'LFtxt_tire_age_5': 'ยางหมดอายุเกิน 5 ปีแนะนำให้เปลี่ยนยาง',
  'RFtxt_tire_age_5': 'ยางหมดอายุเกิน 5 ปีแนะนำให้เปลี่ยนยาง',
  'LBtxt_tire_age_5': 'ยางหมดอายุเกิน 5 ปีแนะนำให้เปลี่ยนยาง',
  'RBtxt_tire_age_5': 'ยางหมดอายุเกิน 5 ปีแนะนำให้เปลี่ยนยาง',
  'FFtxt_tire_age_5': 'ยางหมดอายุเกิน 5 ปีแนะนำให้เปลี่ยนยาง',
  'BBtxt_tire_age_5': 'ยางหมดอายุเกิน 5 ปีแนะนำให้เปลี่ยนยาง',

  'txt_appearance_check':'มีร่องรอยการบาดเจ็บของยางโปรดยืนยันการซ่อมแซม',
  'LFtxt_appearance_check':'มีร่องรอยการบาดเจ็บของยางโปรดยืนยันการซ่อมแซม',
  'RFtxt_appearance_check':'มีร่องรอยการบาดเจ็บของยางโปรดยืนยันการซ่อมแซม',
  'LBtxt_appearance_check':'มีร่องรอยการบาดเจ็บของยางโปรดยืนยันการซ่อมแซม',
  'RBtxt_appearance_check':'มีร่องรอยการบาดเจ็บของยางโปรดยืนยันการซ่อมแซม',
  'FFtxt_appearance_check':'มีร่องรอยการบาดเจ็บของยางโปรดยืนยันการซ่อมแซม',
  'BBtxt_appearance_check':'มีร่องรอยการบาดเจ็บของยางโปรดยืนยันการซ่อมแซม',


  'LFtxt_install_abnormal_check':'ยืนยันการประมวลผลยางรถยนต์',
  'RFtxt_install_abnormal_check':'ยืนยันการประมวลผลยางรถยนต์',
  'LBtxt_install_abnormal_check':'ยืนยันการประมวลผลยางรถยนต์',
  'RBtxt_install_abnormal_check':'ยืนยันการประมวลผลยางรถยนต์',
  'FFtxt_install_abnormal_check':'ยืนยันการประมวลผลยางรถยนต์',
  'BBtxt_install_abnormal_check':'ยืนยันการประมวลผลยางรถยนต์',

  'txt_uneven_bothside': 'ยางเสื่อมสภาพผิดปกติแนะนำให้ตรวจสอบตำแหน่งล้อสี่ล้อ และแรงดันยาง',
  'LFtxt_uneven_bothside': 'ยางเสื่อมสภาพผิดปกติแนะนำให้ตรวจสอบตำแหน่งล้อสี่ล้อ และแรงดันยาง',
  'RFtxt_uneven_bothside': 'ยางเสื่อมสภาพผิดปกติแนะนำให้ตรวจสอบตำแหน่งล้อสี่ล้อ และแรงดันยาง',
  'LBtxt_uneven_bothside': 'ยางเสื่อมสภาพผิดปกติแนะนำให้ตรวจสอบตำแหน่งล้อสี่ล้อ และแรงดันยาง',
  'RBtxt_uneven_bothside': 'ยางเสื่อมสภาพผิดปกติแนะนำให้ตรวจสอบตำแหน่งล้อสี่ล้อ และแรงดันยาง',
  'FFtxt_uneven_bothside': 'ยางเสื่อมสภาพผิดปกติแนะนำให้ตรวจสอบตำแหน่งล้อสี่ล้อ และแรงดันยาง',
  'BBtxt_uneven_bothside': 'ยางเสื่อมสภาพผิดปกติแนะนำให้ตรวจสอบตำแหน่งล้อสี่ล้อ และแรงดันยาง',

  'txt_wear_lookup': 'กรุณาตรวจสอบการสึกหรอของยางด้วย',
  'LFtxt_wear_lookup': 'กรุณาตรวจสอบการสึกหรอของยางด้วย',
  'RFtxt_wear_lookup': 'กรุณาตรวจสอบการสึกหรอของยางด้วย',
  'LBtxt_wear_lookup': 'กรุณาตรวจสอบการสึกหรอของยางด้วย',
  'RBtxt_wear_lookup': 'กรุณาตรวจสอบการสึกหรอของยางด้วย',
  'FFtxt_wear_lookup': 'กรุณาตรวจสอบการสึกหรอของยางด้วย',
  'BBtxt_wear_lookup': 'กรุณาตรวจสอบการสึกหรอของยางด้วย',

  'txt_appearance_lookup': 'กรุณาตรวจสอบสภาพยางด้วยค่ะ',
  'LFtxt_appearance_lookup': 'กรุณาตรวจสอบสภาพยางด้วยค่ะ',
  'RFtxt_appearance_lookup': 'กรุณาตรวจสอบสภาพยางด้วยค่ะ',
  'LBtxt_appearance_lookup': 'กรุณาตรวจสอบสภาพยางด้วยค่ะ',
  'RBtxt_appearance_lookup': 'กรุณาตรวจสอบสภาพยางด้วยค่ะ',
  'FFtxt_appearance_lookup': 'กรุณาตรวจสอบสภาพยางด้วยค่ะ',
  'BBtxt_appearance_lookup': 'กรุณาตรวจสอบสภาพยางด้วยค่ะ',

  'txt_serious_trauma_check': 'ยางรถยนต์ได้รับบาดเจ็บสาหัสแนะนำให้เปลี่ยนยาง',
  'LFtxt_serious_trauma_check': 'ยางรถยนต์ได้รับบาดเจ็บสาหัสแนะนำให้เปลี่ยนยาง',
  'RFtxt_serious_trauma_check': 'ยางรถยนต์ได้รับบาดเจ็บสาหัสแนะนำให้เปลี่ยนยาง',
  'LBtxt_serious_trauma_check': 'ยางรถยนต์ได้รับบาดเจ็บสาหัสแนะนำให้เปลี่ยนยาง',
  'RBtxt_serious_trauma_check': 'ยางรถยนต์ได้รับบาดเจ็บสาหัสแนะนำให้เปลี่ยนยาง',
  'FFtxt_serious_trauma_check': 'ยางรถยนต์ได้รับบาดเจ็บสาหัสแนะนำให้เปลี่ยนยาง',
  'BBtxt_serious_trauma_check': 'ยางรถยนต์ได้รับบาดเจ็บสาหัสแนะนำให้เปลี่ยนยาง',

  'purp_tireservice':'บริการยาง',
  'purp_vehicle_inspection':'ตรวจสอบรถ',
  'merchant': 'เม:',
  'manager': 'หัวหน้าร้าน',
  'assistant': 'เสมียน?',

  'txt_chassis_check': 'ตรวจสอบข้อมูล',
  'txt_chassis_ok': 'ตรวจสอบดี',
  'LFtxt_chassis_ok': 'ตรวจสอบดี',
  'RFtxt_chassis_ok': 'ตรวจสอบดี',
  'LBtxt_chassis_ok': 'ตรวจสอบดี',
  'RBtxt_chassis_ok': 'ตรวจสอบดี',
  'FFtxt_chassis_ok': 'ตรวจสอบดี',
  'BBtxt_chassis_ok': 'ตรวจสอบดี',
  'txt_chassis_oil': 'ลดความดันน้ำมันรั่วแนะนำให้ทำการยืนยันเพิ่มเติมหากจำเป็นโปรดดำเนินการแทน',
  'LFtxt_chassis_oil': 'ลดความดันน้ำมันรั่วแนะนำให้ทำการยืนยันเพิ่มเติมหากจำเป็นโปรดดำเนินการแทน',
  'RFtxt_chassis_oil': 'ลดความดันน้ำมันรั่วแนะนำให้ทำการยืนยันเพิ่มเติมหากจำเป็นโปรดดำเนินการแทน',
  'LBtxt_chassis_oil': 'ลดความดันน้ำมันรั่วแนะนำให้ทำการยืนยันเพิ่มเติมหากจำเป็นโปรดดำเนินการแทน',
  'RBtxt_chassis_oil': 'ลดความดันน้ำมันรั่วแนะนำให้ทำการยืนยันเพิ่มเติมหากจำเป็นโปรดดำเนินการแทน',
  'FFtxt_chassis_oil': 'ลดความดันน้ำมันรั่วแนะนำให้ทำการยืนยันเพิ่มเติมหากจำเป็นโปรดดำเนินการแทน',
  'BBtxt_chassis_oil': 'ลดความดันน้ำมันรั่วแนะนำให้ทำการยืนยันเพิ่มเติมหากจำเป็นโปรดดำเนินการแทน',
  'txt_chassis_aging': 'ยางหัวเพลาเก่าแนะนำให้เปลี่ยนใหม่',
  'LFtxt_chassis_aging': 'ยางหัวเพลาเก่าแนะนำให้เปลี่ยนใหม่',
  'RFtxt_chassis_aging': 'ยางหัวเพลาเก่าแนะนำให้เปลี่ยนใหม่',
  'LBtxt_chassis_aging': 'ยางหัวเพลาเก่าแนะนำให้เปลี่ยนใหม่',
  'RBtxt_chassis_aging': 'ยางหัวเพลาเก่าแนะนำให้เปลี่ยนใหม่',
  'FFtxt_chassis_aging': 'ยางหัวเพลาเก่าแนะนำให้เปลี่ยนใหม่',
  'BBtxt_chassis_aging': 'ยางหัวเพลาเก่าแนะนำให้เปลี่ยนใหม่',
  'awd': 'ดาวน์โหลด',

  'depth': 'ความลึกของช่อง',
  'usura': '&ขาว',
  'usura-l': 'ลายดอกไม้ก็ดูไม่เท่ากัน',
  'usura-m': 'รอยขีดข่วนเล็กน้อย',
  'usura-h': 'ความละเอียดรุนแรง',

  'engine-hood': 'ฝาครอบเครื่องยนต์',
  'change-control': 'เปลี่ยนการควบคุม',
  'subtotal': 'ขนาดเล็ก',

  'replace': 'แทน ที่',
  'vedi': 'ค้นหาคำอธิบาย',
  'ok': 'ปกติ',

  'liquid': 'น้ำยาซักฟอก',
  'level-control': 'การควบคุมระดับ',
  'air': 'ระบบปรับอากาศ',
  'last-reload': 'โหลดครั้ง ที่แล้ว',
  'absorber': 'ลดความเร็ว:',
  'check-loss': 'ตรวจสอบการสูญเสีย ที่มองเห็นได้',
  'fluid': 'ตัวกรองความร้อน',
  'lighting': 'แสง',
  'verification': 'ปฏิบัติการนิวเคลียร์',
  'disc': 'หยุด',
  'condition': 'การสึกหรอ',
  'oil': 'ติดเครื่องได้',
  'wiper': 'ที่ปัดน้ำฝน',
  'pads': 'ผ้าเบรก',
  'brake-fluid': 'ของเหลวเบรค',
  'quality-control': 'การควบคุมคุณภาพ',
  'battery': 'แบตเตอรี่',
  'multimetro': 'การทดสอบตารางหลายแบบ',
   'control-quality': 'การควบคุมคุณภาพ',
   'tightening': 'ตัวสำรวจกระชับ',
  'buone': 'ปกติ',
  'lavoro': 'แถว ที่ทำงาน',
  'concordare': 'อนุญาตการแทน ที่',
  'season_summer': 'ยางฤดูร้อน',
  'season_winter': 'ยางฤดูหนาว',
  'wearecc': 'การสึกหรอ&การบด',

  'inspection_list':'เช็ครายการรถ',
  'lights':'แสง',
  'high_beam': 'สูง',
  'low_beam':'แสงระยะใกล้',
  'daytime': 'โคมไฟ',
  'fog_light':'หมอก',
  'break_light':'ไฟเบรค',
  'turn_signals':'เปิดไฟเลี้ยว',
  'backup_lights':'เปลี่ยนไฟหน้า',
  'hazards':'ไฟฉุกเฉิน',
  'glass':'กระจก',
  'windshield':'กระจกหน้า',
  'backglass':'กระจกหลัง',
  'right_glass':'กระจกด้านขวา',
  'left_glass':'กระจกด้านซ้าย',
  'wipers_front':'ที่ปัดน้ำฝน',
  'wipers_rear':'&ขาว',
  'under_hood':'เริ่มต้นกระสวย',
  'horn':'ลำโพง',
  'oil_level':'ระดับน้ำมัน',
  'air_filter':'กรองแบบว่า ง',
  'trans_fluid':'น้ำมันเครื่อง',
  'break_fluid':'น้ำมันเบรค',
  'coolant_level':'ระดับความเย็นของเหลว',
  'windshield_washer_fluid':'น้ำแก้ว',
  'power_steering_fluid':'เปลี่ยนน้ำยา',
  'belts':'เข็มขัด',
  'hoses':'ท่อ',
  'ac_system':'ระบบปรับอากาศ',
  // 'battery':'แบตเตอรี่',
  'under_vehicle':'ถาดด้านล่าง',
  'suspension':'ช่วงล่าง',
  'exhaust':'ท่อไอเสีย',
  'break_lines':'ถนนสายหลัก',
  'oil_drain_plug':'ใส่ ที่อุดท่อน้ำมัน',
  'differential':'ความแตกต่าง',
  'breaks':'เบรค!',
  'le_fr_brake':'เบรคด้านหน้าซ้าย',
  'ri_fr_brake':'เบรคหน้าขวา',
  'le_re_brake':'เบรกด้านหลังซ้าย',
  'ri_re_brake':'เบรกด้านหลังขวา',
  'parking_brake':'ประจำตำแหน่ง',
  'interior':'ภายใน',
  'cabin_filter':'ตัวกรองแบบมีชีวิต',
  'heating':'เครื่องทำความร้อน',
  'interior_lights':'แสงภายใน',
  'fr_defrost':'ลดน้ำหนักก่อน',
  're_defrost':'ลดน้ำหนัก',
  'pressure': 'แรงดันของยาง',
  'nor':'ปกติ',
  'caution':'ระวัง!',
  'attention':'คำเตือน',

  'trauma_eattyre': '吃胎',
  'txt_uneven_eattyre': '轮胎吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'LFtxt_uneven_eattyre': '[左前轮]轮胎吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'RFtxt_uneven_eattyre': '[右前轮]轮胎吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'LBtxt_uneven_eattyre': '[左后轮]轮胎吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'RBtxt_uneven_eattyre': '[右后轮]轮胎吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'FFtxt_uneven_eattyre': '[前轴]轮胎吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'BBtxt_uneven_eattyre': '[后轴]轮胎吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',


  'txt_appearance_eattyre': '轮胎吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'LFtxt_appearance_eattyre': '[左前轮]轮胎吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'RFtxt_appearance_eattyre': '[右前轮]轮胎吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'LBtxt_appearance_eattyre': '[左后轮]轮胎吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'RBtxt_appearance_eattyre': '[右后轮]轮胎吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'FFtxt_appearance_eattyre': '[前轴]轮胎吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'BBtxt_appearance_eattyre': '[后轴]轮胎吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',


  'txt_chassis_eattyre': '轮胎吃胎，建议对四轮定位，悬挂系统进行检查',
  'LFtxt_chassis_eattyre': '[左前轮]轮胎吃胎，建议对四轮定位，悬挂系统进行检查',
  'RFtxt_chassis_eattyre': '[右前轮]轮胎吃胎，建议对四轮定位，悬挂系统进行检查',
  'LBtxt_chassis_eattyre': '[左后轮]轮胎吃胎，建议对四轮定位，悬挂系统进行检查',
  'RBtxt_chassis_eattyre': '[右后轮]轮胎吃胎，建议对四轮定位，悬挂系统进行检查',
  'FFtxt_chassis_eattyre': '[前轴]轮胎吃胎，建议对四轮定位，悬挂系统进行检查',
  'BBtxt_chassis_eattyre': '[后轴]轮胎吃胎，建议对四轮定位，悬挂系统进行检查',

  'txt_uneven_high_eattyre':'轮胎严重吃胎，建议更换轮胎，以及对四轮定位，悬挂系统进行检查',
  'LFtxt_uneven_high_eattyre': '[左前轮]轮胎严重吃胎，建议更换轮胎，以及对四轮定位，悬挂系统进行检查',
  'RFtxt_uneven_high_eattyre': '[右前轮]轮胎严重吃胎，建议更换轮胎，以及对四轮定位，悬挂系统进行检查',
  'LBtxt_uneven_high_eattyre': '[左后轮]轮胎严重吃胎，建议更换轮胎，以及对四轮定位，悬挂系统进行检查',
  'RBtxt_uneven_high_eattyre': '[右后轮]轮胎严重吃胎，建议更换轮胎，以及对四轮定位，悬挂系统进行检查',
  'FFtxt_uneven_high_eattyre': '[前轴]轮胎严重吃胎，建议更换轮胎，以及对四轮定位，悬挂系统进行检查',
  'BBtxt_uneven_high_eattyre': '[后轴]轮胎严重吃胎，建议更换轮胎，以及对四轮定位，悬挂系统进行检查',


  'txt_appearance_high_eattyre':'轮胎严重吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'LFtxt_appearance_high_eattyre': '[左前轮]轮胎严重吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'RFtxt_appearance_high_eattyre': '[右前轮]轮胎严重吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'LBtxt_appearance_high_eattyre': '[左后轮]轮胎严重吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'RBtxt_appearance_high_eattyre': '[右后轮]轮胎严重吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'FFtxt_appearance_high_eattyre': '[前轴]轮胎严重吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',
  'BBtxt_appearance_high_eattyre': '[后轴]轮胎严重吃胎，建议进行动平衡，以及对四轮定位，悬挂系统进行检查',

  'txt_chassis_high_eattyre':'轮胎严重吃胎，建议对四轮定位，悬挂系统进行检查',
  'LFtxt_chassis_high_eattyre': '[左前轮]轮胎严重吃胎，建议对四轮定位，悬挂系统进行检查',
  'RFtxt_chassis_high_eattyre': '[右前轮]轮胎严重吃胎，建议对四轮定位，悬挂系统进行检查',
  'LBtxt_chassis_high_eattyre': '[左后轮]轮胎严重吃胎，建议对四轮定位，悬挂系统进行检查',
  'RBtxt_chassis_high_eattyre': '[右后轮]轮胎严重吃胎，建议对四轮定位，悬挂系统进行检查',
  'FFtxt_chassis_high_eattyre': '[前轴]轮胎严重吃胎，建议对四轮定位，悬挂系统进行检查',
  'BBtxt_chassis_high_eattyre': '[后轴]轮胎严重吃胎，建议对四轮定位，悬挂系统进行检查',

  'txt_chassis_uneven': 'การรั่วไหลของยาง ที่ผิดปกติแนะนำให้ตรวจสอบตำแหน่งสี่ล้อระบบแขวนคอ',

  'com':'ขับรถพาณิชย์',
  'pass':'ใช้รถ',

  // 大车翻译
  'truck': 'รถลาก',
  'mount': 'ไปขึ้นรถ',
  'left': 'ด้านซ้าย',
  'right': 'ขวา',
  'undevice': 'ไม่มีการตรวจสอบ',
  'axle': 'เส้นทแยง',
  'spare': 'สำรอง',
  'nodata': 'ถือเป็นจำนวนมหาศาล',

  'tireplace': 'พื้น ที่ปลอดภัยของยาง',
  'tire_season': '4 ฤดู',
  'tire_others': 'ยางอื่น ๆ',
  'bolt': 'สลักเกลียว',
  'goods_name': 'ชื่อสินค้า',
  'bolt_yes': 'ครับ',
  'bolt_no': 'ไม่',
}
