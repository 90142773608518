import React, {Component} from 'react';
import "./css/OverallInspection.scss";
import Title from "./Title";
import intl from "react-intl-universal";

class OverallInspection extends Component{

    constructor(props) {
        super(props);
        this.state = {
        }
    }



    render() {
     let {battery,
         brake_disc,
         brake_fluid,
         brake_pads,
         conditioning_system,
         engine_oil,
         lighting,
         quality_control,
         radiator_fluid,
         shock_absorber,
         washing_liquid,
         wiper,
         high_beam,
         low_beam,
         daytime,
         fog_light,
         break_light,
         turn_signals,
         hazards,
         windshield,
         back_glass,
         right_side_windows,
         left_side_windows,
         wipers_rear,
         horn,
         oil_level,
         air_filter,
         coolant_level,
         power_steering_fluid,
         belts,
         hoses,
         ac_system,
         suspension,
         exhaust,
         break_lines,
         oil_drain_plug,
         differential,
         fl_break,
         fr_break,
         lb_break,
         rb_break,
         parking_break,
         cabin_filter,
         heating,
         interior_lights,
         f_defrost,
         r_defrost,
         spare_pressure,
         } =  this.props.inspections
     
    
     let returnColor = (statu) => {

         switch (statu) {

             case 'high':
                 return {backgroundColor: 'red'};

             case 'middle':
                 return {backgroundColor: 'yellow'};
             default:
                 return  {backgroundColor: 'green'}



         }

     };

        
        return (
            <div className="overall">
                <div>
                     <Title content= {intl.get('inspection_list')}/>
                </div>
                <div className="explain">
                    <div className="flag-item"><span>{intl.get('nor')}</span><span className="status" style={{backgroundColor: 'green'}}></span></div>
                    <div className="flag-item"><span>{intl.get('caution')}</span><span className="status" style={{backgroundColor: 'yellow'}}></span></div>
                    <div  className="flag-item"><span>{intl.get('attention')}</span><span className="status" style={{backgroundColor: 'red'}}></span></div>
                </div>
                <div className="itemContainer">
                    <div className="largeitem">
                        <span className="smallTitle">{intl.get('lights')}</span>
                        <div className="smallitem">
                            <div className="microitem"><span className="itemtitle">{intl.get('high_beam')}</span><span className="status" style={returnColor(high_beam)}></span></div>
                            <div className="microitem"><span className="itemtitle">{intl.get('low_beam')}</span><span className="status" style={returnColor(low_beam)}></span></div>
                            <div className="microitem"><span className="itemtitle">{intl.get('daytime')}</span><span className="status" style={returnColor(daytime)}></span></div>
                            <div className="microitem"><span className="itemtitle">{intl.get('fog_light')}</span><span className="status" style={returnColor(fog_light)}></span></div>
                            <div className="microitem"><span className="itemtitle">{intl.get('break_light')}</span><span className="status" style={returnColor(break_light)}></span></div>
                            <div className="microitem"><span className="itemtitle">{intl.get('turn_signals')}</span><span className="status" style={returnColor(turn_signals)}></span></div>
                            <div className="microitem"><span className="itemtitle">{intl.get('backup_lights')}</span><span className="status" style={returnColor(break_light)}></span></div>
                            <div className="microitem"><span className="itemtitle">{intl.get('hazards')}</span><span className="status" style={returnColor(hazards)}></span></div>
                        </div>
                    </div>
                    <div className="largeitem">
                        <span className="smallTitle">{intl.get('glass')}</span>
                        <div className="smallitem">
                            <div className="microitem"><span className="itemtitle">{intl.get('windshield')}</span><span className="status" style={returnColor(windshield)}></span></div>
                            <div className="microitem"><span className="itemtitle">{intl.get('backglass')}</span><span className="status" style={returnColor(back_glass)}></span></div>
                            <div className="microitem"><span className="itemtitle">{intl.get('right_glass')}</span><span className="status" style={returnColor(right_side_windows)}></span></div>
                            <div className="microitem"><span className="itemtitle">{intl.get('left_glass')}</span><span className="status" style={returnColor(left_side_windows)}></span></div>
                            <div className="microitem"><span className="itemtitle">{intl.get('wipers_front')}</span><span className="status" style={returnColor(wiper)}></span></div>
                            <div className="microitem"><span className="itemtitle">{intl.get('wipers_rear')}</span><span className="status" style={returnColor(wipers_rear)}></span></div>
                        </div>
                    </div>
                    <div className="largeitem">
                        <span className="smallTitle">{intl.get('under_hood')}</span>
                        <div className="smallitem">
                            <div className="microitem"><span className="itemtitle">{intl.get('horn')}</span><span className="status" style={returnColor(horn)}></span></div>
                            <div className="microitem"><span className="itemtitle">{intl.get('oil_level')}</span><span className="status" style={returnColor(oil_level)}></span></div>
                            <div className="microitem"><span className="itemtitle">{intl.get('air_filter')}</span><span className="status" style={returnColor(air_filter)}></span></div>
                            <div className="microitem"><span className="itemtitle">{intl.get('trans_fluid')}</span><span className="status" style={returnColor(engine_oil)}></span></div>
                            <div className="microitem"><span className="itemtitle">{intl.get('break_fluid')}</span><span className="status" style={returnColor(coolant_level)}></span></div>
                            <div className="microitem"><span className="itemtitle">{intl.get('coolant_level')}</span><span className="status" style={returnColor(battery)}></span></div>
                            <div className="microitem"><span className="itemtitle">{intl.get('power_steering_fluid')}</span><span className="status" style={returnColor(power_steering_fluid)}></span></div>
                            <div className="microitem"><span className="itemtitle">{intl.get('windshield_washer_fluid')}</span><span className="status" style={returnColor(washing_liquid)}></span></div>
                            <div className="microitem"><span className="itemtitle">{intl.get('belts')}</span><span className="status" style={returnColor(belts)}></span></div>
                            <div className="microitem"><span className="itemtitle">{intl.get('hoses')}</span><span className="status" style={returnColor(hoses)}></span></div>
                            <div className="microitem"><span className="itemtitle">{intl.get('ac_system')}</span><span className="status" style={returnColor(ac_system)}></span></div>
                            <div className="microitem"><span className="itemtitle">{intl.get('battery')}</span><span className="status" style={returnColor(battery)}></span></div>
                        </div>
                    </div>
                </div>
                <div className="itemContainer">
                        <div className="largeitem">
                            <span className="smallTitle">{intl.get('under_vehicle')}</span>
                            <div className="smallitem">
                                 <div className="microitem"><span className="itemtitle">{intl.get('suspension')}</span><span className="status" style={returnColor(suspension)} style={returnColor(battery)}></span></div>
                                <div className="microitem"><span className="itemtitle">{intl.get('exhaust')}</span><span className="status" style={returnColor(exhaust)}></span></div>
                                <div className="microitem"><span className="itemtitle">{intl.get('break_lines')}</span><span className="status" style={returnColor(break_lines)}></span></div>
                                <div className="microitem"><span className="itemtitle">{intl.get('oil_drain_plug')}</span><span className="status" style={returnColor(oil_drain_plug)}></span></div>
                                <div className="microitem"><span className="itemtitle">{intl.get('differential')}</span><span className="status" style={returnColor(differential)}></span></div>
                            </div>
                        </div>
                        <div className="largeitem">
                            <span className="smallTitle">{intl.get('breaks')}</span>
                            <div className="smallitem">
                                 <div className="microitem"><span className="itemtitle">{intl.get('le_fr_brake')}</span><span className="status" style={returnColor(fl_break)}></span></div>
                                <div className="microitem"><span className="itemtitle">{intl.get('ri_fr_brake')}</span><span className="status" style={returnColor(fr_break)}></span></div>
                                <div className="microitem"><span className="itemtitle">{intl.get('le_re_brake')}</span><span className="status" style={returnColor(lb_break)}></span></div>
                                <div className="microitem"><span className="itemtitle">{intl.get('ri_re_brake')}</span><span className="status" style={returnColor(rb_break)}></span></div>
                                <div className="microitem"><span className="itemtitle">{intl.get('parking_brake')}</span><span className="status" style={returnColor(parking_break)}></span></div>
                                <div className="microitem"><span className="itemtitle">{intl.get('spare')}</span><span className="status" style={returnColor(spare_pressure)}></span></div>
                            </div>
                        </div>
                        <div className="largeitem">
                            <span className="smallTitle">{intl.get('interior')}</span>
                            <div className="smallitem">
                                 <div className="microitem"><span className="itemtitle">{intl.get('cabin_filter')}</span><span className="status" style={returnColor(cabin_filter)}></span></div>
                                <div className="microitem"><span className="itemtitle">{intl.get('heating')}</span><span className="status" style={returnColor(heating)}></span></div>
                                <div className="microitem"><span className="itemtitle">{intl.get('ac_system')}</span><span className="status" style={returnColor(conditioning_system)}></span></div>
                                <div className="microitem"><span className="itemtitle">{intl.get('interior_lights')}</span><span className="status" style={returnColor(interior_lights)}></span></div>
                                <div className="microitem"><span className="itemtitle">{intl.get('fr_defrost')}</span><span className="status" style={returnColor(f_defrost)}></span></div>
                                <div className="microitem"><span className="itemtitle">{intl.get('re_defrost')}</span><span className="status" style={returnColor(r_defrost)}></span></div>
                            </div>
                        </div >
                   </div>
            </div>
        )
    }

}

export default OverallInspection;



